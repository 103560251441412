const SELECTOR_MAP_STYLE = {
  style: [
    // ACTIVE (being drawn)
    // line stroke
    {
      id: 'gl-draw-line',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'square',
        'line-join': 'bevel'
      },
      paint: {
        'line-color': '#00FFF0',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    // polygon fill
    {
      id: 'gl-draw-polygon-fill',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      paint: {
        'fill-color': '#00FFF0',
        'fill-outline-color': '#478DD4',
        'fill-opacity': 0.1,
      }
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
      id: 'gl-draw-polygon-stroke-active',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
      layout: {
        'line-cap': 'square',
        'line-join': 'bevel'
      },
      paint: {
        'line-color': '#00FFF0',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    // vertex point halos
    // DAN
    {
      id: 'gl-draw-polygon-and-line-vertex-halo-active',
      type: 'circle',
      filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
      paint: {
        'circle-radius': 5,
        'circle-color': '#00FFF0',
        'circle-stroke-color': '#478DD4'
      }
    },
    // vertex points
    // DAN
    {
      id: 'gl-draw-polygon-and-line-vertex-active',
      type: 'circle',
      filter: ['all', ['==', 'meta', 'vertex'], ['==', '$type', 'Point'], ['!=', 'mode', 'static']],
      paint: {
        'circle-radius': 3,
        'circle-color': '#00FFF0',
        'circle-stroke-color': '#478DD4',
        'circle-stroke-width': 1,
      }
    },
    // INACTIVE (static, already drawn)
    // line stroke
    {
      id: 'gl-draw-line-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'LineString'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'square',
        'line-join': 'bevel'
      },
      paint: {
        'line-color': '#00FFF0',
        'line-width': 3
      }
    },
    // polygon fill
    {
      id: 'gl-draw-polygon-fill-static',
      type: 'fill',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      paint: {
        'fill-color': '#00FFF0',
        'fill-outline-color': '#478DD4',
        'fill-opacity': 0.1
      }
    },
    // polygon outline
    {
      id: 'gl-draw-polygon-stroke-static',
      type: 'line',
      filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
      layout: {
        'line-cap': 'square',
        'line-join': 'bevel'
      },
      paint: {
        'line-color': '#00FFF0',
        'line-width': 3
      }
    }
  ]
};

export default SELECTOR_MAP_STYLE;
