<div class="user-form-container">
  <form [formGroup]="form">
    <!-- first row -->
    <div class="section-title">
      {{'userDetails' | translate}}
    </div>
    <hr class="divider">
    <div class="row general-form-container">
      <!-- userid and password -->
      <div class="details-form-container col-md-6" fxLayout="column" fxLayoutAlign="start start">
        <!-- User ID -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'userid' | translate}}</span>
          <div class="col-md-8 form-field">
            <input class="text-input" formControlName="userid" type="text" placeholder="{{'userName' | translate}}"
              (keyup)="checkEntityidAvailablityAndLowerCase($event)" autucomplete="off" name="hi">
            <gk-form-validation *ngIf="form.controls['userid'].errors?.required" [validationText]="'fieldRequire'">
            </gk-form-validation>
            <gk-form-validation *ngIf="form.controls['userid'].errors?.exist" [validationText]="'idAlreadyExist'">
            </gk-form-validation>
            <gk-form-validation *ngIf="form.controls['userid'].errors?.minlength "
              [validationText]="'userMinLengthError'"></gk-form-validation>
            <gk-form-validation *ngIf="form.controls['userid'].errors?.specialChartsValidator "
              [validationText]="'specialChartsError'"></gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon" *ngIf="!form.controls['userid'].errors">
          </div>
        </div>
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'password' | translate}}</span>
          <div class="col-md-8 form-field">
            <input class="text-input" formControlName="password" type="password"
              placeholder="{{'password' | translate}}" autucomplete="off" name="bye" (focus)="passwordFormFocused(true)"
              (focusout)="passwordFormFocused(false)" (input)="passwordTyped()">
            <gk-form-validation
              *ngIf="form.controls['password'].errors && !form.controls['password'].errors?.threeSameCharts"
              [showTooltip]="passwordTooltip" [validationText]="'passwordError'"></gk-form-validation>
            <gk-form-validation *ngIf="form.controls['password'].errors?.threeSameCharts"
              [validationText]="'threeSameChartsError'"></gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon"
              [hidden]="form.controls['password'].errors">
          </div>
        </div>
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'confirmPassword' | translate}}</span>
          <div class="col-md-8 form-field">
            <input class="text-input" formControlName="confirmPassword" type="password"
              placeholder="{{'confirmPassword' | translate}}"
              [attr.disabled]="form.controls['password'].errors ? '' : null">
            <gk-form-validation *ngIf="form.controls['confirmPassword'].errors"
              [validationText]="'confirmPasswordError'"></gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon"
              [hidden]="form.controls['confirmPassword'].errors">
          </div>
        </div>
        <!-- type of user  -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start start">
          <span class="input-label col-md-4">{{'typeOfUser' | translate}}</span>
          <div class="col-md-8 form-field">
            <div class="select">
              <mat-select [(ngModel)]="user.type" [ngModelOptions]="{standalone: true}" panelClass="user-type-select"
                (selectionChange)="onTypeChanged($event)">
                <mat-select-trigger class="select-value">
                  <svg-icon [src]="'assets/img/svg/' + (selectedUserType?.iconName)" class="svg-icon"
                    [svgStyle]="{'width.px':22, 'height.px': 16, 'fill': '#000000'}">
                  </svg-icon>
                  {{selectedUserType?.translationKey | translate}}
                </mat-select-trigger>
                <mat-option *ngFor="let userType of userTypes" [value]="userType.value" class="typeOption"
                  (mouseover)="userType.isHover = true" (mouseout)="userType.isHover = false">
                  <svg-icon [src]="'assets/img/svg/' + userType.iconName" class="svg-icon"
                    [svgStyle]="{'width.px':22, 'height.px': 16, 'fill': userType.isHover || user.type === userType.value ? '#0dcbcb' : '#8a96aa'}">
                  </svg-icon>
                  <span [ngStyle]="{'color': userType.value === selectedUserType.value ?  '#0dcbcb' : '#8a96aa'}">
                    {{userType.translationKey | translate}}
                  </span>
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>

      <!-- profile -->
      <div class="details-form-container col-md-6">
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'fullName' | translate}}</span>
          <div class="col-md-7 form-field">
            <input class="text-input" formControlName="name" type="text" placeholder="{{'fullName' | translate}}">

            <gk-form-validation
              *ngIf="form.controls['name'].errors?.required && !form.controls['name'].errors?.specialChartsValidator"
              [validationText]="'fieldRequire'"></gk-form-validation>

            <gk-form-validation
              *ngIf="form.controls['name'].errors?.userBadNamesValidator && !form.controls['name'].errors?.specialChartsValidator "
              [validationText]="'userBadNamesError'"></gk-form-validation>

            <gk-form-validation *ngIf="form.controls['name'].errors?.specialChartsValidator "
              [validationText]="'specialChartsError'"></gk-form-validation>

            <img src="assets/img/ic_installed@2x.png" class="validated-icon" [hidden]="form.controls['name'].errors">
          </div>
        </div>
        <!-- <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'lastName' | translate}}</span>
          <div class="col-md-7 form-field">
            <input class="text-input" formControlName="lastName" type="text" placeholder="{{'lastName' | translate}}">
            <gk-form-validation *ngIf="form.controls['lastName'].errors?.required" [validationText]="'fieldRequire'"></gk-form-validation>
            <gk-form-validation *ngIf="form.controls['lastName'].errors?.userBadNamesValidator " [validationText]="'badNameAreNotAllowed'"></gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon" [hidden]="form.controls['lastName'].errors">
          </div>
        </div> -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'email' | translate}}</span>
          <div class="col-md-7 form-field">
            <input class="text-input" formControlName="email" type="text" placeholder="{{'email' | translate}}"
              (keyup)="checkEntityidAvailablityAndLowerCase($event, true)">
            <gk-form-validation *ngIf="form.controls['email'].errors?.exist" [validationText]="'emailAlreadyExist'">
            </gk-form-validation>
            <gk-form-validation *ngIf="form.controls['email'].errors?.required" [validationText]="'twoFactorRequired'">
            </gk-form-validation>
            <gk-form-validation *ngIf="form.controls['email'].value && form.controls['email'].errors?.email "
              [validationText]="'emailIsNotValid'"></gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon"
              [hidden]="!form.controls['email'].value || form.controls['email'].errors">
          </div>
        </div>
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'picture' | translate}}</span>
          <div class="col-md-7 form-field">
            <div class="image-container" fxLayout="row" fxLayoutAlign="start center">
              <gk-image-uploader [image]="user?.extras?.picture" (onImageSelected)="onImageSelected($event)"
                (onImageDeleted)="onImageDeleted()"></gk-image-uploader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- second row -->
    <div class="section-title">
      {{'teamsAndPermissions' | translate}}
    </div>
    <hr class="divider">
    <div class="row general-form-container">
      <!-- Team select -->
      <div class="details-form-container col-md-6" fxLayout="column" fxLayoutAlign="start start">
        <gk-items-selector [mode]="2" style="width: 100%;" (onItemsSelected)="onSelectedTeams($event)"
          [preItems]="user.teams"></gk-items-selector>
        <gk-form-validation class="teams-validations" *ngIf="!selectedTeams.length" [validationText]="'teamRequired'">
        </gk-form-validation>
        <img src="assets/img/ic_installed@2x.png" class="teams-validated-icon" [hidden]="!selectedTeams.length">
      </div>
      <!-- Permissions -->
      <div class="details-form-container col-md-6">
        <div class="form-group" fxLayout="column" fxLayoutAlign="start start">
          <div class="slide-container" fxLayout="row" fxLayoutAlign="start center">
            <span class="input-label col-md-7">{{'monitor' | translate}}</span>
            <div class="col-md-2 form-field">
              <mat-slide-toggle formControlName="monitor"></mat-slide-toggle>
            </div>
          </div>
          <div class="slide-container" fxLayout="row" fxLayoutAlign="start center">
            <span class="input-label col-md-7">{{'controlPanel' | translate}}</span>
            <div class="col-md-2 form-field">
              <mat-slide-toggle formControlName="controlPanel"></mat-slide-toggle>
            </div>
          </div>
        </div>
        <!-- Devices -->
        <div class="devices-container col-md-8" *ngIf="mode == GkMode.Update">
          <h3 mat-subheader>{{'devices' | translate}}</h3>
          <mat-divider></mat-divider>
          <mat-list>
            <mat-list-item *ngFor="let device of user.devices" [hidden]="!user.devices?.length">
              <div class="icon-container" [ngStyle]="{'line-height' : device.lastSeen ? '4' : '0' }">
                <svg-icon [src]="getDeviceIcon(device.useragent)" class="svg-icon" matTooltip="{{ device.useragent }}"
                  matTooltipPosition="left"></svg-icon>
              </div>
              <div class="text-container">
                <h4 mat-line>{{device.name}}</h4>
                <p mat-line *ngIf="device.lastSeen"> {{device.lastSeen | date: 'fullDate'}} </p>
              </div>
              <div class="delete-button" (click)="openDeleteModal(device)">
                <mat-icon>close</mat-icon>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </form>
  <hr class="divider">
  <div class="row" fxLayout="row" fxLayoutAlign="end center">
    <div class="changes-warning" *ngIf="formChanges">
      <mat-icon>warning</mat-icon>
      {{'unsavedChangesWarning' | translate}}
    </div>
    <button mat-raised-button class="form-button" (click)="cancel()">
      <svg-icon src="assets/img/svg/ic_cancel.svg" class="svg-icon"></svg-icon>
      {{'cancel' | translate}}
    </button>
    <button mat-raised-button class="form-button" [disabled]="form.status !== 'VALID'" (click)="onSubmit()">
      <svg-icon src="assets/img/svg/ic_save_blue@3x.svg" class="svg-icon"></svg-icon>
      {{'save' | translate}}
    </button>
  </div>
</div>