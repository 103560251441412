<div class="invitation-link-modal-container" fxLayout="column">
  <div class="close-container">
    <svg-icon src="assets/img/svg/ic_cancele@3x.svg" class="svg-icon" mat-dialog-close="true"
      (click)="dialogRef.close()"></svg-icon>
  </div>

  <div class="title">{{ 'invitationLink' | translate}}</div>
  <div class="helper-text">{{ 'invitationLinkHelperText' | translate}} </div>

  <div class="first-row" fxLayout="row" fxLayoutAlign="space-around center">
    <div fxLayout="column" class="section-container">
      <span class="sub-title">Max installs</span>
      <div class='max-installs'>
        <input type='number' [(ngModel)]='maxInstalls' (change)='setMaxInstalls($event)' (keydown)='preventSpecialCharacters($event)'/>
      </div>
    </div>

    <div fxLayout="column" class="section-container">
      <span class="sub-title">{{'defaultTeamToAssign' | translate}}</span>
      <gk-autocomplete [selectionLimit]="1" [autoOptions]="autocompleteOptions"
        (onItemsSelected)="teamsSelected($event)"></gk-autocomplete>
    </div>
  </div>

  <div class="second-row" fxLayout="row"  fxLayoutAlign="space-around center">
    <div fxLayout="column" class="section-container">
      <span class="sub-title">{{'expiresOn' | translate}}</span>
      <div class="select">
        <!-- Times select -->
        <mat-select #timeSelector id="timeSelector" (selectionChange)="onChangeTimeLimit($event)" [value]='timeLimits[1].value'>
          <mat-option *ngFor="let time of timeLimits" [value]="time.value">
            <span *ngIf="time.translateKey !== 'custom'">
              {{time.translateKey | translate}}{{ ' - ' + time.display }}
            </span>
            <span *ngIf="time.translateKey === 'custom'">
              {{time.translateKey | translate}} {{ viewSelectedTimeLimit ? ' - ' + viewSelectedTimeLimit : '' }}
            </span>
            <!-- Custom date picker -->
            <input *ngIf="pickerOptions && time.translateKey === 'custom'" id="datePickerInput2" [owlDateTime]="dt2"
              [owlDateTimeTrigger]="dt2" [selectMode]="pickerOptions.selectMode" [(ngModel)]="selectedRange"
              placeholder="{{ 'customDate' | translate}}" class="datepicker" (dateTimeChange)="onCustomDateSelected($event)">
            <owl-date-time #dt2 panelClass="link-date-picker"></owl-date-time>
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div fxLayout="column" class="section-container">
      <span class="sub-title">{{'typeOfUser' | translate}}</span>
      <div class="unite-button" fxLayout="row" fxLayoutAlign="start start">
        <button class="left-button" (click)="selectedEntityType = EntityTypes.VOLUNTEER"
          [ngClass]="{'active-button': selectedEntityType === EntityTypes.VOLUNTEER}" fxLayout="row"
          fxLayoutAlign="center center">
          <svg-icon *ngIf="selectedEntityType === EntityTypes.VOLUNTEER" src="assets/img/svg/heart_active.svg"
            class="svg-icon">
          </svg-icon>
          <svg-icon *ngIf="selectedEntityType !== EntityTypes.VOLUNTEER" src="assets/img/svg/heart.svg"
            class="svg-icon"></svg-icon>
          <span class="text">{{'volunteer' | translate}}</span>
        </button>

        <button class="right-button" (click)="selectedEntityType = EntityTypes.USER"
          [ngClass]="{'active-button': selectedEntityType === EntityTypes.USER}" fxLayout="row"
          fxLayoutAlign="center center">
          <svg-icon *ngIf="selectedEntityType === EntityTypes.USER" src="assets/img/svg/shiled_active.svg"
            class="svg-icon">
          </svg-icon>
          <svg-icon *ngIf="selectedEntityType !== EntityTypes.USER" src="assets/img/svg/shiled.svg"
            class="svg-icon"></svg-icon>
          <span class="text">{{'professional' | translate}}</span>
        </button>
      </div>
    </div>
  </div>

  <div class="create-link-button-container">
    <button class="create-link-button" [disabled]="!(selectedTeamId && selectedTeamId.length && selectedEntityType !== undefined)"
      (click)="getInviteLink()">
      <span class="text" *ngIf="!isLoading">{{(invitationId ? 'updateLink' : 'createLink') | translate}}</span>
      <!-- loader -->
      <div class="loader" *ngIf="isLoading"></div>
  </button>
  </div>

  <!-- url copy -->
  <div class="input-group url-copy-input" *ngIf="magicLink" fxLayout="column" fxLayoutAlign="center center">
    <div class="text">{{'customizedLink' | translate}}</div>
    <div class="second-row" fxLayout="row" fxLayoutAlign="space-around center">
      <input class="text-input form-control" [value]="magicLink" type="text" #magicLinkElement>
      <button class="copy-btn" type="button" (click)="copyUrl(magicLinkElement)">{{'copyLink' | translate}}</button>
    </div>
  </div>

</div>
