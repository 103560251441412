<div class="teams-container">
  <div class="page-title">
    {{ 'teams' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>
  <div class="buttons-container offset-4 col-md-8">
    <div class="table-buttons">
      <button mat-raised-button class="table-button" routerLink="/team">
        <mat-icon>group_add</mat-icon>
        {{'addTeam' | translate}}
      </button>
      <button mat-raised-button class="table-button" (click)="exportToCsv()" [disabled]="!(teams && teams.length)">
        <mat-icon>insert_drive_file</mat-icon>
        {{'exportToCsv' | translate}}
      </button>
    </div>
    <div class="disabled-buttons">
      <button mat-raised-button class="disabled-button right-button" (click)="handleTabChange(true)" [ngClass]="{'active-button': !disabledFilter}">
        {{ 'teams' | translate}} <span *ngIf="tabCounter?.enabled > 0" >{{'(' + tabCounter?.enabled + ')'}}</span>
      </button>
      <button mat-raised-button class="disabled-button" (click)="handleTabChange(false)" [ngClass]="{'active-button': disabledFilter}">
        {{ 'disabled' | translate}} <span *ngIf="tabCounter?.disabled > 0" >{{'(' + tabCounter?.disabled + ')'}}</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <!-- teams list -->
  <gk-table [options]="tableOptions"></gk-table>
</div>
