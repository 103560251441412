<div class="integration-container">
  <div class="page-title">
    {{ 'integrations' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>
  <!-- buttons -->
  <div class="buttons-container" fxLayout="row">
    <input type="text" name="search" required [(ngModel)]="search" placeholder="{{'search' | translate}}">
    <div class="tabs-buttons offset-1">
      <button mat-raised-button class="tab-button right-button" (click)="handleTabChange(true)"
        [ngClass]="{'active-button': selectedTab === IntegrationTabs.Installed}">
        {{ 'active' | translate}}
        <span *ngIf="installedIntegrations?.length > 0">{{'(' + installedIntegrations?.length + ')'}}</span>
      </button>
      <button mat-raised-button class="tab-button" (click)="handleTabChange(false)"
        [ngClass]="{'active-button': selectedTab === IntegrationTabs.Explore}">
        {{ 'explore' | translate}} 
        <span *ngIf="integrationTemplates?.length > 0">{{'(' + integrationTemplates?.length + ')'}}</span>
      </button>
    </div>
  </div>

  <div class="helper-text">
    {{'apiToken' | translate}}
  </div>

  <div class="input-group url-copy-input">
    <input id="generate-token-input" class="text-input form-control" [(ngModel)]="organization && organization.apiToken" type="text" #apiToken
    [ngModelOptions]="{standalone: true}">
    <div class="input-group-append">
      <button class="copy-btn" id="copy-token-btn" type="button" *ngIf="organization?.apiToken"
        (click)="copyApiToken(apiToken); tooltip.toggle();">{{'copy' | translate}}</button>

        <!-- <button class="copy-btn" type="button"
        (click)="deleteOrganiztionToken()">{{'delete' | translate}}</button> -->

        <button class="copy-btn" id="generate-token-btn" type="button" *ngIf="!organization?.apiToken"
        (click)="generateApiToken()">{{'generate' | translate}}</button>

      <span matTooltipPosition="right" matTooltip="{{'urlCopied' | translate}}" #tooltip="matTooltip">
      </span>
    </div>
  </div>

  <!-- Integrations Tabs -->
  <mat-tab-group class="tabs-container" [(selectedIndex)]="selectedTab">
    <mat-tab label="{{'active' | translate}}">
      <div class="integrations-container row">
        <!-- Loading PlacHolder -->
        <integration-card-placeholder class="col-md-9" [hidden]="isInit"></integration-card-placeholder>
        <!-- Integration Cards -->
        <div class="installed-container col-md-3"
          *ngFor="let integration of installedIntegrations | nameFilter: search">
          <integration-card class="integration-card" [integration]="integration"
            (onAuthorizeIntegration)="authorizeIntegration(integration)" [isIntalled]="true" [hidden]="!isInit">
          </integration-card>
          <div class="btn-circular delete-button" (click)="openDeleteModal(integration)">
            <mat-icon>close</mat-icon>
          </div>
        </div>
        <!-- No Cards -->
        <div class="no-integrations" fxLayout="column" fxLayoutAlign="center center"
          *ngIf="installedIntegrations.length === 0 && isInit">
          <img src="assets/img/pazle@2x.png" class="pazzle-image">
          <span class="text">{{'noIntegrationYet' | translate}}</span>
          <button mat-raised-button class="more-integrations-button" (click)="goToIntegrationTab()">
            <mat-icon>library_add</mat-icon>
            {{'add' | translate}}
          </button>
        </div>
        <!-- No filtered items -->
        <div class="no-integration-container" fxLayout="column" fxLayoutAlign="center center"
          *ngIf="installedIntegrations?.length > 0 && !(installedIntegrations | nameFilter: search).length">
          <img src="assets/img/desert@2x.png" alt="noItems" class="no-integrations-image">
          <div class="text">
            {{'noResultsText' | translate}} "{{search}}"
            <br> {{'orTry' | translate }}
            <a (click)="search = ''">{{'newSearch' | translate}}</a>
          </div>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="{{ 'explore' | translate}} ">
      <div class="integrations-container row">
        <integration-card class="integration-card col-md-3"
          *ngFor="let integration of integrationTemplates | nameFilter: search" [integration]="integration "
          [isIntalled]="false " (onAddIntegration)="cloneTemplate(integration)"></integration-card>
        <div class="no-integration-container" fxLayout="column" fxLayoutAlign="center center"
          *ngIf="integrationTemplates.length === 0">
          <img class="no-integrations-image" src="assets/img/desert@2x.png" alt="noItems">
          <span class="no-integration-text">{{'noAvailableIntegration' | translate}}</span>
        </div>
      </div>
      <!-- No filtered items -->
      <div class="no-integration-container" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="integrationTemplates?.length > 0 && !(integrationTemplates | nameFilter: search).length">
        <img src="assets/img/desert@2x.png" alt="noItems" class="no-integrations-image">
        <div class="text">
          {{'noResultsText' | translate}} "{{search}}"
          <br> {{'orTry' | translate }}
          <a (click)="search = ''">{{'newSearch' | translate}}</a>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>