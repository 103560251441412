import { TranslationService } from '../../../../services/translation-service.service';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { TableOptions, ColumnTemplete, HistoryStyle, GkUiState } from '../../../../contracts/ui.contracts';
import { GkActivity, GkIncident } from '../../../../contracts/contracts';
import { HistoryService } from '../history.service';
import { GkFilterComponent } from '../../../gk-filter/gk-filter.component';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { BreadCrumbOptions } from '../../../../contracts/ui.contracts';
import { GkFilter } from '../../../../contracts/contracts';
import { Config } from 'app/shared/config';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

import { FilterPipe } from '../../../../shared/pipes/filter.pipe';
import * as moment from 'moment';
import { UiStateService } from '../../../../services/ui.state.service';
import { LocalTimeFormatPipe, LocalDateFormatPipe } from '../../../../shared/pipes/localTime.pipe';
import { NgProgress } from 'ngx-progressbar';
import { ActivatedRoute } from '@angular/router';
import { IncidentsService } from '../../incidents/incidents-list/incidents.service';

@Component({
  selector: 'app-incident-log',
  templateUrl: './incident-log.component.html',
  styleUrls: ['./incident-log.component.scss']
})
export class IncidentLogComponent implements OnDestroy {
  public isfirstSearch: boolean = true;
  public countersText: string;
  public MAX_RECOMMENDED_LOCATIONS: number = 1000;
  public uiState: GkUiState;
  public breadcrumbOptions: BreadCrumbOptions;
  // Filter variable that will emitted from filter component
  public filter: GkFilter = new GkFilter();
  // Filter component
  @ViewChild(GkFilterComponent, {static: false}) filterComponent;
  // Enum
  public HistoryStyle = HistoryStyle;

  public activities: GkActivity[] = [];
  public filteredActivities: GkActivity[];
  public skin: HistoryStyle = HistoryStyle.Map;
  public tableOptions: TableOptions;
  public searchStr: string = '';
  public mapItems: any;
  public locations: any[];
  public showAdvanced: boolean;
  public subscriptions: any[] = [];
  // ENUM
  public historyStyle = HistoryStyle;
  public locationsProgress: number;
  public incident: GkIncident;

  constructor(
    private historyService: HistoryService,
    private incidentsService: IncidentsService,
    private snackbar: GkSnackbar,
    private translationService: TranslationService,
    private loaderService: Ng4LoadingSpinnerService,
    private filterPipe: FilterPipe,
    private uiStateService: UiStateService,
    private timePipe: LocalTimeFormatPipe,
    private datePipe: LocalDateFormatPipe,
    public ngProgress: NgProgress,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params: any) => {
      if (params.incidentId) {
        this.incident = this.incidentsService.getClickedIncident();
        this.breadcrumbOptions = {
          path: [{ titleKey: 'incidents', url: '/incidents' }],
          itemId: this.incident.name
        };
      }
    });

    this.skin = HistoryStyle.Map;
    this.showAdvanced = true;
    this.getUiState();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public getHistoryData = () => {
    // Close filter panel
    this.showAdvanced = false;
    // Gett filter from filter component
    this.filter = this.filterComponent.filter;
    // Match filter missing properties from incident
    this.filter.teams = [this.incident];
    if (!this.filter.startDate) this.filter.startDate = new Date(this.incident.created);
    if (!this.filter.endDate) this.filter.endDate = this.incident.closeTime ?
      new Date(this.incident.closeTime) : new Date();

    if (this.filter.entities.length < 1) {
      this.loaderService.hide();
      this.snackbar.open(this.translationService.getTranslation('pleaseSelectEntities'));
      this.showAdvanced = true;
      return;
    }
    if (this.isfirstSearch) this.isfirstSearch = false;
    // Get locations progress
    this.historyService.getHistoryProgress().subscribe((progress: number) => {
      this.ngProgress.set(progress);
    });
    // Get history data
    this.historyService.getHistoryData(this.filter, true).then(
      (res: any) => {
        this.ngProgress.done();
        let activities = res.activities;
        let locations = res.locations;
        if (activities.length === 0 && locations.length === 0) {
          this.snackbar.open(this.translationService.getTranslation('noMatchHaveFound'));
          this.showAdvanced = true;
          this.loaderService.hide();
          return;
        }
        this.locations = locations;
        this.showAdvanced = false;
        this.filteredActivities = this.activities = activities;
        this.initTable(this.filteredActivities);
        this.initMap(this.filteredActivities);
        // Notify the user for the results
        this.countersText = this.getCountersText();
      },
      () => {
        this.snackbar.open(this.translationService.getTranslation('getActivitiesError'));
        this.showAdvanced = true;
        this.loaderService.hide();
      }
    );
  };

  public getCountersText = () => {
    let translations: any = {
      found: this.translationService.getTranslation('found'),
      from: this.translationService.getTranslation('from'),
      in: this.translationService.getTranslation('in'),
      at: this.translationService.getTranslation('at'),
      users: this.filter.entities && this.filter.entities.length === 1 ?
        this.translationService.getTranslation('user') : this.translationService.getTranslation('users'),
      activities: this.activities && this.activities.length === 1 ? this.translationService.getTranslation('activity')
        : this.translationService.getTranslation('activities'),
      locations: this.locations && this.locations.length === 1 ? this.translationService.getTranslation('location')
        : this.translationService.getTranslation('locations'),
      teams: this.filter.teams && this.filter.teams.length === 1 ?
        this.translationService.getTranslation('team') : this.translationService.getTranslation('teams')
    };

    return `${translations.found} ${this.activities ? this.activities.length : 0} ${translations.activities}  |
     ${this.locations ? this.locations.length : 0} ${translations.locations}  |  ${translations.from}
      ${this.filter.entities ? this.filter.entities.length : 0} ${translations.users}  |
       ${translations.in} ${this.filter.teams ? this.filter.teams.length : 0}
        ${translations.teams} | ${translations.at} ${this.datePipe.transform(this.filter.startDate)}`;
  };

  public initTable = (dataSource: GkActivity[]) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.id = 'historyTable';
    this.tableOptions.pageSize =
      this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.historyTable : null;
    this.tableOptions.rowHoverClass = false;
    this.tableOptions.hideSearch = true;
    this.tableOptions.columns = [
      {
        name: 'data',
        displayNameKey: '',
        template: ColumnTemplete.ActivityTooltip,
        width: '5'
      },
      {
        name: 'date',
        displayNameKey: 'date' // doesn't use our date column template here because got it formatted already
      },
      {
        name: 'time',
        displayNameKey: 'time' // doesn't use our date column template here because got it formatted already
      },
      {
        name: 'userFullName',
        displayNameKey: 'userEntity'
      },
      {
        name: 'teamName',
        displayNameKey: 'team'
      },
      {
        name: 'typeLabel',
        displayNameKey: 'activity',
        template: ColumnTemplete.ActivityElipsys,
      },
      {
        name: 'geoLocation',
        displayNameKey: 'location',
        template: ColumnTemplete.LocationTooltip,
        width: '20'
      },
      {
        name: 'clientCreated',
        displayNameKey: 'download',
        template: ColumnTemplete.DownloadButton
      }
    ];
    if (dataSource) {
      dataSource = this.addDeletePlaceActivities(dataSource);
      this.tableOptions.dataSource = dataSource;
    }
    if (!this.locations || this.skin === HistoryStyle.List) this.loaderService.hide();
  };

  public initMap = (activities: GkActivity[]) => {
    let pharsedActivities: GkActivity[] = [];
    // Parsing activies
    if (activities && activities.length) {
      activities = this.groupActivities(activities);
      activities.forEach((activity: GkActivity) => {
        let properties: any = {
          type: activity.type,
          typeLabel: activity.typeLabel,
          name: activity.userFullName,
          activity
        };
        if (activity.history) properties.history = activity.history;
        if (activity.location) pharsedActivities.push(activity);
      });
    }
    this.mapItems = pharsedActivities;
  };

  public exportToCsv = () => {
    this.loaderService.show();
    // export activities Csv
    if (this.filteredActivities && this.filteredActivities.length) {
      this.subscriptions.push(this.incidentsService.notifyIncidientsActivitiesExport().subscribe(() => {
        let activitiesCsvFileName =
          this.translationService.getTranslation('activities') + '-' + moment.utc().format('MM-DD-YYYY');
        let formattedActivitiesArray = JSON.parse(this.formatDataForCsv(this.filteredActivities, true));
        let activitiesHeaders = Object.keys(formattedActivitiesArray[0]);
        // Translate headers
        for (let i = 0; i < activitiesHeaders.length; i++) {
          activitiesHeaders[i] = this.translationService.getTranslation(activitiesHeaders[i]);
        }
        let safeActivitiesArray = Config.checkChar(formattedActivitiesArray);
        // tslint:disable-next-line:no-unused-expression
        new Angular5Csv(safeActivitiesArray, activitiesCsvFileName, {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          noDownload: false,
          headers: activitiesHeaders
        });
        this.loaderService.hide();
      }, () => {
        this.loaderService.hide();
        this.snackbar.open(this.translationService.getTranslation('failedToExportResults'));
      }));
    }
    // export locations Csv
    if (this.locations && this.locations.length) {
      this.subscriptions.push(this.historyService.notifyLocationsExport().subscribe(() => {
        let locationsCsvFileName =
          this.translationService.getTranslation('locations') + '-' + moment.utc().format('MM-DD-YYYY');
        let formattedLocationsArray = JSON.parse(this.formatDataForCsv(this.locations));
        let locationsHeaders = Object.keys(formattedLocationsArray[0]);
        for (let j = 0; j < locationsHeaders.length; j++) {
          locationsHeaders[j] = this.translationService.getTranslation(locationsHeaders[j]);
        }
        let safeLocationsArray = Config.checkChar(formattedLocationsArray);

        // tslint:disable-next-line:no-unused-expression
        new Angular5Csv(safeLocationsArray, locationsCsvFileName, {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          noDownload: false,
          headers: locationsHeaders
        });
        this.loaderService.hide();
      }, () => {
        this.loaderService.hide();
        this.snackbar.open(this.translationService.getTranslation('failedToExportResults'));
      }));
    }
  };

  public formatDataForCsv = (array: any[], isActivities?: boolean) => {
    let formmatedArray = [];
    for (let i = 0; i < array.length; i++) {
      let row;
      if (isActivities) {
        let data = this.translationService.getTranslation('noData');
        if (array[i].type && array[i].data) {
          if (array[i].type === 'image') {
            data = array[i].data.urls.full_hd;
            data = data.slice(data.lastIndexOf('/') + 1);
          } else if (array[i].type === 'file') {
            data = array[i].data.url;
            data = data.slice(data.lastIndexOf('/') + 1);
          } else if (array[i].data.message) {
            data = array[i].data.message;
          }
        }
        row = {
          type: array[i].type,
          data: data,
          date: this.datePipe.transform(array[i].created),
          time: this.timePipe.transform(array[i].created),
          entityid: array[i].entity ? array[i].entity.entityid : array[i].entityid, // support tasks
          userName: array[i].userFullName,
          teamName: array[i].teamName,
          geolocation: array[i].geoLocation || this.translationService.getTranslation('noGeolocation'),
          accuracy: array[i].accuracy ? array[i].accuracy + 'm' : null
        };
        if (array[i].location && array[i].location.latitude)
          row.location = array[i].location.latitude + ',' + array[i].location.longitude;
        // Foramt tasks
        if (array[i].type === 'task') {
          row = this.getFormmatedTask(array[i]);
        }
      } else {
        row = {
          time: this.timePipe.transform(array[i].created),
          date: this.datePipe.transform(array[i].created),
          name: array[i].entity.name,
          location: array[i].loc.coordinates[1] + ',' + array[i].loc.coordinates[0],
          accuracy: array[i].accuracy ? array[i].accuracy + 'm' : null
        };
        if (array[i].entity && array[i].entity.entityid) row.entityid = array[i].entity.entityid;
      }
      formmatedArray.push(row);
    }

    let safeArray = Config.checkChar(formmatedArray);
    return safeArray;
  };

  public applyFilter(filterValue: string) {
    // filter pipe is recursively itarate in activities array
    // and return results to this.filteredActivities
    // then, update map and table
    this.filteredActivities = this.filterPipe.transform(this.activities, filterValue);
    // we want filter on locations also? its heavy!
    this.initTable(this.filteredActivities);
    this.initMap(this.filteredActivities);
  }

  public getUiState = () => {
    this.subscriptions.push(this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
      this.uiState = state;
      this.initTable(this.activities);
    }));
  }

  public groupActivities = (activities: GkActivity[]) => {
    let groupedActivities: GkActivity[] = [];
    let placesActivitiesDic: any = {};
    let index;
    for (let i = activities.length - 1; i > -1; i--) {
      if (activities[i].data && activities[i].data.place) {
        // It's Place
        if (!placesActivitiesDic.hasOwnProperty(activities[i].data.place._id)) {
          // Is'nt in the dictionary
          // save place in groupedActivities array
          activities[i].history = [];
          index = groupedActivities.length;
          groupedActivities[index] = activities[i];
          // save place index
          placesActivitiesDic[activities[i].data.place._id] = index;
          index++;
          continue;
        } else {
          // Is in the dictionary
          groupedActivities[placesActivitiesDic[activities[i].data.place._id]].history.push(activities[i]);
        }
      } else {
        groupedActivities.push(activities[i]);
      }
    }
    return groupedActivities;
  }

  public closeIdea = () => {
    let element: HTMLCollectionOf<Element> = document.getElementsByClassName('location-alert');
    element[0].remove();
  }

  // Add an fake delete activity for each place activity with end time
  public addDeletePlaceActivities = (dataSource: GkActivity[]) => {
    let newPlaceActivities = [];
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].data && dataSource[i].data.place && dataSource[i].data.place.endTime) {
        let item = Object.assign({}, dataSource[i]);
        item.type = 'place-deleted';
        item.typeLabel = this.historyService.getTypeLabel(item);
        item.date = this.datePipe.transform(item.data.place.endTime);
        item.time = this.timePipe.transform(item.data.place.endTime);
        newPlaceActivities.push(item);
      }
    }
    return newPlaceActivities.concat(dataSource);
  }

  finishDraw() {
    this.loaderService.hide();
  }

  getFormmatedTask = (taskActivity: GkActivity) => {
    let newTask = Object.assign({}, taskActivity);
    let fullLabel = newTask.typeLabel.split('-');

    newTask.type = fullLabel[0];
    newTask.data = fullLabel[1];
    newTask.date = taskActivity.date;
    newTask.time = taskActivity.time;
    return newTask;
  };
}
