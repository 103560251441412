<div class="incident-list-container">
  <div class="page-title">
    {{ 'incidents' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>

  <div class="buttons-container offset-4 col-md-8">
    <div class="table-buttons">
      <button mat-raised-button class="table-button" (click)="exportToCsv()" [disabled]="!(incidents && incidents.length)">
        <mat-icon>insert_drive_file</mat-icon>
        {{'exportToCsv' | translate}}
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>
  <!-- Incidents list -->
  <gk-table [options]="tableOptions"></gk-table>
</div>
