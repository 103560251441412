<div class="gk-toolbar-container" fxLayout="column" fxLayoutAlign="start center">
  <!-- Organiztion Logo -->
  <div class="page-logo" *ngIf="isSidenavOpen">
    <img alt="logo" class="GK-logo-header" src="assets/img/globekeeper_logo@3x.png" [routerLink]="''"
      *ngIf="organization && !organization.logo">
    <img alt="logo" class="GK-logo-header" [src]="organization?.logo" *ngIf="organization && organization.logo"
      [routerLink]="''">
  </div>

  <div class="user-menu-container" fxLayout="row">
    
    <div class="profile-container" (click)="goToEditUser()"
    [ngClass]="{'sideNavClosed' : !isSidenavOpen}" (mouseout)="mouse = false" (mouseover)="mouse = true">
      <img [src]="user && user.extras && user.extras.picture ? user.extras.picture : 'assets/img/user_pic.png'"
      class="avatar" [ngStyle]="{'opacity' : mouse ? 0.4 : 1}" >
      <mat-icon class="column-icon noselect" [ngStyle]="{'opacity': mouse ? 1 : 0}" >edit</mat-icon>
    </div>
  
    <div class="right-section" fxLayout="column" *ngIf="isSidenavOpen">
      <span class="user-name">
        {{ user?.name }}
      </span>
      <div [matMenuTriggerFor]="userMenu" class="my-profile-button" *ngIf="user" fxLayout="row"
        fxLayoutAlign="center center">
        <span class="text">{{'myProfile' | translate}}</span>
        <span class="arrow-selected"></span>
      </div>
    </div>
  </div>
  <!-- User Menu -->
  <mat-menu #userMenu="matMenu" xPosition="after" yPosition="above" [overlapTrigger]="false" class="user-sub-menu">
    <button mat-menu-item *ngIf="user && user.monitor" (click)="goToMonitor()">
      <span>{{'backToMonitor' | translate}}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="languages">
      <span>{{'languages' | translate}}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <span>{{ 'logout' | translate}}</span>
    </button>
  </mat-menu>

  <mat-menu #languages="matMenu" class="user-sub-menu languages-menu">
    <button mat-menu-item (click)="changeLanguage('en')"><span>{{ 'english' | translate }}</span></button>
    <button mat-menu-item (click)="changeLanguage('pl')"><span>{{ 'polish' | translate }}</span></button>
    <button mat-menu-item (click)="changeLanguage('he')"><span>{{ 'hebrew' | translate }}</span></button>
    <button mat-menu-item (click)="changeLanguage('zh')"><span>{{ 'chinese' | translate }}</span></button>
    <button mat-menu-item (click)="changeLanguage('es')"><span>{{ 'spanich' | translate }}</span></button>
    <button mat-menu-item (click)="changeLanguage('it')"><span>{{ 'italian' | translate }}</span></button>
  </mat-menu>

</div>
