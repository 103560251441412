import { IEntityState } from '../../shared/store/reducers/entitiesReducer';
import { ITeamsState } from '../../shared/store/reducers/teamsReducer';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppState, GkActivity, GkEntity, GkFilter, GkTeam, GkUser } from '../../contracts/contracts';
import * as $ from 'jquery';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { EntitiesService } from '../pages/entities/entities.service';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/combineLatest';
import { TeamsService } from '../pages/teams/teams.service';
import { UsersService } from '../pages/users/users.service';
import { Config } from '../../shared/config';
import { Constants } from '../../shared/constant';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { GkSnackbar } from '../../services/gkSnackbar/gk-snackbar.service';
import { GkAlertType } from '../../contracts/ui.contracts';
import { TranslationService } from '../../services/translation-service.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'gk-filter',
  templateUrl: './gk-filter.component.html',
  styleUrls: ['./gk-filter.component.scss']
})
export class GkFilterComponent implements OnInit {
  public pickerOptions: any;
  public selectedRange: any;
  public ranges: any;
  public dateRanges;
  // Lists Data
  public allUsers: any;
  public allTeams: any;
  public allEntities: any;
  // Selected data
  public activitiesTypes: any;
  public shownEntities: any[] = [];
  public selectedTeam: any[] = [];
  public filter: GkFilter = new GkFilter();
  // Filter event to parent
  @Output() filterEvent = new EventEmitter<GkFilter>();
  @Input() recordingFilter;
  @Input() incidentFilter;
  @Input() incidentSelectedTeam;

  // Possible Stop times
  public stopTimes = [
    { id: 30, readable: '0.5 ', string: 'minute' },
    { id: 60, readable: '1 ', string: 'minute' },
    { id: 120, readable: '2 ', string: 'minute' },
    { id: 180, readable: '3 ', string: 'minute' },
    { id: 240, readable: '4 ', string: 'minute' },
    { id: 300, readable: '5 ', string: 'minute' },
    { id: 600, readable: '10 ', string: 'minute' }
  ];
  // All Checked booleans
  public allTeamsChecked: boolean;
  public allEntitiesChecked: boolean;
  public allActivitiesChecked: boolean;
  public allActivitiesDisable: any;
  public allUsersDisable: boolean;
  // Instance of datePicker
  public datePicker: any;
  public currentUser: GkUser;
  public subscriptions: any[] = [];
  selectedStringRange: string;

  constructor(
    private translationService: TranslationService,
    private teamsService: TeamsService,
    private usersService: UsersService,
    private entitiesService: EntitiesService,
    private snackbar: GkSnackbar,
    private store: Store<AppState>,
    private dateAdapter: DateTimeAdapter<any>
  ) {
    this.teamsService.getTeams();
    this.usersService.getUsers();
    this.entitiesService.GetEntities();
    this.usersService.initUsersDic();
    this.dateAdapter.setLocale(navigator.language);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.usersService.getCurrentUser().subscribe((currentUser: GkUser) => {
        this.init(currentUser);
      })
    );
    this.initDaterangePicker();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  // Init GK-Filters
  public init = (currenUser: GkUser) => {
    this.currentUser = currenUser;
    // this.datePicker = $('#datePicker');
    if (!this.incidentFilter) {
      this.getDataInitLists();
    } else {
      this.initIncidentFilter();
    }
  };

  initIncidentFilter() {
    let entities = [];
    for (let i = 0; i < this.incidentSelectedTeam.responders.length; i++) {
      let entity = this.usersService.GetLocalUser(this.incidentSelectedTeam.responders[i]);
      if (entity && entity.type !== Config.EntityTypes.CAMERA) {
        entities.push(entity);
      }
    }
    let teams = [this.incidentSelectedTeam];
    this.allEntities = entities && entities.length ? entities : null;
    // Unselect All items
    if (this.allEntities && this.allEntities.length)
      this.allEntities.forEach((entity: GkEntity) => {
        entity.selected = false;
      });

    this.allTeams = teams && teams.length ? teams : null;
    this.allTeams.forEach((team: GkTeam) => {
      team.selected = false;
    });
    if (this.filter.entities.length === 0 && this.filter.entities.length === 0) {
      this.setDefaultValues();
      if (!this.recordingFilter) {
        this.getActivityTypes();
      }
    }
  }

  public setDefaultValues = () => {
    this.allTeamsChecked = false;
    // Selecting and updating default stop time to 1 minute
    this.filter.stopTime = this.stopTimes[1].id;
    // Default date value
    $('#yesterday').click();
    // Current user team choosen by default or inside incident filter
    let team = this.getTeamById(this.currentUser.team._id);

    this.allUsersDisable = this.selectedTeam.length === 0;
    if (team) {
      team.selected = true;
      this.handleTeamClicked(team, true);
      // TODO Select all entities by default?
    }
  };

  // Get Data Functions
  public getDataInitLists = () => {
    const entities$: Observable<IEntityState> = this.store.select('entities');
    const teams$: Observable<ITeamsState> = this.store.select('teams');
    this.subscriptions.push(
      Observable.combineLatest(entities$, teams$).subscribe(([entities, teams]: any) => {
        if (entities && entities.length && teams && teams.length) {
          this.allEntities = entities && entities.length ? entities : null;
          // Unselect All items
          this.allEntities.forEach((entity: GkEntity) => {
            entity.selected = false;
          });

          this.allTeams = teams && teams.length ? teams : null;
          this.allTeams.forEach((team: GkTeam) => {
            team.selected = false;
          });
          if (this.filter.entities.length === 0 && this.filter.entities.length === 0) {
            this.setDefaultValues();
            if (!this.recordingFilter) {
              this.getActivityTypes();
            }
          }
        }
      })
    );
  };
  public getActivityTypes = () => {
    this.activitiesTypes = [];
    Object.keys(Config.ActivityTypes).forEach((activityType: any) => {
      let activityString = Config.ActivityTypes[activityType].split(',')[0].toLowerCase().replace(/\s+/g, '');
      let activity = {
        name: activityString,
        value: activityType,
        selected: true,
        disabled: false
      };
      this.activitiesTypes.push(activity);
    });

    this.activitiesTypes.forEach((activity: any) => {
      this.activityClicked(activity, activity.selected);
    });
  };

  public handleTeamClicked = (clicked: GkTeam, checked?: boolean) => {
    if (checked !== undefined && clicked) {
      clicked.selected = checked;
    }

    if (checked) {
      if (this.filter.teams.length > 141) {
        this.allTeams = this.allTeams.map((team) => (team._id === clicked._id ? { ...team, selected: false } : team));
        this.snackbar.open(this.translationService.getTranslation('cannotAddMoreTeams'));
      } else {
        this.filter.teams = [...this.filter.teams, clicked];
        this.teamsService.GetTeam(clicked._id).subscribe((team: GkTeam) => {
            this.selectedTeam.push(team)
            this.changeEntities();
          },
          (error: HttpErrorResponse) => {
            if (error.status === 403) {
            }
          });
      }
    } else {
      this.filter.teams = this.filter.teams.filter((team) => team._id !== clicked._id);
      this.selectedTeam = this.selectedTeam.filter((team) => team._id !== clicked._id);
      this.changeEntities();
    }
    if (this.selectedTeam.length === 0) {
      this.allEntitiesChecked = false;
    }

    this.allUsersDisable = this.selectedTeam.length === 0;
  };

  public handleAllTeamsClicked = (checked?: boolean) => {
    if (checked) {
      this.allTeams = this.allTeams.map((team) => ({ ...team, selected: true }));
      this.filter.teams = this.allTeams;
    } else {
      this.allTeams = this.allTeams.map((team) => ({ ...team, selected: false }));
      this.filter.teams = [];
    }
  };

  public entityClicked = (entity: GkEntity, selected?: boolean) => {
    if (selected !== undefined && entity) {
      entity.selected = selected;
    }
    if (entity.selected) {
      this.filter.entities.push(entity);
    } else {
      for (let i = 0; i < this.filter.entities.length; i++) {
        if (this.filter.entities[i]._id === entity._id) {
          _.remove(this.filter.entities, (entityClicked: GkEntity) => {
            return this.filter.entities[i]._id === entityClicked._id;
          });
        }
      }
    }
    if (!this.allUsersDisable) {
      this.allEntitiesChecked = this.isAllSelected(this.shownEntities);
    }
  };

  public activityClicked = (activity: any, selected?: boolean) => {
    // Ignore undefiend selectedevent
    if (selected === undefined) return;

    activity.selected = selected;
    let activityTypeIndex = this.activitiesTypes.findIndex((activityType: any) => {
      return activityType.name === activity.name;
    });

    if (activity.selected) {
      if (activity.name === 'places') {
        this.placesSelected(true);
      }
      this.activitiesTypes[activityTypeIndex].selected = true;
      this.filter.activities.push(activity);
    } else {
      let index = this.filter.activities.findIndex((filterActivity: any) => {
        return filterActivity.name === activity.name;
      });
      this.filter.activities.splice(index, 1);
      this.activitiesTypes[activityTypeIndex].selected = false;
      if (activity.name === 'places') {
        this.placesSelected(false);
      }
    }

    if (!this.allActivitiesDisable) {
      this.allActivitiesChecked = this.isAllSelected(this.activitiesTypes);
    }
  };

  public placesSelected = (selected: boolean) => {
    let enterActivity: any = {
      name: 'geofence-enter',
      value: 'geofence-enter',
      selected,
      disabled: false
    };
    let exitActivity: any = {
      name: 'geofence-exit',
      value: 'geofence-exit',
      selected,
      disabled: false
    };
    if (selected) {
      this.filter.activities.push(enterActivity);
      this.filter.activities.push(exitActivity);
      return;
    }
    // remove places activities from filter array
    let activities = _.filter(this.filter.activities, (filterActivity: any) => {
      return filterActivity.name.indexOf('geofence') === -1 && filterActivity.name.indexOf('place') === -1;
    });
    this.filter.activities = activities;
  };

  public entitiesAllSelected = () => {
    this.allUsersDisable = true;
    this.allEntitiesChecked = !this.allEntitiesChecked;
    this.shownEntities.forEach((entity: GkEntity) => {
      entity.selected = this.allEntitiesChecked;
      this.entityClicked(entity, this.allEntitiesChecked);
    });
    if (!this.allEntitiesChecked) {
      this.filter.entities = [];
    }
    this.allUsersDisable = false;
  };

  public activitiesAllSelected = () => {
    this.allActivitiesDisable = true;
    this.allActivitiesChecked = !this.allActivitiesChecked;
    this.activitiesTypes.forEach((activity: GkActivity) => {
      activity.selected = this.allActivitiesChecked;
      this.activityClicked(activity, this.allActivitiesChecked);
    });
    if (!this.allActivitiesChecked) {
      this.filter.activities = [];
    }
    this.allActivitiesDisable = false;
  };

  // Change dependecy Function - each team select will filter the users by selected team user's.
  public changeEntities = () => {
    this.allUsersDisable = true;
    this.filter.entities = [];
    // allUsers's team is teamId
    this.shownEntities = this.selectedTeam.reduce((prev, current) =>{
      const ids = prev.map(u => u._id);
      const next = current.users.filter(u => !ids.includes(u._id)).map(e => {
        //@ts-ignore
        const user = (this.shownEntities || []).find(s => s._id === e._id);
        return user || e
      });
      return [...prev, ...next];
    }, []);
    this.filter.entities = this.shownEntities.filter(entitie => entitie.selected);
    this.allUsersDisable = false;
    this.allEntitiesChecked = this.isAllSelected(this.shownEntities);
  };

  // Utills Functions
  // Getting specific team by serverId.
  public getTeamById = (serverId: string) => {
    if (this.incidentFilter) return this.incidentSelectedTeam;
    for (let i = 0; i < this.allTeams.length; i++) {
      if (this.allTeams[i]._id === serverId) {
        return this.allTeams[i];
      }
    }
  };

  // Returns true if all array items are selected
  public isAllSelected = (array: any[]) => {
    for (let i = 0; i < array.length; i++) {
      if (!array[i].selected && (array[i].disabled === undefined || array[i].disabled === false)) {
        return false;
      }
    }
    return true;
  };

  // Date Picker Section
  public handleActivitiesDateRange = (startDate: any, endDate: any) => {
    this.filter.startDate = startDate.toDate ? startDate : new Date(startDate);
    this.selectedRange = [];
    this.selectedRange[0] = this.filter.startDate;
    if (endDate) {
      this.filter.endDate = endDate.toDate ? endDate : new Date(endDate);
      this.selectedRange[1] = this.filter.endDate;
    }
  };

  // Date range control function
  public setDateRange = (dateRange: string) => {
    if (dateRange === 'customDate') {
      // for ui
      this.selectedStringRange = dateRange;
      // open date range modal
      return $('#datePickerInput').click();
    } else if (dateRange === 'allIncidents') {
      // for ui
      this.selectedStringRange = dateRange;
      return this.handleActivitiesDateRange(this.incidentSelectedTeam.created, this.incidentSelectedTeam.closeTime);
    }
    // for ui
    this.selectedStringRange = dateRange;
    let start = this.ranges[dateRange][0];
    let end = this.ranges[dateRange][1];
    this.handleActivitiesDateRange(start, end);
  };

  public onDateSelected(event: any) {
    $('#customDate').click();
    // manupulate filter property
    let startDate = event.value[0].toDate();
    let endDate = event.value[1].toDate();
    this.filter.startDate = startDate;
    this.filter.endDate = endDate;
    // manupulate input property
    this.selectedRange = [startDate, endDate];
  }

  public initDaterangePicker = () => {
    this.dateRanges = this.incidentFilter ? ['allIncidents', 'customDate'] : Constants.dateRanges;
    this.pickerOptions = {
      selectMode: 'range'
    };
    if (this.incidentSelectedTeam && this.incidentSelectedTeam.place.created) {
      this.pickerOptions.minDate = new Date(this.incidentSelectedTeam.place.created);
      if (this.incidentSelectedTeam.place.closeTime) {
        this.pickerOptions.maxDate = new Date(this.incidentSelectedTeam.place.closeTime);
      }
    }
    if (!this.incidentFilter) {
      this.ranges = {
        today: [moment().startOf('day'), moment()],
        yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
        last7days: [moment().subtract(6, 'days').startOf('day'), moment()],
        last30days: [moment().subtract(29, 'days').startOf('day'), moment()],
        thisMonth: [moment().startOf('month'), moment()],
        lastMonth: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      };
    }
  };
}
