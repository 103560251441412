import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpMethod } from '../contracts/contracts';
import { Config } from 'app/shared/config';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Injectable()
export class WebApiService {
  public baseUrl: string = Config.ApiUrl;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService,
    private sessionStorage: SessionStorageService
  ) { }

  // TODO - implement all http options and return type
  public makeRequest = (
    url: string,
    data: any,
    method: httpMethod,
    headers?,
    params?,
    withCredentials = true
  ): Observable<any> => {
    let reqOptions: any = {
      body: data,
      headers: headers ? headers : new HttpHeaders().set('Content-Type', 'application/json'),
      params,
      reportProgress: true,
      withCredentials
    };
    switch (method) {
      case httpMethod.Get:
        return this.http.get(this.baseUrl + url, reqOptions);
      case httpMethod.Post:
        return this.http.post(this.baseUrl + url, data, reqOptions);
      case httpMethod.Delete:
        return this.http.delete(this.baseUrl + url, reqOptions);
      case httpMethod.Put:
        return this.http.put(this.baseUrl + url, data, reqOptions);
      case httpMethod.Patch:
        return this.http.patch(this.baseUrl + url, data, reqOptions);
      default:
        return null;
    }
  };

  public makeThirdPartyRequest = (
    url: string,
    data: any,
    method: httpMethod,
    headers?,
    withCredentials = true
  ): Observable<any> => {
    let reqOptions: any = {
      body: data,
      headers: headers ? headers : new HttpHeaders().set('Content-Type', 'application/json'),
      withCredentials
    };
    switch (method) {
      case httpMethod.Get:
        return this.http.get(url, reqOptions);
      case httpMethod.Post:
        return this.http.post(url, data, reqOptions);
      case httpMethod.Delete:
        return this.http.delete(url, reqOptions);
      case httpMethod.Put:
        return this.http.put(url, data, reqOptions);
      default:
        return null;
    }
  };

  public logout = () => {
    this.localStorageService.clear('loggedUserId');
    this.sessionStorage.clear();
    this.makeRequest('/logout', null, httpMethod.Get).subscribe((res: any) => {
      // remove security coockie
      this.cookieService.delete('gk.sid');
      window.location.href = Config.AppUrl;
    });
  };
}
