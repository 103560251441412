import { Injectable } from "@angular/core";
import { Observable, throwError, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Config } from "app/shared/config";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Before sending the requset
    return next.handle(req).pipe(
      tap(
        event => {
          //logging the http response to browser's console in case of a success
          if (event instanceof HttpResponse) {
            // Success
          }
        },
        error => {
          //logging the http response to browser's console in case of a failuer
          if (event instanceof HttpResponse) {
            console.error(error);
            if (error.status === 401 || error.status === 403) {
              let login = Config.AppUrl || "http://127.0.0.1:5002/#";
              if (!login.endsWith("/#/login")) {
                login = login + "/#/login";
              }
              let url = login + "/?redirect=" + window.location.href;
              window.location.href = url;
            }
          }
        }
      )
    );
  }
}
