<div class="search-container">
  <input type="text" id="search-input" #searchInput [(ngModel)]="allItemsFilter" [ngModelOptions]="{standalone: true}"
    placeholder="{{'search' | translate}}">
</div>
<div class="item-selection-container" fxLayout="row" fxLayoutAlign="space-between start">
  <div class="col-sm-5">
    <div class="panel-heading">{{('all' + modeStringValue) | translate}}</div>

    <ul class="list-group" cdkDropList #itemsList="cdkDropList" [cdkDropListData]="items" [cdkDropListConnectedTo]="[selectedItemsList]"
      (cdkDropListDropped)="drop($event)">
      <li *ngFor="let item of items | nameFilter: allItemsFilter" (click)="moveItem(item,'general')" cdkDrag id="{{item._id}}" [hidden]="!item.enabled"
        class="list-group-item">{{item.name}}</li>
    </ul>

  </div>
  <mat-icon>repeat</mat-icon>
  <div class="col-sm-5">
    <div class="panel-heading">{{('assigned' + modeStringValue) | translate}}</div>

    <ul class="list-group selectedItems" cdkDropList #selectedItemsList="cdkDropList" [cdkDropListData]="selectedItems"
      [cdkDropListConnectedTo]="[itemsList]" (cdkDropListDropped)="drop($event)">
      <li (click)="moveItem(item,'selected')" cdkDrag class="list-group-item" *ngFor="let item of selectedItems | nameFilter: allItemsFilter"
        id="{{item._id}}" [ngClass]="{'added-item': item.added}" [hidden]="!item.enabled">
        {{item.name}}
      </li>
    </ul>

  </div>
</div>
