import { GkIntegration, AuthType } from './../../contracts/contracts';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INTEGRATIONS_CONSTS } from 'app/components/pages/integrations/integrationConsts';

@Component({
  selector: 'integration-card',
  templateUrl: './integration-card.component.html',
  styleUrls: ['./integration-card.component.scss']
})
export class IntegrationCardComponent {
  @Input() integration: GkIntegration;
  @Input() isIntalled: boolean;
  @Output() onAddIntegration: EventEmitter<any> = new EventEmitter();
  @Output() onAuthorizeIntegration: EventEmitter<any> = new EventEmitter();

  public authTypeStrings: string[] = INTEGRATIONS_CONSTS.authTypeStrings;
  public integrationTypeStrings: string[] = INTEGRATIONS_CONSTS.integrationTypeStrings;
  // ENUM
  public AuthType = AuthType;

  constructor() {}

  public addIntegration(): void {
    this.onAddIntegration.emit(this.integration);
  }

  public authorizeIntegration = (): void => {
    this.onAuthorizeIntegration.emit(this.integration);
  };
}
