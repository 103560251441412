import { IEntityState } from './reducers/entitiesReducer';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { usersReducer, IUsersState } from './reducers/usersReducers';
import { teamsReducer, ITeamsState } from './reducers/teamsReducer';
import { integrationsReducer, IIntegrationState } from './reducers/integrationsReducer';
import { entitiesReducer } from 'app/shared/store/reducers/entitiesReducer';

export interface Istore {
  users: IUsersState;
  teams: ITeamsState;
  integrations: IIntegrationState;
  entities: IEntityState;
}

const store = {
  users: usersReducer,
  teams: teamsReducer,
  integrations: integrationsReducer,
  entities: entitiesReducer
};

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(store, {
      initialState: {
        users: {
          users: [],
          loading: false,
          error: null
        },
        teams: {
          teams: [],
          loading: false,
          error: null
        },
        integrations: {
          integrations: [],
          loading: false,
          error: null
        },
        entities: {
          entities: [],
          loading: false,
          error: null
        }
      }
    })
  ],
  exports: [StoreModule],
  declarations: []
})
export class GkStoreModule {}
