import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GkAlertType } from '../../contracts/ui.contracts';
import Swal from 'sweetalert2';
import { TranslationService } from 'app/services/translation-service.service';

@Injectable()
export class GkSnackbar {
  constructor(private snackBar: MatSnackBar, private translationService: TranslationService) {}

  public open = (message: string, action: string = null, data: any = null) => {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.data = data;
    config.horizontalPosition = 'right';
    config.duration = 2000;
    config.panelClass = 'snackbar-container';
    config.direction = this.translationService.LANGUAGE === 'he' ? 'rtl' : 'ltr';
    this.snackBar.open(message, action, config);
  };

  public openSweetAlert = (type: GkAlertType, text: string = '', description: string = '') => {
    switch (type) {
      case GkAlertType.Success:
        Swal.fire({
          imageUrl: 'assets/img/svg/circled_check.svg',
          title: `<span class="text-capitalize">${this.translationService.getTranslation('success')}</span>`,
          width: '38.75rem',
          confirmButtonColor: '#0dcbcb',
          confirmButtonText: `<span class="ml-5 mr-5">${this.translationService.getTranslation('backToMain')}</span>`,
          text,
        });
        break;
      case GkAlertType.Error:
        Swal.fire({
          title: `<span class="text-capitalize">${this.translationService.getTranslation('error')}</span>`,
          width: '38.75rem',
          type: 'error',
          confirmButtonText: `<span class="ml-2 mr-2">${this.translationService.getTranslation('backToMain')}</span>`,
          confirmButtonColor: '#0dcbcb',
          html: `${text}<br>${description}`
        });
        break;
    }
  };
}
