import { GkEntity } from './../../../contracts/contracts';
import { ActionReducer } from '@ngrx/store';
import * as t from './usersReducers';


export interface IEntityState {
  entities: GkEntity[];
  loading: false;
  error: null;
}

export const ENTITY_STATE: IEntityState = {
  entities: [],
  loading: false,
  error: null
};

export interface CustomAction {
  type: string;
  payload?: any;
}


export function entitiesReducer(state: IEntityState = ENTITY_STATE, action: any): IEntityState {
  switch (action.type) {
    case 'GET_ENTITIES':
      return state;
    case 'SET_ENTITIES':
      return action.payload;
    case 'ADD_ENTITY':
      return state;
    default:
      return state;
  }
}
