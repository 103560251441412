import {
  GkActivity,
  GkLocation,
  LocationFeature
} from './../../contracts/contracts';
import {
  GkMapType,
  FlyOptions,
  FeatureCollection,
  GeoJson
} from './../../contracts/maps.contracts';
import { MapsService } from './../../services/maps-service';
import { Config } from './../../shared/config';
import { Component, OnInit, Input, Output, EventEmitter, ContentChild, ViewChild } from '@angular/core';
import * as mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import * as _ from 'lodash';
import Supercluster from 'supercluster/index.js';
import CONSTS from './map.consts';
import * as turf from '@turf/turf';
import { TranslationService } from './../../services/translation-service.service';
import { MatMenuTrigger } from '@angular/material';
const CP_MAPSTYLE_PREFIX = 'history_';
@Component({
  selector: 'gk-map',
  templateUrl: './gk-map.component.html',
  styleUrls: ['./gk-map.component.scss']
})
export class GkMapComponent implements OnInit {
  @ViewChild('menuTrigger', {static: false}) trigger: MatMenuTrigger;

  public isTooltipOpen: any;
  public map: any;
  public mapInit: boolean;
  public mapTypes: GkMapType[] = [];
  public mapStyle: any;
  public lat = CONSTS.START_LAT;
  public lng = CONSTS.START_LON;
  // Sources
  public entitiesCircleRadiusGeojson: FeatureCollection = new FeatureCollection(
    []
  );
  public accuracyCircleGeojson: FeatureCollection = new FeatureCollection([]);
  public activities: FeatureCollection = new FeatureCollection([]);
  public locations: FeatureCollection = new FeatureCollection([]);
  public locationsPoints: FeatureCollection = new FeatureCollection([]);

  public accuracyCircleEntityId: any;
  public entityAccuracyCircle: any;

  public zoomLevel: number;
  public currentZoom: number = 1;
  public cluster: any;
  public popup: any;
  public isClusterClick: boolean;
  public isMouseOnClusteredLayer: boolean;
  // Layers toggled
  public layersToggled: any = {
    activities: true,
    locations: true
  };

  @Output() finishDraw = new EventEmitter<any>();
  // Set function for Activities input
  @Input()
  set activitiesData(activities: any[]) {
    if (activities && activities.length === 0) this.activities.features = [];
    if (activities && activities.length > 0) {
      this.activities.features = [];
      for (let i = 0; i < activities.length; i++) {
        if (
          activities[i].location.longitude === 0 &&
          activities[i].location.latitude === 0
        ) {
          continue;
        }
        this.addActivityMarker(activities[i]);
      }
      if (this.map) {
        this.generalBoundsFit();
      }
    }
  }
  // Set function for Routes input
  @Input()
  set locationsData(locationsValue: any[]) {
    if (locationsValue && locationsValue.length > 0) {
      if (!this.locationsPoints)
        this.locationsPoints = new FeatureCollection([]);
      let parsedLocations: any = this.getLocationsFeatures(locationsValue);
      this.locations.features = parsedLocations[0];
      this.locationsPoints.features = parsedLocations[1];
      if (this.map) {
        this.draw();
        this.generalBoundsFit();
      }
    }
  }
  constructor(private mapService: MapsService, private translationService: TranslationService) {}

  ngOnInit() {
    this.getMapStyles();
  }

  // Get map styles from api and start map init
  private getMapStyles = () => {
    this.mapService.GetStyles().subscribe((serverMapTypes: any) => {
      this.mapTypes = [];
      serverMapTypes = serverMapTypes.filter((mapType) => {
        return mapType.id.indexOf(CP_MAPSTYLE_PREFIX) !== -1;
      });
      serverMapTypes.forEach((mapStyle) => {
      // Filter CP styles
        this.mapTypes.push({
          id: mapStyle.id,
          name: this.translationService.getTranslation(mapStyle.id.split(CP_MAPSTYLE_PREFIX)[1]),
          url: mapStyle.url
        });
      });
      // Cloud
      if (Config.MAPBOX_ACCESS_TOKEN) {
        mapboxgl.accessToken = Config.MAPBOX_ACCESS_TOKEN;
        // RTL
        if (mapboxgl.getRTLTextPluginStatus() && mapboxgl.getRTLTextPluginStatus() !== 'loaded') {
          mapboxgl.setRTLTextPlugin(Config.RTL_LAB);
        }
      }
      // Change after connect to ui state
      this.mapStyle = this.mapTypes[0];
      this.initializeMap();
    });
  };
  // then, Get user location and call this.buildMap()
  private initializeMap() {
    // locate and center the user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
    this.buildMap();
  }
  // init map obj and call this.load()
  public buildMap() {
    let mapOptions: any = {
      container: 'gk-map',
      style: this.mapStyle ? this.mapStyle.url : this.mapTypes[0],
      zoom: CONSTS.START_ZOOM,
      minZoom: CONSTS.MIN_ZOOM,
      center: [this.lng, this.lat]
    };
    this.map = new mapboxgl.Map(mapOptions);
    this.load();
  }
  // add map onLoad event then
  // Loads map sources, init clusters, add map layers and listeners
  // fitting view to all features
  public load = () => {
    this.map.on('load', () => {
      // Init clusters
      this.cluster = new Supercluster({
        radius: CONSTS.CLUSTER_RADIUS,
        maxZoom: CONSTS.CLUSTER_ZOOM
      });

      this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
      // Add a new source from our GeoJSON data (this.activities)
      this.addSources();
      // Add map layers
      this.addMapLayers();
      // Add Listeners
      this.addMapListeners();
      this.addLayersListeners();
      if (this.activities && this.activities.features.length) {
        this.fitBoundsToFeatures(this.activities.features);
      }
    });
  };

  public addMapLayers = () => {
    this.map.addLayer(CONSTS.LAYERS.ENTITIES_CIRCLE_RADIUS);
    this.map.addLayer(CONSTS.LAYERS.ENTITIES_CIRCLE_RADIUS_TEXT);
    this.map.addLayer(CONSTS.LAYERS.ENTITIES_ACCURACY_CIRCLE);
    this.map.addLayer(CONSTS.LAYERS.LOCATIONS);
    this.map.addLayer(CONSTS.LAYERS.LOCATION_POINTS);
    this.map.addLayer(CONSTS.LAYERS.CLUSTERED_ACTIVITIES);
    this.map.addLayer(CONSTS.LAYERS.ACTIVITIES_COUNT);
    this.map.addLayer(CONSTS.LAYERS.UNCLUSTERED_ACTIVITIES);
    this.map.addLayer(CONSTS.LAYERS.ACTIVITIES_ICONS);
  };
  public addMapListeners = () => {
    this.map.on('zoom', (e: any) => {
      this.zoomLevel = this.map.getZoom();
      if (Math.floor(this.currentZoom) === 0) {
        this.currentZoom = 1;
      }
      if (Math.floor(this.zoomLevel) !== Math.floor(e.currentZoom)) {
        this.currentZoom = this.zoomLevel;
      }
    });
    this.map.on('zoomend', (e: any) => {
      this.draw();
    });
  };
  public addLayersListeners = () => {
    // Center the map on the coordinates of any clicked symbol from the 'symbols' layer.
    this.map.on('click', CONSTS.LAYERS.UNCLUSTERED_ACTIVITIES.id, (e: any) => {
      if (this.isClusterClick) return;
      this.activityMarkerClick(e);
    });
    this.map.on('mousemove', CONSTS.LAYERS.LOCATIONS.id, (e: any) => {
      if (this.popup || this.isMouseOnClusteredLayer) {
        return;
      }
      this.locationHover(
        e,
        JSON.parse(e.features[0].properties.location).entityName
      );
    });

    this.map.on('mouseleave', CONSTS.LAYERS.LOCATIONS.id, () => {
      if (this.isTooltipOpen) return;
      this.map.getCanvas().style.cursor = '';
      this.clearPopup();
    });
    this.map.on('click', CONSTS.LAYERS.CLUSTERED_ACTIVITIES.id, (e: any) => {
      console.log('CLUSTERED_ACTIVITIES clicked');
      this.clusterClicked();
      let clickedCluster = e.features[0];
      let id = clickedCluster.properties.cluster_id;
      let zoom = this.cluster.getClusterExpansionZoom(id);
      // // Geting the clustered items assosiated with this clusterId
      let activitiesFeatures = this.cluster.getLeaves(id, Infinity);
      this.fitBoundsToFeatures(activitiesFeatures);
    });
    // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
    this.map.on(
      'mouseenter',
      CONSTS.LAYERS.CLUSTERED_ACTIVITIES.id,
      (e: any) => {
        this.isMouseOnClusteredLayer = true;
        this.map.getCanvas().style.cursor = 'pointer';
      }
    );
    // Leaving clustered layer
    this.map.on(
      'mouseleave',
      CONSTS.LAYERS.CLUSTERED_ACTIVITIES.id,
      (e: any) => {
        this.isMouseOnClusteredLayer = false;
      }
    );
    // Point pop op on click
    this.map.on('click', CONSTS.LAYERS.LOCATION_POINTS.id, (e: any) => {
      if (this.isClusterClick) return;
      console.log('location clicked');
      this.locationClicked(e);
    });
    // Click on the map only.
    this.map.on('click', (e: any) => {
      const features = this.map.queryRenderedFeatures(e.point, {
        layers: CONSTS.ALL_LAYERS
      });
      if (!features.length) {
        this.removeEntityAccuracyCircle();
        this.draw();
      }
    });
  };

  // location hover function
  public locationHover = (e: any, entityName: string) => {
    // Change the cursor style as a UI indicator.
    this.map.getCanvas().style.cursor = 'pointer';
    let coordinates = e.features[0].geometry.coordinates.slice();

    // Populate the popup and set its coordinates
    // based on the feature found.
    this.popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    this.popup
      .setLngLat(e.lngLat)
      .setHTML(entityName)
      .addTo(this.map);
  };

  // open Activity tooltip
  public activityMarkerClick = (e: any) => {
    let coordinates = e.lngLat || e.features[0].geometry.coordinates;
    let properties = e.features[0].properties;
    // Get tooltip template by activity type
    let tooltipTemplate = this.mapService.getActivitiyTooltipTemplate(
      properties.activity
    );
    // Using two steps-clicks for getting the accurate location of the marker
    // Mapbox gl return diffrent location that effects by diffrent zoom levels
    let center = this.map.queryRenderedFeatures(e.point)[0].geometry
      .coordinates;
    this.map.flyTo({
      center,
      zoom:
        this.map.getZoom() === CONSTS.STEP_ONE_ZOOM
          ? CONSTS.STEP_TWO_ZOOM
          : Math.max(8, this.map.getZoom())
    });
    this.map.once('zoomend', (zoomE: any) => {
      new mapboxgl.Popup({ offset: CONSTS.POPUP_OFFSET })
        .setLngLat(center)
        .setHTML(tooltipTemplate)
        .addTo(this.map);
    });
    this.isTooltipOpen = true;
  };

  // open location tooltip
  public locationClicked = (e: any) => {
    let location = JSON.parse(e.features[0].properties.location);
    let coordinates = e.lngLat;
    // Get tooltip template by activity type
    let tooltipTemplate = this.mapService.getLocationTooltipTemplate(location);
    this.map.panTo(coordinates);
    this.clearPopup();
    this.popup = new mapboxgl.Popup({ offset: CONSTS.POPUP_OFFSET })
      .setLngLat(coordinates)
      .setHTML(tooltipTemplate)
      .addTo(this.map);
    this.isTooltipOpen = true;
    // Add acuracy circle
    if (e.features[0].properties.accuracy) {
      this.addOrUpdateEntityAccuracyCircle(e.features[0]);
    } else {
      this.removeEntityAccuracyCircle();
    }
  };

  private addSources() {
    this.map.addSource(CONSTS.LAYERS.UNCLUSTERED_ACTIVITIES.source, {
      type: 'geojson',
      data: this.activities
    });
    // Add a new source from our locations data (this.locations)
    this.map.addSource(CONSTS.LAYERS.LOCATIONS.source, {
      type: 'geojson',
      data: this.locations
    });
    // Add a new source from our locations points
    this.map.addSource(CONSTS.LAYERS.LOCATION_POINTS.source, {
      type: 'geojson',
      data: this.locationsPoints
    });
    // Add a new source for entities accuracy circle
    this.map.addSource(CONSTS.LAYERS.ENTITIES_ACCURACY_CIRCLE.source, {
      type: 'geojson',
      data: this.entitiesCircleRadiusGeojson
    });
    this.map.addSource(CONSTS.LAYERS.ENTITIES_CIRCLE_RADIUS.source, {
      type: 'geojson',
      data: this.accuracyCircleGeojson
    });
  }

  // Wrap for mapbox flyTo method
  public flyTo(options: FlyOptions) {
    this.map.flyTo({
      center: options.center,
      zoom: Math.max(CONSTS.DEFAULT_ZOOM, this.map.getZoom()),
      bearing: 0,
      speed: CONSTS.FLY_SPEED
    });
  }

  // get activity and add activity matker to the map
  public addActivityMarker = (activity: GkActivity) => {
    let activityMarker: GeoJson = new GeoJson(
      [activity.location.longitude, activity.location.latitude],
      null
    );
    activityMarker.properties = {
      id: activity._id,
      name: activity.userFullName,
      type: activity.type,
      color: '#1B2733',
      icon: this.getActivityIcon(activity),
      activity
    };
    this.activities.features.push(activityMarker);
    return activityMarker;
  };

  private getLocationPoint = (
    location: GkLocation,
    color: string = CONSTS.LOCATION_COLOR
  ) => {
    let point: GeoJson = new GeoJson(
      [location.loc.coordinates[0], location.loc.coordinates[1]],
      null
    );
    point.properties = {
      color,
      lastLocationDate: location.created,
      location: {
        entityName: location.entity.name,
        coordinates: location.loc.coordinates,
        date: location.created
      }
    };
    if (location.accuracy) {
      point.properties.accuracy = location.accuracy;
    }
    return point;
  };

  private getLocationFeature = (location: GkLocation, color?: string) => {
    if (!color) {
      color = '#' + Math.floor(Math.random() * 16777215).toString(16);
    }
    let line: LocationFeature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [location.loc.coordinates]
      },
      properties: {
        color,
        lastLocationDate: location.created,
        location: {
          entityName: location.entity.name,
          coordinates: location.loc.coordinates,
          date: location.created
        }
      }
    };
    return line;
  };

  // Parse location as map line
  public getLocationsFeatures = (locations: GkLocation[]) => {
    let entitiesPoints: any = [];
    let entitiesLines: any = [];
    let entitiesLinesIndex = {};
    // running index for handling
    let index = 0;
    // running on locations data array
    for (let i = 0; i < locations.length; i++) {
      let checkedLocation = locations[i];
      // Ignore 0,0
      if (
        checkedLocation.loc.coordinates[0] === 0 &&
        checkedLocation.loc.coordinates[1] === 0
      ) {
        continue;
      }
      entitiesPoints.push(this.getLocationPoint(checkedLocation));

      // Calculate Lines
      if (!entitiesLinesIndex[checkedLocation.entity._id]) {
        // get location feature
        let line = this.getLocationFeature(checkedLocation);
        // push line the entitiesLines
        entitiesLines[index] = line;
        // save entity line index
        entitiesLinesIndex[checkedLocation.entity._id] = index;
        index++;
      } else {
        let entityIndex = entitiesLinesIndex[checkedLocation.entity._id];
        // check if the diffrence between last-location-data and current-location-date
        // is greater then CONSTS.MAX_DATES_SPACE, for seperate the activities
        if (
          Math.abs(
            entitiesLines[entityIndex].properties.lastLocationDate -
              checkedLocation.created
          ) > CONSTS.MAX_DATES_SPACE
        ) {
          let line = this.getLocationFeature(
            checkedLocation,
            entitiesLines[entityIndex].properties.color
          );
          entitiesLines[index] = line; // push feature to lines array
          entitiesLinesIndex[checkedLocation.entity._id] = index; // save entity index in entitiesLinesIndex
          index++;
          continue;
        }
        entitiesLines[entityIndex].geometry.coordinates.push(
          checkedLocation.loc.coordinates
        );
        entitiesLines[entityIndex].properties.lastLocationDate =
          checkedLocation.created;
      }
    }
    return [entitiesLines, entitiesPoints];
  };

  // get activity icon by activity type
  public getActivityIcon = (activity: GkActivity) => {
    switch (activity.type) {
      case 'message':
        return 'ic_message';
      case 'real-time':
        return 'ic_realtime';
      case 'image':
        return 'ic_image';
      case 'panic':
        return 'ic_panic';
      case 'incident':
        return 'ic_incident';
      case 'place-created':
        return 'ic_place';
      case 'file':
        return this.getFileActivityIcon(activity);
    }
  };
  // Draw layers and clusters
  public draw = () => {
    // Data loading to Cluster and to Source
    this.currentZoom = this.map.getZoom();
    if (this.cluster) {
      this.cluster.load(this.activities.features);
      let activitiesClusterData = turf.featureCollection(
        this.cluster.getClusters(
          CONSTS.WORLD_BOUNDS,
          Math.floor(this.currentZoom)
        )
      );
      this.map
        .getSource(CONSTS.LAYERS.UNCLUSTERED_ACTIVITIES.source)
        .setData(activitiesClusterData);
    }
    if (this.map.getSource(CONSTS.LAYERS.LOCATIONS.source))
      this.map
        .getSource(CONSTS.LAYERS.LOCATIONS.source)
        .setData(this.locations);
    if (this.map.getSource(CONSTS.LAYERS.LOCATION_POINTS.source))
      this.map
        .getSource(CONSTS.LAYERS.LOCATION_POINTS.source)
        .setData(this.locationsPoints);

    // Draw Entity accurate circle
    this.accuracyCircleGeojson.features = this.getaccuracyCircleGeojson();
    if (this.map.getSource(CONSTS.LAYERS.ENTITIES_ACCURACY_CIRCLE.source))
      this.map
        .getSource(CONSTS.LAYERS.ENTITIES_ACCURACY_CIRCLE.source)
        .setData(this.accuracyCircleGeojson);

    // Draw Entity accurate circle radius line
    this.entitiesCircleRadiusGeojson.features = this.getEntitiesCircleRadiusFeature();
    if (this.map.getSource(CONSTS.LAYERS.ENTITIES_CIRCLE_RADIUS.source))
      this.map
        .getSource(CONSTS.LAYERS.ENTITIES_CIRCLE_RADIUS.source)
        .setData(this.entitiesCircleRadiusGeojson);

    this.mapInit = true;
    this.finishDraw.emit();
  };

  // fit to activities and location bounds
  public generalBoundsFit = () => {
    if (this.activities.features && this.activities.features) {
      let allFeatures = Object.assign(
        {},
        this.locations.features,
        this.activities.features
      );
      this.fitBoundsToFeatures(allFeatures);
    }
  };

  // Get featurs list, calculate the bounds and fit the map.
  public fitBoundsToFeatures = (features: any) => {
    if (_.isEmpty(features)) {
      return;
    }
    if (features.length === 1) {
      return this.flyTo({ center: features[0].geometry.coordinates });
    }
    let bounds = new mapboxgl.LngLatBounds();
    _.forEach(features, (boundedFeature: any) => {
      let coord: any = boundedFeature.geometry.coordinates;
      if (Array.isArray(boundedFeature.geometry.coordinates[0])) {
        // extend to first and last coordinate
        bounds.extend(boundedFeature.geometry.coordinates[0]);
        bounds.extend(
          boundedFeature.geometry.coordinates[
            boundedFeature.geometry.coordinates.lenght - 1
          ]
        );
        return;
      }
      bounds.extend(coord);
    });
    this.map.fitBounds(bounds, {
      padding: CONSTS.MAP_PADDING
    });
  };
  // clear popups
  public clearPopup = () => {
    if (this.popup) {
      this.popup.remove();
      this.popup = null;
      this.isTooltipOpen = false;
    }
  };
  // Hide and show al group layers on the map
  public toggleLayer = (layerGroupName: string, show: boolean) => {
    let layers: string[] =
      layerGroupName === 'activities'
        ? CONSTS.ACTIVITY_LAYERS
        : CONSTS.LOCATION_LAYERS;
    for (let i = 0; i < layers.length; i++) {
      this.map.setLayoutProperty(layers[i], 'visibility', show ? 'visible' : 'none');
      this.layersToggled[layerGroupName] = show;
    }
    if (!this.layersToggled['locations']) {
      this.removeEntityAccuracyCircle();
      this.clearPopup();
    }
  };

  public mapZoom = (zoom: string) => {
    if (zoom === 'in') {
      this.map.flyTo({ zoom: this.map.getZoom() + 1 });
    } else {
      this.map.flyTo({ zoom: this.map.getZoom() - 1 });
    }
  };

  // Entity Accuracy

  // return polygon geojson that draw the entity accuracy circle
  // by gps accuracy as radius, his and his location - lat, lng as the center of the circle
  public getaccuracyCircleGeojson = (): any => {
    if (this.entityAccuracyCircle) {
      let radiusInM = this.entityAccuracyCircle.radius;
      let points = 128;
      let coords = {
        latitude: this.entityAccuracyCircle.center[1],
        longitude: this.entityAccuracyCircle.center[0]
      };
      let km = radiusInM / 1000; // radius in M to KM
      let ret = [];
      let distanceX =
        km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      let distanceY = km / 110.574;
      let theta;
      let x;
      let y;
      for (let i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);
        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);
      return [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret]
          },
          properties: {
            color: this.entityAccuracyCircle.fillColor
          }
        }
      ];
    } else {
      return [];
    }
  };

  // return geojson feature from type 'LineString' that contains 2 points
  // entity location as start point and use getDestinationPoint() to calculate the second
  // point by radius, the first point and angle
  public getEntitiesCircleRadiusFeature = (): any => {
    if (this.entityAccuracyCircle) {
      let startPoint = {
        latitude: this.entityAccuracyCircle.center[0],
        longitude: this.entityAccuracyCircle.center[1]
      };
      let destination = this.getDestinationPoint();
      return [
        {
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: [
              [destination[0], destination[1]],
              [startPoint.latitude, startPoint.longitude]
            ]
          },
          properties: {
            color: this.entityAccuracyCircle.fillColor,
            radius: this.entityAccuracyCircle.radius
          }
        }
      ];
    }
    return [];
  };

  public deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };

  public rad2deg = (rad: number) => {
    return (rad * 180) / Math.PI;
  };

  public getDestinationPoint = () => {
    if (this.accuracyCircleGeojson && this.accuracyCircleGeojson.features) {
      // entity accuracy circle start to draw from the right side of the circle
      // this is our destination point to draw the radius line from the circle center
      return this.accuracyCircleGeojson.features[0].geometry.coordinates[0][0];
    }
  };

  public addOrUpdateEntityAccuracyCircle = (marker: any) => {
    if (typeof marker.geometry.coordinates[0] === 'string') {
      marker.geometry.coordinates = [
        parseInt(marker.geometry.coordinates[0]),
        parseInt(marker.geometry.coordinates[1])
      ];
    }
    // Check if only update is needed
    if (this.entityAccuracyCircle) {
      if (this.accuracyCircleEntityId === marker.properties.id) {
        // same entity circle - should update the center and the radius
        this.entityAccuracyCircle.radius =
          Math.round(marker.properties.accuracy) ||
          this.entityAccuracyCircle.radius;
        this.entityAccuracyCircle.center = marker.geometry.coordinates;
        this.entityAccuracyCircle.fillColor =
          marker.properties.color || this.entityAccuracyCircle.fillColor;
        this.draw();
        return;
      }
    }
    this.removeEntityAccuracyCircle();
    this.entityAccuracyCircle = {
      radius: Math.round(marker.properties.accuracy),
      center: marker.geometry.coordinates,
      fillColor: marker.properties.color
    };
    this.accuracyCircleEntityId = marker.properties.id;
    this.draw();
  };

  public removeEntityAccuracyCircle = () => {
    this.entityAccuracyCircle = undefined;
    this.accuracyCircleEntityId = null;
    this.accuracyCircleGeojson.features = [];
    this.entitiesCircleRadiusGeojson.features = [];
    this.draw();
  };

  public clusterClicked = () => {
    this.isClusterClick = true;
    setTimeout(() => {
      this.isClusterClick = false;
    }, 500);
  };

  public getFileActivityIcon = (activity: GkActivity) => {
    switch (activity.data.type) {
      case 'pdf':
        return 'ic_pdf';
      case 'xlx':
      case 'xls':
      case 'xlsx':
        return 'ic_xlxs';
      case 'doc':
      case 'docx':
        return 'ic_doc';
      case 'txt':
        return 'ic_txt';
      case 'csv':
        return 'ic_csv';
      case 'ppt':
      case 'pptx':
        return 'ic_ppt';
      default:
        return 'ic_ppr';
    }
  };

  changeMapStyle(type) {
    // Stop drawing while changing map style
    // preventing map race condition
    if (_.get(type, 'id') !== _.get(this.mapStyle, 'id')) {
      this.map.setStyle(type.url);
      this.map.once('styledata', (data: any) => {
        this.mapStyle = type;
        this.limitZoomByMapType();
        if (data.style._loaded) {
          // Add a new source from our GeoJSON data (this.activities)
          this.addSources();
          // Add map layers
          this.addMapLayers();
          if (!this.layersToggled['activities']) {
            this.toggleLayer('activities', false);
          }
          if (!this.layersToggled['locations']) {
            this.toggleLayer('locations', false);
          }
        }
      });
    }
  }

  // Limit max zoom on raster map types
  limitZoomByMapType() {
    if (this.mapStyle.id !== 'basic') {
      this.map.setMaxZoom(18);
      return;
    }
    this.map.setMaxZoom();
  }
}
