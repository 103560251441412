import { GkUser } from './../../../../contracts/contracts';
import { UsersService } from './../users.service';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbOptions, GkMode } from '../../../../contracts/ui.contracts';

@Component({
  selector: 'user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.scss']
})
export class UserComponent implements OnDestroy {
  public users: GkUser[];
  public breadcrumbOptions: BreadCrumbOptions;
  public mode: GkMode;
  public user: GkUser;
  public subscriptions: any = [];

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService
  ) {
    this.init();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  private init = () => {
    this.subscriptions.push(this.route.params.subscribe((params: any) => {
      this.mode = params.entityId ? GkMode.Update : GkMode.Add;
      this.breadcrumbOptions = {
        path: [
          { titleKey: 'users', url: '/../users-list' },
          {
            titleKey: this.mode === GkMode.Add ? 'addUser' : 'updateUser',
            url: this.mode === GkMode.Add ? '../user' : '../../user/' + params.entityId
          }
        ]
      };
      if (this.mode === GkMode.Update) {
        this.subscriptions.push(this.usersService.GetUser(params.entityId).subscribe((user: GkUser) => {
          this.user = Object.assign({}, user);
          this.breadcrumbOptions.itemId = user.name;
        }));
      }
    }));
  };
}
