
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { extendMoment } from 'moment-precise-range-plugin';

// Great way to win the evil Lint.
let momentUse = Object.assign({}, extendMoment, moment);
@Pipe({ name: 'duration' })
export class DurationPipe implements PipeTransform {

  transform(duration): string {
    return momentUse.preciseDiff(0, duration);
  }
}
