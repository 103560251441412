import { Config } from './../../shared/config';
import { Component, OnInit } from '@angular/core';
declare var VERSION: any;
var pjson = require('./../../../../package.json');

@Component({
  selector: 'gk-version',
  templateUrl: './gk-version.component.html',
  styleUrls: ['./gk-version.component.css']
})
export class GkVersionComponent {

  public version: string;
  constructor() {
    this.version = pjson.version;
  }
}
