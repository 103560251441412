<div class="image-container" [ngClass]="{'with-image': image}"
(dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">

    <!-- image -->
    <img [src]="image ? image : DEFAULT_IMAGE" [ngClass]="{'selected-image': image, 'default-image': !image || image === DEFAULT_IMAGE}"
      class="image" id="image-uploader">
    <!-- image input -->
    <input type="file" id="files" title="{{(!image || image === DEFAULT_IMAGE) ? ('chooseFile' | translate) : ''}}" class="image-input" (change)="handleFileInput($event)" accept="image/*">
    <div class="select-text" [hidden]="(isDashboard && image !== DEFAULT_IMAGE) || (!isDashboard && image)">{{'chooseFile' | translate}}</div>
    <!-- remove button for dashboard -->
    <div class="btn-circular delete-button" (click)="deleteImage($event)" [hidden]="!image || image === DEFAULT_IMAGE"
      *ngIf="isDashboard">
      <mat-icon>close</mat-icon>
    </div>
    <!-- Circle Buttons -->
    <div fxLayout="row" fxLayoutAlign="center center" class="buttons-container"
      [hidden]="!image || image === DEFAULT_IMAGE" *ngIf="!isDashboard">
      <div class="circle-button delete-button edit-button">
        <input type="file" id="files-input-button" class="button-input" (change)="handleFileInput($event)" accept="image/*">
        <label for="files-input-button" title="Edit file">
          <mat-icon>edit</mat-icon>
        </label>
      </div>
      <div class="circle-button delete-button" title="Delete file" (click)="deleteImage($event)">
        <mat-icon>delete</mat-icon>
      </div>
    </div>

</div>
