import { AbstractControl, ValidatorFn, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Constants } from 'app/shared/constant';

const hebReg: RegExp = new RegExp(/[\u0590-\u05fe/]/);
// tslint:disable-next-line: max-line-length
const emailReg: RegExp = /[\w\-\._]+@[\w\-\._]+\.\w{2,10}/;

export function patternValidator(regexp: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    return !regexp.test(value) ? { patternInvalid: { regexp } } : null;
  };
}

export function moreThanOneValidator(control: AbstractControl) {
  if (!control.value || control.value.length < 1) return { moreThanOne: true };
  return null;
}

export function passwordValidator(control: AbstractControl) {
  if (!control.dirty) return;
  let value = control.value;
  let reg: RegExp = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/);
  if ((reg.test(value) && value.length >= 8) || value === '' || value === null) return null;
  return { passwordValidator: true };
}

export function threeSameCharts(control: AbstractControl) {
  let value = control.value;
  let reg: RegExp = new RegExp(/([a-zA-Z0-9])\1{2,}/);
  if ((!reg.test(value) || value === '' || value === null)) return null;
  return { threeSameCharts: true };
}

export function firstCapitalValidator(control: AbstractControl) {
  let value = control.value;
  let reg: RegExp = new RegExp(/^[A-Z]/);
  // Support heb chars
  if (reg.test(value) || hebReg.test(value)) return null;
  return { firstCapitalValidator: true };
}

export class PasswordValidation {
  static MatchPassword(AC: AbstractControl) {
    let password = AC.get('password').value; // to get value in input tag
    let confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true });
    } else {
      return null;
    }
  }
}

export function badNamesValidator(control: AbstractControl) {
  let value = control.value;
  let reg: RegExp = new RegExp(Constants.BAD_NAME_REGEX);
  if (!(reg.test(value))) {
    // BAD NAME !!!!
    return { badNamesValidator: true };
  }
  return null;
}

export function userBadNamesValidator(control: AbstractControl) {
  if (!control.dirty) return;
  // let fullName = control.parent.get('firstName').value + ' ' + control.parent.get('lastName').value;
  let reg: RegExp = new RegExp(Constants.BAD_NAME_REGEX);
  if (!(reg.test(control.value) || hebReg.test(control.value))) {
    // BAD NAME !!!!
    return { userBadNamesValidator: true };
  }
  return null;
}

export function specialChartsValidator(control: AbstractControl) {
  let value = control.value;
  let regSpecialChars: RegExp = new RegExp(Constants.USER_ID_REGGEX);
  let regEmail: RegExp = new RegExp(Constants.EMAIL_REGEX);

  if (regSpecialChars.test(value) && !regEmail.test(value)) {
    return { specialChartsValidator: true };
  }
  if (value && value.length < 3) {
    return { minlength: true };
  }

  return null;
}

export function customEmailValidator(control: AbstractControl): ValidationErrors {
  if (!control.value || !control.dirty) {
    return null;
  }
  if (emailReg.test(control.value)) {
    return null;
  }
  return { email: true };
}
