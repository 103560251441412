import { entityTypes } from './../../contracts/contracts';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { GkUser, AppState, GkTeam } from '../../contracts/contracts';
import { Store } from '@ngrx/store';

import { TeamsService } from '../pages/teams/teams.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { UsersService } from '../pages/users/users.service';

@Component({
  selector: 'gk-items-selector',
  templateUrl: './gk-items-selector.component.html',
  styleUrls: ['./gk-items-selector.component.scss'],
})
export class GkItemsSelectorComponent implements OnInit {
  @Input()
  set mode(value: entityTypes) {
    this.selectorMode = value;
    this.modeStringValue = this.selectorMode === entityTypes.entities ? 'Users' : 'Teams';
  }
  @Input()
  set preItems(selectedItems: GkUser[] | GkTeam[]) {
    this.selectedItems = [];
    if (selectedItems && selectedItems.length) {
      this.selectedItems = selectedItems;
      this.initItems();
    }
  }
  @Output() onItemsSelected: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  public allItemsFilter: string;
  public items: any | GkUser[] | GkTeam[] = [];
  public selectedItems: any | GkUser[] | GkTeam[] = [];
  public selectorMode: entityTypes;
  public modeStringValue: 'Users' | 'Teams';
  public subscriptions: any[] = [];

  constructor(private store: Store<AppState>, private usersService: UsersService, private teamsService: TeamsService) {}

  ngOnInit() {
    if (this.selectorMode === entityTypes.entities) {
      this.usersService.getUsers();
    } else {
      this.teamsService.getTeams();
    }
    this.initItems();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public initItems = () => {
    let storeName: any = this.modeStringValue.toLowerCase();
    this.subscriptions.push(
      this.store.select(storeName).subscribe((items: any) => {
        if (items && items.length > 0) {
          // slice() function for place the data and not the reference
          // for avoid ngrx store issue
          this.items = items.slice();
          // splice selected users from users array
          if (this.selectedItems) {
            for (let selectedItem of this.selectedItems) {
              for (let item of this.items) {
                if (selectedItem._id === item._id) {
                  let index = this.items.indexOf(item);
                  this.items.splice(index, 1);
                }
              }
            }
          }
        }
      })
    );
  };

  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.refreshFilters(event.container.data[event.currentIndex]._id);
    this.allItemsFilter = '';
    this.onItemsSelected.emit(this.selectedItems);
  }

  public moveItem = (item: any | GkUser | GkTeam, group: string) => {
    if (group === 'general') {
      this.selectedItems.unshift(item);
      this.items.splice(this.items.indexOf(item), 1);
    } else {
      // Remove from Selected Users
      this.items.unshift(item);
      this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
    }
    this.refreshFilters(item._id);
    this.onItemsSelected.emit(this.selectedItems);
  };

  // copy the items array to trigger the Angular filter pipe
  // call mark item
  public refreshFilters = (itemId: string) => {
    this.markItem(itemId);
    this.allItemsFilter = '';
    this.items = [...this.items];
    this.selectedItems = [...this.selectedItems];
    this.searchInput.nativeElement.focus();
  };

  // make the item blinks twice
  // setTimeout for waiting the item to finish move to the next list
  public markItem = (itemId: string) => {
    setTimeout(() => {
      document.getElementById(itemId).className += ' added-item';
    }, 100);
  };
}
