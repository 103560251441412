import { Observable, ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Config } from 'app/shared/config';
import { LocalStorageService } from 'ngx-webstorage';
import { GkUiState, StateProperties } from 'app/contracts/ui.contracts';
import { UsersService } from '../components/pages/users/users.service';
import { GkUser } from '../contracts/contracts';
import { Router, NavigationEnd } from '@angular/router';

const uiStatePrefix = 'UiState:';

@Injectable()
export class UiStateService {

  public currentUser: GkUser;
  public state: any;
  public gkStateServer: ReplaySubject<GkUiState> = new ReplaySubject();
  public previousUrl: string;
  public currentUrl: string;


  constructor(
    private localStorageService: LocalStorageService,
    private userService: UsersService,
    private router: Router
  ) {
    this.state = this.getDefultState();
    this.getCurrentUser();
    // Save the last url
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {        
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      };
    });
  }

  public GetUiState = () => {
    if (!this.currentUser) {
      this.getCurrentUser().then((user: GkUser) => {
        this.state = this.localStorageService.retrieve(uiStatePrefix + user._id);
        if (this.state) {
          this.gkStateServer.next(this.state);
        } else {
          return this.getDefultState();
        }
      });
    } else {
      this.state = this.localStorageService.retrieve(uiStatePrefix + this.currentUser._id);
      if (!this.state) {
        this.state = this.getDefultState();
      }
      this.gkStateServer.next(this.state);
    }
    return this.gkStateServer;
  }

  public SaveUiState = (StateProperty: StateProperties, propertyKey: string, value: any) => {
    if (!this.state[StateProperties.invitationPin]) {
      this.state[StateProperties.invitationPin] = {};
    }
    this.state[StateProperty][propertyKey] = value;
    this.localStorageService.store(uiStatePrefix + this.currentUser._id, this.state);
  }

  public getCurrentUser = () => {
    return new Promise<GkUser>((resolve, reject) => {
      this.userService.getCurrentUser().subscribe((user: GkUser) => {
        this.currentUser = user;
        resolve(user);
      }, (e) => {
        reject(e);
      });
    });
  }

  public getDefultState = (): GkUiState => {
    return {
      itemPerPage: {
        usersList: 10,
        teamList: 10,
        sessionsManagment: 10,
        historyTable: 10,
        untrustedDevices: 10,
        recordingsTable: 10,
        recordingSessionTable: 10,
        emailInvitations: 10,
        incidents: 10,
        magicEmailInvitations: 10
      },
      invitationPinCode: false
    };
  };

  public getPreviousUrl = () => {
    return this.previousUrl;
  }
}
