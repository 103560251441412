import { GkTeam } from '../../../contracts/contracts';

export interface ITeamsState {
  teams: GkTeam[];
  loading: false;
  error: null;
}

export const TEAM_STATE: ITeamsState = {
  teams: [],
  loading: false,
  error: null
};

export interface CustomAction {
  type: string;
  payload?: any;
  }

export function teamsReducer(state: ITeamsState = TEAM_STATE, action: CustomAction): ITeamsState {
  switch (action.type) {
    case 'GET_TEAMS':
      return state;
    case 'SET_TEAMS':
      return action.payload;
    case 'DELETE_TEAM':
      return action.payload;
    case 'UPDATE_TEAM':
      return action.payload;
    default:
      return state;
  }
}
