export const sortByLastSeen = (array: any[]) => {
  let sortedArray = [];
  sortedArray = array.sort((a: any, b: any) => {
    if (!a.lastSeen) return 1;
    if (!b.lastSeen) return -1;
    if (Number.isNaN(b.lastSeen - a.lastSeen)) {
      return new Date(b.lastSeen).getTime() - new Date(a.lastSeen).getTime();
    }
    return b.lastSeen - a.lastSeen;
  });
  return sortedArray;
};
