import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'gk-form-validation',
  templateUrl: './gk-form-validation.component.html',
  styleUrls: ['./gk-form-validation.component.scss']
})
export class GkFormValidationComponent implements OnInit {

  @Input() validationText: string;
  @ViewChild(MatTooltip, {static: false}) tooltip: MatTooltip;
  @Input()
  set showTooltip(openTooltip: boolean) {
    if (openTooltip) {
      this.tooltip.show();
    }
  }
  public position: string = 'right'; // default value for now

  constructor() { }

  ngOnInit() {
  }

}
