<div class="row" fxLayout="row" fxLayoutAlign="center center">
    <input #index0 type="password" id="tab0" required pattern="\d*" maxlength="1" class="tab" (input)="onInputEntry($event,'tab',0)" [disabled]="activeInputIndex !== 0" [(ngModel)]="pinCode[0]"
    (keydown)="onInputKeyDown($event, 0)"/>
    <input #index1 type="password" id="tab1" required pattern="\d*" maxlength="1" class="tab" (input)="onInputEntry($event,'tab',1)" [disabled]="activeInputIndex !== 1" [(ngModel)]="pinCode[1]"
    (keydown)="onInputKeyDown($event, 1)"/>
    <input #index2 type="password" id="tab2" required pattern="\d*" maxlength="1" class="tab" (input)="onInputEntry($event,'tab',2)" [disabled]="activeInputIndex !== 2" [(ngModel)]="pinCode[2]"
    (keydown)="onInputKeyDown($event, 2)"/>
    <input #index3 type="password" id="tab3" required pattern="\d*" maxlength="1" class="tab" (input)="onInputEntry($event,'tab',3)" [disabled]="activeInputIndex !== 3" [(ngModel)]="pinCode[3]"
    (keydown)="onInputKeyDown($event, 3)"/>
</div>
