<div class="recordings-container">
  <div class="page-title">
    {{ 'recordingsArchive' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions" *ngIf="breadcrumbOptions"></gk-breadcrumb>

  <div class="row">
    <div class="search-container">
      <input type="text" placeholder="{{'search' | translate}}" (keyup)="applyFilter($event.target.value)" [(ngModel)]="searchStr">
    </div>
    <span class="advanced-search" (click)="showAdvanced = !showAdvanced">
      {{'advancedSearch' | translate}}
    </span>

    <div class="right">
      <!-- <button mat-raised-button class="export-button" (click)="exportToCsv()" disabled="recordings">
        <svg-icon src="assets/img/svg/ic_exported@3x.svg" class="svg-icon"></svg-icon>
        {{'export' | translate}}
      </button> -->
    </div>
  </div>

  <mat-divider></mat-divider>

  <!-- Filters -->
  <div class="filters-card" [hidden]="!showAdvanced">
    <gk-filter recordingFilter="true"></gk-filter>
    <button class="serach-button" mat-raised-button (click)="getRecordings()">
      <mat-icon>search</mat-icon>
      {{'search' | translate}}
    </button>
  </div>

  <div class="skins-container" [ngStyle]="{'padding-top' : showAdvanced ? '35px': '0px'}">
    <!-- Recordings -->
    <ng-container>
      <gk-table [options]="tableOptions" *ngIf="tableOptions && tableOptions.dataSource"></gk-table>
    </ng-container>
  </div>
</div>
