import { ConfirmationComponent } from './../../../modals/confirmation.modal/confirmation.modal.component';
import { GkSnackbar } from './../../../../services/gkSnackbar/gk-snackbar.service';
import { BreadCrumbOptions, TableOptions, ColumnTemplete, GkMode, GkUiState } from '../../../../contracts/ui.contracts';
import { AppState, GkTeam } from '../../../../contracts/contracts';
import { TranslationService } from '../../../../services/translation-service.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

import { TeamsService } from '../teams.service';
import * as moment from 'moment';
import { Config } from '../../../../shared/config';
import { UiStateService } from '../../../../services/ui.state.service';
import { take } from 'rxjs/operators';
import { LocalFullDateFormatPipe } from 'app/shared/pipes/localTime.pipe';

@Component({
  selector: 'teams-list',
  templateUrl: './teamsList.component.html',
  styleUrls: ['../teams.component.scss'],
  providers: []
})
export class TeamsListComponent implements OnInit {

  public uiState: GkUiState;
  public disabledFilter: boolean;
  public dataSource: any;
  public teams: GkTeam[];
  public breadcrumbOptions: BreadCrumbOptions;
  public tableOptions: TableOptions;
  public inAction: boolean;
  public selectedTab: number;
  public tabCounter: any;
  public subscriptions: any[] = [];

  constructor(
    private teamsService: TeamsService,
    private translationService: TranslationService,
    private store: Store<AppState>,
    private router: Router,
    private snackbar: GkSnackbar,
    public dialog: MatDialog,
    private uiStateService: UiStateService,
    private localDatePipe: LocalFullDateFormatPipe
  ) { }

  ngOnInit() {
    this.teamsService.getTeams();
    this.initTable();
    this.initTeams();
    this.initBreadCrumb();
    this.getUiState();
  }

  public initTeams() {
    this.subscriptions.push(this.store.select('teams').subscribe((teams: any) => {
      if (teams && teams.length) {
        this.teams = teams;
        this.teamsCount();
        this.initTable(this.teams);
      }
    }));
  }

  public initBreadCrumb() {
    this.breadcrumbOptions = new BreadCrumbOptions();
    this.breadcrumbOptions.path = [{ titleKey: 'teams', url: '../teams-list' }];
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public initTable = (dataSource?: any) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.id = 'teamList';
    this.tableOptions.pageSize = this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.teamList : null;
    this.tableOptions.rowHoverClass = true;
    this.tableOptions.columns = [
      {
        name: 'name',
        displayNameKey: 'name'
      },
      {
        name: 'created',
        displayNameKey: 'created',
        template: ColumnTemplete.Date
      },
      {
        name: 'monitor',
        displayNameKey: 'actions',
        template: ColumnTemplete.MenuUpdateButton,
        isTeam: true,
        onClick: (e: any, team: GkTeam) => {
          if (e.target.id.indexOf('delete') !== -1) {
            this.openDeleteModal(team);
          } else if (e.target.id.indexOf('edit') !== -1) {
            if (!this.inAction) {
              this.inAction = true;
              this.router.navigateByUrl('team/' + team._id);
            }
          } else {
            this.toggleDisable(team);
          }
        }
      }];
    if (this.disabledFilter) {
      this.tableOptions.disabledFilter = {
        isItemsDisabled: true
      };
    } else {
      this.tableOptions.disabledFilter = {
        isItemsDisabled: false
      };
    }
    if (dataSource) {
      this.tableOptions.dataSource = dataSource;
    }
  };

  public deleteTeam = (teamId: string) => {
    // fb style - dont wait to the server response, rollback if needed
    let index = this.teams.findIndex((team: GkTeam) => {
      return team._id === teamId;
    });
    // save for rollback option
    let savedTeam = Object.assign({}, this.teams[index]);
    let teamName = this.teams[index].name;
    // remove deleted team from team list
    this.teams.splice(index, 1);
    this.teamsCount();
    this.initTable(this.teams);
    this.subscriptions.push(this.teamsService.DeleteTeam(teamId).subscribe(
      () => {
        // Update store
        this.store.dispatch({ type: 'SET_TEAMS', payload: this.teams });
        this.snackbar.open(teamName + ' ' + this.translationService.getTranslation('wasRemoved'));
      },
      () => {
        // Roolback if needed
        this.snackbar.open(this.translationService.getTranslation('failedToDelete') + ' ' + teamName);
        this.teams.push(savedTeam);
        this.store.dispatch({ type: 'SET_TEAMS', payload: this.teams });
        this.teamsCount();
        this.initTable(this.teams);
      }
    ));
  };

  public openTeam = (mode: GkMode, teamId: string) => {
    switch (mode) {
      case GkMode.Add:
        this.router.navigateByUrl('teams/new-team');
        break;
      case GkMode.Update:
        this.router.navigateByUrl('teams/' + teamId);
        break;
      default:
        this.router.navigateByUrl('404');
        return;
    }
  };

  public openDeleteModal(team: GkTeam): void {
    let confirmDialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      height: '300px',
      data: {
        team,
        deepWarning: true
      }
    });

    confirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteTeam(team._id);
      }
      this.inAction = false;
    });
  }

  public exportToCsv = () => {
    let newTeams: any = this.teams.slice();
    let headers = ['Name', 'Description', 'Activity Count', 'Last Active', 'Created'];

    let csvFileName = 'teams-' + moment.utc().format('MM-DD-YYYY');
    let formattedArray = this.formatArray(newTeams);
    let safeArray = Config.checkChar(formattedArray);
    this.subscriptions.push(this.teamsService.notifyTeamsExport().subscribe(() => {
      // tslint:disable-next-line:no-unused-expression
      new Angular5Csv(safeArray, csvFileName, {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        noDownload: false,
        headers
      });
    }));
  };

  public formatArray = (formmatedDatesArray: any[]) => {
    let newArray = [];
    for (let i = 0; i < formmatedDatesArray.length; i++) {
      // Handle each team properties
      newArray.push({
        name: formmatedDatesArray[i]['name'],
        description: this.formatTool('description', formmatedDatesArray[i]['description']),
        activities: formmatedDatesArray[i]['activities'],
        lastActivityTimestamp:
          this.formatTool('lastActivityTimestamp', formmatedDatesArray[i]['lastActivityTimestamp']),
        created: this.formatTool('created', formmatedDatesArray[i]['created']),
        disabled: !formmatedDatesArray[i].enabled
      });
    }
    return newArray;
  };

  public formatTool = (key: string, data: any) => {
    // Format dates
    if (key === 'created' || key === 'lastActivityTimestamp') {
      return this.localDatePipe.transform(data);
    }
    // Fill empty cells
    if (!data || data === '') {
      return this.translationService.getTranslation('noData');
    }
    return data;
  };

  public handleTabChange = (allTeams: boolean) => {
    // All teams
    if (allTeams) {
      this.disabledFilter = false;
    } else {
      // Diactivated teams
      this.disabledFilter = true;
    }
    this.initTable(this.teams);
  };

  public toggleDisable = (team: GkTeam) => {
    team.enabled = !team.enabled;
    if (team.enabled) {
      team.mode = (team.mode & Config.TeamModes.DISABLED);
    } else {
      team.mode = team.mode &= ~Config.TeamModes.DISABLED;
    }
    this.subscriptions.push(this.teamsService.UpdateTeam(team, team._id).pipe(take(1)).subscribe(
      (res: any) => {
        if (res.enabled || team.enabled) {
          this.snackbar.open(team.name + ' ' + this.translationService.getTranslation('wasEnabled'));
          this.handleTabChange(true);
          this.tabCounter.disabled--;
          this.tabCounter.enabled++;
        } else {
          this.snackbar.open(team.name + ' ' + this.translationService.getTranslation('wasDisabled'));
          this.handleTabChange(false);
          this.tabCounter.disabled++;
          this.tabCounter.enabled--;
        }
      },
      () => {
        this.snackbar.open(this.translationService.getTranslation('failedToUpdate') + ' ' + team.name);
      }
    ));
  };

  public teamsCount = () => {
    this.tabCounter = {
      enabled: 0,
      disabled: 0
    };
    for (let i = 0; i < this.teams.length; i++) {
      if (this.teams[i].enabled) {
        this.tabCounter.enabled++;
      } else {
        this.tabCounter.disabled++;
      }
    }
  };

  public getUiState = () => {
    this.subscriptions.push(this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
      this.uiState = state;
      this.tableOptions.pageSize = this.uiState.itemPerPage.usersList;
      this.initTable(this.teams);
    }));
  }
}
