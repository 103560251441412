import { GkOrganization, GkIncident } from './../../../../contracts/contracts';
import { BreadCrumbOptions, TableOptions, GkUiState, ColumnTemplete } from '../../../../contracts/ui.contracts';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UiStateService } from '../../../../services/ui.state.service';
import { IncidentsService } from './incidents.service';
import { take } from 'rxjs/operators';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';

import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslationService } from 'app/services/barrel-services';
import { Config } from 'app/shared/config';
import { UsersService } from '../../users/users.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LocalFullDateFormatPipe } from 'app/shared/pipes/localTime.pipe';
const DEFAULT_PAGE_SIZE = 10;
const FISRT_PAGE = 0;

@Component({
  selector: 'incidents-list',
  templateUrl: './incidentsList.component.html',
  styleUrls: ['./incidentsList.component.scss']
})

export class IncidentsListComponent implements OnInit {
  public dataSource: any;
  public incidents: GkIncident[];
  public breadcrumbOptions: BreadCrumbOptions;
  public tableOptions: TableOptions;
  public organization: GkOrganization;
  public uiState: GkUiState;
  public subscriptions: any[] = [];
  totalIncidentsLength: number;

  constructor(
    private incidentsService: IncidentsService,
    public dialog: MatDialog,
    public uiStateService: UiStateService,
    private router: Router,
    private translationService: TranslationService,
    private usersService: UsersService,
    private loaderService: Ng4LoadingSpinnerService,
    private datePipe: LocalFullDateFormatPipe) { }

  ngOnInit() {
    this.usersService.initUsersDic();
    this.getIncidentsCount();
    this.breadcrumbOptions = new BreadCrumbOptions();
    this.breadcrumbOptions.path = [{ titleKey: 'incidents', url: '../incidents' }];
    this.getUiState();
    this.incidentsInit();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public incidentsInit = () => {
    this.incidentsService.GetIncidents(FISRT_PAGE, this.uiState && this.uiState.itemPerPage &&
      this.uiState.itemPerPage.incidents ? this.uiState.itemPerPage.incidents : DEFAULT_PAGE_SIZE)
      .pipe(take(1))
      .subscribe((incidents: any) => {
        // Paginated req
        if (incidents && incidents.length) {
          this.incidents = this.formatIncidents(incidents);
          this.tableInit(this.incidents);
        }
      }, () => {
        console.error('Failed to get organization features');
      });
  }

  getIncidentsCount() {
    // get incident count
    this.incidentsService.getIncidentsCount().subscribe((res: any) => {
      this.totalIncidentsLength = res.count;
    });
  }

  public getMoreIncidents = (page: number, skip: number) => {
    // Got all Data
    this.subscriptions.push(this.incidentsService.GetIncidents(page, skip)
      .pipe(take(1)
      ).subscribe((incidents: any) => {
        // Paginated req
        if (incidents && incidents.length) {
          this.incidents = this.formatIncidents(incidents);
          this.tableInit(this.incidents);
        }
      }, () => {
        console.error('Failed to get organization features');
      }));
  }

  public formatIncidents = (incidents: GkIncident[]) => {
    for (let i = 0; i < incidents.length; i++) {
      if (_.get(incidents[i], 'place.geo.properties.address')) {
        incidents[i].address = incidents[i].place.geo.properties.address;
      }
      if (_.get(incidents[i], 'place.geo.coordinates')) {
        incidents[i].location = {
          longitude: incidents[i].place.geo.coordinates[0],
          latitude: incidents[i].place.geo.coordinates[1]
        };
      }
    }
    return incidents;
  }

  public tableInit = (dataSource?: any) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.pageSize = this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.incidents : null;
    this.tableOptions.id = 'incidents';
    this.tableOptions.rowHoverClass = true;
    this.tableOptions.columns = [
      {
        name: 'place.type',
        displayNameKey: 'type',
        template: ColumnTemplete.IncidentType,
        width: '10'
      },
      {
        name: 'name',
        displayNameKey: 'incidentTitle',
        template: ColumnTemplete.Elipsys
      },
      {
        name: 'created',
        displayNameKey: 'created',
        template: ColumnTemplete.Date,
        width: '15'
      },
      {
        name: 'closeTime',
        displayNameKey: 'endTime',
        template: ColumnTemplete.Date,
        width: '15'
      },
      {
        name: 'creator.name',
        displayNameKey: 'createdBy',
        width: '15'
      }, {
        name: 'address',
        displayNameKey: 'location',
        template: ColumnTemplete.AddressLocationTooltip
      }
    ];
    this.tableOptions.pagination = {
      pageChange: (event) => {
        this.getMoreIncidents(event.pageIndex, event.pageSize);
      },
      totalItemsLength: this.totalIncidentsLength
    };
    this.tableOptions.onRowClicked = (e: any, incident: GkIncident) => {
      // save incident becouse we don't have get req api
      this.incidentsService.saveClickedIncident(incident);
      this.router.navigateByUrl('incident/' + incident._id);
    };
    if (dataSource) {
      // add last seen sort
      if (this.incidents && this.incidents[0]) this.incidents = this.sortByLastSeen(dataSource);
      this.tableOptions.dataSource = this.incidents;
    }
  };

  public exportToCsv = () => {
    this.loaderService.show();
    let headers = [
      this.translationService.getTranslation('created'),
      this.translationService.getTranslation('endTime'),
      this.translationService.getTranslation('createdBy'),
      this.translationService.getTranslation('incidentTitle'),
      this.translationService.getTranslation('entities'),
      this.translationService.getTranslation('location'),
      this.translationService.getTranslation('activitiesCount')
    ];
    let csvFileName = 'incidentsList-' + moment.utc().format('MM-DD-YYYY');
    let formattedArray = this.formatIncidentsForCsv(this.incidents);
    let safeArray = Config.checkChar(formattedArray);
    this.subscriptions.push(this.incidentsService.notifyIncidientsExport().subscribe(() => {
      // tslint:disable-next-line:no-unused-expression
      new Angular5Csv(safeArray, csvFileName, {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        noDownload: false,
        headers
      });
    }));
    this.loaderService.hide();
  };

  public formatIncidentsForCsv = (incidents: GkIncident[]) => {
    let formmatedArray = [];
    for (let i = 0; i < incidents.length; i++) {
      formmatedArray.push({
        a: this.datePipe.transform(incidents[i].created),
        b: this.datePipe.transform(incidents[i].closeTime),
        c: incidents[i].creator ? incidents[i].creator.name : this.translationService.getTranslation('unknownEntity'),
        e: incidents[i].name,
        g: this.getIncidentResponders(incidents[i].responders),
        h: _.get(incidents[i], 'place.geo.properties.address') ? incidents[i].place.geo.properties.address :
        this.translationService.getTranslation('noGeolocation'),
        i: incidents[i].activities || 0
      });
    }
    return formmatedArray;
  };

  public getUiState = () => {
    this.subscriptions.push(this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
      this.uiState = state;
      if (!this.tableOptions) this.tableOptions = new TableOptions();
      this.tableOptions.pageSize = this.uiState.itemPerPage.incidents || DEFAULT_PAGE_SIZE;
      this.tableInit(this.incidents);
    }));
  }

  public sortByLastSeen = (array: any[]) => {
    let sortedArray = [];
    sortedArray = array.sort((a: any, b: any) => {
      if (!a.lastSeen) return 1;
      if (!b.lastSeen) return -1;
      if (Number.isNaN(b.lastSeen - a.lastSeen)) {
        return new Date(b.lastSeen).getTime() - new Date(a.lastSeen).getTime();
      }
      return b.lastSeen - a.lastSeen;
    });
    return sortedArray;
  }

  public getIncidentResponders = (responders: string[]) => {
    let fullResponders = [];
    for (let i = 0; i < responders.length; i++) {
      let entity = this.usersService.GetLocalUser(responders[i]);
      fullResponders.push(entity ? entity.name : this.translationService.getTranslation('unknownEntity'));
    }
    return JSON.stringify(fullResponders);
  }
}
