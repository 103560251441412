import { UsersService } from './../pages/users/users.service';
import { TranslationService } from './../../services/translation-service.service';
import { TableOptions, ColumnTemplete } from './../../contracts/ui.contracts';
import { GkIntegration, GkEntity, IntegrationType } from './../../contracts/contracts';
import { IntegrationsService } from './../pages/integrations/integrations.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadCrumbOptions, GkMode } from '../../contracts/ui.contracts';
import { MatDialog } from '@angular/material/dialog';
import { GkSnackbar } from '../../services/gkSnackbar/gk-snackbar.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ConfirmationComponent } from '../modals/confirmation.modal/confirmation.modal.component';
import { INTEGRATIONS_CONSTS } from '../pages/integrations/integrationConsts';
import { Config } from '../../shared/config';
import { MatTooltipModule } from '@angular/material';

@Component({
  selector: 'gk-integration',
  templateUrl: './gk-integration.component.html',
  styleUrls: ['./gk-integration.component.scss'],
  providers: [MatTooltipModule],
})
export class GkIntegrationComponent {
  public breadcrumbOptions: BreadCrumbOptions;
  public tableOptions: TableOptions;
  public mode: GkMode;
  public integration: GkIntegration;
  public authTypeStrings: string[] = INTEGRATIONS_CONSTS.authTypeStrings;
  public integrationTypeStrings: string[] = INTEGRATIONS_CONSTS.integrationTypeStrings;
  public disabledFilter: boolean;
  public tabCounter: any;
  public subscriptions: any[] = [];
  // ENUM
  public IntegrationType = IntegrationType;
  constructor(
    private route: ActivatedRoute,
    private integrationService: IntegrationsService,
    private translationService: TranslationService,
    public dialog: MatDialog,
    private snackbar: GkSnackbar,
    private router: Router,
    private loaderService: Ng4LoadingSpinnerService,
    private usersService: UsersService
  ) {
    this.initTable();
    this.init();
    this.breadcrumbOptions = new BreadCrumbOptions();
    this.breadcrumbOptions.path = [{ titleKey: 'integrations', url: '/integrations' }];
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  private init = () => {
    this.subscriptions.push(
      this.route.params.subscribe(
        (params: any) => {
          if (params.integrationId) {
            this.subscriptions.push(
              this.integrationService.GetIntegration(params.integrationId).subscribe((integration: GkIntegration) => {
                this.integration = integration;
                this.breadcrumbOptions.itemId = integration.name;
                if (this.integration.entities) {
                  this.entitiesCount();
                }
                this.initTable();
              })
            );
          }
        },
        (error: any) => {
          this.loaderService.hide();
          this.snackbar.open(this.translationService.getTranslation('integrationNotFound'));
        }
      )
    );
  };

  public openDeleteModal(entity: any): void {
    let confirmDialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      height: '300px',
      data: {
        entity,
        deepWarning: true,
      },
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteEntity(entity._id);
      }
    });
  }

  public deleteEntity = (entity: GkEntity) => {
    // fb style - dont wait to the server response, rollback if needed
    let savedEntity = Object.assign({}, entity);
    let index = this.integration.entities.findIndex((checkedEntity: GkEntity) => {
      return entity._id === checkedEntity._id;
    });
    this.integration.entities.splice(index, 1);
    this.entitiesCount();
    this.initTable();
    let entityName: string = this.integration.entities[index].name;
    this.loaderService.show();
    this.subscriptions.push(
      this.integrationService.DeleteEntity(entity._id).subscribe(
        (isDeleted: any) => {
          this.snackbar.open(entityName + ' ' + this.translationService.getTranslation('wasRemoved'));
        },
        (error: any) => {
          this.snackbar.open(this.translationService.getTranslation('failedToDelete') + ' ' + entityName);
          // Rollback
          this.integration.entities.push(savedEntity);
          this.entitiesCount();
          this.initTable();
        },
        () => {
          this.loaderService.hide();
        }
      )
    );
  };

  public initTable = () => {
    this.tableOptions = new TableOptions();
    this.tableOptions.rowHoverClass = true;
    this.tableOptions.columns = [
      {
        name: 'name',
        displayNameKey: 'name',
      },
      {
        name: 'created',
        displayNameKey: 'created',
        template: ColumnTemplete.Date,
      },
      {
        name: '_id',
        displayNameKey: 'actions',
        template: ColumnTemplete.MenuUpdateButton,
        onClick: (e: any, entity: GkEntity) => {
          if (e.target.id.indexOf('delete') !== -1) {
            this.openDeleteModal(entity);
          } else if (e.target.id.indexOf('edit') !== -1) {
            this.router.navigateByUrl('entity/' + entity._id);
          } else {
            this.toggleDisable(entity);
          }
        },
      },
    ];
    if (this.disabledFilter) {
      this.tableOptions.disabledFilter = {
        isItemsDisabled: true,
      };
    } else {
      this.tableOptions.disabledFilter = {
        isItemsDisabled: false,
      };
    }
    this.tableOptions.dataSource =
      this.integration && this.integration.entities ? this.integration.entities : undefined;
  };

  public copyUrl = (inputElement: HTMLInputElement) => {
    // Copy the url
    let url = this.getIntegrationUrl();
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  };

  public getIntegrationUrl = () => {
    if (!this.integration || !this.integration._id) return;
    return Config.ApiUrl + '/integrations/' + this.integration._id;
  };

  public fetchEntities = () => {
    this.subscriptions.push(
      this.integrationService.fetchIntegrationEntities(this.integration._id).subscribe(
        (res: any) => {
          // entities fetched
          if (res && res.length) {
            this.integration.entities = res;
            this.snackbar.open(this.translationService.getTranslation('entitiesIntegrationFetchSuccess'));
            this.initTable();
          }
        },
        // entities fetch error
        (e: any) => {
          this.loaderService.hide();
          console.log('Failed to fetch integration entities');
        }
      )
    );
  };

  public handleDisableChange = (allEntities: boolean) => {
    // All entities
    if (allEntities) {
      this.disabledFilter = false;
    } else {
      // Diactivated entities
      this.disabledFilter = true;
    }
    this.initTable();
  };

  public toggleDisable = (entity: GkEntity) => {
    entity.enabled = !entity.enabled;
    this.subscriptions.push(
      this.usersService.UpdateUser(entity._id, entity).subscribe(
        (res: any) => {
          if (res.enabled) {
            this.snackbar.open(entity.name + ' ' + this.translationService.getTranslation('wasEnabled'));
            this.handleDisableChange(true);
            this.tabCounter.disabled--;
            this.tabCounter.enabled++;
          } else {
            this.snackbar.open(entity.name + ' ' + this.translationService.getTranslation('wasDisabled'));
            this.handleDisableChange(false);
            this.tabCounter.disabled++;
            this.tabCounter.enabled--;
          }
        },
        (e: any) => {
          this.snackbar.open(this.translationService.getTranslation('failedToUpdate ' + entity.name));
        }
      )
    );
  };

  public entitiesCount = () => {
    this.tabCounter = {
      enabled: 0,
      disabled: 0,
    };
    for (let i = 0; i < this.integration.entities.length; i++) {
      if (this.integration.entities[i].enabled) {
        this.tabCounter.enabled++;
      } else {
        this.tabCounter.disabled++;
      }
    }
  };
}
