import { TranslationService } from './../../../../services/translation-service.service';
import { UsersService } from './../../users/users.service';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { GkTeam, GkUser, GkEntity } from './../../../../contracts/contracts';
import { GkAlertType } from '../../../../contracts/ui.contracts';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { moreThanOneValidator } from '../../../../shared/validations';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Config } from 'app/shared/config';

@Component({
  selector: 'entity-form',
  templateUrl: './entity-form.component.html',
  styleUrls: ['./entity-form.component.scss']
})
export class EntityFormComponent implements OnInit {
  @Input()
  set gkEntity(entity: GkEntity) {
    if (entity) {
      this.entity = entity;
      this.form.get('identifier').setValue(entity.entityid);
      this.form.get('name').setValue(entity.name);
      this.form
        .get('description')
        .setValue(entity.extras && entity.extras.description ? entity.extras.description : '');
      this.form.get('selectedTeams').setValue(this.onSelectedTeams(this.entity.teams));
      this.detectChanges();
    }
  }

  public entity: GkEntity;
  public teams: GkTeam[] = [];
  public users: GkUser[] = [];
  public selectedTeams: string[] = [];
  public form: FormGroup;
  public selectedImage: any;
  public formChanges: boolean;
  // Consts
  public CameraEntityType: any = Config.EntityTypes.CAMERA;
  constructor(
    private usersService: UsersService,
    private fb: FormBuilder,
    private snackbar: GkSnackbar,
    private translationService: TranslationService,
    private router: Router
  ) {
    this.initForm();
  }

  ngOnInit() { }

  // Init form values and validators
  public initForm = () => {
    this.form = this.fb.group({
      identifier: [{ value: '', disabled: true }, { disabled: true }, Validators.compose([Validators.required])],
      name: ['', Validators.compose([Validators.required])],
      description: ['', Validators.compose([])],
      selectedTeams: [null, moreThanOneValidator]
    });
  };

  // Form submit and hanlde request
  public updateIntegration = (entity: GkEntity = this.entity) => {
    this.entity.team = this.selectedTeams[0];
    let obj = {
      entityid: entity.entityid,
      name: this.form.value.name,
      extras: { description: this.form.value.description },
      teamId: entity.team || this.selectedTeams[0],
      teams: this.selectedTeams,
      file: this.selectedImage,
      location: this.entity.location
    };
    this.usersService.UpdateUser(this.entity._id, obj).subscribe(
      (res: any) => {
        this.snackbar.openSweetAlert(
          GkAlertType.Success,
          entity.name + ' ' + this.translationService.getTranslation('wasEdited')
        );
        this.router.navigateByUrl('integrations/' + res.integration);
      },
      () => {
        this.snackbar.openSweetAlert(
          GkAlertType.Error,
          entity.name + ' ' + this.translationService.getTranslation('failedToUpdate')
        );
      }
    );
  };

  // Handle image upload
  public onImageSelected = (image: any) => {
    this.selectedImage = image;
  };

  // Team selection event
  public onSelectedTeams = (selectedTeams: GkTeam[]) => {
    let teams: string[] = [];
    for (const team of selectedTeams) {
      teams.push(team._id);
    }
    this.selectedTeams = teams;
    // Update form value
    this.form.get('selectedTeams').setValue(this.selectedTeams);
    return this.selectedTeams;
  };

  // Cancel button redirect to team list
  public cancel = () => {
    this.router.navigateByUrl('integrations');
  };

  public onImageDeleted = () => {
    if (!this.entity.extras) this.entity.extras = {};
    this.entity.extras.picture = this.selectedImage = null;
  }

  public onLocationSelected = (location: any) => {
    this.entity.location = location;
  }

  public detectChanges = () => {
    this.form.valueChanges.subscribe((changes: any) => {
      this.formChanges = true;
    });
  }
}
