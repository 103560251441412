import { MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { GkTeam, GkEntity } from "./contracts";

// GK-BreadCrumb
export class BreadCrumbOptions {
  public path: BreadPath[] = [{ titleKey: "No Path", url: "" }];
  public itemId?: string;
}

class BreadPath {
  public titleKey: string;
  public url: string;
}

// GK-Table

export class TableOptions {
  public id: string;
  public title?: string;
  public sort?: boolean = true;
  public paginator?: boolean = true;
  public filter: boolean = true;
  public disabledFilter?: { isItemsDisabled: boolean };
  public columns: GkColumn[];
  public dataSource: any;
  public pageSize: number = 10;
  public pageSizeOptions: number[] = [];
  public onRowClicked?: (data: any, $event?: any) => any;
  public rowHoverClass: boolean = true;
  public rowError: boolean = false;
  public hideSearch: boolean = false;
  public customSort?: MatSortable;
  public pagination?: GkPagination;
  public fullActivitiesData?: any[];
  public isLoading?: boolean;
}

export class GkPagination {
  public totalItemsLength: number;
  public pageChange?: ($event?: any) => any;
}

export class GkColumn {
  public name: string;
  public displayNameKey: string;
  public template?: ColumnTemplete;
  public templateCode?: string;
  public width?: string;
  public onClick?: (data: any, $event?: any) => any;
  public isTeam?: boolean;
}

export enum ColumnTemplete {
  DeleteButton = 1,
  UpdateButton = 2,
  DeleteUpdateButton = 3,
  Custom = 4,
  Interval = 5,
  Date = 6,
  DateOnly = 7,
  Time = 8,
  DownloadButton = 9,
  ActivityTooltip = 10,
  LocationTooltip = 11,
  Cellular = 12,
  Location = 13,
  MenuUpdateButton = 14,
  trustButton = 15,
  recordingsActions = 16,
  recordingsTooltip = 17,
  FileSize = 18,
  Duration = 19,
  EmailStatus = 20,
  EmailActions = 21,
  MagicEmailActions = 22,
  ActivityElipsys = 23,
  Elipsys = 24,
  IncidentType = 25,
  AddressLocationTooltip = 26,
  MaxHits = 27,
  Boolean
}

export class GkRecording {
  sessionId: string;
  team: any; // string from the server || converted to GkTeam;
  duration: number;
  meta: GkRecordingMeta;
  url: string;
  size: number;
  entities: string[];
  started: Date;
  stopped: Date;
  created: Date; // when the db got updated
  date?: string; // optional front end property
  time?: string; // optional front end property
}

export class GkRecordingMeta {
  [entityId: string]: {
    [sessionId: string]: RecordingMeta;
  };
}

export class RecordingMeta {
  started: Date;
  stopped: Date;
  size: number;
  duration: number;
  url: string; // optional front end property
  entity?: GkEntity; // optional front end property
}
export class AutocompleteOptions {
  public selectable?: boolean;
  public removable?: boolean;
  public allItems: any[];
  public addOnBlur: boolean;
  public placeholder: string;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public defaultItems: any[];
}

export class LineChartOptions {
  public dataSource: any[];
  public warningCount: number;
  public limitCount: number;
  public maxGraphY: number;
  public maxGraphX: number;
  public licenseWarn: boolean;
}

export enum GkMode {
  Add = 1,
  Update = 2
}

export enum GkAlertType {
  Success = 1,
  Error = 2
}

export enum HistoryStyle {
  List = 1,
  Map = 2,
  Recording = 3
}

// UI-STATE
export class GkUiState {
  public itemPerPage: {
    usersList: number;
    teamList: number;
    sessionsManagment: number;
    historyTable: number;
    untrustedDevices: number;
    recordingsTable: number;
    recordingSessionTable: number;
    emailInvitations: number;
    incidents: number;
    magicEmailInvitations: number;
  };
  public invitationPinCode?: boolean;
}

export enum StateProperties {
  itemPerPage = "itemPerPage",
  invitationPin = "invitationPin"
}

export enum PaingationItems {
  Sessions = 0,
  incidents = 1,
  Invitations = 2,
  MagicInvitations = 3
}

export enum EntityTypes {
  USER = 0,
  TRACKER = 1,
  RADIO = 2,
  CAMERA = 3,
  PHONE = 4,
  COMMUNITY = 5,
  VOLUNTEER = 6,
  SNIPER = 7
}
