import { UsersService } from '../../users/users.service';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { GKLicenseActivity, GkOrganization } from '../../../../contracts/contracts';
import { OrganizationService } from '../organization.service';
import { Component, OnDestroy } from '@angular/core';
import { TranslationService } from '../../../../services/barrel-services';
import { GkUiState, LineChartOptions } from '../../../../contracts/ui.contracts';
import { Constants } from '../../../../shared/constant';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from 'moment';

const DAY_MS: number = 24 * 60 * 60 * 1000;

@Component({
  selector: 'gk-dashboard',
  templateUrl: './gk-dashboard.component.html',
  styleUrls: ['./gk-dashboard.component.scss']
})
export class GkDashboardComponent implements OnDestroy {
  public licenseInitialized: any;
  public licenses: GKLicenseActivity[];
  public uiState: GkUiState;
  public counters: any;
  public organization: GkOrganization;
  public IS_CLOUD: boolean;
  public email: string;
  public phone: string;
  public lineChartOptions: LineChartOptions;
  public licenseWarningChecked: boolean;
  public maxLicensesWarning: number;
  public subscriptions: any[] = [];
  public preparingUsageData = false;

  constructor(
    private organizationService: OrganizationService,
    private snackBar: GkSnackbar,
    private translationService: TranslationService,
    private usersService: UsersService
  ) {
    this.usersService.getUsers();
    this.initOrganization();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public initOrganization = () => {
    this.subscriptions.push(
      this.organizationService.GetMyOrganization().subscribe((organization: any) => {
        this.phone = organization.phone;
        this.email = organization.email;

        this.organization = organization;
        // parse organization to component variables
        this.counters = [
          {
            name: 'users',
            count: this.organization.usersCount,
            url: '/users-list'
          },
          {
            name: 'teams',
            count: this.organization.teamsCount,
            url: '/teams-list'
          },
          {
            name: 'incidents',
            count: this.organization.incidentsCount,
            url: '/incidents'
          },
          {
            name: 'integrations',
            count: this.organization.integrationsCount,
            url: '/integrations'
          }
        ];
        if (!this.licenseInitialized) this.getLicensesData();
      })
    );
  };

  public updateOrganizationEmail = () => {
    if (Constants.EMAIL_REGEX.test(this.email)) {
      this.organization.email = this.email;
      this.subscriptions.push(
        this.organizationService.UpdateOrganization(this.organization).subscribe(
          () => {
            this.organizationService.localUpdateOrganiztion(this.organization);
            this.snackBar.open(this.translationService.getTranslation('organizationEmailSaved'));
          },
          () => {
            this.snackBar.open(this.translationService.getTranslation('organizationEmailFailed'));
          }
        )
      );
    } else {
      this.email = this.organization.email;
      this.snackBar.open(this.translationService.getTranslation('emailNotValid'));
    }
  };

  public updateOrganizationIcon = (icon: any) => {
    this.organization.file = this.organization.icon = icon;
    this.subscriptions.push(
      this.organizationService.UpdateOrganization(this.organization).subscribe(
        (savedOrganization: GkOrganization) => {
          this.organization.icon = savedOrganization.icon;
          this.organizationService.localUpdateOrganiztion(this.organization);
          this.snackBar.open(this.translationService.getTranslation('organizationIconSaved'));
        },
        () => {
          this.snackBar.open(this.translationService.getTranslation('organizationIconFailed'));
        }
      )
    );
  };

  public updateOrganizationPhone = () => {
    if (this.phone === this.organization.phone) return;
    this.organization.phone = this.phone;
    this.subscriptions.push(
      this.organizationService.UpdateOrganization(this.organization).subscribe(
        (savedOrganization: GkOrganization) => {
          this.organization.phone = savedOrganization.phone;
          this.organizationService.localUpdateOrganiztion(this.organization);
          this.snackBar.open(this.translationService.getTranslation('organizationPhoneSaved'));
        },
        () => {
          this.snackBar.open(this.translationService.getTranslation('organizationPhoneFailed'));
        }
      )
    );
  };

  public onImageDeleted = () => {
    this.updateOrganizationIcon(null);
  };

  public getLicensesData = () => {
    this.licenseWarningChecked = this.organization.seatsWarning > 0;
    this.maxLicensesWarning = this.organization.seatsWarning;
    // return active seats in the last 60 days sorted by last seen
    this.subscriptions.push(
      this.organizationService.GetLicenses().subscribe((licenses: GKLicenseActivity[]) => {
        this.licenses = licenses;
        this.initLicensesGraph();
      })
    );
    this.licenseInitialized = true;
  };

  public initLicensesGraph = () => {
    this.lineChartOptions = new LineChartOptions();
    this.lineChartOptions.licenseWarn = this.organization.seatsWarning > 0;
    this.lineChartOptions.warningCount = this.organization.seatsWarning;
    // add test for make sure that organiztion is here and got no race condition;
    this.lineChartOptions.limitCount = this.organization.seats;
    this.lineChartOptions.dataSource = this.parseLicensesData(this.licenses);
  };

  public licensesWarningToggle = (event: any) => {
    this.licenseWarningChecked = event.checked;
    this.organization.seatsWarning = this.maxLicensesWarning = !this.licenseWarningChecked
      ? 0
      : Math.round(this.organization.seats * 0.8);
    this.subscriptions.push(
      this.organizationService.UpdateOrganization(this.organization).subscribe((org: GkOrganization) => {
        this.organization = org;
        this.initLicensesGraph();
        this.snackBar.open(this.translationService.getTranslation('licensesLimitWarningHasUpdated'));
      })
    );
  };

  public licenseCountChange = (event: any) => {
    let value;
    if (typeof event === 'number') {
      value = event;
    } else {
      value = parseInt(event.target.value);
    }
    this.maxLicensesWarning = this.organization.seatsWarning = value;
    this.subscriptions.push(
      this.organizationService.UpdateOrganization(this.organization).subscribe(() => {
        this.initLicensesGraph();
        this.snackBar.open(this.translationService.getTranslation('licensesLimitWarningHasUpdated'));
      })
    );
  };

  // Get license array from the server, parse it for gkLineChart-component
  // with a summary of users in each last 60 days
  // return object with {date: users} pairs
  public parseLicensesData = (licenses: GKLicenseActivity[]) => {
    let licensesPerDay = this.prepareLicesnsesDictionary();
    // Got licenses from the old to the new
    for (let i = 0; i < licenses.length; i++) {
      if (!licenses[i].endTime) licenses[i].endTime = new Date().getTime() + DAY_MS;
      licensesPerDay = this.addLicenseActivity(licenses[i], licensesPerDay);
    }
    return licensesPerDay;
  };

  // return dictionary with times as keys with a day between them
  public prepareLicesnsesDictionary = (): any => {
    const DAYS_COUNT: number = 60;
    let daysDic: any = {};
    // Dates parse
    let currentDay = new Date().getTime();
    // set the first day
    daysDic[currentDay] = 0;
    for (let i = 1; i < DAYS_COUNT + 1; i++) {
      // set date in the dictionary
      // set the previous day
      currentDay -= DAY_MS;
      daysDic[currentDay] = 0;
    }
    return daysDic;
  };

  // Add license activity for the next 'seat' time - 30 days
  public addLicenseActivity = (license: GKLicenseActivity, dic: any) => {
    // Find lastSeen time in the dictionary and add one for licenses count
    // tslint:disable-next-line:forin
    for (let day in dic) {
      let dayAfter = parseInt(day) + DAY_MS;
      let dayBefore = parseInt(day) - DAY_MS;
      // checking if activity time is in range of licensecreated day
      if (dayBefore <= license.created && dayAfter >= license.created) {
        dic = this.countLicensesFromCreatedToEndTime(day, dic, license.endTime);
        return dic;
      }
    }
    return dic;
  };

  // add +1 to each day license.created to license.endTIme
  public countLicensesFromCreatedToEndTime = (day: string, dic: any, endTime: number) => {
    let numberedDay: number = parseInt(day);
    let stringDate: string = day;
    while (numberedDay < endTime) {
      // check for non exist future dates
      if (dic[stringDate] || dic[stringDate] === 0) {
        dic[stringDate]++;
      }
      // Go to the next day
      numberedDay = numberedDay + DAY_MS;
      stringDate = numberedDay.toString();
    }
    return dic;
  };

  downloadUsage = () => {
    this.preparingUsageData = true;
    this.organizationService.GetLicensesFor(3).subscribe((response: GKLicenseActivity[]) => {
      const usageArray = [];

      response.forEach((license: GKLicenseActivity) => {
        usageArray.push({
          id: license.entity.entityid,
          name: license.entity.name,
          seat_start: moment(license.created).format('DD/MM/YYYY'),
          seat_end: license.endTime ? 'Not active' : 'Active',
          current_team: license.team ? license.team.name : 'unassigned',
          current_team_desc: license.team && license.team.description ? license.team.description : ''
        });
      });

      this.preparingUsageData = false;
      return new Angular5Csv(usageArray, 'usage', {
        quoteStrings: '',
        headers: ['id', 'name', 'seat start', 'seat end', 'current team', 'current team description']
      });
    });
  };
}
