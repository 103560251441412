import { GkAlertType, EntityTypes } from './../../../../contracts/ui.contracts';
import { Router } from '@angular/router';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { GkTeam, GkUser, GkDevice, SecurityTypes, GkOrganization } from './../../../../contracts/contracts';
import { GkMode } from '../../../../contracts/ui.contracts';
import { Component, OnInit, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import {
  passwordValidator, PasswordValidation, moreThanOneValidator, userBadNamesValidator, specialChartsValidator,
  customEmailValidator, threeSameCharts
} from '../../../../shared/validations';
import * as _ from 'lodash';
import { UsersService } from '../../../pages/users/users.service';
import { TeamsService } from '../../teams/teams.service';
import { TranslationService } from 'app/services/translation-service.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Config } from '../../../../shared/config';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '../../../modals/confirmation.modal/confirmation.modal.component';
import { OrganizationService } from '../../dashboard/organization.service';
import { UiStateService } from 'app/services/ui.state.service';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  public imageChanged: any;
  public userTypes: any;
  public selectedUserType: any;
  public entityTypesDic: any;
  @Input()
  set gkuser(user: GkUser) {
    this.teamsService.getTeams();
    this.mode = GkMode.Add;
    this.user.type = EntityTypes.USER;
    if (user) {
      this.mode = GkMode.Update;
      this.user = Object.assign({}, user);
      this.form.get('userid').setValue(user.entityid);
      this.form.get('name').setValue(user.name);
      // Overide browser autocomplete
      this.form.get('password').setValue(null);
      this.form.get('confirmPassword').setValue(null);
      if (user.email) this.form.get('email').setValue(user.email);
      if (user.monitor) this.form.get('monitor').setValue(user.monitor);
      this.form.get('controlPanel').setValue(user.admin);
      this.form.get('selectedTeams').setValue(this.onSelectedTeams(this.user.teams));
      this.selectedUserType = this.entityTypesDic[user.type];
      // Filter user devices by organization security
      this.filterUserDevices(user);
      this.detectChanges();
    }
  }

  public user: GkUser = new GkUser();
  public uploader: FileUploader = new FileUploader({ url: 'URL' });
  public selectedTeams: string[] = [];
  public teams: GkTeam[] = [];
  public form: FormGroup;
  public password: string;
  public confirmPassword: string;
  public passwordTooltip: boolean;
  // public fullName: string;
  public fields: any[] = [];
  public init: boolean = false;
  public mode: GkMode;
  public selectedImage: any;
  public currentUser: GkUser;
  public formChanges: boolean;
  // enum
  public GkMode = GkMode;
  public EntityTypes = EntityTypes;
  public subscriptions: any[] = [];

  constructor(
    private usersService: UsersService,
    private teamsService: TeamsService,
    private fb: FormBuilder,
    private snackbar: GkSnackbar,
    private router: Router,
    private translationService: TranslationService,
    private loaderService: Ng4LoadingSpinnerService,
    public dialog: MatDialog,
    private organiztionService: OrganizationService,
    private uiStateService: UiStateService
  ) {
    this.getEntityTypes();
  }

  private getEntityTypes() {
    this.userTypes = [
      {
        translationKey: 'volunteer',
        value: this.EntityTypes.VOLUNTEER,
        iconName: 'volunteer.svg'
      },
      {
        translationKey: 'sniper',
        value: this.EntityTypes.SNIPER,
        iconName: 'sniper.svg'
      }, {
        translationKey: 'professional',
        value: this.EntityTypes.USER,
        iconName: 'pro.svg'
      }
    ];
    this.entityTypesDic = {};
    for (let i = 0; i < this.userTypes.length; i++) {
      this.entityTypesDic[this.userTypes[i].value] = this.userTypes[i];
    }
    if (!this.selectedUserType) this.selectedUserType = this.entityTypesDic[Config.EntityTypes.USER];
  }

  ngOnInit() {
    this.setSecurityOptions();
    // Call get teams for init teams in teams service
    // then subscribe to teame store obj
    this.teamsService.getTeams();
    this.usersService.getUsers();
    this.usersService.getCurrentUser().subscribe((user: GkUser) => {
      this.currentUser = user;
    });
    this.initForm();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  // Init form values and validators
  public initForm = () => {
    // Change mode here to win the init condtition before this.user setter
    if (this.router.url !== '/user') this.mode = GkMode.Update;
    let options = {
      userid: ['', Validators.compose([specialChartsValidator, Validators.required])],
      password: ['', Validators.compose([passwordValidator, threeSameCharts, this.mode === GkMode.Add ?
        Validators.required : null])],
      confirmPassword: ['', this.mode === GkMode.Add ? Validators.required : null],
      name: ['', Validators.compose([Validators.required, specialChartsValidator, userBadNamesValidator])],
      // tslint:disable-next-line:max-line-length
      email: ['', customEmailValidator],
      monitor: [false, Validators.required],
      controlPanel: [false, Validators.required],
      selectedTeams: [null, moreThanOneValidator]
    };
    this.form = this.fb.group(options, {
      validator: PasswordValidation.MatchPassword
    });
  };

  public onImageSelected = (image: any) => {
    this.imageChanged = true;
    this.selectedImage = image;
  };

  // Team selection event
  public onSelectedTeams = (selectedTeams: GkTeam[]) => {
    let teams: string[] = [];
    for (const team of selectedTeams) {
      teams.push(team._id);
    }
    this.selectedTeams = teams;
    // Update form value
    this.form.get('selectedTeams').setValue(this.selectedTeams);
    return this.selectedTeams;
  };

  // Form submit and hanlde request
  public onSubmit = () => {
    this.loaderService.show();
    let reqUserObj: any = {
      entityid: this.form.value.userid,
      password: this.form.value.password,
      email: this.form.value.email,
      admin: this.form.value.controlPanel,
      monitor: this.form.value.monitor,
      teams: this.selectedTeams ? this.selectedTeams : this.onSelectedTeams(this.user.teams),
      name: this.form.value.name,
      fields: this.fields,
      entitytype: this.user.type
    };
    if (this.imageChanged) {
      reqUserObj.file = this.selectedImage;
    }
    if (this.mode === GkMode.Add) {
      reqUserObj.teamId = this.selectedTeams[0];
      this.subscriptions.push(this.usersService.CreateUser(reqUserObj).subscribe(
        (res: any) => {
          this.loaderService.hide();
          if (res) {
            this.snackbar.openSweetAlert(
              GkAlertType.Success,
              res.name + ' ' + this.translationService.getTranslation('wasAdded')
            );
            this.router.navigateByUrl('users-list');
          }
        },
        (err: any) => {
          this.loaderService.hide();
          this.handleErrors(err, reqUserObj.name);
        }
      ));
    } else {
      this.subscriptions.push(this.usersService.UpdateUser(this.user._id, reqUserObj).subscribe(
        (user: GkUser) => {
          this.loaderService.hide();
          if (user) {
            this.snackbar.openSweetAlert(
              GkAlertType.Success,
              user.name + ' ' + this.translationService.getTranslation('wasEdited')
            );
            if (this.currentUser && this.currentUser._id === user._id) {
              let updatedCurrentUser = user;
              this.usersService.initCurrentUser(updatedCurrentUser);
            }
            let previousUrl = this.uiStateService.getPreviousUrl();
            this.router.navigateByUrl(previousUrl);
          }
        },
        (err: any) => {
          this.loaderService.hide();
          this.handleErrors(err, reqUserObj.name);
        }
      ));
    }
  };

  // Cancel button redirect to team list
  public cancel = () => {
    this.router.navigateByUrl('users-list');
  };

  // return full name in array [first, last]
  // public getFullName = (userName: string): string[] => {
  //   let first = userName.split(' ')[0];
  //   let last = userName.split(' ')[1];
  //   return [first, last];
  // };
  // get user name from the form
  // public getUserlName = (): string => {
  //   return this.form.value.firstName + ' ' + this.form.value.lastName;
  // };

  // Handle update/add req with same name err
  public handleErrors = (err: any, name: string) => {
    this.snackbar.openSweetAlert(
      GkAlertType.Error,
      this.translationService.getTranslation('failedToUpdate') + ' ' + name + '. ',
      err.error.message
    );
  };

  public checkEntityidAvailablityAndLowerCase = (event: any, isEmail?: boolean) => {
    if (!this.usersService.checkEntityidAvailable(event.target.value) && event.target.value !== this.user.entityid) {
      if (isEmail) {
        this.form.controls['email'].setErrors({ exist: true });
        return;
      }
      this.form.controls['userid'].setErrors({ exist: true });
    }
  };

  public getDeviceIcon = (userAgent: string) => {
    return Config.getDeviceIcon(userAgent);
  };

  public openDeleteModal(device: GkDevice): void {
    let confirmDialog = this.dialog.open(ConfirmationComponent, {
      data: {
        device: {
          name: device.name
        }
      }
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteTrustedDevice(device);
      }
    });
  }

  public deleteTrustedDevice = (device: GkDevice) => {
    let index = this.user.devices.findIndex((trustedDevice: GkDevice) => {
      return trustedDevice._id === device._id;
    });
    this.subscriptions.push(this.usersService.DeclineDevice(device._id).subscribe(
      () => {
        this.user.devices.splice(index, 1);
        this.snackbar.openSweetAlert(
          GkAlertType.Success,
          device.name + ' ' + this.translationService.getTranslation('wasRemoved')
        );
      },
      () => {
        this.snackbar.openSweetAlert(
          GkAlertType.Error,
          this.translationService.getTranslation('failedToDelete') + ' ' + device.name
        );
      }
    ));
  };

  public onImageDeleted = () => {
    this.imageChanged = true;
    if (!this.user.extras) this.user.extras = {};
    this.user.extras.picture = this.selectedImage = null;
  }

  public setSecurityOptions = () => {
    this.organiztionService.isSecurityFeatureOn(SecurityTypes.DEVICE_TRUST_USER).then((securityFeatureOn: boolean) => {
      if (securityFeatureOn) {
        let emailField: AbstractControl = this.form.get('email');
        emailField.setValidators([Validators.required, Validators.email]);
        emailField.updateValueAndValidity();
      }
    });
  }

  public filterUserDevices = (user: GkUser): any => {
    this.subscriptions.push(this.organiztionService.GetMyOrganization().subscribe((org: GkOrganization) => {
      if (!(Config.hasFlags(org.security, [Config.SecurityFeatures.DEVICE_TRUST_ADMIN,
         Config.SecurityFeatures.DEVICE_TRUST_USER]))) {
        this.user.devices = user.devices || [];
        return;
      }
      let approvedDevices: GkDevice[] = [];
      for (let i = 0; i < user.devices.length; i++) {
        if (Config.hasFlag(user.devices[i].trusted, org.security)) {
          approvedDevices.push(user.devices[i]);
        }
      }
      this.user.devices = approvedDevices;
    }));
  };

  public passwordFormFocused = (isFocus: boolean) => {
    this.passwordTooltip = isFocus;
  }

  public passwordTyped = () => {
    if (this.form.get('password').value && this.form.get('password').value.length === 1
      && this.form.get('password').errors) {
      this.passwordTooltip = false;
      setTimeout(() => {
        this.passwordTooltip = true;
      }, 200);
    }
  }

  public detectChanges = () => {
    this.form.valueChanges.subscribe((changes: any) => {
      this.formChanges = true;
    });
  }

  public getUserStyle = (userType: any) => {
    return this.user.type === userType.value ? '#0dcbcb' : '#8a96aa';
  }

  public onTypeChanged = (event: MatSelectChange) => {
    this.selectedUserType = this.entityTypesDic[event.value];
  }
}
