import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'trustConfirmationModal',
  templateUrl: './trustConfirmation.modal.component.html',
  styleUrls: ['./trustConfirmation.modal.component.scss']
})
export class TrustConfirmationComponent implements OnInit {
  public type: string;
  constructor(private dialogRef: MatDialogRef<TrustConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
