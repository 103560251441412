import { BreadCrumbOptions } from './../../contracts/ui.contracts';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gk-breadcrumb',
  templateUrl: './gk-breadcrumb.component.html',
  styleUrls: ['./gk-breadcrumb.component.scss']
})
export class GkBreadcrumbComponent implements OnInit {

  public isRtl: boolean;
  // declear input (by bindings from outside the component)
  @Input() options: BreadCrumbOptions;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.detectLangChange();
  }

  public detectLangChange = () => {
    if (this.translateService.currentLang === 'he') return this.isRtl = true;
    this.translateService.onLangChange.subscribe((res: any) => {
      if (res.lang === 'he') return this.isRtl = true;
      return this.isRtl = false;
    });
  }

}
