<div
  class="row gk-filter-controller"
  fxLayout="row"
  fxLayoutAlign="center start"
  [ngClass]="{ 'special-filter': recordingFilter || incidentFilter }"
>
  <!-- Date -->
  <div class="col-md-3 filter-container">
    <label class="filter-title">{{ 'dateRanges' | translate }}</label>
    <div class="md-radio" *ngFor="let range of dateRanges">
      <input type="radio" id="{{ range }}" name="time_range" class="md-radiobtn" (click)="setDateRange(range)" />
      <label for="{{ range }}">
        <span></span>

        <span class="box"><span class="check" [ngClass]="{ checked: selectedStringRange === range }"></span></span
        >{{ range | translate }}</label
      >
      <input
        *ngIf="pickerOptions && range === 'customDate'"
        id="datePickerInput"
        [owlDateTime]="dt1"
        [owlDateTimeTrigger]="dt1"
        [min]="pickerOptions.minDate"
        [max]="pickerOptions.maxDate"
        [selectMode]="pickerOptions.selectMode"
        [(ngModel)]="selectedRange"
        placeholder="{{ 'customDate' | translate }}"
        class="datepicker"
        (dateTimeChange)="onDateSelected($event)"
      />
      <owl-date-time #dt1></owl-date-time>
    </div>
  </div>

  <!-- Teams -->
  <div class="col-md-2 filter-container" *ngIf="!incidentFilter">
    <div class="form-group">
      <label class="filter-title">{{ 'teams' | translate }}</label>
<!--      <div class="md-checkbox">-->
<!--        <label for="allTeamsCheckbox" class="my-checkbox" fxLayout="row">-->
<!--          <input-->
<!--            [checked]="allTeamsChecked"-->
<!--            (change)="handleAllTeamsClicked($event.target.checked)"-->
<!--            type="checkbox"-->
<!--            id="alTeamsCheckbox"-->
<!--          />-->
<!--          <span class="checbox-title">{{ 'allTeams' | translate }}</span>-->
<!--        </label>-->
<!--      </div>-->
      <div class="filter-block filter-teams">
        <div *ngFor="let team of allTeams" class='team-checkbox-wrapper'>
          <label for="{{ team._id }}" class="my-checkbox">
            <input
              (disabled)="(team.disable)"
              type="checkbox"
              id="{{ team._id }}"
              [checked]="team.selected"
              (change)="handleTeamClicked(team, $event.target.checked)"
              value="team.selected"
            />
            <span></span>
          </label>
          <div>{{ team.name }}</div>
        </div>
      </div>
    </div>
  </div>

  <!-- Users + Entities-->
  <div class="col-md-2 filter-container" *ngIf="!recordingFilter">
    <div class="form-group">
      <label class="filter-title">{{ 'entities' | translate }}</label>
      <div class="md-checkbox">
        <label (click)="entitiesAllSelected()" for="allEntitiesCheckbox" class="my-checkbox">
          <input [checked]="allEntitiesChecked" type="checkbox" id="allEntitiesCheckbox" [disabled]="allUsersDisable" />
          <span> {{ 'allEntities' | translate }}</span>
        </label>
      </div>
      <div class="filter-block">
        <div class="md-checkbox" *ngFor="let entity of shownEntities">
          <label for="{{ entity._id }}" class="my-checkbox" (click)="entityClicked(entity, $event.target.checked)">
            <input id="{{ entity._id }}" type="checkbox" [checked]="entity.selected" value="entity.selected" />
            <span>{{ entity.name }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- Activity Types -->
  <div class="col-md-2 filter-container activity-types-block" *ngIf="!recordingFilter">
    <div class="form-group">
      <label class="filter-title">{{ 'activityTypes' | translate }}</label>
      <div class="filter-block">
        <div class="md-checkbox">
          <label for="allActivitiesTypesCheckbox" class="my-checkbox" fxLayout="row" (click)="activitiesAllSelected()">
            <input
              [checked]="allActivitiesChecked"
              type="checkbox"
              id="allActivitiesTypesCheckbox"
              [disabled]="allActivitiesDisable"
            />
            <span>{{ 'allActivities' | translate }} </span>
          </label>
        </div>
        <div
          class="md-checkbox"
          *ngFor="let activityType of activitiesTypes"
          (click)="activityClicked(activityType, $event.target.checked)"
        >
          <label (disabled)="(activityType.disabled)" for="{{ activityType.value }}" class="my-checkbox">
            <input
              (disabled)="(activityType.disabled)"
              id="{{ activityType.value }}"
              type="checkbox"
              [checked]="activityType.selected"
              value="activityType.selected"
            />
            <span>{{ activityType.name | translate }}</span>
            <select
              class="form-control"
              tooltip-placement="top"
              uib-tooltip="Minimum Stop Time"
              id="current-stop-time"
              [(ngModel)]="filter.stopTime"
              [hidden]="activityType.value !== 'stop' || activityType.disabled || !activityType.selected"
            >
              <option *ngFor="let stopTime of stopTimes" [ngValue]="stopTime.id">
                {{ stopTime.readable }} {{ stopTime.string | translate }}
              </option>
            </select>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
