<div class="recording-session-modal-container">

  <mat-icon class="close-icon" (click)="close()">close</mat-icon>

  <div class="page-title">
    <div class="title" fxLayout="row">
      <span class="name">{{data?.recording?.team.name }}</span>
      <span class="created">{{ 'from' | translate }} {{ data?.recording?.created | date: 'MM/dd/yy, HH:mm:ss'}}</span>
    </div>
    <div class="sub-title row">
      <span class="name">
        {{ recordingSession?.length }} {{ recordingSession?.length > 1 ? 'parts' : 'part' | translate}} <span
          class="divider">|</span>
      </span>
      <span class="duration">
        {{'duration' | translate}}: {{ data?.recording?.duration | duration}} <span class="divider">|</span>
      </span>
      <span class="size"> {{ data?.recording?.size | fileSize}} </span>
    </div>

  </div>

  <gk-table [options]="tableOptions" *ngIf="tableOptions && tableOptions.dataSource"></gk-table>
</div>