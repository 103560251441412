import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TeamsService } from 'app/components/pages/teams/teams.service';
import { Store } from '@ngrx/store';
import { GkTeam } from 'app/contracts/contracts';
import { EntityTypes } from 'app/contracts/ui.contracts';
import * as moment from 'moment';
import * as $ from 'jquery';
import { LocalFullDateFormatPipe } from 'app/shared/pipes/localTime.pipe';
import { OrganizationService } from 'app/components/pages/dashboard/organization.service';
import { GkSnackbar } from 'app/services/gkSnackbar/gk-snackbar.service';
import { TranslationService } from 'app/services/barrel-services';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'invitationLinkModal',
  templateUrl: './invitationLink.modal.component.html',
  styleUrls: ['./invitationLink.modal.component.scss']
})
export class InvitationLinkModalComponent implements OnInit {
  public subscriptions: any = [];
  public autocompleteOptions: any = {};
  public teams: GkTeam[] = [];
  public EntityTypes = EntityTypes;
  public timeLimits: any = [
    {
      value: moment(moment()).add(1, 'days'),
      display: this.datePipe.transform(moment(moment()).add(1, 'days')),
      translateKey: 'oneDay'
    },
    {
      value: moment(moment()).add(1, 'week'),
      display: this.datePipe.transform(moment(moment()).add(1, 'days')),
      translateKey: 'oneWeek'
    },
    {
      value: moment(moment()).add(1, 'month'),
      display: this.datePipe.transform(moment(moment()).add(1, 'days')),
      translateKey: 'oneMonth'
    },
    {
      value: 'custom',
      display: 'custom',
      translateKey: 'custom'
    }
  ];
  public pickerOptions: { selectMode: string } = {
    selectMode: 'single'
  };
  public magicLink: string;
  public invitationId: any;
  // selected
  public selectedEntityType: EntityTypes;
  public selectedTeamId: string;
  public maxInstalls: number = 10;
  public selectedTimeLimit: any = this.timeLimits[1].value;
  public viewSelectedTimeLimit: string;
  public isLoading: boolean;

  @ViewChild('timeSelector', { static: false }) dateElement: MatSelect;

  constructor(
    public dialogRef: MatDialogRef<InvitationLinkModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private teamsService: TeamsService,
    private store: Store<any>,
    private datePipe: LocalFullDateFormatPipe,
    private organizationService: OrganizationService,
    private snackBar: GkSnackbar,
    private translationService: TranslationService
  ) {
    if (this.data && this.data.emailInvitation) {
      // update
      this.maxInstalls = this.data.emailInvitation.maxHits;
      this.selectedTimeLimit = this.data.emailInvitation.expiration;
      this.selectedTeamId = this.data.emailInvitation.team;
      this.selectedEntityType = this.data.emailInvitation.type;
      this.magicLink = this.data.emailInvitation.url;
      this.invitationId = this.data.emailInvitation._id;
      // For view
      this.viewSelectedTimeLimit = this.datePipe.transform(this.selectedTimeLimit);
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.store.select('teams').subscribe((teams: any) => {
        if (teams && teams.length) {
          this.teams = this.removeDisabledTeams(teams);
          this.prepateAutocompleteOptions();
        } else {
          this.teamsService.getTeams();
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public prepateAutocompleteOptions = () => {
    this.autocompleteOptions = {};
    this.autocompleteOptions.addOnBlur = true;
    this.autocompleteOptions.removable = true;
    this.autocompleteOptions.selectable = true;
    if (this.selectedTeamId) {
      let index = this.teams.findIndex((team: GkTeam) => {
        return team._id === this.selectedTeamId;
      });
      if (index !== -1) {
        this.autocompleteOptions.defaultItems = [this.teams[index]];
      }
    }

    this.autocompleteOptions.allItems = this.teams.slice();
  };

  public teamsSelected = (selectedTeam: any) => {
    this.selectedTeamId = selectedTeam;
  };

  public onChangeTimeLimit = (timeLimit: any) => {
    if (timeLimit === 'custom') {
      // Open date range modal
      return $('#datePickerInput2').click();
    } else {
      this.selectedTimeLimit = timeLimit.value;
    }
  };

  public onCustomDateSelected = (event: any) => {
    this.selectedTimeLimit = event.value;
    this.viewSelectedTimeLimit = this.datePipe.transform(this.selectedTimeLimit);
    this.dateElement.close();
    this.dateElement.value = 'custom';
  };

  public getInviteLink = () => {
    this.isLoading = true;
    if (this.invitationId) {
      // Update
      this.organizationService
        .UpdateMagicEmailInvitation(this.invitationId, {
          timeLimit: this.selectedTimeLimit,
          hitsLimit: this.maxInstalls,
          type: this.selectedEntityType,
          team: this.selectedTeamId
        })
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.snackBar.open(this.translationService.getTranslation('linkUpdatedSuccefully'));
          },
          (e) => {
            console.error('Failed to get magic link');
            this.isLoading = false;
          }
        );
      return;
    }
    // Create
    this.organizationService
      .getInviteLink(this.selectedTimeLimit, this.maxInstalls, this.selectedEntityType, this.selectedTeamId)
      .subscribe(
        (res: any) => {
          this.magicLink = res.url;
          this.isLoading = false;
        },
        (e) => {
          console.error('Failed to get magic link');
          this.isLoading = false;
        }
      );
  };

  public copyUrl = (inputElement: HTMLInputElement) => {
    // Copy the url
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.snackBar.open(this.translationService.getTranslation('linkCopiedSuccessfully'));
  };

  public removeDisabledTeams = (teams: GkTeam[]): GkTeam[] => {
    for (let i = 0; i < teams.length; i++) {
      if (!teams[i].enabled) teams.splice(i, 1);
    }
    return teams;
  };

  public setMaxInstalls = (event: Event) => {
    let targetValue = parseInt((event.target as HTMLInputElement).value);
    this.maxInstalls = targetValue > 0 && targetValue <= 100 ? targetValue : 10;
  };

  public preventSpecialCharacters = (event: KeyboardEvent) => {
    let invalidChars = ['.', '-', '+', '=', 'e', 'E'];
    if (invalidChars.indexOf(event.key) !== -1) {
      event.preventDefault();
    }
  };
}
