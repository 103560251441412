import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'gk-pin-selector',
  templateUrl: './gk-pin-selector.component.html',
  styleUrls: ['./gk-pin-selector.component.scss'],
})
export class GkPinSelectorComponent {
  public pinCode: number[] = [null, null, null, null];
  public input: any = {};
  public activeInputIndex: number;

  @Output() onPinEntered: EventEmitter<number[]> = new EventEmitter();
  @ViewChild('index0', { static: false }) inputEl1: ElementRef;
  @ViewChild('index1', { static: false }) inputEl2: ElementRef;
  @ViewChild('index2', { static: false }) inputEl3: ElementRef;
  @ViewChild('index3', { static: false }) inputEl4: ElementRef;

  public inputEl = [this.inputEl1, this.inputEl2, this.inputEl3, this.inputEl4];

  constructor(private renderer: Renderer2) {
    this.activeInputIndex = 0;
  }

  public onInputEntry = (event: any, id: string, nextInputIndex: number) => {
    // allow only numbers
    if (!this.isNumberKey(event)) {
      // delete if diferrent char
      this.renderer.selectRootElement('#tab' + nextInputIndex).value = null;
      return;
    }
    this.activeInputIndex++;
    event.value = event.data;
    let input = event.target;
    let nextInput = +nextInputIndex + 1;
    let newID = id + nextInput;
    // If reached to the last digit
    // Emit to parent component
    if (!document.getElementById(newID)) {
      this.onPinEntered.emit(this.pinCode);
      return;
    }
    // use dellay 0 for waiting the new id will generated
    let element = this.renderer.selectRootElement('#' + newID);
    setTimeout(() => element.focus(), 0);
  };

  // Handle backspace key and deleting
  public onInputKeyDown = (event: any, nextInputIndex: number) => {
    if (nextInputIndex === 0) return;
    if (event.key === 'Backspace') {
      let element = this.renderer.selectRootElement('#tab' + (nextInputIndex - 1));
      this.pinCode[nextInputIndex - 1] = null;
      this.activeInputIndex--;
      setTimeout(() => element.focus(), 0);
    }
  };

  // Check if number
  public isNumberKey = (evt: any) => {
    let charCode = parseInt(evt.target.value, 10);
    if (charCode >= 0 && charCode <= 9) {
      return true;
    }
    return false;
  };
}
