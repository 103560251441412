import { GkUser } from './../../contracts/contracts';
import { UsersService } from '../pages/users/users.service';
import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  HostListener
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { Config } from './../../shared/config';

const OPEN_SIDEBAR_MIN_SCREEN_WIDTH = 1150;
@Component({
  selector: 'gk-navbar',
  templateUrl: './gk-navbar.component.html',
  styleUrls: ['./gk-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GkNavbarComponent implements OnInit {
  public isRtl: boolean;
  public user: GkUser;
  public tabs: any[] = [];
  public tabsExpanded: boolean;
  public isSidenavOpen: boolean;
  public selectedTitle: string;
  public isCloud: boolean = Config.IS_CLOUD;
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleScreenWidth();
  }
  constructor(
    private usersService: UsersService,
    private router: Router,
    private elRef: ElementRef,
    private translateService: TranslateService
  ) {
    this.usersService.getCurrentUser();
    this.detectLangChange();
    this.detectRouteChanges();
  }

  ngAfterViewInit() {
    this.findSelectedTab(true);
  }

  ngOnInit() {
    this.tabs = [
      {
        title: 'dashboard',
        icon: 'dashboard',
        url: 'dashboard'
      },
      {
        title: 'security',
        icon: 'security',
        url: 'security'
      },
      {
        title: 'users',
        icon: 'account_circle',
        url: 'users-list'
      },
      {
        title: 'teams',
        icon: 'group',
        url: 'teams-list'
      },
      {
        title: 'integrations',
        icon: 'extension',
        url: 'integrations'
      },
      {
        title: 'history',
        icon: 'history',
        url: 'history-log',
      }, {
        title: 'incidents',
        icon: 'warning',
        url: 'incidents'
      }];

    // Add recordings tab while NOT on premise (Only Cloud)
    let recordingsTab: any = {
      title: 'recordings',
      icon: 'video_library',
      url: 'recordings',
    };
    if (this.isCloud) this.tabs.push(recordingsTab);
    // End

    this.handleScreenWidth();
  }

  public toggleSidenav = (isSidenavOpen?: any) => {
    if (isSidenavOpen !== undefined) {
      this.isSidenavOpen = isSidenavOpen;
    } else {
      this.isSidenavOpen = !this.isSidenavOpen;
    }
    let indicatorElement = this.elRef.nativeElement.querySelector('.mat-expansion-indicator');
    if (indicatorElement) {
      if (this.isSidenavOpen) {
        indicatorElement.style.visibility = 'visible';
        return;
      }
      indicatorElement.style.visibility = 'hidden';
    }
  };

  public navigate = (page: string) => {
    this.router.navigateByUrl(page);
  };

  public panelClicked = (event: MouseEvent, tab: any, withoutNavigate?: boolean) => {
    if (tab.url) {
      this.tabsExpanded = false;
      // tslint:disable-next-line:no-shadowed-variable
      let element;
      if (this.selectedTitle) {
        element = $('#' + this.selectedTitle);
        element.removeClass('active');
      }
      this.selectedTitle = tab.title;
      element = $('#' + this.selectedTitle);
      element.addClass('active');
      if (!withoutNavigate) this.router.navigateByUrl(tab.url);
    }
  };

  public handleScreenWidth = () => {
    if (window.innerWidth < OPEN_SIDEBAR_MIN_SCREEN_WIDTH) {
      this.toggleSidenav(false);
      return;
    }
    this.toggleSidenav(true);
  };

  public findSelectedTab = (withoutNavigate?: boolean) => {
    let pagePath = location.hash.split('/')[1];
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].url.indexOf(pagePath) !== -1) {
        return this.panelClicked(null, this.tabs[i], withoutNavigate);
      }
    }
  }

  public detectLangChange = () => {
    this.translateService.onLangChange.subscribe((res: any) => {
      if (res.lang === 'he') return this.isRtl = true;
      return this.isRtl = false;
    });
  }

  public detectRouteChanges = () => {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.findSelectedTab(true);
      }
    });
  }

}
