import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../../services/translation-service.service';

@Pipe({
  name: 'localFullDateFormat'
})
export class LocalFullDateFormatPipe implements PipeTransform {
  constructor(private translationService: TranslationService) { }
  transform(date: any): string {
    let result = new Date(date).toLocaleString([],
      { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false });
    if (result === 'Invalid Date') {
      return this.translationService.getTranslation('never');
    }
    return result;
  }
}

@Pipe({
  name: 'localTimeFormat'
})
export class LocalTimeFormatPipe implements PipeTransform {
  transform(date: any): string {
    return new Date(date).toLocaleString([], { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
  }
}

@Pipe({
  name: 'localDateFormat'
})
export class LocalDateFormatPipe implements PipeTransform {
  transform(date: any): string {
    return new Date(date).toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric'});
  }
}
