import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

@NgModule({
  imports: [
    CommonModule,
    Ng4LoadingSpinnerModule.forRoot()
  ],
  declarations: []
})
export class UicommonModule { }
