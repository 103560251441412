import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import {
  GkUser,
  GkFilter,
  GkActivity,
  GkHistoryType,
  GkLocation,
  GkTeam,
  GkTask,
  GkEntity,
  GkTaskActivityType,
  GkActivityType
} from "./../../../contracts/contracts";
import { WebApiService } from "./../../../services/web-api.service";
import { Config } from "app/shared/config";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as moment from "moment";
import { httpMethod } from "../../../contracts/contracts";
import { Constants } from "app/shared/constant";
import * as _ from "lodash";
import { GkRecording } from "../../../contracts/ui.contracts";
import { TranslationService } from "app/services/translation-service.service";
import {
  LocalTimeFormatPipe,
  LocalDateFormatPipe
} from "../../../shared/pipes/localTime.pipe";
import { HttpParams } from "@angular/common/http";
import { take, skip } from "rxjs/operators";
import { UsersService } from "../users/users.service";

@Injectable()
export class HistoryService {
  public data: any = {};
  public filter: any = {};
  public historyProgress: Subject<number> = new Subject();
  public progressState: any;
  activities: any[];

  constructor(
    private webApi: WebApiService,
    private loaderService: Ng4LoadingSpinnerService,
    private translationService: TranslationService,
    private timePipe: LocalTimeFormatPipe,
    private datePipe: LocalDateFormatPipe,
    private userService: UsersService
  ) {
    this.progressState = {
      tasks: 0.001,
      activities: 0.001,
      locations: 0.001
    };
  }

  // Serve Function
  public GetServerActivities(
    startDate: Date,
    endDate: Date,
    teams: string,
    isIncident: boolean,
    skip?: number,
    total?: number
  ) {
    let params: any = {
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
      teams
    };
    if (isIncident) params.isIncident = isIncident;
    if (skip) params.skip = skip;
    if (total) params.total = total;
    return this.webApi.makeRequest(
      Constants.Services.HistoryActivities,
      null,
      httpMethod.Get,
      null,
      params
    );
  }

  public GetServerTasks(
    startDate: Date,
    endDate: Date,
    teams: string[],
    entities: string[],
    skip?: number,
    total?: number
  ) {
    let params: any = {};
    if (skip) params.skip = skip;
    if (total) params.total = total;
    let body = {
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
      entities,
      teams
    };
    return this.webApi.makeRequest(
      Constants.Services.HistoryTasks,
      body,
      httpMethod.Post,
      null,
      params
    );
  }

  public GetServerLocations(
    startDate: Date,
    endDate: Date,
    entities: any[],
    params?: any
  ) {
    let data = {
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
      entities
    };
    return this.webApi.makeRequest(
      Constants.Services.Locations,
      data,
      httpMethod.Post,
      undefined,
      params
    );
  }

  public GetServerRecordings = (
    startDate: any,
    endDate: any,
    teams: GkTeam[]
  ) => {
    let params = this.getRequestParams(startDate, endDate, teams);
    return this.webApi.makeRequest(
      Constants.Services.Recordings,
      null,
      httpMethod.Get,
      null,
      params
    );
  };

  // History Main function
  public getHistoryData = (
    filter: GkFilter,
    isIncident?: boolean
  ): Promise<any[]> => {
    return new Promise<GkActivity[]>((resolve: any, reject: any) => {
      // Initilizing start and end dates
      this.data.startDate = filter.startDate = filter.startDate.getTime
        ? filter.startDate
        : filter.startDate.toDate();
      this.data.endDate = filter.endDate = filter.endDate.getTime
        ? filter.endDate
        : filter.endDate.toDate();
      // Get requests promisses
      this.data.locations = [];
      this.data.activities = [];
      this.data.tasks = [];

      this.data.tasks = [];

      let activityPromise = this.getActivities(filter, isIncident);
      let locationsPromise, tasksPromise;
      // Check if tasks and locations is selected
      for (let i = 0; i < this.filter.activities.length; i++) {
        if (this.filter.activities[i].value === "tasks") {
          tasksPromise = this.getTasks(filter);
          continue;
        } else if (this.filter.activities[i].value === "locations") {
          locationsPromise = this.getLocations(filter);
        }
      }
      if (!tasksPromise) this.progressState["tasks"] = 100;
      if (!locationsPromise) this.progressState["locations"] = 100;
      let promisesArray: any = [activityPromise];
      let locationsIndex, tasksIndex;
      if (tasksPromise) {
        promisesArray.push(tasksPromise);
        tasksIndex = promisesArray.length - 1;
      }
      if (locationsPromise) {
        promisesArray.push(locationsPromise);
        locationsIndex = promisesArray.length - 1;
      }
      // Wait for the promises to be resolved
      Promise.all(promisesArray).then(
        (results: any) => {
          let res: any = {
            activities: [].concat(results[0], tasksIndex !== undefined ? results[tasksIndex] : []),
            locations: locationsIndex !== undefined ? results[locationsIndex] : []
          };
          // Add an fake delete activity for each place activity with end time
          res.activities = this.addDeletePlaceActivities(res.activities);
          this.activities = res.activities;
          res.fullActivitiesData = this.activities;
          res.totalActivities = res.activities.length;
          resolve(res);
        },
        (error: any) => {
          this.loaderService.hide();
          reject(error);
        }
      );
    });
  };

  public getNextActivitiesPage = (pageSize: number, pageNumber: number) => {
    return new Promise((resolve: any, reject: any) => {
        resolve(this.activities.slice(pageNumber * pageSize, pageNumber * pageSize + pageSize));
    });
  };

  public getLocations = (filter: GkFilter): Promise<GkLocation[]> => {
    let entitiesToQuery: string[] = [];
    if (filter.entities) {
      for (let i = 0; i < filter.entities.length; i++) {
        entitiesToQuery.push(filter.entities[i]._id);
      }
    }
    return new Promise<GkLocation[]>((resolve: any, reject: any) => {
      this.GetServerLocations(filter.startDate, filter.endDate, entitiesToQuery)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            // items - locations got
            // total - total locations
            // skip - how much I have got allready
            this.handleServerLocationsRequests(
              filter,
              entitiesToQuery,
              res,
              resolve,
              reject
            );
          },
          e => {
            reject(e);
          }
        );
    });
  };

  // Get locations request response, return to client component,
  // add to locations local service bank, check if need to get more items
  public handleServerLocationsRequests = (
    filter: GkFilter,
    entities: string[],
    res: any,
    resolve,
    reject
  ) => {
    // Check if last itarate;
    if (res.skip > res.total) res.skip = res.total;
    this.updateHistoryProgress("locations", res.total, res.skip);
    if (!(res.skip >= res.total)) {
      // Need to get more locations
      let params = {
        skip: res.skip,
        total: res.total
      };
      this.GetServerLocations(
        filter.startDate,
        filter.endDate,
        entities,
        params
      ).subscribe(
        (res2: any) => {
          this.handleServerLocationsRequests(
            filter,
            entities,
            res2,
            resolve,
            reject
          );
        },
        e => {
          // Error handling
          reject(e);
        }
      );
      if (res && res.items) {
        this.data.locations = this.data.locations.concat(res.items);
      }
    } else {
      if (res && res.items) {
        this.data.locations = this.data.locations.concat(res.items);
      }
      // Finish get all locations
      resolve(this.data.locations);
    }
  };

  // Get activities request response, return to client component,
  // add to activities local service bank, check if need to get more items
  public handleServerActivitiesRequests = (
    filter: GkFilter,
    teams: string,
    res: any,
    resolve: Function,
    reject: Function,
    isIncident: boolean
  ) => {
    // Check if last itarate;
    if (res.skip > res.total) res.skip = res.total;
    this.updateHistoryProgress("activities", res.total, res.skip);
    if (!(res.skip >= res.total)) {
      // Need to get more locations
      let params = {
        skip: res.skip,
        total: res.total
      };
      this.GetServerActivities(
        filter.startDate,
        filter.endDate,
        teams,
        isIncident,
        res.skip,
        res.total
      ).subscribe(
        (res2: any) => {
          this.handleServerActivitiesRequests(
            filter,
            teams,
            res2,
            resolve,
            reject,
            isIncident
          );
        },
        e => {
          // Error handling
          reject(e);
        }
      );
      if (res && res.items) {
        this.data.activities = this.data.activities.concat(res.items);
      }
    } else {
      if (res && res.items) {
        this.data.activities = this.data.activities.concat(res.items);
      }
      // Finish get all activities
      // return GkActivity type after being sure the activites comeing back with an entity
      _.each(this.data.activities, (activity: any) => {
        if (!activity.entity) activity.entity = {};
        // Formatting activities
        activity.date = this.datePipe.transform(activity.created);
        activity.time = this.timePipe.transform(activity.created);
        activity.userFullName = activity.entity.name ||
          this.translationService.getTranslation("unknownEntity");
        // Populating team name
        activity.teamName = activity.entity.team && this.getTeamName(activity.entity.team._id) || this.translationService.getTranslation('unknown');
        activity.entity.location = activity.location;
        activity.entity.lastSeen = activity.created;
        // Adding type label (for ui only)
        activity.typeLabel = this.getTypeLabel(activity);
      });
      resolve(this.filterActivities());
    }
  };

  public getTasks = (filter: GkFilter): Promise<GkTask[]> => {
    let entitiesToQuery: any = [];
    if (filter.entities) {
      entitiesToQuery = this.getEntityForRequest(filter.entities, true);
    }
    let teamsToQuery: any = [];
    if (filter.teams) {
      teamsToQuery = this.getTeamForRequest(filter.teams, true);
    }
    return new Promise<GkTask[]>((resolve: any, reject: any) => {
      this.GetServerTasks(
        filter.startDate,
        filter.endDate,
        teamsToQuery,
        entitiesToQuery
      )
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            // items - locations got
            // total - total locations
            // skip - how much I have got allready
            this.handleServerTasksRequests(
              filter,
              teamsToQuery,
              entitiesToQuery,
              res,
              resolve,
              reject
            );
          },
          e => {
            reject(e);
          }
        );
    });
  };

  // Get activities request response, return to client component,
  // add to activities local service bank, check if need to get more items
  public handleServerTasksRequests = (
    filter: GkFilter,
    teams: string[],
    entities: string[],
    res: any,
    resolve,
    reject
  ) => {
    if (res.skip > res.total) res.skip = res.total;
    this.updateHistoryProgress("tasks", res.total, res.skip);
    if (!(res.skip >= res.total)) {
      // Need to get more locations
      let params = {
        skip: res.skip,
        total: res.total
      };
      this.GetServerTasks(
        filter.startDate,
        filter.endDate,
        teams,
        entities,
        res.skip,
        res.total
      ).subscribe(
        (res2: any) => {
          this.handleServerTasksRequests(
            filter,
            teams,
            entities,
            res2,
            resolve,
            reject
          );
        },
        e => {
          // Error handling
          reject(e);
        }
      );
      if (res && res.items) {
        this.data.tasks = this.data.tasks.concat(res.items);
      }
    } else {
      if (res && res.items) {
        this.data.tasks = this.data.tasks.concat(res.items);
      }
      // Finish get all activities
      // Break task to 1-3 activies (by properties)
      let taskActivities: GkActivity[] = [];
      _.each(this.data.tasks, (task: GkTask) => {
        // Formatting activities
        let newTaskActivity: GkActivity = new GkActivity();
        let createdEntity = this.userService.GetLocalUser(task.createdBy);
        newTaskActivity.type = "task";
        newTaskActivity.date = this.datePipe.transform(task.created);
        newTaskActivity.time = this.timePipe.transform(task.created);
        newTaskActivity.userFullName = newTaskActivity.entityid =
        createdEntity ? createdEntity.name : this.translationService.getTranslation("unknownEntity");
        // Populating team name
        newTaskActivity.teamName = this.getTeamName(task.team);
        // Checking by properties
        // Created Activity
        let createdTaskActivity = Object.assign({}, newTaskActivity);
        createdTaskActivity.typeLabel = this.getTaskTypeLabel(
          task,
          GkTaskActivityType.Created
        );
        if (this.isInFilterDatesRange(task.created)) taskActivities.push(createdTaskActivity);
        // Resolved Activity
        if (task.resolvedBy && task.resolvedTime && this.isInFilterDatesRange(task.resolvedTime)) {
          let resolvedEntity = this.userService.GetLocalUser(task.resolvedBy);
          let resolvedTaskActivity = Object.assign({}, newTaskActivity);
          resolvedTaskActivity.typeLabel = this.getTaskTypeLabel(
            task,
            GkTaskActivityType.Resolved
          );
          resolvedTaskActivity.userFullName = resolvedTaskActivity.entityid =
          resolvedEntity ? resolvedEntity.name : this.translationService.getTranslation("unknownEntity");
          resolvedTaskActivity.created = task.resolvedTime;
          resolvedTaskActivity.date = this.datePipe.transform(task.resolvedTime);
          resolvedTaskActivity.time = this.timePipe.transform(task.resolvedTime);
        
          taskActivities.push(resolvedTaskActivity);
        }
        // Archived Activity
        if (task.archivedBy && task.archivedTime && this.isInFilterDatesRange(task.archivedTime)) {
          let archivedEntity: any = this.userService.GetLocalUser(task.archivedBy);
          let archivedTaskActivity = Object.assign({}, newTaskActivity);
          archivedTaskActivity.typeLabel = this.getTaskTypeLabel(
            task,
            GkTaskActivityType.Archived
          );
          archivedTaskActivity.entityid = archivedTaskActivity.userFullName =
          archivedEntity ? archivedEntity.name : this.translationService.getTranslation("unknownEntity");
          archivedTaskActivity.created = task.archivedTime;
          archivedTaskActivity.date = this.datePipe.transform(task.archivedTime);
          archivedTaskActivity.time = this.timePipe.transform(task.archivedTime);
          taskActivities.push(archivedTaskActivity);
        }
      });
      resolve(taskActivities);
    }
  };

  // Will first go and get first items from the server
  // then use this.handleServerActivitiesRequests for handle response and check if need to
  // get more items
  public getActivities = (
    filter: GkFilter,
    isIncident: boolean
  ): Promise<GkActivity[]> => {
    return new Promise<GkActivity[]>((resolve: any, reject: any) => {
      this.filter = filter;
      let stringTeams = this.getTeamForRequest(filter.teams);
      let stringEntities = this.getEntityForRequest(filter.entities);
      this.GetServerActivities(
        filter.startDate,
        filter.endDate,
        stringTeams,
        isIncident
      )
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            // items - locations got
            // total - total locations
            // skip - how much I have got allready
            this.handleServerActivitiesRequests(
              filter,
              stringTeams,
              res,
              resolve,
              reject,
              isIncident
            );
          },
          e => {
            reject(e);
          }
        );
    });
  };

  public getRecordings = (filter: GkFilter): Observable<GkRecording[]> => {
    // Api call
    this.filter = filter;
    return this.GetServerRecordings(
      filter.startDate,
      filter.endDate,
      filter.teams
    );
  };

  // Utils Functions
  public filterActivities = (): Promise<GkActivity[]> => {
    return new Promise<GkActivity[]>((resolve, reject) => {
      // Going over each activity
      this.data.activities = _.filter(
        this.data.activities,
        (activity: GkActivity) => {
          // We want to discard activities from stream type
          // TODO: Remove PATCH one day
          if (activity.type === "stream") {
            return false;
          }
          let created = moment(activity.created);
          let isMatch = true;
          // Checking if it from the wanted type
          let isType = false;
          for (let i = 0; i < this.filter.activities.length; i++) {
            if (this.filter.activities[i].value === activity.type) {
              isType = true;
              break;
            }
          }
          isMatch = isType;
          // Checking if got no entity - show
          if (!(activity.entity && activity.entity._id)) {
            return isType; // add activity only if is from choosed type
          }
          // Checking that it's from the wanted team
          isMatch =
            isMatch &&
            this.shouldfilter(this.filter.teams, activity.entity.team._id);
          // Checking if it's from the wanted entity
          isMatch =
            isMatch &&
            ((this.filter.users &&
              this.shouldfilter(this.filter.users, activity.entity._id)) ||
              (this.filter.entities &&
                this.shouldfilter(this.filter.entities, activity.entity._id)));
          // Checking if it's from the wanted dates
          isMatch =
            isMatch &&
            created < this.filter.endDate &&
            created > this.filter.startDate;

          // Doing stats
          if (isMatch) {
            // We want to get the address only if it's a valid activity
            if (activity.location && activity.location.address) {
              activity.geoLocation = activity.location.address;
            } else {
              this.getActivityGeoLocation(activity);
            }
            return true;
          } else {
            return false;
          }
        }
      );
      resolve(this.data.activities);
    });
  };

  // Checking if activty's stop time is bigger than minimum.
  public filterStop(activity) {
    return activity.data.stopTime > this.filter.minStopTime;
  }
  // Converting millis stop time to a readble string
  // activity - the stop.
  public stopTimeToReadable(activity) {
    let SECOND = 1000;
    let MINUTE = 60 * SECOND;
    let HOUR = 60 * MINUTE;
    let DAY = 24 * HOUR;
    let WEEK = 7 * DAY;

    let stopTime = activity.data.stopTime;
    let readable;
    if (stopTime < MINUTE) {
      readable = (stopTime / SECOND).toFixed(1) + " Seconds";
    } else if (stopTime < HOUR) {
      readable = (stopTime / MINUTE).toFixed(1) + " Minutes";
    } else if (stopTime < DAY) {
      readable = (stopTime / HOUR).toFixed(1) + " Hours";
    } else if (stopTime < WEEK) {
      readable = (stopTime / DAY).toFixed(1) + " Days";
    } else {
      readable = (stopTime / WEEK).toFixed(1) + " Weeks";
    }

    activity.data.readableStop = readable;
  }

  // Getting a specific activity geolocation.
  public getActivityGeoLocation(activity) {
    if (
      activity.location &&
      activity.location.latitude === 0 &&
      activity.location.longitude === 0
    ) {
      activity.geoLocation = "Unknown Location";
      return;
    }
  }

  // Getting team name by serverid.
  public getTeamName(id) {
    let name;
    this.filter.teams.forEach((team: GkTeam) => {
      if (team._id === id) {
        name = team.name;
        return;
      }
    });
    return name;
  }

  public shouldfilter(array, id) {
    let filter = false;
    array.forEach((element: any) => {
      if (element._id === id) {
        filter = true;
      }
    });
    return filter;
  }

  public getTypeLabel = (activity: GkActivity) => {
    switch (activity.type) {
      case "geofence-enter":
        return (
          this.translationService.getTranslation("enter") +
          " " +
          activity.data.place.name
        );
      case "geofence-exit":
        return (
          this.translationService.getTranslation("exit") +
          " " +
          activity.data.place.name
        );
      case "place-created":
        return (
          this.translationService.getTranslation("create") +
          " " +
          activity.data.place.name
        );
      case "place-deleted":
        return (
          this.translationService.getTranslation("delete") +
          " " +
          activity.data.place.name
        );
      case "image":
      case "video":
      case "panic":
      case "message":
        let translationKey = Config.ActivityTypes[activity.type].split(",")[1];
        return this.translationService.getTranslation(translationKey);
      case "real-time":
        if (Config.hasFlag(activity.data.mode, Config.TeamModes.REAL_TIME)) {
          return this.translationService.getTranslation("realTimeTurnOn");
        }
        return this.translationService.getTranslation("realTimeTurnOff");
      case "file":
        return `${
          activity.data && activity.data.type
            ? activity.data.type.toUpperCase()
            : null
        } ${this.translationService.getTranslation("upload")}`;
    }
  };

  // Notify to Audit before any export
  public notifyActivitiesExport = () => {
    return this.webApi.makeRequest(
      Constants.Services.Activities + "/export",
      null,
      httpMethod.Get
    );
  };

  public notifyLocationsExport = () => {
    return this.webApi.makeRequest(
      Constants.Services.Locations + "/export",
      null,
      httpMethod.Get
    );
  };

  // return params object for recording request
  public getRequestParams = (startDate: any, endDate: any, teams: GkTeam[]) => {
    let stringTeams = this.getTeamForRequest(teams);
    return {
      startDate: startDate._isAMomentObject
        ? startDate.format("x")
        : startDate.getTime().toString(),
      endDate: endDate._isAMomentObject
        ? endDate.format("x")
        : endDate.getTime().toString(),
      teams: stringTeams
    };
  };

  // return string - teams id's seperated with comma
  public getTeamForRequest = (teams: GkTeam[], isTask?: boolean) => {
    if (!isTask) {
      let stringTeams = "";
      for (let i = 0; i < teams.length; i++) {
        if (i === 0) {
          stringTeams += teams[i]._id;
          continue;
        }
        stringTeams += "," + teams[i]._id;
      }
      return stringTeams;
    } else {
      let resTeams: any = [];
      for (let i = 0; i < teams.length; i++) {
        resTeams.push(teams[i]._id);
      }
      return resTeams;
    }
  };

  public getEntityForRequest = (users: GkEntity[], isTask?: boolean) => {
    if (!isTask) {
      let stringEntities = "";
      for (let i = 0; i < users.length; i++) {
        if (i === 0) {
          stringEntities += users[i]._id;
          continue;
        }
        stringEntities += "," + users[i]._id;
      }
      return stringEntities;
    } else {
      let resEntities: any = [];
      for (let i = 0; i < users.length; i++) {
        resEntities.push(users[i]._id);
      }
      return resEntities;
    }
  };

  // Return label by task type
  public getTaskTypeLabel = (task: GkTask, taskType: GkTaskActivityType) => {
    switch (taskType) {
      case GkTaskActivityType.Created:
        return (
          this.translationService.getTranslation("createdTask") +
          " - " +
          task.title
        );
      case GkTaskActivityType.Archived:
        return (
          this.translationService.getTranslation("archivedTask") +
          " - " +
          task.title
        );
      case GkTaskActivityType.Resolved:
        return (
          this.translationService.getTranslation("resolvedTask") +
          " - " +
          task.title
        );
      default:
        break;
    }
  };

  public getHistoryProgress = () => {
    return this.historyProgress;
  };

  public updateHistoryProgress = (
    type: string,
    total: number,
    skipped: number
  ) => {
    // Handle no items as 100%
    if (total === 0) {
      skipped = total = 1;
    }
    if (skipped > total) skipped = total;
    // Calculating progress percentage for this type
    this.progressState[type] = skipped / total;
    // Calculating all progress percentage
    let calculatedProgress =
      (this.progressState["tasks"] +
        this.progressState["activities"] +
        this.progressState["locations"]) /
      3;
    this.historyProgress.next(calculatedProgress);
  };

  // Add an fake delete activity for each place activity with end time
  public addDeletePlaceActivities = (dataSource: GkActivity[]) => {
    let newPlaceActivities = [];
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource[i].data && dataSource[i].data.place && dataSource[i].data.place.endTime) {
        let item = Object.assign({}, dataSource[i]);
        item.type = 'place-deleted';
        item.typeLabel = this.getTypeLabel(item);
        item.date = this.datePipe.transform(item.data.place.endTime);
        item.time = this.timePipe.transform(item.data.place.endTime);
        newPlaceActivities.push(item);
      }
    }
    return newPlaceActivities.concat(dataSource);
  }

  public isInFilterDatesRange = (time: any): Boolean => {
    return time < this.filter.endDate && time > this.filter.startDate;
  }

}
