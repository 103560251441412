import { Component, OnDestroy } from '@angular/core';
// tslint:disable-next-line:max-line-length
import {
  BreadCrumbOptions,
  ColumnTemplete,
  GkAlertType,
  GkUiState,
  TableOptions
} from '../../../contracts/ui.contracts';
import * as _ from 'lodash';
import {
  EmailInvitation,
  GkDevice,
  GkOrganization,
  GkSession,
  GkUser,
  OrganizationsFeature
} from '../../../contracts/contracts';
import { Config } from '../../../shared/config';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { OrganizationService } from '../dashboard/organization.service';
import { GkSnackbar } from '../../../services/gkSnackbar/gk-snackbar.service';
import { TranslationService } from 'app/services/translation-service.service';
import { UsersService } from 'app/components/pages/users/users.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';
import { UiStateService } from '../../../services/ui.state.service';
import { ConfirmationComponent } from '../../modals/confirmation.modal/confirmation.modal.component';
// tslint:disable-next-line:max-line-length
import { DisableConfirmationComponent } from '../../modals/disableConfirmation.modal/disableConfirmation.modal.component';
import { TrustConfirmationComponent } from '../../modals/trustConfirmation.modal/trustConfirmation.modal.component';
import { PinModalComponent } from 'app/components/modals/pin.modal/pin.modal.component';
import { QrModalComponent } from 'app/components/modals/qr.modal/qr.modal.component';
import { take } from 'rxjs/operators';
import { sortByLastSeen } from '../../../shared/sorting';

// tslint:disable-next-line:no-var-requires
const qr = require('qr-image');
// tslint:disable-next-line:no-var-requires
const FileSaver = require('file-saver');
const DEFAULT_PAGE_SIZE = 10;
const FIRST_PAGE = 0;

@Component({
  selector: 'security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss'],
  providers: []
})
export class GkSecurityComponent implements OnDestroy {
  totalSessionsLength: any = 0;
  sessionsInitialized: boolean;
  public uiState: GkUiState;
  public IS_CLOUD: boolean;
  public qrImageSize: number = 115;
  public breadcrumbOptions: BreadCrumbOptions;
  public organizationFeatures: OrganizationsFeature[];
  public organization: GkOrganization;
  public sessions: GkSession[];
  public untrustedDevices: GkDevice[];
  public emailInvitations: EmailInvitation[];
  public tableOptions: TableOptions;
  public trustedTableOptions: TableOptions;
  public emailInvitationsTableOptions: TableOptions;
  public magicEmailInvitationsTableOptions: TableOptions;
  public currentUser: GkUser;
  public subscriptions: any[] = [];

  constructor(
    private organizationService: OrganizationService,
    public dialog: MatDialog,
    private snackBar: GkSnackbar,
    private translationService: TranslationService,
    private usersService: UsersService,
    private uiStateService: UiStateService,
    private loaderService: Ng4LoadingSpinnerService,
    private router: Router
  ) {
    this.IS_CLOUD = Config.IS_CLOUD;
    this.usersService.getUsers();
    // Get current user
    this.subscriptions.push(
      this.usersService.getCurrentUser().subscribe((currentUser: GkUser) => {
        this.currentUser = currentUser;
      })
    );
    this.getUiState();
    this.breadcrumbOptions = new BreadCrumbOptions();
    this.breadcrumbOptions.path = [{ titleKey: 'security', url: '../security' }];
    this.initOrganization();
    this.initSecurityFeatures();
    this.checkUntrustedDevices();
    this.sessionsInit();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  // QR functions
  // Send req with the organization pin and get new string QR code.
  // Then generate and show the QR image
  public generateNewQr = (pin: string) => {
    this.subscriptions.push(
      this.organizationService.GenerateQr(pin).subscribe(
        (organization: GkOrganization) => {
          this.snackBar.openSweetAlert(GkAlertType.Success, this.translationService.getTranslation('newQrSuccess'));
          // Parse new organization properties
          this.organization.created = organization.created;
          this.organization.domain = organization.domain;
          this.organization.name = organization.name;
          this.organization.qr = organization.qr;
          this.organization.trialOver = organization.trialOver;
          this.organization._id = organization._id;
        },
        (error: any) => {
          this.snackBar.openSweetAlert(GkAlertType.Error, this.translationService.getTranslation('newQrFailed'));
        }
      )
    );
  };

  // Open pin dialog, then generate new qr code
  public openPinDialog = (): string | void => {
    let pinDialog = this.dialog.open(PinModalComponent, {
      height: '250px',
      width: '350px'
    });
    pinDialog.afterClosed().subscribe((pin: string) => {
      if (pin) {
        this.generateNewQr(pin);
      }
    });
  };

  // Download qr code as png
  public downloadQR = () => {
    let filename: string = this.organization.name.toLocaleLowerCase().replace(' ', '_') + '.png';
    //  Get qr image as data
    let code = qr.imageSync(this.organization.qr, { type: 'png' });
    // Make blob from image data
    let blob = new Blob([code], { type: 'application/png' });
    // Save the file as image
    FileSaver.saveAs(blob, filename);
  };

  // Open QR modal
  public openQRDialog = (): string | void => {
    let qrDialog = this.dialog.open(QrModalComponent, {
      height: '250px',
      width: '250px',
      data: {
        qrString: this.organization.qr,
        size: 200
      }
    });
  };

  // Sessions functions
  public sessionsInit = () => {
    this.organizationService
      .GetSessions(
        FIRST_PAGE,
        this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.sessionsManagment : DEFAULT_PAGE_SIZE
      )
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          // Paginated req
          if (res.items && res.items.length) {
            this.sessions = this.handleInvalidSessions(res.items);
            this.totalSessionsLength = res.total;
            this.sessionsTableInit(this.sessions);
          }
        },
        (error: any) => {
          console.error('Failed to get organization features');
        }
      );
  };

  public getMoreSessions = (page, skip) => {
    // Got all Data
    this.subscriptions.push(
      this.organizationService
        .GetSessions(page, skip)
        .pipe(take(1))
        .subscribe(
          (res: any) => {
            // Paginated req
            if (res.items && res.items.length) {
              this.sessions = this.handleInvalidSessions(res.items);
              this.sessionsTableInit(this.sessions);
            }
          },
          (error: any) => {
            console.error('Failed to get organization features');
          }
        )
    );
  };

  // Sessions table init
  public sessionsTableInit = (dataSource?: any) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.id = 'sessionsManagment';
    this.tableOptions.pageSize =
      this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.sessionsManagment : null;
    this.tableOptions.rowHoverClass = true;
    this.tableOptions.columns = [
      {
        name: 'entityid',
        displayNameKey: 'userid'
      },
      {
        name: 'lastSeen',
        displayNameKey: 'lastSeen',
        template: ColumnTemplete.Date
      },
      {
        name: 'location.formattedAddress',
        displayNameKey: 'location'
      },
      {
        name: 'sessInfo.ip',
        displayNameKey: 'ip'
      },
      {
        name: 'sessInfo.ua',
        displayNameKey: 'device',
        width: '15',
        template: ColumnTemplete.Cellular
      },
      {
        name: '_id',
        displayNameKey: 'delete',
        width: '15',
        template: ColumnTemplete.DeleteButton,
        onClick: (e: MouseEvent, session: GkSession) => {
          this.openDeleteModal(session);
        }
      }
    ];
    this.tableOptions.pagination = {
      pageChange: (event) => {
        this.getMoreSessions(event.pageIndex, event.pageSize);
      },
      totalItemsLength: this.totalSessionsLength
    };
    if (dataSource) {
      // add last seen sort
      // Currently comment-out because got sorted data from server pagination
      // if (this.sessions && this.sessions[0]) this.sessions = this.sortByLastSeen(dataSource);
      this.tableOptions.dataSource = this.sessions;
    }
  };

  public openDeleteModal(session: GkSession): void {
    let confirmDialog = this.dialog.open(ConfirmationComponent, {
      data: {
        session: {
          name: session.entityid
        }
      }
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteSession(session._id);
      }
    });
  }

  public deleteSession = (sessionId: string) => {
    let index = this.sessions.findIndex((session: GkSession) => {
      return session._id === sessionId;
    });
    this.loaderService.show();
    this.subscriptions.push(
      this.organizationService.DeleteSession(sessionId).subscribe(
        (isDeleted: any) => {
          let entityid = this.sessions[index].entityid;
          this.sessions.splice(index, 1);
          this.sessionsTableInit(this.sessions);
          this.snackBar.openSweetAlert(
            GkAlertType.Success,
            entityid + ' ' + this.translationService.getTranslation('wasRemoved')
          );
        },
        (error: any) => {
          this.snackBar.openSweetAlert(
            GkAlertType.Error,
            this.translationService.getTranslation('failedToDelete') + ' ' + sessionId
          );
        },
        () => {
          this.loaderService.hide();
        }
      )
    );
  };

  public deleteAllSessions = () => {
    let deleteAllConfirmDialog = this.dialog.open(ConfirmationComponent, {
      data: {
        allSessions: {}
      }
    });
    deleteAllConfirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.push(
          this.organizationService.DeleteAllSessions().subscribe((e: any) => {
            let currentUserSession = _.find(this.sessions, { entityid: this.currentUser.entityid });
            this.sessions = [currentUserSession];
            this.sessionsTableInit(this.sessions);
            this.snackBar.openSweetAlert(
              GkAlertType.Success,
              this.translationService.getTranslation('allSessionsHasRemoved')
            );
          })
        );
      }
    });
  };

  public toCamelCase = (str: any) => {
    if (!str) return;
    str = str.toLowerCase();
    let newString: any = [];
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '_') {
        // upperCase to next letter
        newString.push(str[i + 1].toUpperCase());
        i++;
      } else {
        newString.push(str[i]);
      }
    }
    return newString.join('');
  };
  public handleInvalidSessions = (sessions: any[]): GkSession[] => {
    for (let i = 0; i < sessions.length; i++) {
      // Check invalid lat lon
      if (sessions[i].location === '0, 0') {
        sessions[i].location = this.translationService.getTranslation('unknown');
      }
      // Check invalid ip
      let ip = _.get(sessions[i], 'sessInfo.ip', 'unknown').toLowerCase();
      if (!ip || ip.indexOf('unknown') !== -1) {
        sessions[i].sessInfo = {
          ip: this.translationService.getTranslation('unknown')
        };
      }
    }
    return sessions;
  };

  // Security Functions
  public initSecurityFeatures = () => {
    this.subscriptions.push(
      this.organizationService.GetOrganizationsFeatures().subscribe(
        (organizationFeatures: OrganizationsFeature[]) => {
          this.organizationFeatures = [];
          // tslint:disable-next-line:forin
          for (let key in organizationFeatures) {
            let value = {
              translationKey: this.toCamelCase(key),
              translationDescKey: this.toCamelCase(key + '_desc'),
              value: organizationFeatures[key].value,
              readable: organizationFeatures[key].readable,
              description: organizationFeatures[key].description,
              id: key
            };
            this.organizationFeatures.push(value);
          }
          // Saving security features in common organization
          this.organizationService.setSecurityFeatures(this.organizationFeatures);
        },
        (error: any) => {
          console.error('Failed to get organization features');
        }
      )
    );
  };

  public isSecurityToggleChecked = (toggleValue: number) => {
    if (this.organization && this.organization.security) {
      // tslint:disable-next-line:triple-equals
      return (toggleValue & this.organization.security) != 0;
    }
  };

  public securityFeatureChanged = ($event: MatSlideToggleChange, toggle: any) => {
    let confirmDialog = this.dialog.open(DisableConfirmationComponent, {
      width: '400px',
      disableClose: true,
      data: {
        type: 'securityFeature',
        name: toggle.translationKey,
        description: toggle.translationDescKey,
        isDisabled: $event.checked,
        id: toggle.id
      }
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      // Checking if got an entity id from the modal
      let entityId = _.get(result, '_id');
      if (entityId) {
        this.router.navigateByUrl('user/' + result._id);
        return;
      }
      if (result) {
        let newSecurity;
        let oldSecurity = this.organization.security;
        if ($event.checked) {
          newSecurity = this.organization.security | toggle.value;
        } else {
          newSecurity = this.organization.security &= ~toggle.value;
        }
        let change = {
          mode: toggle.value,
          enabled: $event.checked
        };
        this.organization.security = oldSecurity;
        let updatedOrganization = Object.assign({}, this.organization);
        updatedOrganization.security = newSecurity;
        this.subscriptions.push(
          this.organizationService.ToggleOrganizationSecurityFeature(change).subscribe(
            (res: any) => {
              this.organizationService.localUpdateOrganiztion(updatedOrganization);
              this.snackBar.open(this.translationService.getTranslation('organizationSecurityUpdateSuccefully'));
            },
            (error: any) => {
              updatedOrganization.security = oldSecurity;
              this.organizationService.localUpdateOrganiztion(updatedOrganization);
              this.snackBar.open(this.translationService.getTranslation('organizationSecurityUpdateFailed'));
              console.error('Error while trying update organization');
            }
          )
        );
      } else {
        $event.source.checked = !$event.source.checked;
        $event.checked = !$event.checked;
      }
    });
  };

  // Untrusted Devices
  public initUntrustedDevices = (afterPageLoad?: boolean) => {
    // If load after toggle security toggle
    this.loaderService.show();
    this.subscriptions.push(
      this.usersService.GetUntrustedDevices().subscribe(
        (untrustedDevices: GkDevice[]) => {
          this.untrustedDevices = untrustedDevices;
          this.initUntrustedDevicesTable(this.untrustedDevices);
          this.loaderService.hide();
        },
        (e) => {
          console.error('Error while trying get untrusted devices');
        },
        () => {
          if (afterPageLoad) this.loaderService.hide();
        }
      )
    );
  };

  public initUntrustedDevicesTable = (untrustedDevices?: any[]) => {
    this.trustedTableOptions = new TableOptions();
    this.trustedTableOptions.id = 'untrustedDevices';
    this.trustedTableOptions.pageSize =
      this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.untrustedDevices : null;
    this.trustedTableOptions.rowHoverClass = true;
    this.trustedTableOptions.columns = [
      {
        name: 'username',
        displayNameKey: 'userName'
      },
      {
        name: 'name',
        displayNameKey: 'name'
      },
      {
        name: 'lastSeen',
        displayNameKey: 'lastSeen',
        template: ColumnTemplete.Date
      },
      {
        name: 'fingerprint',
        displayNameKey: 'fingerprint',
        width: '20'
      },
      {
        name: 'ip',
        displayNameKey: 'ip',
        width: '10'
      },
      {
        name: 'useragent',
        displayNameKey: 'device',
        width: '7',
        template: ColumnTemplete.Cellular
      },
      {
        name: '_id',
        displayNameKey: 'trust',
        width: '15',
        template: ColumnTemplete.trustButton,
        onClick: (e: any, device: GkDevice) => {
          if (e.target.className.indexOf('delete') !== -1) {
            this.opentrustModal(device, true);
            return;
          }
          this.opentrustModal(device, false);
        }
      }
    ];
    if (untrustedDevices) {
      // Preventing from fuck the loader
      if (untrustedDevices[0]._id) this.untrustedDevices = sortByLastSeen(untrustedDevices);
      this.trustedTableOptions.dataSource = this.untrustedDevices;
      if (this.sessionsInitialized) this.loaderService.hide();
      return;
    }
    this.trustedTableOptions.dataSource = [];
    if (this.sessionsInitialized) this.loaderService.hide();
  };

  public opentrustModal = (device: GkDevice, untrust: boolean) => {
    let confirmDialog = this.dialog.open(TrustConfirmationComponent, {
      data: {
        width: '400px',
        name: device.name,
        untrust
      }
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      if (!untrust) {
        if (result) {
          this.subscriptions.push(
            this.usersService
              .AproveDevice(device._id)
              .pipe(take(1))
              .subscribe(
                (trustedDevice: GkDevice) => {
                  let index = this.untrustedDevices.findIndex((untrustedDevice: GkDevice) => {
                    return untrustedDevice._id === device._id;
                  });
                  this.untrustedDevices.splice(index, 1);
                  this.initUntrustedDevicesTable(this.untrustedDevices);
                  this.snackBar.open(this.translationService.getTranslation('deviceTrustedSuccess'));
                },
                (error: any) => {
                  this.initUntrustedDevicesTable(this.untrustedDevices);
                  this.snackBar.open(this.translationService.getTranslation('deviceTrustedFailed'));
                }
              )
          );
        }
      } else {
        if (result) {
          this.untrustedDevice(device);
        }
      }
    });
  };

  public untrustedDevice = (device: GkDevice) => {
    this.subscriptions.push(
      this.usersService.DeleteTrustedDevice(device._id).subscribe(
        (res: any) => {
          let index = this.untrustedDevices.findIndex((untrustedDevice: GkDevice) => {
            return untrustedDevice._id === device._id;
          });
          this.untrustedDevices.splice(index, 1);
          this.initUntrustedDevicesTable(this.untrustedDevices);
          this.snackBar.open(this.translationService.getTranslation('deviceUntrustedSuccess'));
        },
        (error: any) => {
          this.snackBar.open(this.translationService.getTranslation('deviceUntrustedFailed'));
        },
        () => {
          this.loaderService.hide();
        }
      )
    );
  };

  public getUiState = () => {
    this.subscriptions.push(
      this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
        this.uiState = state;
        this.sessionsTableInit(this.sessions);
      })
    );
  };

  public initOrganization = () => {
    this.subscriptions.push(
      this.organizationService.GetMyOrganization().subscribe((organization: any) => {
        this.organization = organization;
        this.checkUntrustedDevices();
      })
    );
  };

  public checkUntrustedDevices = () => {
    if (this.isSecurityToggleChecked(Config.SecurityFeatures.DEVICE_TRUST_ADMIN)) {
      if (this.sessionsInitialized) this.loaderService.show();
      this.initUntrustedDevices(true);
      return;
    }
    this.untrustedDevices = [];
  };
}
