import { GkOrganization, SecurityTypes } from 'app/contracts/contracts';
import { Observable, ReplaySubject } from 'rxjs';
import { WebApiService } from '../../../services/web-api.service';
import { Injectable, OnDestroy } from '@angular/core';
import { httpMethod } from '../../../contracts/contracts';
import { Constants } from '../../../shared/constant';
import { PaginationService } from '../../../services/pagination-service';
import { EntityTypes, PaingationItems } from '../../../contracts/ui.contracts';

@Injectable()
export class OrganizationService implements OnDestroy {
  public myOrganization: GkOrganization;
  public organizationServer: ReplaySubject<GkOrganization> = new ReplaySubject();
  public organizationSecurityFeatures: any;
  public isInit: boolean;
  public subscriptions: any[] = [];

  constructor(private webApi: WebApiService, private paginationService: PaginationService) {}

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public InitMyOrganization = () => {
    this.isInit = true;
    this.subscriptions.push(
      this.webApi
        .makeRequest(Constants.Services.Organization, null, httpMethod.Get)
        .subscribe((org: GkOrganization) => {
          this.myOrganization = org;
          this.organizationServer.next(this.myOrganization);
        })
    );
  };

  public GetMyOrganization = (): ReplaySubject<GkOrganization> => {
    if (!this.isInit) {
      this.InitMyOrganization();
    }
    return this.organizationServer;
  };

  public UpdateOrganization = (organziation: GkOrganization) => {
    return this.webApi.makeRequest(
      Constants.Services.Organizations + '/' + organziation._id,
      organziation,
      httpMethod.Post
    );
  };

  public ToggleOrganizationSecurityFeature = (change: { mode: number; enabled: boolean }) => {
    return this.webApi.makeRequest(
      Constants.Services.Organizations + '/' + this.myOrganization._id + '/security',
      change,
      httpMethod.Patch
    );
  };

  // Get organiztion licenses
  public GetLicenses = () => {
    return this.webApi.makeRequest(Constants.Services.Licenses, null, httpMethod.Get);
  };

  public GetLicensesFor = (months: number) => {
    return this.webApi.makeRequest(Constants.Services.Licenses, null, httpMethod.Get, null, {months});
  }

  // Get string pin and generate new qr qode
  public GenerateQr = (pin: string): Observable<GkOrganization> => {
    return this.webApi.makeRequest(Constants.Services.Organization + '/qr', { pin }, httpMethod.Post);
  };

  // Sessions
  // page = the page number in the table (Starting with 0)
  // skip = the number of items in each page
  public GetSessions = (page: number, skip: number) => {
    return this.paginationService.GetItems(PaingationItems.Sessions, page, skip);
  };

  public DeleteAllSessions = (): Observable<any> => {
    return this.webApi.makeRequest(Constants.Services.Sessions, null, httpMethod.Delete);
  };

  public DeleteSession = (sessionId: string): Observable<any> => {
    return this.webApi.makeRequest(Constants.Services.Sessions + '/' + sessionId, null, httpMethod.Delete);
  };

  public GetOrganizationsFeatures = (): Observable<any> => {
    return this.webApi.makeRequest(Constants.Services.Organizations + '/features', null, httpMethod.Get);
  };

  public localUpdateOrganiztion = (organiztion: GkOrganization) => {
    this.myOrganization = organiztion;
    this.organizationServer.next(this.myOrganization);
  };

  public setSecurityFeatures = (scFeatures: any) => {
    if (!this.organizationSecurityFeatures) this.organizationSecurityFeatures = {};
    for (let i = 0; i < scFeatures.length; i++) {
      this.organizationSecurityFeatures[scFeatures[i].id] = scFeatures[i].value;
    }
  };

  public SetServiceSecurityFeatures = () => {
    return new Promise((resolve, reject) => {
      if (this.organizationSecurityFeatures && Object.keys(this.organizationSecurityFeatures).length) resolve();
      this.subscriptions.push(
        this.GetOrganizationsFeatures().subscribe((scFeatures: any) => {
          if (scFeatures) {
            this.organizationSecurityFeatures = {};
            // tslint:disable-next-line:forin
            for (let key in scFeatures) {
              this.organizationSecurityFeatures[key] = scFeatures[key].value;
            }
            resolve();
          }
        })
      );
    });
  };

  public isSecurityFeatureOn = (securityKey: SecurityTypes) => {
    return new Promise((resolve, reject) => {
      this.SetServiceSecurityFeatures().then(() => {
        if (this.myOrganization) {
          // tslint:disable-next-line:triple-equals
          resolve((this.organizationSecurityFeatures[securityKey] & this.myOrganization.security) != 0);
          return;
        }
        this.subscriptions.push(
          this.GetMyOrganization().subscribe((organiztion: GkOrganization) => {
            if (organiztion) {
              // tslint:disable-next-line:triple-equals
              resolve((this.organizationSecurityFeatures[securityKey] & organiztion.security) != 0);
            }
          })
        );
      });
    });
  };

  // page = the page number in the table (Starting with 1)
  // skip = the number of items in each page

  public GetEmailInvitations = (page: number, skip: number) => {
    return this.webApi.makeRequest(Constants.Services.Invitations + '/email', null, httpMethod.Get, undefined, {
      page,
      skip,
    });
  };

  public DeleteEmailInvitations = (email: string) => {
    return this.webApi.makeRequest(Constants.Services.Invitations + '/' + email, null, httpMethod.Delete);
  };

  public ResendEmailInvitations = (email: string) => {
    return this.webApi.makeRequest(Constants.Services.Invitations + '/' + email + '/' + 'resend', null, httpMethod.Put);
  };

  // Magic link
  // page = the page number in the table (Starting with 1)
  // skip = the number of items in each page
  public GetMagicEmailInvitations = (page: number, skip: number) => {
    return this.webApi.makeRequest(Constants.Services.MagicInvitation, null, httpMethod.Get, undefined, {
      page,
      skip,
    });
  };

  public UpdateMagicEmailInvitation = (
    invitationId: string,
    update: { timeLimit: any; hitsLimit: number; type: EntityTypes; team: string }
  ) => {
    // Parsing times
    update.timeLimit =
      typeof update.timeLimit === 'string'
        ? new Date(update.timeLimit).getTime()
        : update.timeLimit._isAMomentObject
        ? update.timeLimit.toDate().getTime()
        : typeof update.timeLimit === 'number'
        ? update.timeLimit
        : update.timeLimit.getTime();
    return this.webApi.makeRequest(Constants.Services.Invitations + '/magic/' + invitationId, update, httpMethod.Put);
  };

  public DeleteMagicInvitation = (invitationId: string) => {
    return this.webApi.makeRequest(Constants.Services.Invitations + '/magic/' + invitationId, null, httpMethod.Delete);
  };

  public ExportAttacments = (activities: string[], email: string, toSave?: boolean) => {
    let data = {
      activities,
      email: {
        address: email,
        toSave,
      },
    };
    return this.webApi.makeRequest('/export/attachments', data, httpMethod.Post);
  };

  public getInviteLink = (timeLimit: any, hitsLimit: number, type: EntityTypes, teamId: string): Observable<string> => {
    let data = {
      timeLimit:
        typeof timeLimit === 'string'
          ? new Date(timeLimit).getTime()
          : timeLimit._isAMomentObject
          ? timeLimit.toDate().getTime()
          : typeof timeLimit === 'number'
          ? timeLimit
          : timeLimit.getTime(),
      hitsLimit,
      type,
      team: teamId,
    };
    return this.webApi.makeRequest('/invitations/magic', data, httpMethod.Post);
  };

  // Organiztion tokens
  public generateOrganizationsToken = (): Observable<GkOrganization> => {
    return this.webApi.makeRequest(Constants.Services.Organization + '/token', null, httpMethod.Post);
  };

  public deleteOrganizationsToken = (): Observable<GkOrganization> => {
    return this.webApi.makeRequest(Constants.Services.Organization + '/token', null, httpMethod.Delete);
  };
}
