<div class="download-modal-container">
  <h1 mat-dialog-title>
      {{ "bulkMediaDownload" | translate }}
  </h1>
  <mat-dialog-content>
    <div class="no-loading-conainer" *ngIf="!isLoading">
      <div class="no-email-container" *ngIf="!finishDownload" fxLayout="column" fxLayoutAlign="space-between center">
        <span class="text">
            {{ 'bulkMediaHelper' | translate}}
        </span>
        <!-- Email input -->
        <input type="email" [(ngModel)]="email"/>
        <!-- Email checkbox -->
        <mat-checkbox
          [(ngModel)]="saveEmail"
          matRippleDisabled
          [indeterminate]="false"
          [disableRipple]="true">
          {{ 'makeEmailPrimary' | translate}}
        </mat-checkbox>
        
        <!-- Error message -->
        <div class="error-message" *ngIf="errorMessage">{{ errorMessage }}</div>
        <!-- Send button -->
        <button mat-raised-button type="submit" [disabled]="!email" class="confirm-button"
        (click)="sendDownloadRequest()" >{{'send' | translate }}</button>
      </div>

      <div class="success-container" fxLayout="column" fxLayoutAlign="space-between center" *ngIf="finishDownload">
        <div class="text" fxLayout="column" fxLayoutAlign="center center">
          <span>{{ data?.files?.length }} {{ 'filesDownloadSuccess' | translate}} </span>
          <span>{{ 'anEmailIsOnTheWay' | translate }}</span>
          <span> {{email}}.</span>
        </div>
        <button mat-raised-button class="confirm-button" (click)="dialogRef.close()" >{{ 'close' | translate }}</button>
      </div>
    </div>
    <!-- loader -->
    <div class="loader" *ngIf="isLoading"></div>
  </mat-dialog-content>
</div>
