import { WebApiService } from './../../services/web-api.service';
import { TranslationService } from './../../services/translation-service.service';
import { UsersService } from './../pages/users/users.service';
import { Component, OnInit, Input } from '@angular/core';
import { GkUser, GkOrganization } from '../../contracts/contracts';
import { Config } from './../../shared/config';
import { OrganizationService } from '../pages/dashboard/organization.service';
import { Router } from '@angular/router';
const GK_LANGUAGE_KEY = 'GK_LANGUAGE';
@Component({
  selector: 'gk-toolbar',
  templateUrl: './gk-toolbar.component.html',
  styleUrls: ['./gk-toolbar.component.scss'],
})
export class GkToolbarComponent implements OnInit {
  public user: GkUser;
  public languages: any[];
  public organization: GkOrganization;
  @Input() isSidenavOpen: boolean;
  public subscriptions: any[] = [];
  public mouse: any;

  constructor(
    private usersService: UsersService,
    private translationService: TranslationService,
    private webApi: WebApiService,
    private organizationService: OrganizationService,
    private router: Router
  ) {
    this.initCurrentUser();
    this.initOrganiztion();
  }
  public initOrganiztion() {
    this.subscriptions.push(
      this.organizationService.GetMyOrganization().subscribe((org: GkOrganization) => {
        this.organization = org;
      })
    );
  }

  public initCurrentUser() {
    this.subscriptions.push(
      this.usersService.getCurrentUser().subscribe((user: GkUser) => {
        this.user = user;
        // User doens't have admin permissions
        if (this.user && !this.user.admin) {
          this.goToMonitor();
          return;
        }
        this.languages = this.translationService.getLangs();
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public changeLanguage = (language: string) => {
    let updatedUser = {
      _id: this.user._id,
      locale: language,
      teams: this.usersService.getCurrentUserTeamsId()
    };
    this.usersService.UpdateUser(this.user._id, updatedUser).subscribe((res: any) => {
      localStorage.setItem(GK_LANGUAGE_KEY, btoa(language));
      this.translationService.changeLanguage(language);
      window.location.reload();
    }, (e) => {
      console.error('Failed to change language', e.message);
    });
  };

  public logout = () => {
    this.webApi.logout();
  };

  public goToMonitor = () => {
    let url = Config.AppUrl || 'http://127.0.0.1:5002/#';
    window.location.href = url;
  };

  public goToEditUser = () => {
    this.router.navigateByUrl('user/' + this.user._id);
  };
}
