import { Config } from 'app/shared/config';
import { WebApiService } from './web-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { httpMethod, GkActivity, GkUser } from '../contracts/contracts';
import * as moment from 'moment';
import { TranslationService } from 'app/services/translation-service.service';
import { LocalDateFormatPipe, LocalTimeFormatPipe, LocalFullDateFormatPipe } from '../shared/pipes/localTime.pipe';
import { UsersService } from '../components/pages/users/users.service';
import { Constants } from 'app/shared/constant';
import { take } from 'rxjs/operators';
import { FileSizePipe } from 'app/shared/pipes/filesSize.pipe';

const GEOFENCE_ENTER_ACTIVITY = 'geofence-enter';
const DEFAULT_ENTITY_IMAGE = 'assets/img/user_pic.png';
@Injectable()
export class MapsService {

  constructor(private webApi: WebApiService, private translationService: TranslationService,
              private datePipe: LocalDateFormatPipe, private timePipe: LocalTimeFormatPipe,
              private fullDatePipe: LocalFullDateFormatPipe,
              private usersService: UsersService,
              private fileSize: FileSizePipe) { }

  public GetStyles = (): Observable<any> => {
    return this.webApi.makeThirdPartyRequest(Config.mapStyleUrl, null, httpMethod.Get, null, false);
  }

  public getToolTipImage = (type: any, location: any) => {
    if (!(location && location.latitude)) return '';
    let icon;
    // Checking which type we want - representing icon on map
    if (type === Config.EntityTypes.USER) {
      icon = 'https://s3.amazonaws.com/globekeeper/public/img/activity_user_pin.png';
    } else if (type === Config.EntityTypes.TRACKER) {
      icon = 'https://s3.amazonaws.com/globekeeper/public/img/activity_entity_pin.png';
    } else if (type === Config.KEY_PANIC) {
      icon = 'https://s3.amazonaws.com/globekeeper/public/img/activity_panic_pin.png';
    } else if (type === 'image' || type === 'video') {
      type = 'user';
    } else {
      icon = 'https://s3.amazonaws.com/globekeeper/public/img/activity_user_pin.png';
    }
    // Tooltip URL
    let urlImage = icon;
    // place-created is will return the place image for all the place events
    if (typeof type === 'string') {
      if (type.indexOf('geofence') !== -1 || type.indexOf('place') !== -1) type = 'place-created';
    }
    // Building HTML
    let apiUrl: string = Config.ApiUrl;
    let url = apiUrl + '/staticmaps/?lat=' + location.latitude + '&lon=' + location.longitude + '&type=' + type;
    let html = '<img class="data-image tooltip-image" src="' + url + '">';
    return html;
  }

  // Utils functions for map components
  // get activity and return his html with the currect template
  public getActivitiyTooltipTemplate = (activity: any) => {
    let data: GkActivity = JSON.parse(activity);
    let entityImage = data.entity.extras && data.entity.extras.picture ? data.entity.extras.picture :
      DEFAULT_ENTITY_IMAGE;
    let isPlaceActivty = data.data && data.data.place;
    let activityLocation = data.geoLocation ? data.geoLocation :
      data.location.latitude + ' / ' + data.location.longitude;
    let template =
      `<div class="activity-tooltip-container">
        <div class="user-block row">
            <img src="` + entityImage + `" class="avatar">
            <div class="name-date-block" fxLayout="column">
              <span class="entity-name">` + data.userFullName + `</span>
              <span class="activity-date">` + this.fullDatePipe.transform(isPlaceActivty ? data.data.place.created
        : data.created) + `
                <span class="end-time"></span>
              </span>
            </div>
        </div>
        <div class="location-block row">
          <img class="location-icon" src="assets/img/svg/location_icon.svg">
          <span class="location-text">` + activityLocation + `</span>
        </div>
        <div class="data-block" fxLayout="row" fxLayoutAlign="center center">
        ` + this.getTooltipDataHtml(data) + `
        </div>
      </div>`;
    // add 'created' in case of a place with history
    if (data.type === 'place-created') {
      let createdString: string = '<span class="activity-date">';
      template = template.replace('<span class="activity-date">', createdString);
    }
    // add 'ended' in case of a place is deleted
    if (isPlaceActivty && data.data.place.endTime) {
      let endString: string =
        ' - <span class="end-time">' +
        this.fullDatePipe.transform(data.data.place.endTime) + '</span>';
      template = template.replace('<span class="end-time"></span>', endString);
    }
    // add place name in case of a place
    if (data.type.indexOf('geofence') !== -1 || data.type.indexOf('place') !== -1) {
      let placeNameString: string =
        '<span class="place-name">' + data.data.place.name + '</span> <span class="activity-date">';
      template = template.replace('<span class="activity-date">', placeNameString);
    }
    return template;
  }

  // get activity and return his data-section-html with his currect template
  public getLocationTooltipTemplate = (location: any) => {
    // handle time
    let locationTime = this.timePipe.transform(location.date);
    let locationDate = this.datePipe.transform(location.date);
    let html =
      `<div class="location-tooltip-container">
        <div class="data-block" fxLayout="column" fxLayoutAlign="center center">
          <span class="entity-name">` + location.entityName + `</span>
          <span class="location-date">` + locationDate + `, ` + locationTime + `</span>
          </div>
        </div>
      </div>`;
    return html;
  }

  // get activity and return his data-section-html with his currect template
  public getPlaceTooltip = (activity: GkActivity) => {
    let imageHtml = this.getToolTipImage(activity.type, activity.location);
    if (activity.history && activity.history.length || activity.data.place.endTime) {
      imageHtml +=
        `</div><div class="place-history-container">
          ` + this.getPlaceHistoryHtml(activity) + `
        </div>`;
    } else {
      imageHtml += `</div>`;
    }
    return imageHtml;
  }

  public getPlaceHistoryHtml = (activity: GkActivity) => {
    let html = '';
    for (let i = 0; i < activity.history.length; i++) {
      if (!activity.history[i].type) return;
      let typeTranslation: string = activity.history[i].type === GEOFENCE_ENTER_ACTIVITY ?
        this.translationService.getTranslation('geoEnter') : this.translationService.getTranslation('geoExit');
      let placeImage: string = activity.history[i].entity.extras && 
      activity.history[i].entity.extras.picture ? activity.history[i].entity.extras.picture : DEFAULT_ENTITY_IMAGE;
      let typeColor: string = activity.history[i].type === GEOFENCE_ENTER_ACTIVITY ? '#DF695B' : '#478DD4';

      html += `<div class='item-container'>`;
      html += `<img src='` + placeImage;
      html += `' class="avatar">`;
      html += `<div class="column">`;
      html += `<div class="history-item-name">` + activity.history[i].entity.name + `</div>
      <div class="history-item" style="color: ` + typeColor + `!important">
      <span class="type">` + typeTranslation + `</span>
      <span class="type">` + activity.history[i].time + `</span>
      </div></div></div>
      <span class="divider"></span>`;
      if (i === activity.history.length - 1 && !activity.data.place.endTime) html += `</div>`;
    }
    if (activity.data.place.endTime) {
      let entity: any = this.usersService.GetLocalUser(activity.data.place.endEntity) || {};
      if (!entity.name) entity = {
        name: this.translationService.getTranslation('unknownEntity')
      };
      let image = entity.extras && entity.extras.picture ? entity.extras.picture : DEFAULT_ENTITY_IMAGE;
      let deletedString = this.translationService.getTranslation('deleteThisLocation');

      html += `<div class='item-container'>`;
      html += `<img src='` + image + `' class="avatar">`;
      html += `<div class="column" style="color: #DF695B">`;
      html += `<div class="history-item-name" style="color: #DF695B">` + entity.name + `</div>`;
      html += `<div class="history-item">`;
      html += `<span class="type">` + deletedString + `</span>
        <span class="type">` + this.fullDatePipe.transform(activity.data.place.endTime) + `</span>
        </div></div></div>
        <span class="divider"></span></div>`;
      return html;
    } else {
      return html;
    }
  }

  // get activity and return his data-section-html with his currect template
  public getTooltipDataHtml = (activity: GkActivity) => {
    switch (activity.type) {
      case 'image':
        return `<img src=` + activity.data.urls.vga + ` class="data-image">`;
      case 'video':
        return `<video src=` + activity.data.url + ` class="data-image" controls></video>`;
      case 'message':
        return `<div class="message-box">` + activity.data.message + `</div>`;
      case 'panic':
        return this.getToolTipImage(activity.type, activity.location);
      case 'place-created':
      case 'geofence-exit':
      case 'geofence-enter':
        return this.getPlaceTooltip(activity);
      case 'real-time':
        let isTurnedOn: boolean = Config.hasFlag(activity.data.mode, Config.TeamModes.REAL_TIME);
        let translationText: string = isTurnedOn ? this.translationService.getTranslation('realTimeModeTurnedOn') :
          this.translationService.getTranslation('realTimeModeTurnedOff');
        let additionalOnClass: string = isTurnedOn ? 'on' : 'off';
        let iconName: string = isTurnedOn ? 'realtime_on_icon' : 'realtime_off_icon';
        return `<div class="real-time-block" fxLayout="row" fxLayoutAlign="center center">
         <span class="text ${additionalOnClass}"> ${translationText} </span>
        <img class="real-time-icon" src="assets/img/svg/${iconName}.svg"></div> `;
      case 'file':
        return `
        <div class="name-details" fxLayout="row">
          <div class="lefti" fxLayout="column">
            <div class="name">${activity.data.name}</div>
            <div class="details-row" fxLayout="row">
              <span class="type">${activity.data.type}</span>
              <span class="point">&#x25CF;</span>
              <span>${this.fileSize.transform(activity.data.size)}</span>
            </div>
          </div>
          <a download="${activity.data.url}" href="${activity.data.url}">
            <img class="download-button" src="assets/img/svg/ic_download_blue.svg"/>
          </a>
        </div>
        `;
      default:
        break;
    }
  }

  // Retriev address by lat,lon
  getGeoLocation(latitude: number, longitude: number) {
    return new Promise((resolve, reject) => {
      if (!latitude || !longitude || longitude === 0 && latitude === 0)
        reject(console.error('geocode was not provided with location'));
      let params = {
        lat: latitude,
        lng: longitude
      };
      this.webApi.makeRequest(Constants.Services.GeoCoding, null, httpMethod.Get, null, params)
        .pipe(take(1)).subscribe((response) => {
          resolve(response[0].formattedAddress);
        }, () => {
          reject(console.error('Failed to get geocode.'));
        });
    });
  }
}
