import { Constants } from './../../../shared/constant';
import { Observable } from 'rxjs';
import { httpMethod, GkIntegration } from './../../../contracts/contracts';
import { Injectable, OnInit } from '@angular/core';
import { WebApiService } from '../../../services/barrel-services';
import { Store } from '@ngrx/store';
import { Istore } from '../../../shared/store/store.module';
import 'rxjs/add/operator/map';

@Injectable()
export class IntegrationsService implements OnInit {
  Î;
  public ngOnInit() {}

  constructor(private webApi: WebApiService, private store: Store<Istore>) {}

  public GetIntegrations = () => {
    this.webApi
      .makeRequest(Constants.Services.Integrations, null, httpMethod.Get)
      .map((Integrations: any) => {
        return { type: 'SET_INTEGRATIONS', payload: Integrations };
      })
      .subscribe((action) => this.store.dispatch(action));
  };

  public CloneTemplate = (templateId: string) => {
    let url = Constants.Services.Integrations + '/templates/' + templateId;
    return this.webApi.makeRequest(Constants.Services.Integrations + '/templates/' + templateId, null, httpMethod.Post);
  };

  public GetIntegrationTemplates = () => {
    return this.webApi.makeRequest(Constants.Services.Integrations + '/templates', null, httpMethod.Get);
  };

  public DeleteEntity = (entityId: string) => {
    return this.webApi.makeRequest(Constants.Services.Entities + '/' + entityId, null, httpMethod.Delete);
  };

  public SetIntegration = (): Observable<GkIntegration> => {
    return this.webApi.makeRequest(Constants.Services.Integrations, null, httpMethod.Post);
  };

  public GetIntegration = (IntegrationId: string): Observable<GkIntegration> => {
    return this.webApi.makeRequest(Constants.Services.Integrations + '/' + IntegrationId, null, httpMethod.Get);
  };

  public DeleteIntegration = (IntegrationId: string) => {
    return this.webApi.makeRequest(Constants.Services.Integrations + '/' + IntegrationId, null, httpMethod.Delete);
  };

  public CreateIntegration = (Integration: any): Observable<GkIntegration> => {
    return this.webApi.makeRequest(Constants.Services.Integrations, Integration, httpMethod.Post);
  };

  public UpdateIntegration = (Integration: any, IntegrationId: string): Observable<GkIntegration> => {
    return this.webApi.makeRequest(Constants.Services.Integrations + '/' + IntegrationId, Integration, httpMethod.Put);
  };

  // Auth integration
  public authIntegration = (IntegrationId: string, authForm: any) => {
    return this.webApi.makeRequest(Constants.Services.Integrations + '/' +
    IntegrationId +  '/auth', authForm, httpMethod.Post);
  };

  // Fetch integration entities
  public fetchIntegrationEntities = (IntegrationId: string) => {
    return this.webApi.makeRequest(Constants.Services.Integrations + '/' +
    IntegrationId +  '/fetch', null, httpMethod.Get);
  };
}
