// tslint:disable-next-line:max-line-length
import { UserPasswordModalComponent } from './../../../modals/userpassword-integration.modal/userpassword-integration.modal.component';
import { IntegrationsService } from './../integrations.service';
import { OnInit, Component } from '@angular/core';
import { BreadCrumbOptions } from '../../../../contracts/ui.contracts';
import { GkIntegration, AppState, AuthType, GkOrganization } from '../../../../contracts/contracts';
import { TranslationService } from '../../../../services/translation-service.service';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ConfirmationComponent } from '../../../modals/confirmation.modal/confirmation.modal.component';
import { OrganizationService } from '../../dashboard/organization.service';

enum IntegrationTabs {
  Installed = 0,
  Explore = 1,
}

@Component({
  selector: 'integration-list',
  templateUrl: './integration-list.component.html',
  styleUrls: ['./integration-list.component.scss'],
  providers: [],
})
export class IntegrationListComponent implements OnInit {
  public dataSource: any;
  public installedIntegrations: GkIntegration[] = [];
  public integrationTemplates: any = [];
  public breadcrumbOptions: BreadCrumbOptions;
  public search: string = '';
  public isInit: boolean = false;
  public selectedTab: IntegrationTabs = IntegrationTabs.Installed;
  public subscriptions: any[] = [];
  // ENUM
  public AuthType = AuthType;
  public IntegrationTabs = IntegrationTabs;
  public organization: GkOrganization;

  constructor(
    private integrationsService: IntegrationsService,
    private organiztionService: OrganizationService,
    private translationService: TranslationService,
    private store: Store<AppState>,
    private snackbar: GkSnackbar,
    public dialog: MatDialog,
    private loaderService: Ng4LoadingSpinnerService
  ) {
    this.integrationsService.GetIntegrations();
    this.organiztionService.GetMyOrganization().subscribe((org: GkOrganization) => {
      this.organization = org;
    });
  }

  ngOnInit() {
    this.isInit = false;
    this.initBreadCrumb();
    this.getIntegrations();
  }

  public getIntegrations() {
    this.subscriptions.push(
      this.store.select('integrations').subscribe((res: any) => {
        if (res) {
          this.installedIntegrations = res.integrations;
          this.getTemplates();
        }
      })
    );
  }

  public initBreadCrumb() {
    this.breadcrumbOptions = new BreadCrumbOptions();
    this.breadcrumbOptions.path = [{ titleKey: 'integrations', url: '../integrations' }];
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public getTemplates = () => {
    this.subscriptions.push(
      this.integrationsService.GetIntegrationTemplates().subscribe(
        (templates: any) => {
          this.integrationTemplates = templates.filter((template: any) => {
            let filter = true;
            if (template.multiply) {
              return filter;
            }
            this.installedIntegrations.forEach((integration: GkIntegration) => {
              if (template.name === integration.name) {
                // Installed integration have found
                filter = false;
              }
            });
            return filter;
          });
          this.isInit = true;
        },
        (error: any) => {
          this.loaderService.hide();
          this.snackbar.open(error);
        }
      )
    );
  };

  public cloneTemplate = (template: any) => {
    this.loaderService.show();
    this.integrationsService.CloneTemplate(template._id).subscribe(
      (integration: GkIntegration) => {
        if (!integration.multiply) {
          let index = this.integrationTemplates.findIndex((integrationTemplate: GkIntegration) => {
            return integrationTemplate._id === template._id;
          });
          // remove added integration from integration templates list
          // push it to installed list
          this.integrationTemplates.splice(index, 1);
        }
        this.installedIntegrations.push(integration);
        if (integration.auth.type === AuthType.USERNAME) {
          // Starting auth process
          // Open login modal
          this.authorizeIntegration(integration);
        }
        // Filter installed Integrations
        this.integrationTemplates = this.integrationTemplates.filter((filteredTemplate: GkIntegration) => {
          return this.installedIntegrations.findIndex((foundIntegration: GkIntegration) => {
            return filteredTemplate.name === foundIntegration.name;
          });
        });
        // move to installed integrations tab
        this.selectedTab = IntegrationTabs.Installed;
      },
      (error: any) => {
        this.snackbar.open(error);
      },
      () => {
        this.loaderService.hide();
      }
    );
  };

  public authorizeIntegration = (integration: GkIntegration) => {
    let confirmDialog = this.dialog.open(UserPasswordModalComponent, {
      panelClass: 'userpassword-integration-modal',
      height: 'unset',
      width: 'unset',
      data: {
        integration,
      },
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      this.search = null;
      if (result && result._id) {
        this.installedIntegrations[this.installedIntegrations.length - 1] = result;
      }
    });
  };

  public goToIntegrationTab = () => {
    this.selectedTab = IntegrationTabs.Explore;
  };

  public handleTabChange = (installedIntegrations: boolean) => {
    this.selectedTab = installedIntegrations ? IntegrationTabs.Installed : IntegrationTabs.Explore;
  };

  public openDeleteModal(integration: GkIntegration): void {
    let confirmDialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      height: '300px',
      data: {
        integration,
        deepWarning: true,
      },
    });

    confirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteIntegration(integration._id);
      }
    });
  }

  public deleteIntegration = (integrationId: string) => {
    let index = this.installedIntegrations.findIndex((integration: GkIntegration) => {
      return integration._id === integrationId;
    });
    let integrationName = this.installedIntegrations[index].name;
    this.loaderService.show();
    this.integrationsService.DeleteIntegration(integrationId).subscribe(
      (isDeleted: any) => {
        // remove deleted integration from team installed Integration list
        // and make it available for install
        this.installedIntegrations.splice(index, 1);
        // get templates after removed one
        this.getTemplates();
        this.snackbar.open(integrationName + ' ' + this.translationService.getTranslation('wasRemoved'));
      },
      (error: any) => {
        this.snackbar.open(this.translationService.getTranslation('failedToDelete') + ' ' + integrationName);
      },
      () => {
        this.loaderService.hide();
      }
    );
  };

  public generateApiToken = () => {
    // save old token for failed
    let oldToken = this.organization.apiToken;
    this.organiztionService.generateOrganizationsToken().subscribe(
      (org: GkOrganization) => {
        this.organization.apiToken = org.apiToken;
      },
      (e: any) => {
        this.organization.apiToken = oldToken;
      }
    );
  };

  public deleteOrganiztionToken = () => {
    // FB style
    let token = this.organization.apiToken;
    this.organization.apiToken = null;
    this.organiztionService.deleteOrganizationsToken().subscribe(
      (res: any) => {
        this.snackbar.open(
          this.translationService.getTranslation('apiToken') +
            ' ' +
            this.translationService.getTranslation('wasRemoved')
        );
      },
      (e: any) => {
        // Rollback
        this.organization.apiToken = token;
        this.snackbar.open(
          this.translationService.getTranslation('failedToDelete') +
            ' ' +
            this.translationService.getTranslation('apiToken')
        );
      }
    );
  };

  public copyApiToken = (inputElement: HTMLInputElement) => {
    // Copy the url
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  };
}
