<div class="user-container">
  <div class="page-title" [ngSwitch]="mode">
    <span *ngSwitchCase="1">
      {{ 'addUser' | translate }}
    </span>
    <span *ngSwitchCase="2">
      {{ 'updateUser' | translate }}
    </span>
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>

  <user-form [gkuser]="user"></user-form>
</div>
