<div class="gk-location-selector-container">
  <div id='gk-location-selector-map'></div>
  <pre id='coordinates' class='coordinates' *ngIf="location && location.latitude > 0">
    {{'Longitude: ' + location.longitude + '<br />Latitude: ' + location.latitude}}
    {{address}}
  </pre>

  <div class="map-buttons-container" fxLayout="column">
    <button (click)="mapZoom('in')" matTooltip="{{ 'zoomIn' | translate }}" matTooltipPosition="left"
      class="zoom-button" id="plus-button">
      <mat-icon>add</mat-icon>
    </button>
    <button (click)="mapZoom('out')" matTooltip="{{ 'zoomOut' | translate }}" matTooltipPosition="left"
      class="zoom-button">
      <mat-icon>remove</mat-icon>
    </button>
    <button (click)="deleteLocation()" matTooltip="Delete Location" matTooltipPosition="left"
      *ngIf="location && location.latitude > 0">
      <svg-icon src="assets/img/svg/ic_delete@3x.svg" class="svg-icon-delete"></svg-icon>
    </button>
  </div>

</div>
