<div class="line-chart-container">

  <div class="licenses-alert" fxLayout="row" *ngIf="licenseWarning">
    <img class="idea-icon" src="assets/img/ic_idea.png">
    <div class="text">
      {{'licensesUsageWarningMessage' | translate}}
    </div>
    <mat-icon class="close-button" (click)="closeWarning()">close</mat-icon>
  </div>

  <div class="lds-roller" *ngIf="!chartLoaded">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>

  <div #chart id="chart" height="300"></div>
</div>
