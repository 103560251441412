/**
 * Angular 2 decorators and services
 */
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState } from './app.service';
import { OrganizationService } from './components/pages/dashboard/organization.service';
import { GkOrganization } from './contracts/contracts';
import { UsersService } from './components/pages/users/users.service';
import { TranslationService } from './services/barrel-services';
import { MeProvider } from './MeProvider';
export const ROOT_SELECTOR = 'app';
declare global {
  interface Window { config: any; }
}

/**
 * App Component
 * Top Level Component
 */
declare global {
  interface Window { config: any; }
}
@Component({
  selector: ROOT_SELECTOR,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public name = 'Globekeeper';

  public subscriptions: any[] = [];

  constructor(public appState: AppState, private organizationService: OrganizationService,
    private usersService: UsersService, private translationService: TranslationService, private meProvider: MeProvider) {
  }

  public ngOnInit() {
    let currentUser = this.meProvider.getMe();
    if (this.redirectIfNotLoggedIn(currentUser)) return;

    this.usersService.initCurrentUser(currentUser);
    if (currentUser.locale) this.translationService.changeLanguage(currentUser.locale);
    this.subscriptions.push(this.organizationService.GetMyOrganization().subscribe((organization: GkOrganization) => {
      this.handleTitleChange(organization);
    }));
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public handleTitleChange = (organization: GkOrganization) => {
    if (organization && !organization.domain) return;
    let splittedDomain = organization.domain.split('.');
    let title = splittedDomain[splittedDomain.length - 2];
    if (window.location.href.indexOf(title.toLowerCase()) !== -1) {
      document.title = organization.name;
    }
  }

  redirectIfNotLoggedIn = (encodedEntity: any) => {
    if (!encodedEntity) {
      let login = window.config ? window.config.APP_URL : 'https://localhost.globekeeper.com:5002/#';
      if (!login.endsWith('/#/login')) {
        login = login + '/#/login';
      }
      let url = login + '/?redirect=' + window.location.href;
      window.location.href = url
      return true;
    }
    return false;
  }
}
