import { WebApiService } from './web-api.service';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Constants } from 'app/shared/constant';
import { httpMethod, GkSession } from 'app/contracts/contracts';
import 'rxjs/add/observable/zip';
import { PaingationItems } from '../contracts/ui.contracts';

@Injectable()
export class PaginationService {
  // Sessions
  public totalSessions: number = Number.MAX_SAFE_INTEGER;
  public sessionsServer: ReplaySubject<{ items: GkSession[], total: number }> = new ReplaySubject();
  public sessions: GkSession[] = [];

  // Incidents
  public totalIncidents: number = Number.MAX_SAFE_INTEGER;
  public incidentsServer: ReplaySubject<{ items: GkSession[], total: number }> = new ReplaySubject();
  public incidents: GkSession[] = [];

  constructor(private webApi: WebApiService) { }

  // Service's clients function - need to behave exactly like the regular API
  // page = the page number in the table (Starting with 0)
  // skip = the number of items in each page
  public GetItems = (type: PaingationItems, page: number, skip: number):
    ReplaySubject<{ items: any[], total: number }> => {
    // Selected Items type
    let workObj: any = this.getDataArray(type); // items, total, server
    // Get request items indexes
    let firstAskedIndex = page !== 0 ? (page * skip) : 0;
    let lastAskedIndex = Math.min(((page + 1) * skip) - 1, workObj.total - 1);
    // Check if have much items
    if (!workObj.items[firstAskedIndex] || !workObj.items[lastAskedIndex]) {
      this._getItems(workObj, page, skip);
    } else {
      // Act like Observable
      setTimeout(() => {
        let askedItems = workObj.items.slice(firstAskedIndex, lastAskedIndex + 1);
        workObj.server.next({
          items: askedItems,
          total: workObj.total
        });
        // Check if got two pages more
        firstAskedIndex = lastAskedIndex + 1;
        lastAskedIndex = Math.min(firstAskedIndex + (skip * 2), workObj.total - 1);
        if (!workObj.items[firstAskedIndex] || !workObj.items[lastAskedIndex]) {
          this._getItems(workObj, workObj.items.length / skip, skip, false);
        }
      }, 100);
    }
    if (workObj.server) workObj.server = new ReplaySubject();
    return workObj.server;
  }

  // In Service data functions
  public _getItems = (workObj: any, page: number, skip: number, emit: boolean = true) => {
    let leftRequest = Math.ceil(skip / (workObj.total % ((page - 1) * skip)));
    let array = [];
    array.push(this.serverGetItems(workObj.url, page, skip));
    if (leftRequest === 2 || isNaN(leftRequest)) array.push(this.serverGetItems(workObj.url, page + 1, skip));
    if (leftRequest > 2 || isNaN(leftRequest)) {
      array.push(this.serverGetItems(workObj.url, page + 1, skip));
      array.push(this.serverGetItems(workObj.url, page + 2, skip));
    }

    Observable.zip(...array).subscribe((res: any[]) => {
      workObj.total = res[0].total;
      for (let i = 0; i < res.length; i++) {
        this.addItemsToLists(workObj.type, res[i].items, res[i].total);
      }
      if (emit) workObj.server.next({
        items: res[0].items,
        total: workObj.total
      });
    });
  }

  // Server function
  public serverGetItems = (url: string, page: number, skip: number) => {
    return this.webApi.makeRequest(url, null, httpMethod.Get, undefined,
      { page, skip });
  }

  // Utils
  public getDataArray = (type: PaingationItems) => {
    switch (type) {
      case PaingationItems.Sessions:
        return {
          items: this.sessions,
          total: this.totalSessions,
          server: this.sessionsServer,
          url: Constants.Services.Sessions,
          type
        };
      case PaingationItems.incidents:
        return {
          items: this.incidents,
          total: this.totalIncidents,
          server: this.incidentsServer,
          url: Constants.Services.HistoryIncidents,
          type
        };
      default:
        break;
    }
  }

  public addItemsToLists = (type: PaingationItems, items: any[], total: number) => {
    switch (type) {
      case PaingationItems.Sessions:
        this.sessions = this.sessions.concat(...items);
        this.totalSessions = total;
        break;
      case PaingationItems.incidents:
        this.incidents = this.incidents.concat(...items);
        this.totalIncidents = total;
        break;
      default:
        break;
    }
  }

}
