import { Observable, ReplaySubject } from "rxjs";
import {
  httpMethod,
  GkUser,
  InvitedUser
} from "./../../../contracts/contracts";
import { Injectable, OnDestroy } from "@angular/core";
import { WebApiService } from "../../../services/barrel-services";
import { Store } from "@ngrx/store";
import { Istore } from "../../../shared/store/store.module";
import { Constants } from "../../../shared/constant";
import "rxjs/add/operator/map";
import { EntitiesService } from "../entities/entities.service";
import { EntityTypes } from "../../../contracts/ui.contracts";

@Injectable()
export class UsersService implements OnDestroy {
  public usersNamesDictionry: any = {};
  public usersDictionry: any = {};
  public usersWithoutEmail: GkUser[] = [];
  public entities: any = {};
  public currentUser: GkUser;
  public currentUserServer: ReplaySubject<GkUser> = new ReplaySubject();
  public subscriptions: any[] = [];

  constructor(
    private webApi: WebApiService,
    private store: Store<Istore>,
    private entitiesService: EntitiesService
  ) {
    this.entitiesService.GetEntities();
  }
  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public initCurrentUser = (currentUser: GkUser) => {
    this.currentUser = currentUser;
    this.currentUserServer.next(this.currentUser);
  };
  public getUsers = () => {
    this.subscriptions.push(
      this.webApi
        .makeRequest(Constants.Services.Users, null, httpMethod.Get)
        .map((payload: any) => {
          return { type: "SET_USERS", payload };
        })
        .subscribe((action: any) => {
          this.store.dispatch(action);
          this.initUsersDic();
        })
    );
  };

  public setCurrentUser = (user: GkUser) => {
    return (this.entities[user._id] = user);
  };

  public getCurrentUser = (): Observable<GkUser> => {
    return this.currentUserServer;
  };

  public GetUser = (userId: string): Observable<GkUser> => {
    return this.webApi.makeRequest(
      Constants.Services.Users + "/" + userId,
      null,
      httpMethod.Get
    );
  };

  public GetLocalUser = (userId: string): GkUser => {
    return this.entities[userId];
  };

  public DeleteUser = (entityid: string) => {
    return this.webApi.makeRequest(
      Constants.Services.Entities + "/" + entityid,
      null,
      httpMethod.Delete
    );
  };

  public CreateUser = (user: any): Observable<GkUser> => {
    let reqOptions = this.prepareUserReq(user);
    return this.webApi.makeRequest(
      Constants.Services.Users,
      reqOptions.user,
      httpMethod.Post,
      reqOptions.headers
    );
  };

  public UpdateUser = (userId: string, user: any) => {
    let reqOptions = this.prepareUserReq(user);
    return this.webApi.makeRequest(
      Constants.Services.Entities + "/" + userId,
      reqOptions.user,
      httpMethod.Post,
      reqOptions.headers
    );
  };

  public InviteUsers = (
    invitedUsers: InvitedUser[],
    includePin: boolean,
    entityType: EntityTypes
  ) => {
    let obj = {
      invites: invitedUsers,
      includePin,
      entitytype: entityType
    };
    return this.webApi.makeRequest("/invite", obj, httpMethod.Post);
  };

  public GetUntrustedDevices = () => {
    return this.webApi.makeRequest("/devices/untrusted", null, httpMethod.Get);
  };

  public DeleteTrustedDevice = (deviceId: string) => {
    return this.webApi.makeRequest(
      "/devices/" + deviceId,
      null,
      httpMethod.Delete
    );
  };

  public AproveDevice = (deviceId: string) => {
    return this.webApi.makeRequest(
      "/devices/" + deviceId + "/trust",
      null,
      httpMethod.Put
    );
  };

  public DeclineDevice = (deviceId: string) => {
    return this.webApi.makeRequest(
      "/devices/" + deviceId + "/untrust",
      null,
      httpMethod.Put
    );
  };

  // Utils
  public initUsersDic = () => {
    this.usersNamesDictionry = {};
    this.usersDictionry = {};
    this.subscriptions.push(
      Observable.combineLatest(
        this.store.select("users"),
        this.store.select("entities")
      ).subscribe(([users, entities]) => {
        let lintedUsers: any = users;
        let lintedEntities: any = entities;
        if (lintedUsers.length) {
          for (let i = 0; i < lintedUsers.length; i++) {
            this.usersNamesDictionry[users[i].entityid] = 1;
            if (lintedUsers[i].email)
              this.usersNamesDictionry[lintedUsers[i].email] = 1;
            this.usersDictionry[lintedUsers[i].entityid] = 1;
            this.entities[lintedUsers[i]._id] = lintedUsers[i];
            if (lintedUsers[i].email && lintedUsers[i].email.length)
              this.usersDictionry[lintedUsers[i].email] = 1;
          }
        }
        if (lintedEntities.length) {
          for (let i = 0; i < lintedEntities.length; i++) {
            this.usersNamesDictionry[lintedEntities[i].entityid] = 1;
            if (lintedEntities[i].email)
              this.usersNamesDictionry[lintedEntities[i].email] = 1;
            this.usersDictionry[lintedEntities[i].entityid] = 1;
            this.entities[lintedEntities[i]._id] = lintedEntities[i];
            if (lintedEntities[i].email && lintedEntities[i].email.length)
              this.usersDictionry[lintedEntities[i].email] = 1;
          }
        }
      })
    );
  };

  public checkEntityidAvailable = (entityid: string): boolean => {
    return !(this.usersNamesDictionry && this.usersNamesDictionry[entityid]);
  };

  public prepareUserReq = (user: any) => {
    let headers = new Headers();
    headers.set("Accept", "application/json");
    return {
      user,
      headers
    };
  };

  public checkAllUsersEmail = () => {
    return new Promise((resolve: any, reject: any) => {
      this.usersWithoutEmail = [];
      this.store.select("users").subscribe(
        (users: any) => {
          if (users.length) {
            for (let i = 0; i < users.length; i++) {
              if (!users[i].email) this.usersWithoutEmail.push(users[i]);
            }
            if (this.usersWithoutEmail && this.usersWithoutEmail.length)
              resolve(this.usersWithoutEmail);
            resolve(false);
          }
        },
        (e: any) => {
          reject();
        }
      );
    });
  };

  public notifyUsersExport = () => {
    return this.webApi.makeRequest(
      Constants.Services.Users + "/export",
      null,
      httpMethod.Get
    );
  };

  public getCurrentUserTeamsId = () => {
    let teams = [];
    for (let i = 0; i < this.currentUser.teams.length; i++) {
      teams.push(this.currentUser.teams[i]._id);
    }
    return teams;
  };
}
