import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GkUser } from '../../../contracts/contracts';

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {

  @Input() url: string;
  // Data for image details
  @Input() entityImage: GkUser;
  @Input() created: Date;
  @Input() entityName: string;
  @Input() type: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<VideoModalComponent>) {
    if (this.data.type) this.type = this.data.type;
  }

  ngOnInit() {
  }

  public getDefaultImage = () => {
    if (this.type === 'team') {
      return 'assets/img/avatar_group.png';
    } else {
      return 'assets/img/avatar.png';
    }
  }

  public close = () => {
    this.dialogRef.close();
  }

}
