import { Observable } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import 'rxjs/add/operator/map';
import { Config } from 'app/shared/config';
import { GkIncident, httpMethod, GkFilter } from 'app/contracts/contracts';
import { WebApiService } from 'app/services/web-api.service';
import { Istore } from 'app/shared/store/store.module';
import { Constants } from 'app/shared/constant';
import { reject } from 'q';
import { PaginationService } from 'app/services/pagination-service';
import { PaingationItems } from 'app/contracts/ui.contracts';

@Injectable()
export class IncidentsService {
  isInit: boolean;
  savedIncident: GkIncident;
  constructor(private webApi: WebApiService, private store: Store<Istore>,
    private paginationService: PaginationService) { }
  public incidents: GkIncident[] = [];

  // INCIDENTS FUNCTIONS

  // page = the page number in the table (Starting with 1)
  // skip = the number of items in each page
  // public GetIncidents = (page: number, skip: number) => {
  //   return this.paginationService.GetItems(PaingationItems.incidents, page, skip);
  // };

  public GetIncidents(page: number, count: number) {
    return this.webApi.makeRequest(Constants.Services.HistoryIncidents, null, httpMethod.Get, undefined, {
      page, count
    });
  }

  public getIncidentsCount() {
    return this.webApi.makeRequest(Constants.Services.IncidentsCount, null, httpMethod.Get);
  }

  public saveClickedIncident = (incident: GkIncident) => {
    this.savedIncident = incident;
  }

  public getClickedIncident = () => {
    return this.savedIncident;
  }

  // Notify to list
  public notifyIncidientsExport = () => {
    return this.webApi.makeRequest(Constants.Services.Incidents + '/export', null, httpMethod.Get);
  };

  // Notify to activities and locatrion
  public notifyIncidientsActivitiesExport = () => {
    return this.webApi.makeRequest(Constants.Services.HistoryActivities + '/export', null, httpMethod.Get);
  };
}
