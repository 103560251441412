<div class="security-container">
  <div class="page-title">
    {{ 'securityManagement' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>

  <div class="section-title">
    {{'general' | translate}}
  </div>
  <hr class="divider">

  <div class="first-row" fxLayout="row" fxLayoutAlign="start start">
    <div class="security-toggles">
      <div class="toggles-container" fxLayout="column" fxLayoutAlign="space-between center">
        <div class="toggle-container" *ngFor="let toggle of organizationFeatures">
          <div class="slide-container" fxLayout="row" fxLayoutAlign="space-around start">
            <span class="input-label">{{toggle.translationKey | translate}}</span>
            <div class="form-field" matTooltip="{{ toggle.translationDescKey | translate }}" matTooltipPosition="right">
              <mat-slide-toggle [checked]="isSecurityToggleChecked(toggle.value)"
                (change)="securityFeatureChanged($event, toggle)"></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="qr-container" *ngIf="!IS_CLOUD" fxLayout="row" fxLayoutAlign="center center">
      <div class="qr-image-container" (click)="openQRDialog()">
        <qr-code [value]="organization?.qr" [size]="qrImageSize" *ngIf="organization?.qr"></qr-code>
      </div>
      <div class="buttons-container" fxLayout="column" fxLayoutAlign="end center">
        <button mat-raised-button class="download-button" (click)="downloadQR()">
          <svg-icon src="assets/img/svg/ic_download@3x.svg" class="svg-icon"></svg-icon>
          {{'downloadQRCode' | translate}}
        </button>
        <button mat-raised-button (click)="openPinDialog()">
          <svg-icon src="assets/img/svg/revert.svg" class="revert-icon"></svg-icon>
          {{'generateNewCode' | translate}}
        </button>
      </div>
    </div>
  </div>

  <!-- Session Management -->
  <div class="session-management">
    <div class="section-title">
      {{'sessions' | translate}}
    </div>
    <hr class="divider">
    <div class="table-container">
      <gk-table [options]="tableOptions" *ngIf="tableOptions"></gk-table>
      <button mat-raised-button class="delete-all-button" [disabled]="!(sessions?.length > 1)"
        (click)="deleteAllSessions()">
        <svg-icon src="assets/img/svg/ic_delete@3x.svg" class="delete-icon"></svg-icon>
        {{'deleteAll' | translate}}
      </button>
    </div>
  </div>


  <!-- Trusted Devices -->
  <div class="untrusted-devices" *ngIf="untrustedDevices?.length > 0">
    <div class="section-title">
      {{'untrustedDevices' | translate}}
    </div>
    <hr class="divider">
    <div class="table-container">
      <gk-table [options]="trustedTableOptions" *ngIf="trustedTableOptions"></gk-table>
    </div>
  </div>

</div>
