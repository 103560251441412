import { GkIntegration } from './../../../contracts/contracts';

export interface IIntegrationState {
  integrations: GkIntegration[];
  loading: false;
  error: null;
}

export const INTEGRATION_STATE: IIntegrationState = {
  integrations: [],
  loading: false,
  error: null
};

export interface CustomAction {
  type: string;
  payload?: any;
}

export function integrationsReducer(
  state: IIntegrationState = INTEGRATION_STATE,
  action: CustomAction
): IIntegrationState {
  switch (action.type) {
    case 'GET_INTEGRATIONS':
      return state;
    case 'SET_INTEGRATIONS':
      return { integrations: action.payload, loading: false, error: null };
    case 'DELETE_INTEGRATION':
      return action.payload;
    case 'UPDATE_INTEGRATION':
      return action.payload;
    default:
      return state;
  }
}
