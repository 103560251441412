import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from '../../shared/constant';
import { GkSnackbar } from '../../services/gkSnackbar/gk-snackbar.service';
import { TranslationService } from '../../services/barrel-services';

@Component({
  selector: 'gk-image-uploader',
  templateUrl: './gk-image-uploader.component.html',
  styleUrls: ['./gk-image-uploader.component.scss']
})
export class GkImageUploaderComponent {
  @Input() image: any;
  @Input() isDashboard: any;
  @Output() onImageSelected = new EventEmitter<any>();
  @Output() onImageDeleted = new EventEmitter<any>();

  public base64textString: string;
  // CONST
  public DEFAULT_IMAGE: string = 'assets/img/desert@2x.png';

  constructor(private snackbar: GkSnackbar, private translationService: TranslationService) {
}

  // Handle image upload
  public handleFileInput = (event: any) => {
    let files = event.target.files || event.dataTransfer.files;
    let file = files[0];
    if (!file) return;
    // size test
    if (file.size <= Constants.IMAGE_UPLOAD_LIMIT) {
      if (files && file) {
        // base64 reader
        let reader = new FileReader();
        reader.onload = this.handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
        // reader for display
        let imageReader = new FileReader();
        imageReader.onload = (e: any) => {
          this.image = e.target.result;
        }
        imageReader.readAsDataURL(file);
      }
    } else {
      this.snackbar.open(this.translationService.getTranslation('imageUploadSizeError'));
    }
  }

  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.handleFileInput(event);
  }

  private handleReaderLoaded = (readerEvent: any) => {
    this.base64textString = btoa(readerEvent.target.result);
    let imagePrefix = "data:image/png;base64,";
    this.onImageSelected.emit(imagePrefix + this.base64textString);
  }

  // utils
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById('image-uploader').classList.add('is-dragover');
  }

  public onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    document.getElementById('image-uploader').classList.remove('is-dragover');
  }

  public deleteImage = (event: any) => {
    event.preventDefault();
    this.image = null;
    this.onImageDeleted.emit('image deleted');
  }

  public getImageHeight = () => {
    return document.getElementById('image-uploader').clientHeight;
  }
}
