<div class="confirmation-modal-container" fxLayout="column" fxLayoutAlign="space-around start">
  <h1 mat-dialog-title>{{ (data.untrust ? 'untrust' : 'trust') | translate }} {{ 'device' | translate}}</h1>
  <mat-dialog-content>
    {{ (data.untrust ? 'areYouSureYouWantToUntrust' : 'areYouSureYouWantToTrust')  | translate}} {{data.name}} ?
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between start">
    <button mat-raised-button type="button" class="confirm-button" mat-dialog-close>{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit" class="confirm-button trust-button" [ngStyle]="{'color': data.untrust ? 'red' : 'green'}" mat-dialog-close="true">{{ (data.untrust ?'untrust' : 'trust') | translate}} {{'device' | translate }}</button>
  </mat-dialog-actions>
</div>
