<div class="breadCrumb-container">
  <div class="page-bar">
    <ul class="page-breadcrumb">
      <li>
        <a routerLink="../">{{'home' | translate}}</a>
      </li>
      <li *ngFor="let item of options?.path">
        <mat-icon>{{isRtl ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}</mat-icon>
        <a [routerLink]="item?.url?.toString()">{{item?.titleKey | translate}}</a>
      </li>
      <li *ngIf="options?.itemId">
        <mat-icon>{{isRtl ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}</mat-icon>
        <span>{{options?.itemId}}</span>
      </li>
    </ul>
  </div>
</div>