import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  GkUiState, BreadCrumbOptions, GkRecording, TableOptions,
  ColumnTemplete
} from '../../../../contracts/ui.contracts';
import { GkFilter } from '../../../../contracts/contracts';
import { GkFilterComponent } from '../../../gk-filter/gk-filter.component';
import { HistoryService } from '../history.service';
import { GkSnackbar } from 'app/services/gkSnackbar/gk-snackbar.service';
import { TranslationService } from 'app/services/translation-service.service';
import { FilterPipe } from 'app/shared/pipes/filter.pipe';
import { UiStateService } from 'app/services/ui.state.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  RecordingSessionModalComponent
} from '../../../modals/recording-session-modal/recording-session-modal.component';
import { VideoModalComponent } from '../../../modals/video-modal/video-modal.component';
import { LocalTimeFormatPipe, LocalDateFormatPipe } from '../../../../shared/pipes/localTime.pipe';
import { TeamComponent } from '../../teams/team/team.component';
import { TeamsService } from '../../teams/teams.service';

@Component({
  selector: 'app-recordings',
  templateUrl: './recordings.component.html',
  styleUrls: ['./recordings.component.scss']
})
export class RecordingsComponent implements OnDestroy {
  public uiState: GkUiState;
  public breadcrumbOptions: BreadCrumbOptions;
  // Filter variable that will emitted from filter component
  public filter: GkFilter = new GkFilter();
  public subscriptions: any[] = [];
  // Filter component
  @ViewChild(GkFilterComponent, {static: false}) filterComponent;

  public showAdvanced: boolean;
  public recordings: GkRecording[];
  public filteredRecordings: GkRecording[];
  public tableOptions: TableOptions;
  public searchStr: string;

  constructor(
    private historyService: HistoryService,
    private snackbar: GkSnackbar,
    private translationService: TranslationService,
    private loaderService: Ng4LoadingSpinnerService,
    private uiStateService: UiStateService,
    public dialog: MatDialog,
    private timeFilter: LocalTimeFormatPipe,
    private dateFilter: LocalDateFormatPipe,
    private teamService: TeamsService
  ) {
    this.breadcrumbOptions = {
      path: [{ titleKey: 'recordings', url: '../recordings' }]
    };
    this.teamService.initTeamsService();
    this.showAdvanced = true;
    this.getUiState();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public getUiState = () => {
    this.subscriptions.push(this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
      this.uiState = state;
      this.initTable(this.recordings);
    }));
  }

  public initTable = (dataSource: GkRecording[]) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.id = 'recordingsTable';
    this.tableOptions.pageSize = this.uiState && this.uiState.itemPerPage ?
      this.uiState.itemPerPage.recordingsTable : null;
    this.tableOptions.rowHoverClass = false;
    this.tableOptions.hideSearch = true;
    this.tableOptions.columns = [
      {
        name: 'sessionId',
        displayNameKey: '',
        template: ColumnTemplete.recordingsTooltip,
        width: '10',
        onClick: (e: any, recording: GkRecording) => {
          this.openRecordingModal(recording);
        }
      },
      {
        name: 'date',
        displayNameKey: 'date',
        width: '15'
      },
      {
        name: 'time',
        displayNameKey: 'time',
        width: '15'
      },
      {
        name: 'team.name',
        displayNameKey: 'teamName',
        width: '20'
      },
      {
        name: 'duration',
        displayNameKey: 'length',
        template: ColumnTemplete.Duration,
        width: '15'
      },
      {
        name: 'size',
        displayNameKey: 'fileSize',
        width: '15',
        template: ColumnTemplete.FileSize
      },
      {
        name: 'meta',
        displayNameKey: '',
        width: '10',
        template: ColumnTemplete.recordingsActions,
        onClick: (e: any, recording: GkRecording) => {
          if (e.currentTarget.id.indexOf('download') !== -1) {
            this.downloadRecording(recording);
          } else if (e.currentTarget.id.indexOf('play') !== -1) {
            this.openRecordingModal(recording);
          } else {
            // open-session-button
            this.openSessionModal(recording);
          }
        }
      }];
    if (dataSource) {

      this.tableOptions.dataSource = dataSource;
    }
    this.loaderService.hide();
  };

  public getRecordings = () => {
    this.filter = this.filterComponent.filter;
    this.subscriptions.push(this.historyService.getRecordings(this.filter)
      .subscribe((recordings: GkRecording[]) => {
        // handle null
        this.recordings = this.parseRecordings(recordings);
        this.initTable(this.recordings);
        this.showAdvanced = false;
      }, (error: any) => {
        console.error('Failed to get Recordings');
        this.snackbar.open(this.translationService.getTranslation('getRecordingsError'));
      }));
  }

  public openRecordingModal = (recording: GkRecording) => {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      url: recording.url + '/' + recording.sessionId + '.mkv',
      sessionId: recording.sessionId,
      entityName: recording.team.name,
      created: recording.created,
      type: 'team'
    };
    dialogConfig.panelClass = 'video-modal';
    let confirmDialog = this.dialog.open(VideoModalComponent, dialogConfig);
  };

  public downloadRecording = (recording: GkRecording) => {
    let link = document.createElement('a');
    link.download = recording.team.name + '-' + this.dateFilter.transform(recording.created);
    link.href = recording.url + '/' + recording.sessionId + '.mkv';
    link.click();
  };

  public openSessionModal = (recording: GkRecording) => {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data = { recording, recordingsUrl: recording.url };
    let confirmDialog = this.dialog.open(RecordingSessionModalComponent, dialogConfig);
  };

  public applyFilter(filterValue: string) {
    // filter recordings array by searching team name - can add other rules in filter callback
    // and return results to this.filteredRecordings, when finish, update and table
    this.filteredRecordings = this.recordings.filter((record: GkRecording) => {
      return record.team.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
    });
    // we want filter on locations also? its heavy!
    this.initTable(this.filteredRecordings);
  }

  public parseRecordings = (recordings: GkRecording[]) => {
    for (let i = 0; i < recordings.length; i++) {
      recordings[i].date = this.dateFilter.transform(recordings[i].started);
      recordings[i].time = this.timeFilter.transform(recordings[i].started);
      if (recordings[i].team) {
        recordings[i].team = this.teamService.GetLocalTeam(recordings[i].team);
      }
    }
    return recordings;
  }

}
