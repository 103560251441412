import { GkUser } from '../../../contracts/contracts';

export interface IUsersState {
  users: GkUser[];
  loading: false;
  error: null;
}

export interface CustomAction {
  type: string;
  payload?: any;
  }

export const USER_STATE: IUsersState = {
  users: [],
  loading: false,
  error: null
};

export function usersReducer(state: IUsersState = USER_STATE, action: CustomAction): IUsersState {
  switch (action.type) {
    case 'GET_USERS':
      return state;
    case 'SET_USERS':
      return action.payload;
    case 'ADD_USER':
      return state;
    default:
      return state;
  }
}
