<div class="geo-selector-container">
  <div id='gk-selector-map'></div>
  <div class="map-buttons-container" fxLayout="column">
    <button (click)="startDraw()" [ngClass]="{'active': isDrawing}" matTooltip="{{ 'draw' | translate }}"
      matTooltipPosition="left">
      <svg-icon src="assets/img/svg/map_shape.svg" class="svg-icon"></svg-icon>
    </button>
    <button (click)="delete()" matTooltip="{{ 'delete' | translate }}" matTooltipPosition="left">
      <svg-icon src="assets/img/svg/ic_delete@3x.svg" class="svg-icon-delete"></svg-icon>
    </button>
    <button (click)="mapZoom('in')" matTooltip="{{ 'zoomIn' | translate }}" matTooltipPosition="left" class="zoom-button" id="plus-button">
      <mat-icon>add</mat-icon>
    </button>
    <button (click)="mapZoom('out')" matTooltip="{{ 'zoomOut' | translate }}" matTooltipPosition="left" class="zoom-button">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
</div>
