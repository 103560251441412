<mat-sidenav-container class="sidenav-container" *ngIf="true">
  <!-- SideNav -->
  <mat-sidenav mode="side" opened [ngClass]="{'sidenav-open': isSidenavOpen}" [disableClose]="true">

    <gk-toolbar [isSidenavOpen]="isSidenavOpen"></gk-toolbar>

    <mat-accordion>

      <mat-expansion-panel *ngFor="let tab of tabs" class="sidenav-item" id="{{tab.title}}">
        <mat-expansion-panel-header [ngClass]="{'tab-closed': !isSidenavOpen}" (click)="panelClicked($event, tab)">
          <mat-panel-title>
            <mat-icon mat-list-icon matTooltipPosition="right"
              matTooltip="{{(!isSidenavOpen ? tab.title : '') | translate}}">
              {{tab.icon}}
            </mat-icon>
            <span class="title-text" mat-line>{{tab.title | translate}}</span>
            <span class="arrow-selected"></span>
          </mat-panel-title>
        </mat-expansion-panel-header>

      </mat-expansion-panel>

      <div class="sidebar-lock" (click)="toggleSidenav()" fxLayout="row">
        <div class="version-container" [hidden]="!isSidenavOpen">
          <gk-version></gk-version>
        </div>
        <div class="icon-container" [ngClass]="{'folded': !isSidenavOpen}">
          <mat-icon *ngIf="!isSidenavOpen">{{isRtl ? 'arrow_back' : 'arrow_forward'}}</mat-icon>
          <mat-icon *ngIf="isSidenavOpen">{{isRtl ? 'arrow_forward' : 'arrow_back'}}</mat-icon>
        </div>
      </div>
    </mat-accordion>
  </mat-sidenav>

  <!-- Content -->
  <mat-sidenav-content class="pages-container" [ngClass]="{'pages-container-nav-open': isSidenavOpen}">
    <ng4-loading-spinner></ng4-loading-spinner>
    <ng-progress 
       [color]="'#00fff0'" [thick]="false">
    </ng-progress>

    <router-outlet>
    </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
