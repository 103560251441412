import { GeoJson } from 'app/contracts/maps.contracts';
import { IIntegrationState } from './../shared/store/reducers/integrationsReducer';
import { Injectable } from '@angular/core';
import { IUsersState } from '../shared/store/reducers/usersReducers';
import { ITeamsState } from '../shared/store/reducers/teamsReducer';
import { IEntityState } from '../shared/store/reducers/entitiesReducer';
import { IGeoJson } from './maps.contracts';
import { Geometry } from '@turf/turf';
import { EntityTypes } from './ui.contracts';

// interfaces
export interface AppState {
  users: IUsersState;
  teams: ITeamsState;
  entities: IEntityState;
  integrations: IIntegrationState;
}

export class InvitedUser {
  public email: string = '';
  public name: string = '';
  public monitor: boolean = false;
  public teams: string[] = [''];
  public expiration?: number;
  public retryCount?: number;
  public verification?: boolean = false;
}

export class GkUser {
  public _id: string;
  public name: string;
  public email: string;
  public entityid: string;
  public type: EntityTypes;
  public admin: boolean;
  public monitor: boolean;
  public teams: GkTeam[];
  public extras: GkUserExtras;
  public status: Statuses;
  public location: LatLon;
  public lastSeen: Date;
  public realTime: boolean;
  public created: Date;
  public team?: GkTeam;
  public selected?: boolean = false;
  public enabled: boolean;
  public devices: GkDevice[];
  public locale?: string;
}

export class GkTask {
  public _id: string;
  public createdBy: string; // entityId
  public title: string;
  public team: string; // teamId
  public created: Date;
  public resolvedBy?: string; // entityId
  public due?: Date;
  public resolvedTime?: Date;
  public startTime?: Date;
  public archivedTime?: Date;
  public archivedBy?: string;
}

export enum GkTaskActivityType {
  Created = 0,
  Resolved = 1,
  Archived = 2,
}

export class GkDevice {
  public _id: string;
  public fingerprint: string;
  public trusted: number;
  public useragent: string;
  public name: string;
  public username: string;
  public ip: string;
  public lastSeen: Date;
}

export class GkTeam {
  public _id: string;
  public name: string;
  public mode: number;
  public description?: string;
  public activities: number;
  public lastActivityTimestamp: Date;
  public updated: Date;
  public created: Date;
  public picture?: string;
  public organization: string;
  public sessionId: string;
  public users: GkUser[];
  public geozone: GeoJson[];
  public selected?: boolean = false;
  public enabled: boolean;
}

export class GkIncident extends GkTeam {
  public place: any;
  responders: string[];
  creator: GkEntity;
  closeTime: Date;
  address: string; // client side property
  location: { latitude: number; longitude: number }; // client side property
}

export class GkIntegration {
  public _id: string;
  public auth: {
    type: AuthType;
    authorized: boolean;
    username?: string;
  };
  public created: Date;
  public image: string;
  public multiply: boolean;
  public name: string;
  public organization: string;
  public type: number;
  public updated: Date;
  public entities?: GkEntity[];
  public description?: string;
}

export class GkOrganization {
  public _id: string;
  public name: string;
  public created: Date | number;
  public security: number;
  public trialOver: boolean;
  public email: string;
  public domain: string;
  public file?: string; // only update
  public logo: string;
  public icon: string;
  public phone: string;
  public qr: string;
  public teamsCount: number;
  public usersCount: number;
  public entitiesCount: number;
  public integrationsCount: number;
  public incidentsCount: number;
  public seats: number; // licenses
  public seatsWarning: number;
  public apiToken?: string;
}

export class OrganizationsFeature {
  public description: string;
  public readable: string;
  public value: number;
  public translationKey?: any;
  public translationDescKey?: any;
}

export class GkSession {
  public _id: string;
  public entityid: string;
  public ip: string;
  public sessInfo: SessionInfo;
  public lastSeen: number;
  public location: EntityLocation;
}

export class SessionInfo {
  ip: string;
  ua: string;
}
export class GkEntity {
  public created: Date;
  public entityid: string;
  public extras: GkEntityExtras;
  public integration: string;
  public lastSeen: Date;
  public location: EntityLocation;
  public name: string;
  public realTime: boolean;
  public status: number;
  public team: any; // Coming in multiple variation from the server...
  public teams: any[];
  public type: number;
  public _id: string;
  public selected?: boolean;
  public enabled: boolean;
}

export class EmailInvitation {
  public email: string;
  public name: string;
  public sent: number;
  public created: Date;
  public expiration: Date;
  public monitor: boolean;
  public isExpired: boolean;
  public actions?: boolean; // Fake property for table column
}

export class MagicInvitation {
  public _id: string;
  public user: { name: string; _id: string; location: { latitude: string; longitude: string } };
  public created: number;
  public isExpired: boolean;
  public expiration: Date;
  public hits: number;
  public maxHits: number;
  public isMaxedOut: boolean;
  public url: string;
  public team: string;
  public type: EntityTypes;
  public userName?: string; // Fake property for table column
}

export class GkLocation {
  public created: number;
  public entity: GkEntity;
  public loc: LocationGeo;
  public accuracy?: number;
}

export class LocationFeature {
  public type: string = 'Feature';
  properties?: any;
  geometry: LocationGeo;
}
export class LocationGeo {
  public coordinates: any;
  public type: string;
}
export class EntityLocation {
  public longitude: number;
  public latitude: number;
  public address?: string;
  public route?: any[];
}
export class GkEntityExtras {
  public battery?: GkBattery;
  public device?: string;
  public osVersion?: string;
  public speed?: number;
  public description?: string;
  public picture?: string;
}

export enum AuthType {
  NONE = 0,
  USERNAME = 1,
  OAUTH = 2,
  APIKEY = 3,
}

export enum AuthStep {
  AUTH = 0,
  TWO_SV = 1,
}

export enum IntegrationType {
  INTERNAL = 0,
  INCOMING = 1,
  OUTGOING = 2,
  POLLING = 3,
}

export class IntegrationTemplate {
  public _id: string;
  public name: string;
  public activities: number;
  public reportInterval: number;
  public lastActivityTimestamp: Date;
  public updated: Date;
  public created: Date;
  public organization: string;
  public sessionId: string;
  public users: GkUser[];
  public description?: string;
}

export class GkUserExtras {
  public detectedActivity?: number;
  public device?: string;
  public osVersion?: string;
  public speed?: number;
  public battery?: GkBattery;
  public picture?: string;
}

export enum entityTypes {
  entities = 1,
  teams = 2,
}

export class LatLon {
  public longitude: number;
  public latitude: number;
}

export class GkBattery {
  public level: number;
  public charging: boolean;
}

export class GkActivity {
  public name: string;
  public clientCreated: Date;
  public created: Date;
  public data: any;
  public entity: GkEntity;
  public location: EntityLocation;
  public type: string;
  public _id: string;
  public selected?: boolean;
  // Only for showing
  public date: string;
  public time: string;
  public userFullName: string;
  public teamName: string;
  public typeLabel: string;
  public geoLocation: string;
  public history?: any[];
  public entityid?: string;
}

export class GkActivityType {
  public name: string;
  public selected?: boolean;
}
export class GkFilter {
  public teams: GkTeam[] = [];
  public entities: GkEntity[] = [];
  public activities: GkActivity[] = [];
  public minStopTime: number;
  public stopTime: number;
  public endDate: any; // can be moment
  public startDate: any; // can be moment
  public allActivities: boolean;
  public allUsers: boolean;
  public allTeams: boolean;
  public allEntities: boolean;
  public isActivities: boolean;
  public isLocations: boolean;
  public isTasks: boolean;
}

export class GKLicenseActivity {
  public _id: string;
  public created: number;
  public endTime: number;
  public entity: GkEntity;
  public team: GkTeam;
}

export enum GkHistoryType {
  Activities = 0,
  Locations = 1,
  Tasks = 2,
}

export enum gkFilterType {
  Entities = 0,
  Teams = 1,
  Activities = 2,
  Users = 3,
}

export enum Statuses {
  NONE = 0,
  ONLINE = 1 << 1,
  LOGGED_OUT = 1 << 2,
  LOST_CONNECTION = 1 << 3,
  STREAMING_AUDIO = 1 << 4,
  STREAMING_VIDEO = 1 << 5,
  NO_LOCATION = 1 << 6,
  COMMANDER = 1 << 7,
  PANIC = 1 << 8,
  SLEEPING = 1 << 9,
}

export enum StreamingStatuses {
  STREAMING_AUDIO = 1 << 4,
  STREAMING_VIDEO = 1 << 5,
}

export enum Types {
  USER = 0,
  TRACKER = 1,
  RADIO = 2,
}

export enum httpMethod {
  Get = 1,
  Post = 2,
  Delete = 3,
  Put = 4,
  Patch = 5,
}

export enum SecurityTypes {
  HIDE_NOTIFICATIONS_CONTENT = 'HIDE_NOTIFICATIONS_CONTENT',
  DEVICE_TRUST_USER = 'DEVICE_TRUST_USER',
  DEVICE_TRUST_ADMIN = 'DEVICE_TRUST_ADMIN',
}

export const TeamModes = Object.freeze({
  NONE: 0,
  ROUTINE: 1 << 1,
  REAL_TIME: 1 << 2,
  DISABLED: 1 << 3,
});
