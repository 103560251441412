<!-- !Installed -->
<mat-card class="integration-card" fxLayout="column" fxLayoutAlign="start center" *ngIf="isIntalled" [routerLink]="integration?.auth?.type === AuthType.USERNAME &&
 !integration?.auth.authorized ? null : integration._id">
  <div class="image-container" fxLayout="col" fxLayoutAlign="center center">
    <img [src]="'assets/' + integration?.image" class="card-image">
  </div>
  <mat-card-title class="title">{{integration?.name}}</mat-card-title>
  <!-- user name -->
  <div class="integration-type" fxLayout="row" *ngIf="integration?.auth.username">
    <svg-icon src="assets/img/svg/ic_user@3x.svg" class="svg-icon"></svg-icon>
    <span class="type ellipsis">{{'userid' | translate}}: {{integration?.auth?.username}}</span>
  </div>
  <!-- auth type -->
  <div class="integration-type full-width" fxLayout="row">
    <svg-icon src="assets/img/svg/authentication@3x.svg" class="svg-icon"></svg-icon>
    <span class="type ellipsis">{{'authentication' | translate}}:
      {{authTypeStrings[integration?.auth?.type] | translate}}</span>
  </div>
  <!-- integration type type -->
  <div class="integration-type" fxLayout="row">
    <svg-icon src="assets/img/svg/integration@3x.svg" class="svg-icon"></svg-icon>
    <span class="type ellipsis">{{'integration' | translate}}:
      {{integrationTypeStrings[integration?.type] | translate}}</span>
  </div>

  <p class="description" *ngIf="integration?.description">{{integration?.description}}</p>
  <p class="description" *ngIf="!integration?.description">{{'noDescription' | translate}}</p>
  <span class="divider"></span>
  <div class="actions-container">
    <span class="text" *ngIf="integration.auth.type !== AuthType.USERNAME || integration.auth.authorized">
      <svg-icon src="assets/img/svg/ic_installed@3x.svg" class="svg-icon installed-icon"></svg-icon>
      {{'active' | translate}}
    </span>
    <button mat-button class="unauthorized"
      *ngIf="integration?.auth.type === AuthType.USERNAME  && !integration?.auth.authorized"
      (click)="authorizeIntegration()">
      <svg-icon src="assets/img/svg/ic_unauthorized@3x.svg" class="svg-icon unauthorized-icon"></svg-icon>
      {{'unauthorized' | translate}}
    </button>
  </div>
</mat-card>

<!-- !isInstalled -->
<mat-card class="integration-card" fxLayout="column" fxLayoutAlign="start center" *ngIf="!isIntalled">
  <div class="image-container" fxLayout="col" fxLayoutAlign="center center">
    <img [src]="'assets/' + integration?.image" class="card-image">
  </div>
  <mat-card-title class="title">{{integration?.name}}</mat-card-title>
  <div class="integration-type full-width" fxLayout="row">
    <svg-icon src="assets/img/svg/authentication@3x.svg" class="svg-icon"></svg-icon>
    <span class="type">{{'authentication' | translate}}: {{authTypeStrings[integration?.auth?.type] | translate}}</span>
  </div>
  <div class="integration-type" fxLayout="row">
    <svg-icon src="assets/img/svg/integration@3x.svg" class="svg-icon"></svg-icon>
    <span class="type">{{'integration' | translate}}: {{integrationTypeStrings[integration?.type] | translate}}</span>
  </div>
  <p class="description" *ngIf="integration?.description">{{integration.description}}</p>
  <p class="description" *ngIf="!integration?.description">{{'noDescription' | translate}}</p>
  <span class="divider"></span>
  <div class="actions-container">
    <button mat-button class="text-add" *ngIf="!isIntalled" (click)="addIntegration()">
      <img src="assets/img/ic_add_installed@2x.png" alt=""> {{'add' | translate}}
    </button>
  </div>
</mat-card>