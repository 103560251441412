import { UsersService } from '../../pages/users/users.service';
import { TeamsService } from '../../pages/teams/teams.service';
import { AppState, GkTeam, InvitedUser } from '../../../contracts/contracts';
import { GkOrganization } from 'app/contracts/contracts';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import {
  AutocompleteOptions,
  EntityTypes,
  GkAlertType,
  GkUiState,
  StateProperties
} from '../../../contracts/ui.contracts';
import { HttpErrorResponse } from '@angular/common/http';
import { GkSnackbar } from '../../../services/gkSnackbar/gk-snackbar.service';
import { TranslationService } from '../../../services/barrel-services';
import { Constants } from '../../../shared/constant';
import { UiStateService } from '../../../services/ui.state.service';
import { Config } from '../../../shared/config';

const INVITE_NAME_ERROR = 'inviteNameError';
const INVITE_EMAIL_ERROR = 'inviteEmailError';
const INVITE_EXIST_EMAIL_ERROR = 'inviteExistEmailError';
const INVITE_TEAMS_ERROR = 'inviteTeamsError';
const DOUBLE_INVITE_EMAIL_ERROR = 'doubleInviteTeamsError';

@Component({
  selector: 'invitation',
  templateUrl: './invitation.modal.component.html',
  styleUrls: ['./invitation.modal.component.scss']
})
export class InvitationModalComponent implements OnInit {
  public organization: GkOrganization;
  public invitations: { email?: string; fullName?: string; monitor?: boolean; errors?: any }[] = [{}];
  public autocompleteOptions: AutocompleteOptions;
  public selectedTeams: string[];
  public isAutocompleteOpened: boolean = false;
  public invitationsError: string;
  public teams: GkTeam[];
  public organizationName: string;
  public loader: boolean;
  public includePin: boolean;
  public IS_CLOUD: boolean = Config.IS_CLOUD;
  public subscriptions: any[] = [];

  public EntityTypes = EntityTypes;
  public selectedEntityType: EntityTypes = EntityTypes.USER;

  availability = false;
  availabilityTimePeriodUnits = ['Seconds', 'Minutes', 'Hours', 'Days', 'Weeks'];
  selectedTimePeriodValue = 12;
  selectedTimePeriodUnit: 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks' = 'days';
  selectedRetryCount = 12;

  constructor(
    private teamsService: TeamsService,
    public dialogRef: MatDialogRef<InvitationModalComponent>,
    private store: Store<AppState>,
    private usersService: UsersService,
    private snackbar: GkSnackbar,
    private translationService: TranslationService,
    public uiStateService: UiStateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.getUiState();
    this.initModal();
  }

  public initModal() {
    if (this.data && this.data.organizationName) this.organizationName = this.data.organizationName;
    this.subscriptions.push(
      this.store.select('teams').subscribe((teams: any) => {
        if (teams && teams.length) {
          this.teams = teams;
          this.prepareAutocompleteOptions();
        } else {
          this.teamsService.getTeams();
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public prepareAutocompleteOptions = () => {
    this.autocompleteOptions = {} as AutocompleteOptions;
    this.autocompleteOptions.addOnBlur = true;
    this.autocompleteOptions.removable = true;
    this.autocompleteOptions.selectable = true;
    this.autocompleteOptions.allItems = this.teams.slice();
    this.autocompleteOptions.placeholder = 'searchAndSelect';
  };

  public addInvitation = () => {
    let invite = new InvitedUser();
    // General team as default team
    invite.teams = this.selectedTeams;
    this.invitations.push(invite);
  };

  public deleteInput = (inputIndex: number) => {
    this.invitations.splice(inputIndex, 1);
    this.checkForInputsErrors();
  };

  public trackByIndex = (index: number) => {
    return index;
  };

  public onInputTyped = (event: Event, InviteIndex: any) => {
    let element = event.target as HTMLInputElement;
    let value = element.value;
    if (!(value && value.length)) {
      // ignore null value
      if (this.invitations[InviteIndex].errors) this.invitations[InviteIndex].errors[element.type] = {};
      this.checkForInputsErrors();
      return;
    }
    let isEmail = element.type === 'email';
    if (!this.invitations[InviteIndex].errors) this.invitations[InviteIndex].errors = {};
    if (!value && isEmail) {
      this.checkForInputsErrors();
      this.invitations[InviteIndex] = {};
      return;
    }
    if (isEmail) {
      // Email validation
      if (Constants.EMAIL_REGEX.test(value)) {
        this.invitations[InviteIndex].errors.email = false;
        // Check if id is available
        if (!this.usersService.checkEntityidAvailable(value)) {
          this.invitations[InviteIndex].errors.email = true;
          this.invitationsError = INVITE_EXIST_EMAIL_ERROR;
          return;
        }
        if (!this.checkForDoubleEmails(value, InviteIndex)) {
          this.invitations[InviteIndex].errors.email = true;
          this.invitationsError = DOUBLE_INVITE_EMAIL_ERROR;
          return;
        }
        this.checkForInputsErrors();
      } else {
        this.invitations[InviteIndex].errors.email = true;
        this.invitationsError = INVITE_EMAIL_ERROR;
      }
    } else {
      // Full Name validation
      if (Constants.BAD_NAME_REGEX.test(value)) {
        this.invitations[InviteIndex].errors.name = false;
        this.checkForInputsErrors();
      } else {
        this.invitations[InviteIndex].errors.name = true;
        this.invitationsError = INVITE_NAME_ERROR;
      }
    }
  };

  public teamsSelected = (selectedTeams: string[]) => {
    this.selectedTeams = selectedTeams;
    if (this.selectedTeams.length === 0) this.invitationsError = INVITE_TEAMS_ERROR;
    // tslint:disable-next-line:no-unused-expression
    else this.invitationsError === INVITE_TEAMS_ERROR ? (this.invitationsError = null) : null;
  };

  public sendInvitations = () => {
    this.checkForInputsErrors(true);
    if (this.invitationsError || !this.invitations[0].email) return;
    this.loader = true;
    let invitationRequest: InvitedUser[] = this.prepareInvitationsObj();
    this.usersService.InviteUsers(invitationRequest, this.includePin, this.selectedEntityType).subscribe(
      () => {
        this.snackbar.openSweetAlert(
          GkAlertType.Success,
          this.translationService.getTranslation('invitationHasSentSucceffully')
        );
        this.dialogRef.close();
      },
      (res: HttpErrorResponse) => {
        this.loader = false;
        if (res.error && res.error.message) {
          return this.snackbar.open(res.error.message);
        }
        this.snackbar.open(this.translationService.getTranslation('failedToSendInvatations'));
      }
    );
  };

  public prepareInvitationsObj = (): InvitedUser[] => {
    let obj: InvitedUser[] = [];
    for (let i = 0; i < this.invitations.length; i++) {
      const invitation = this.invitations[i];
      if (invitation.email && invitation.email.length) {
        // make a valid InvitedUser obj
        let newInvitation: InvitedUser = {
          name: invitation.fullName,
          email: invitation.email,
          monitor: invitation.monitor,
          teams: this.selectedTeams && this.selectedTeams.length ? this.selectedTeams : [],
          retryCount: this.selectedRetryCount
        };
        obj.push(newInvitation);
      }
    }
    return obj;
  };

  toggleLinkAvailabilityOptions = () => (this.availability = !this.availability);

  public checkForInputsErrors = (fromSubmit?: boolean) => {
    for (let i = 0; i < this.invitations.length; i++) {
      if (this.invitations[i].errors && this.invitations[i].errors.name) {
        this.invitationsError = INVITE_NAME_ERROR;
        return;
      } else if (this.invitations[i].errors && this.invitations[i].errors.email) {
        this.invitationsError = INVITE_EMAIL_ERROR;
        return;
      }
    }
    if ((!this.selectedTeams || this.selectedTeams.length === 0) && fromSubmit)
      return (this.invitationsError = INVITE_TEAMS_ERROR);
    this.invitationsError = null;
  };

  public getUiState = () => {
    this.subscriptions.push(
      this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
        if (state.invitationPinCode) this.includePin = state.invitationPinCode;
      })
    );
  };

  public checkForDoubleEmails = (value: string, index: number) => {
    for (let i = 0; i < this.invitations.length; i++) {
      if (index !== i && this.invitations[i].email === value) return false;
    }
    return true;
  };
}
