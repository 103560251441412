<div id="incident-log-scroll-container" class="incident-log-container">
  <div class="page-title">
    {{ 'incidentHistory' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions" *ngIf="breadcrumbOptions"></gk-breadcrumb>

  <!-- Incident - dates -->
  <div class="incident-dates" fxLayout="row" fxLayoutAlign="start start">
    <span class="start-time">{{ incident?.created | localFullDateFormat}}</span>
    <span class="end-time" *ngIf="incident?.closeTime">&nbsp;-  {{ incident?.closeTime | localFullDateFormat}}</span>
  </div>

  <div class="first-row row" *ngIf="!isfirstSearch">
    <span class="counters-container">
      {{ countersText }}
    </span>
    <span class="advanced-search" (click)="showAdvanced = !showAdvanced">
      {{'editSearch' | translate}}
    </span>
  </div>

  <!-- Filters -->
  <div class="filters-card" [hidden]="!showAdvanced">
    <gk-filter [incidentFilter]="true" [incidentSelectedTeam]="incident"></gk-filter>
    <button class="serach-button" mat-raised-button (click)="getHistoryData()">
      <mat-icon>search</mat-icon>
      {{'search' | translate}}
    </button>
  </div>

  <div class="second-row" fxLayout="row" fxLayoutAlign="space-between start" *ngIf="!isfirstSearch">

    <div class="search-container">
      <input type="text" placeholder="{{'search' | translate}}" (keyup)="applyFilter($event.target.value)"
        [(ngModel)]="searchStr">
    </div>

    <div class="unite-button" fxLayout="row" fxLayoutAlign="center center">
      <button mat-raised-button class="skin-button left-button" (click)="skin = HistoryStyle.List"
        [ngClass]="{'active-button': skin === HistoryStyle.List}" fxLayout="row" fxLayoutAlign="center center">
        <svg-icon *ngIf="skin === HistoryStyle.List" src="assets/img/svg/ic_list_active@3x.svg" class="svg-icon">
        </svg-icon>
        <svg-icon *ngIf="skin !== HistoryStyle.List" src="assets/img/svg/ic_list@3x.svg" class="svg-icon"></svg-icon>
        <span class="text">{{'listMode' | translate}}</span>
      </button>

      <button mat-raised-button class="skin-button" (click)="skin = HistoryStyle.Map"
        [ngClass]="{'active-button': skin === HistoryStyle.Map}" fxLayout="row" fxLayoutAlign="center center">
        <svg-icon *ngIf="skin === HistoryStyle.Map" src="assets/img/svg/ic_map_active@3x.svg" class="svg-icon">
        </svg-icon>
        <svg-icon *ngIf="skin !== HistoryStyle.Map" src="assets/img/svg/ic_map@3x.svg" class="svg-icon"></svg-icon>
        <span class="text">{{'mapMode' | translate}}</span>
      </button>
    </div>

    <button mat-raised-button class="export-button" (click)="exportToCsv()">
      <svg-icon src="assets/img/svg/ic_exported@3x.svg" class="svg-icon"></svg-icon>
      {{'export' | translate}}
    </button>
  </div>

  <mat-divider [ngStyle]="{'margin-top' : showAdvanced ? '15px': '0px'}"></mat-divider>

  <div class="skins-container" [ngSwitch]="skin" *ngIf="!isfirstSearch">
    <!-- Activities table -->
    <ng-container *ngSwitchCase="HistoryStyle.List">
      <gk-table [options]="tableOptions" *ngIf="tableOptions && tableOptions.dataSource"
        [hidden]="!filteredActivities || filteredActivities.length === 0"></gk-table>
      <!-- No Data -->
      <div class="no-data-container" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="searchStr && filteredActivities.length === 0">
        <img src="assets/img/desert@2x.png" alt="noItems">
        <div class="text">
          {{'noResultsText' | translate}} "{{ searchStr }}"
          <br> {{'orTry' | translate }}
          <a (click)="searchStr = ''">{{'newSearch' | translate}}</a>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="HistoryStyle.List">
      <div class="no-data-container" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="locations?.length && !(activities?.length)">
        <img src="assets/img/desert@2x.png" alt="noItems">
        <div class="text">
          {{'noActivitiesHaveBeenFound' | translate}}
        </div>
      </div>
    </ng-container>

    <!-- Activities map -->
    <ng-container *ngSwitchCase="HistoryStyle.Map">
      <div class="location-alert" fxLayout="row" *ngIf="locations?.length > MAX_RECOMMENDED_LOCATIONS">
        <img class="idea-icon" src="assets/img/ic_idea.png">
        <div class="text">
          <span>{{'tooMuchLocationsWarningFirstLine' | translate}}</span> <br>
          <span>{{'tooMuchLocationsWarningSecondLine' | translate}}</span>
        </div>
        <mat-icon class="close-button" (click)="closeIdea()">close</mat-icon>
      </div>
      <gk-map style="height: 300px; width: 300px;" [activitiesData]="mapItems" [locationsData]="locations"
        (finishDraw)="finishDraw()"></gk-map>
    </ng-container>
  </div>
</div>
