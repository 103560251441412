<div class="gk-integration-container">
  <div class="page-title">
    <span>
      {{integration?.name}} {{ 'integrationList' | translate }}
    </span>
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions" *ngIf="breadcrumbOptions"></gk-breadcrumb>

  <!-- url copy -->
  <div class="input-group url-copy-input" *ngIf="integration?.type === IntegrationType.INCOMING">
    <input [value]="getIntegrationUrl()" type="text" #integrationUrl>
    <div class="input-group-append">
      <button class="copy-btn" type="button"
        (click)="copyUrl(integrationUrl); tooltip.toggle();">{{'copy' | translate}}</button>
      <span matTooltipPosition="right" matTooltip="{{'urlCopied' | translate}}" #tooltip="matTooltip">
      </span>
    </div>
  </div>

  <!-- Integration Types -->
  <div class="integration-type" fxLayout="row" *ngIf="integration?.auth?.username">
    <svg-icon src="assets/img/svg/ic_user@3x.svg" class="svg-icon"></svg-icon>
    <span class="type">{{'userid' | translate}}: {{integration.auth.username}}</span>
  </div>

  <div class="integration-type" fxLayout="row">
    <svg-icon src="assets/img/svg/authentication@3x.svg" class="svg-icon"></svg-icon>
    <span class="type">{{'authentication' | translate}}: {{authTypeStrings[integration?.auth.type] | translate}}</span>
  </div>

  <div class="integration-type last-type" fxLayout="row">
    <svg-icon src="assets/img/svg/integration@3x.svg" class="svg-icon"></svg-icon>
    <span class="type">{{'integration' | translate}}: {{integrationTypeStrings[integration?.type] | translate}}</span>
  </div>

  <!-- Fetch entities -->
  <button mat-raised-button class="fetch-entities-button" (click)="fetchEntities()"
    *ngIf="integration?.type === IntegrationType.POLLING || integration?.type === IntegrationType.INCOMING"
    [ngStyle]="{'bottom': !integration?.entities?.length ? '30px' : ''}">
    <mat-icon>supervised_user_circle</mat-icon>
    <span class="text">{{'fetchEntities' | translate}}</span>
  </button>

  <div class="disabled-buttons" [hidden]="!integration?.entities?.length">
    <button mat-raised-button class="disabled-button right-button" (click)="handleDisableChange(true)"
      [ngClass]="{'active-button': !disabledFilter}">
      {{ 'entities' | translate}}
      <span class="counter-digits" *ngIf="tabCounter?.enabled > 0">{{'(' + tabCounter?.enabled + ')'}}</span>
    </button>
    <button mat-raised-button class="disabled-button" (click)="handleDisableChange(false)"
      [ngClass]="{'active-button': disabledFilter}">
      {{ 'disabled' | translate}}
      <span class="counter-digits" *ngIf="tabCounter?.disabled > 0">{{'(' + tabCounter?.disabled + ')'}}</span>
    </button>
  </div>

  <mat-divider></mat-divider>

  <gk-table [options]="tableOptions" *ngIf="tableOptions && integration?.entities?.length"></gk-table>

  <!-- No Entities -->
  <div class="no-data-container" fxLayout="column" fxLayoutAlign="center center"
    [hidden]="!tableOptions?.dataSource || disabledFilter || tableOptions.dataSource.length > 0">
    <img src="assets/img/desert@2x.png" alt="noItems">
    <div class="text">
      {{'noEntitiesInIntegration' | translate}}
    </div>
  </div>
</div>