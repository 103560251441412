import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'confirmationModal',
  templateUrl: './confirmation.modal.component.html',
  styleUrls: ['./confirmation.modal.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  public type: string;
  public deepWarning: boolean;

  constructor(private dialogRef: MatDialogRef<ConfirmationComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.deepWarning = this.data.deepWarning;
    this.type = Object.keys(this.data)[0];
  }

  public closeDialog() {
    this.dialogRef.close();
  }
}
