
const ACTIVITIES_COUNT_ID = 'activities-count';
const CLUSTERED_ACTIVITIES_LAYER_ID = 'clustered-activities';
const UNCLUSTERED_ACTIVITIES_ID = 'unclustered-activities';
const ACTIVITIES_ICONS_ID = 'activities-icons';
const LOCATION_ID = 'locations';
const LOCATION_POINTS_ID = 'location-points';
const ENTITIES_CIRCLE_RADIUS_ID = 'circle-radius-layer';
const ENTITIES_CIRCLE_SOURCE_ID = 'circle-radius-source';
const ENTITIES_ACCURACY_CIRCLE_ID = 'entities-accuracy-circle-layer';
const ENTITIES_ACCURACY_CIRCLE_SOURCE_ID = 'entities-accuracy-circle-source';
const ENTITIES_CIRCLE_RADIUS_TEXT_ID = 'radius-text';

const ACTIVITY_LAYERS = [ACTIVITIES_COUNT_ID, CLUSTERED_ACTIVITIES_LAYER_ID,
  UNCLUSTERED_ACTIVITIES_ID, ACTIVITIES_ICONS_ID];
const LOCATION_LAYERS = [LOCATION_ID, LOCATION_POINTS_ID, ENTITIES_CIRCLE_RADIUS_ID, ENTITIES_CIRCLE_RADIUS_TEXT_ID,
  ENTITIES_ACCURACY_CIRCLE_ID];
const ALL_LAYERS = [ACTIVITIES_COUNT_ID, CLUSTERED_ACTIVITIES_LAYER_ID,
  UNCLUSTERED_ACTIVITIES_ID, ACTIVITIES_ICONS_ID, LOCATION_ID, LOCATION_POINTS_ID, ENTITIES_CIRCLE_RADIUS_ID,
  ENTITIES_CIRCLE_RADIUS_TEXT_ID, ENTITIES_ACCURACY_CIRCLE_ID];

const CONSTS = {
  DEFAULT_ZOOM: 16,
  CLUSTER_RADIUS: 45,
  CLUSTER_ZOOM: 19,
  FLY_SPEED: 0.5,
  POPUP_OFFSET: 13,
  STEP_ONE_ZOOM: 8,
  STEP_TWO_ZOOM: 15,
  MAX_DATES_SPACE: 10.25 * 60 * 1000,
  WORLD_BOUNDS: [-180.0000, -90.0000, 180.0000, 90.0000],
  MAP_PADDING: { top: 30, bottom: 30, left: 60, right: 40 },
  START_LON: 0,
  START_LAT: 15,
  START_ZOOM: 1,
  MIN_ZOOM: 1,
  LOCATION_COLOR: '#26a69a',
  RTL_LAB: `https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.1.2/mapbox-gl-rtl-text.js`,
  LAYERS: {
    CLUSTERED_ACTIVITIES: {
      id: CLUSTERED_ACTIVITIES_LAYER_ID,
      type: 'circle',
      source: 'activities',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': '#1b2733',
        'circle-radius': [
          'step', ['get', 'point_count'],
          12,
          4,
          16,
          8,
          20
        ],
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    },
    ACTIVITIES_COUNT: {
      id: ACTIVITIES_COUNT_ID,
      type: 'symbol',
      source: 'activities',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold']
      },
      paint: {
        'text-color': '#ffffff'
      }
    },
    UNCLUSTERED_ACTIVITIES: {
      id: UNCLUSTERED_ACTIVITIES_ID,
      type: 'circle',
      source: 'activities',
      filter: ['!has', 'point_count'],
      paint: {
        'circle-color': {
          property: 'color',
          type: 'identity'
        },
        'circle-radius': 12,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      }
    },
    ACTIVITIES_ICONS: {
      id: ACTIVITIES_ICONS_ID,
      type: 'symbol',
      source: 'activities',
      filter: ['!has', 'point_count'],
      layout: {
        'icon-image': {
          property: 'icon',
          type: 'identity'
        },
        'icon-size': 1.2
      }
    },
    LOCATIONS: {
      id: LOCATION_ID,
      type: 'line',
      source: 'locations',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 6
      }
    },
    LOCATION_POINTS: {
      id: LOCATION_POINTS_ID,
      type: 'circle',
      source: 'locations-points',
      paint: {
        'circle-color': '#26a69a',
        'circle-radius': 5,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#fff'
      },
    },
    ENTITIES_CIRCLE_RADIUS: {
      id: ENTITIES_CIRCLE_RADIUS_ID,
      type: 'line',
      source: ENTITIES_CIRCLE_SOURCE_ID,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': '#000000',
        'line-width': 3,
        'line-opacity': 0.75,
        'line-dasharray': [3, 3]
      }
    },
    ENTITIES_CIRCLE_RADIUS_TEXT: {
      id: ENTITIES_CIRCLE_RADIUS_TEXT_ID,
      type: 'symbol',
      source: ENTITIES_CIRCLE_SOURCE_ID,
      paint: {
        'text-color': '#000000',
      },
      layout: {
        'symbol-placement': 'line',
        'text-anchor': 'center',
        'text-justify': 'center',
        'symbol-spacing': 500000,
        'text-field': '{radius} m',
        'text-size': 18,
        'text-offset': [0, 1]
      }
    },
    ENTITIES_ACCURACY_CIRCLE: {
      id: ENTITIES_ACCURACY_CIRCLE_ID,
      type: 'fill',
      source: ENTITIES_ACCURACY_CIRCLE_SOURCE_ID,
      layout: {},
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.2,
        'fill-outline-color': '#ffffff'
      }
    }
  },
  ACTIVITY_LAYERS,
  LOCATION_LAYERS,
  ALL_LAYERS
};

export default CONSTS;
