import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'integration-card-placeholder',
  templateUrl: './integration-card-placeholder.component.html',
  styleUrls: ['./integration-card-placeholder.component.scss']
})
export class IntegrationCardPlaceholderComponent implements OnInit {

  // tslint:disable-next-line:no-empty
  constructor() { }

  // tslint:disable-next-line:no-empty
  ngOnInit() {
  }

}
