import { MatPaginatorIntl } from '@angular/material/paginator';
import { Constants } from '../shared/constant';
const GK_LANGUAGE_KEY = 'GK_LANGUAGE';

const getRangeLabel = (page: number, pageSize: number, length: number) => {
  let fromTranslation;
  switch (getPrefferedLanguage()) {
    case 'he':
      fromTranslation = 'מתוך';
      break;
    case 'pl':
      fromTranslation = 'z';
      break;
    case 'es':
      fromTranslation = 'de';
      break;
    case 'it':
      fromTranslation = 'da';
      break;
    case 'zh':
      fromTranslation = '从';
      break;
    default:
      fromTranslation = 'from';
      break;
  }
  if (length === 0 || pageSize === 0) {
    return `0 ${fromTranslation} ${length}`;
  }

  length = Math.max(length, 0);
  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} ${fromTranslation} ${length}`;
};

export function getPaginatorIntlByLang() {
  const paginatorIntl = new MatPaginatorIntl();
  switch (getPrefferedLanguage()) {
    case 'he':
      paginatorIntl.itemsPerPageLabel = 'פריטים לעמוד:';
      paginatorIntl.nextPageLabel = 'עמוד הבא';
      paginatorIntl.previousPageLabel = 'עמוד קודם';
      paginatorIntl.lastPageLabel = 'עמוד אחרון';
      paginatorIntl.firstPageLabel = 'עמוד ראשון';
      paginatorIntl.getRangeLabel = getRangeLabel;
      break;
    case 'pl':
      paginatorIntl.itemsPerPageLabel = 'przedmioty na stronę:';
      paginatorIntl.nextPageLabel = 'Następna strona';
      paginatorIntl.previousPageLabel = 'Poprzednia strona';
      paginatorIntl.lastPageLabel = 'Ostatnia strona';
      paginatorIntl.firstPageLabel = 'Pierwsza strona';
      paginatorIntl.getRangeLabel = getRangeLabel;
      break;
    case 'it':
      paginatorIntl.itemsPerPageLabel = 'Articoli in piedi:';
      paginatorIntl.nextPageLabel = 'Pagina successiva';
      paginatorIntl.previousPageLabel = 'Ultima pagina';
      paginatorIntl.lastPageLabel = 'Pagina precedente';
      paginatorIntl.firstPageLabel = 'Prima pagina';
      paginatorIntl.getRangeLabel = getRangeLabel;
      break;
    case 'es':
      paginatorIntl.itemsPerPageLabel = 'Artículos para estar de pie:';
      paginatorIntl.nextPageLabel = 'Página siguiente';
      paginatorIntl.previousPageLabel = 'Página anterior';
      paginatorIntl.lastPageLabel = 'Última página';
      paginatorIntl.firstPageLabel = 'Primera página';
      paginatorIntl.getRangeLabel = getRangeLabel;
      break;
    case 'zh':
      paginatorIntl.itemsPerPageLabel = '站立的物品：:';
      paginatorIntl.nextPageLabel = '下一页';
      paginatorIntl.previousPageLabel = '前一页';
      paginatorIntl.lastPageLabel = '最后一页';
      paginatorIntl.firstPageLabel = '最后一页';
      paginatorIntl.getRangeLabel = getRangeLabel;
      break;
    default:
      paginatorIntl.itemsPerPageLabel = 'Items per page:';
      paginatorIntl.nextPageLabel = 'Next Page';
      paginatorIntl.previousPageLabel = 'Previous Page';
      paginatorIntl.lastPageLabel = 'Last Page';
      paginatorIntl.firstPageLabel = 'First Page';
      paginatorIntl.getRangeLabel = getRangeLabel;
      break;
  }
  console.log(paginatorIntl);
  return paginatorIntl;
}

function getPrefferedLanguage() {
  let language = 'en'; // default language
  let savedLang = localStorage.getItem(GK_LANGUAGE_KEY);
  if (savedLang) {
    language = atob(savedLang);
  } else if (navigator.language) {
    let browserLanguage = navigator.language.split('-')[navigator.language];
    for (let i = 0; i < Constants.AVAILABLE_LANGUAGES.length; i++) {
      if (browserLanguage === Constants.AVAILABLE_LANGUAGES[i]) {
        language = browserLanguage;
      }
    }
  }
  return language;
}
