<div class="team-form-container">
  <form [formGroup]="form">
    <div class="section-title">
      {{'general' | translate}}
    </div>
    <hr class="divider">
    <div class="row general-form-container">
      <div class="left-form-container col-md-5" fxLayout="column" fxLayoutAlign="start start">
        <!-- Image -->
        <div class="form-group form-group__image" fxLayout="row" fxLayoutAlign="start start">
          <span class="input-label col-md-4">{{'picture' | translate}}</span>
          <div class="col-md-7 form-field">
            <div class="image-container" fxLayout="row" fxLayoutAlign="start start">
              <gk-image-uploader [image]="team?.picture" (onImageSelected)="onImageSelected($event)"
                (onImageDeleted)="onImageDeleted()">
              </gk-image-uploader>
            </div>
          </div>
        </div>
        <!-- Team Name -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'name' | translate}}</span>
          <div class="col-md-7 form-field">
            <input class="text-input" formControlName="teamName" type="text" placeholder="{{'teamName' | translate}}"
              (keyup)="onKey($event)">
            <gk-form-validation *ngIf="form.controls['teamName'].errors?.firstCapitalValidator"
              [validationText]="'firstCapitalError'"></gk-form-validation>
            <gk-form-validation *ngIf="form.controls['teamName'].errors?.exist" [validationText]="'idAlreadyExist'">
            </gk-form-validation>
            <gk-form-validation *ngIf="form.controls['teamName'].errors?.minlength "
              [validationText]="'teamMinLengthError'"></gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon"
              [hidden]="form.controls['teamName'].errors">
          </div>
        </div>
        <!-- Description -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start start">
          <span class="input-label col-md-4" id="description-label">{{'description' | translate}}</span>
          <div class="col-md-7 form-field">
            <textarea class="text-area-input" name="description" rows="10" formControlName="description"></textarea>
          </div>
        </div>
      </div>
      <div class="right-form-container col-md-7">
        <div class="items-selector__wrapper">
          <gk-items-selector (onItemsSelected)="onSelectedUsers($event)" [preItems]="team.users" [mode]="1">
          </gk-items-selector>
          <gk-form-validation class="users-validations" *ngIf="!selectedUsers.length" [validationText]="'userRequired'">
          </gk-form-validation>
          <img src="assets/img/ic_installed@2x.png" class="users-validated-icon" [hidden]="!selectedUsers.length">
        </div>
      </div>
    </div>
    <div class="big-control-area-container" *ngIf="IS_CLOUD">
      <div class="section-title">
        {{'controlArea' | translate}}
      </div>
      <hr class="divider">
      <div class="control-area-container">
        <span class="selector-helper-text">{{'teamMarkControlArea' | translate}}</span>
        <br>
        <span class="selector-helper-text">{{'teamMarkControlAreaPartTwo' | translate}}</span>
        <div class="map-container">
          <gk-geo-selector (onAreaSelected)="areaSelected($event)" [areaSelected]="team.geozone"></gk-geo-selector>
        </div>
      </div>
    </div>
  </form>

  <hr class="divider bottom-divider">
  <div class="row" fxLayout="row" fxLayoutAlign="end center">
    <div class="changes-warning" *ngIf="formChanges">
      <mat-icon>warning</mat-icon>
      {{'unsavedChangesWarning' | translate}}
    </div>
    <button mat-raised-button class="form-button" (click)="cancel()">
      <svg-icon src="assets/img/svg/ic_cancel.svg" class="svg-icon"></svg-icon>
      {{'cancel' | translate}}
    </button>
    <button mat-raised-button class="form-button" [disabled]="form.status !== 'VALID'" (click)="onSubmit()">
      <svg-icon src="assets/img/svg/ic_save_blue@3x.svg" class="svg-icon"></svg-icon>
      {{'save' | translate}}
    </button>
  </div>
</div>