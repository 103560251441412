<div class="video-modal-container">

  <div class="close-button-container">
    <mat-icon class="close-button" (click)="close()">close</mat-icon>
  </div>

  <video width="600" height="400" src="{{data?.url}}" controls type="video/webm"></video>

  <div class="data-container" fxLayout="row" fxLayoutAlign="start start">
    <img class="avatar" src="{{data?.entityImage || getDefaultImage()}}" alt="">
    <div class="data" fxLayout="column" fxLayoutAlign="space-around start">
      <span class="name">{{data?.entityName}}</span>
      <span>
        {{data?.created | localFullDateFormat }}
      </span>
    </div>
  </div>

</div>
