import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrganizationService } from 'app/components/pages/dashboard/organization.service';
import { TranslationService } from 'app/services/barrel-services';

@Component({
  selector: 'downloadModalCompon  ent',
  templateUrl: './downloadModalComponent.html',
  styleUrls: ['./downloadModalComponent.scss'],
})
export class DownloadModalComponent implements OnInit {
  public email: string;
  public saveEmail: boolean;
  public isLoading: boolean;
  public finishDownload: boolean;
  errorMessage: any;

  constructor(
    private organiztionService: OrganizationService,
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<DownloadModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.user && this.data.user.email && this.data.user.email.length) {
      this.email = this.data.user.email;
      this.sendDownloadRequest();
    }
  }

  public sendDownloadRequest = () => {
    this.isLoading = true;
    this.organiztionService.ExportAttacments(this.data.files, this.email, this.saveEmail).subscribe(
      (res: any) => {
        this.isLoading = false;
        this.finishDownload = true;
      },
      e => {
        // error handling
        this.isLoading = false;
        this.errorMessage = this.translationService.getTranslation('bulkErrorDownload');
      }
    );
  };
}
