import { TranslationService } from './../../../../services/translation-service.service';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { AppState, GkTeam, GkUser } from './../../../../contracts/contracts';
import { GkMode, GkAlertType } from '../../../../contracts/ui.contracts';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { firstCapitalValidator, moreThanOneValidator } from '../../../../shared/validations';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { TeamsService } from '../../teams/teams.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Config } from '../../../../shared/config';
@Component({
  selector: 'team-form',
  templateUrl: './team-form.component.html',
  styleUrls: ['./team-form.component.scss']
})
export class TeamFormComponent implements OnInit {
  @Input()
  set gkTeam(team: GkTeam) {
    this.mode = GkMode.Add;
    if (team) {
      this.mode = GkMode.Update;
      this.team = team;
      this.form.get('teamName').setValue(team.name);
      this.form.get('description').setValue(team.description);
      this.form.get('selectedUsers').setValue(this.onSelectedUsers(this.team.users));

      this.detectChanges();
    }
  }

  public team: GkTeam = new GkTeam();
  public teams: GkTeam[] = [];
  public form: FormGroup;
  public mode: GkMode;
  public selectedArea: any;
  public selectedUsers: string[] = [];
  public selectedImage: any;
  public description: string;
  public IS_CLOUD: boolean;
  public formChanges: boolean;
  public imageChanged: any;

  constructor(
    private teamsService: TeamsService,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private snackbar: GkSnackbar,
    private translationService: TranslationService,
    private router: Router,
    private loaderService: Ng4LoadingSpinnerService
  ) {
    this.IS_CLOUD = Config.IS_CLOUD;
  }

  ngOnInit() {
    this.initForm();
  }

  public initForm = () => {
    this.form = this.fb.group({
      teamName: ['', Validators.compose([firstCapitalValidator, Validators.minLength(3)])],
      description: [''],
      selectedUsers: [null, moreThanOneValidator]
    });
  };

  public onImageSelected = (image: any) => {
    this.imageChanged = true;
    this.selectedImage = image;
  };

  public onSelectedUsers = (selectedUsers: GkUser[]) => {
    let users: string[] = [];
    for (const user of selectedUsers) {
      users.push(user._id);
    }
    this.selectedUsers = users;
    // Update form value
    this.form.get('selectedUsers').setValue(this.selectedUsers);
    return this.selectedUsers;
  };

  public getStringIntreval = (interval: number) => {
    if (interval === 1) {
      return ' ' + this.translationService.getTranslation('realTime');
    }
    let duration = moment.duration(interval, 'seconds');
    if (duration.minutes() > 0) {
      return duration.minutes() + ' ' + this.translationService.getTranslation('minutes');
    }
    return duration.seconds() + ' ' + this.translationService.getTranslation('seconds');
  };

  public onSubmit = () => {
    this.loaderService.show();
    let reqTeamObj: any = {
      name: this.form.value.teamName,
      description: this.form.value.description,
      users: this.selectedUsers ? this.selectedUsers : this.onSelectedUsers(this.team.users),
    };
    if (this.IS_CLOUD) {
      reqTeamObj.geozone = this.getSelectedArea(this.selectedArea);
    }
    if (this.imageChanged) {
      reqTeamObj.file = this.selectedImage;
    }

    if (this.mode === GkMode.Add) {
      this.teamsService.CreateTeam(reqTeamObj).subscribe(
        (team: GkTeam) => {
          this.loaderService.hide();
          if (team) {
            let teams = this.teams.push(team);
            this.store.dispatch({ type: 'SET_TEAMS', payload: teams });
            this.snackbar.openSweetAlert(
              GkAlertType.Success,
              team.name + ' ' + this.translationService.getTranslation('wasAdded')
            );
            this.router.navigateByUrl('teams-list');
          }
        },
        (err: any) => {
          this.loaderService.hide();
          this.handleErrors(err, reqTeamObj.name);
        }
      );
    } else {
      this.teamsService.UpdateTeam(reqTeamObj, this.team._id).subscribe(
        (team: GkTeam) => {
          this.loaderService.hide();
          if (team) {
            this.snackbar.openSweetAlert(
              GkAlertType.Success,
              team.name + ' ' + this.translationService.getTranslation('wasEdited')
            );
            this.router.navigateByUrl('teams-list');
          }
        },
        (err: any) => {
          this.loaderService.hide();
          this.handleErrors(err, reqTeamObj.name);
        }
      );
    }
  };

  public areaSelected = (area: any) => {
    this.selectedArea = area;
  };

  public cancel = () => {
    this.router.navigateByUrl('teams-list');
  };

  public onImageDeleted = () => {
    this.imageChanged = true;
    this.team.picture = this.selectedImage = null;
  }

  // Handle update/add req with same name err
  public handleErrors = (err: any, name: string) => {
    this.snackbar.openSweetAlert(
      GkAlertType.Error,
      this.translationService.getTranslation('failedToUpdate') + ' ' + name
    );
  };

  public onKey = (event: any) => {
    if (event.target.value.length < 4) return;
    if (this.teamsService.checkTeamNameAvailable(event.target.value)) {
      if (!this.form.controls['teamName'].errors) this.form.controls['teamName'].setErrors(null);
      return;
    }
    this.form.controls['teamName'].setErrors({ exist: true });
  };

  public getSelectedArea = (area: any) => {
    if (!area || !area.coordinates || !area.coordinates.length) {
      return {
        type: 'Point',
        coordinates: [0, 0]
      };
    }
    return area;
  }

  public detectChanges = () => {
    this.form.valueChanges.subscribe((changes: any) => {
      this.formChanges = true;
    });
  }
}
