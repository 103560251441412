<div class="gk-map-container">
  <div class="map-buttons-container">
    <div class="maps-buttons-container">
      <button (click)="mapZoom('in')" matTooltip="{{ 'zoomIn' | translate }}" matTooltipPosition="left"
        class="zoom-button">
        <mat-icon>add</mat-icon>
      </button>
      <button (click)="mapZoom('out')" matTooltip="{{ 'zoomOut' | translate }}" matTooltipPosition="left"
        class="zoom-button" id="minus-button">
        <mat-icon>remove</mat-icon>
      </button>
      <a class="map-button" mat-raised-button (click)="toggleLayer('locations', !layersToggled.locations)" [ngStyle]="{'color': layersToggled.locations ? '#17ffef' : '#1d2834',
       'background-color': layersToggled.locations ? '#1c2733' : '#ebebeb'}"
        matTooltip="{{ 'toggleLocations' | translate }}" matTooltipPosition="left">
        <mat-icon>timeline</mat-icon>
      </a>
      <a class="map-button" mat-raised-button (click)="toggleLayer('activities', !layersToggled.activities)"
        [ngStyle]="{'color': layersToggled.activities ? '#17ffef' : '#1d2834', 'background-color': layersToggled.activities ? '#1c2733' : '#ebebeb'}"
        matTooltip="{{ 'toggleActivities' | translate }}" matTooltipPosition="left">
        <svg-icon
          [src]="layersToggled.activities ? 'assets/img/svg/lighting_active.svg' : 'assets/img/svg/lighting.svg'"
          class="icon"></svg-icon>
      </a>
      <!-- Map Mode -->
      <a id="style-menu-button" class="map-button" mat-raised-button fxLayout="row" [matMenuTriggerFor]="mapTypeMenu"
        #menuTrigger="matMenuTrigger" (mouseenter)="menuTrigger.openMenu()" [hidden]="!mapTypes?.length">
        <svg-icon [src]="'assets/img/svg/globus.svg'" class="icon"></svg-icon>
      </a>

      <mat-menu #mapTypeMenu="matMenu" [overlapTrigger]="false" yPosition="below" panelClass="map-style-menu">
        <span (mouseleave)="menuTrigger.closeMenu()" fxLayout="column">
          <button type="button" id="{{type?.id}}"class="map-style-button list-group-item" *ngFor="let type of mapTypes"
            ngClass="{selected : mapType.id == type.id}" (click)="changeMapStyle(type)">
            <img class="check-map-icon" [src]="'assets/img/svg/check.svg'" *ngIf="mapStyle.id == type.id">
            <div class="text">{{type.name}}</div>
          </button>
        </span>
      </mat-menu>
    </div>

    <div class="map-container" id="gk-map"></div>
  </div>
</div>