export const Constants = {
  Services: {
    Users: '/users',
    Me: '/me',
    Teams: '/teams',
    Entities: '/entities',
    Integrations: '/integrations',
    Organization: '/organization',
    Organizations: '/organizations',
    Sessions: '/sessions',
    Licenses: '/licenses',
    HistoryActivities: '/history/activities',
    HistoryTasks: '/history/tasks',
    Activities: '/activities',
    Locations: '/locations',
    Recordings: '/recordings',
    Invitations: '/invitations',
    MagicInvitation: '/invitations/magic',
    GeoCoding: '/geocode',
    Incidents: '/incidents',
    HistoryIncidents: '/history/incidents',
    IncidentsCount: '/history/inicidents/count'
  },
  ReportIntervals: [10, 20, 30, 60, 120, 180, 300],
  dateRanges: ['today', 'yesterday', 'last7days', 'last30days', 'thisMonth', 'lastMonth', 'customDate'],
  language: 'en',
  AVAILABLE_LANGUAGES: ['en', 'pl', 'he', 'zh', 'es', 'it'],
  map: {
    ZOOM_LEVEL: 4
  },
  // Regex
  BAD_NAME_REGEX: /^([a-z\u0590-\u05fe]+\s[a-z\u0590-\u05fe]+)$/i,
  USER_ID_REGGEX: (/[!#\$%\^\&*\)\(+=-]/),
  // tslint:disable-next-line:max-line-length
  EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  IMAGE_UPLOAD_LIMIT: 2 * 1024 * 1024 // 2MB
};
