import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'qr-modal',
  templateUrl: './qr.modal.component.html',
  styleUrls: ['./qr.modal.component.scss']
})
export class QrModalComponent implements OnInit {
  // Set function for Activities input
  @Input() qrString: string;
  @Input() size: number;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.qrString = this.data.qrString;
    this.size = this.data.size;
  }
}
