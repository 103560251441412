import { TranslationService } from './../../services/translation-service.service';
import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { UiStateService } from '../../services/ui.state.service';
import { LineChartOptions } from '../../contracts/ui.contracts';
import * as d3 from 'd3';
import { LocalDateFormatPipe } from '../../shared/pipes/localTime.pipe';

const GRAPH_MARGIN = { top: 30, right: 70, bottom: 30, left: 40 };

@Component({
  selector: 'gk-line-chart',
  templateUrl: './gk-line-chart.component.html',
  styleUrls: ['./gk-line-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GkLineChartComponent implements OnInit {
  dots: any;
  y: d3.ScaleLinear<number, number>;
  x: d3.ScaleTime<number, number>;
  resultDiv: d3.Selection<HTMLDivElement, {}, HTMLElement, any>;
  limitDiv: d3.Selection<HTMLDivElement, {}, null, undefined>;
  warningDiv: d3.Selection<HTMLDivElement, {}, null, undefined>;
  tooltipDiv: d3.Selection<HTMLDivElement, {}, null, undefined>;
  maxY: number = 0;

  public options: LineChartOptions;
  public maxGraphY: number;
  public licenseWarning: boolean;
  public chartLoaded: boolean;

  @ViewChild('chart', { static: false }) private chartContainer: ElementRef;

  @Input()
  set chartOptions(value: LineChartOptions) {
    if (value) {
      this.options = value;
      this.maxGraphY = this.options.maxGraphY || this.options.limitCount * 1.35;
      // data object as {date: usersCount} pairs
      // Remove all the old charts and childs before start to draw
      d3.selectAll('svg').remove();
      this.createChart();
    }
  }

  @Output() onWarningSelected = new EventEmitter<number>();

  constructor(
    private translationService: TranslationService,
    private localDatePipe: LocalDateFormatPipe
  ) {}

  ngOnInit() {}

  private createChart = (): void => {
    const element = this.chartContainer.nativeElement;
    // parse the date / time
    let parseTime = d3.timeParse('%d-%b-%y');
    // format the data
    let data = this.formatGraphData(parseTime);
    let toolbarHeight = 140;
    let sidenavWidth = window.innerWidth * 0.18; // sidebar width is 15% of vw
    // set the dimensions and margins of the graph
    let width = window.innerWidth - sidenavWidth - GRAPH_MARGIN.left - GRAPH_MARGIN.right;
    let height = window.innerHeight * 0.6 - toolbarHeight - GRAPH_MARGIN.top - GRAPH_MARGIN.bottom;
    // set the ranges
    this.x = d3.scaleTime().range([0, width]);
    this.y = d3.scaleLinear().range([height, 0]);
    // define the Main line
    this.defineTooltips(element);
    // append the svg object to the body of the page, appends a 'group' element to 'svg'
    // moves the 'group' element to the top left margin
    let svg = d3
      .select(element)
      .append('svg')
      .attr('width', width + GRAPH_MARGIN.left + GRAPH_MARGIN.right)
      .attr('height', height + GRAPH_MARGIN.top + GRAPH_MARGIN.bottom)
      .append('g')
      .attr('transform', 'translate(' + GRAPH_MARGIN.left + ',' + GRAPH_MARGIN.top + ')');
    // Scale to the range of the data
    this.makeDomain(this.x, this.y, data);
    this.addYGridAndAxis(svg, this.y, this.x, width, height);
    this.addValueLine(svg, this.x, this.y, data);
    this.addDots(svg, data, this.x, this.y);
    this.defineLimitLine(this.y, svg, width);
    this.defineWarningLine(this.x, this.y, svg, width);
    this.addVerticalLine(svg, height, element, this.x, this.y);

    this.resultDiv = d3
      .select(element)
      .append('div')
      .attr('class', 'just-tooltip')
      .style('display', 'none');

    this.chartLoaded = true;
  };

  public closeWarning = () => {
    this.licenseWarning = false;
  };

  public makeYgridlines(y: any) {
    return d3.axisLeft(y).ticks(this.maxY < 5 ? this.maxY : 5);
  }

  public dragStarted = (event: any, d: any) => {
    d3.select(d)
      .raise()
      .classed('active', true)
      .style('cursor', 'grabbing');
  };

  public dragged = (event: any, d: any) => {
    let newY = this.y(this.y.invert(event.y));
    d3.select(d)
      .attr('y1', newY)
      .attr('y2', newY)
      .style('cursor', 'grabbing');
    if (Math.round(this.y.invert(event.y)) > 0) {
      this.options.warningCount = Math.round(this.y.invert(event.y));
    }
  };

  public dragEnd = (event: any, d: any) => {
    d3.select(d)
      .classed('active', false)
      .style('cursor', 'grab');
    if (Math.round(this.y.invert(event.y)) > 0) {
      this.options.warningCount = Math.round(this.y.invert(event.y));
      this.onWarningSelected.emit(this.options.warningCount);
    }
  };

  public formatGraphData = (parseTime: any) => {
    let data: any[] = [];
    let licenseWarningChanged;
    for (let item in this.options.dataSource) {
      if (item) {
        // remember the biggest value
        if (this.options.dataSource[item] > this.maxY) this.maxY = this.options.dataSource[item];
        data.push({
          date: parseTime(moment(parseInt(item)).format('DD-MMM-YY')),
          count: +this.options.dataSource[item],
        });
        // check for the warning, if relvante
        if (this.options.licenseWarn) {
          if (data[data.length - 1].count >= this.options.warningCount) {
            licenseWarningChanged = true;
            this.licenseWarning = true;
          }
        }
      }
    }
    if (!licenseWarningChanged && this.licenseWarning) this.licenseWarning = false;
    return data;
  };

  public defineTooltips = (element: any) => {
    // Define the div for the tooltip
    // Delete if exists
    if (document.getElementsByClassName('tooltip')[0]) document.getElementsByClassName('tooltip')[0].remove();
    this.tooltipDiv = d3
      .select(element)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0);
    if (document.getElementsByClassName('warning-tooltip')[0]) {
      document.getElementsByClassName('warning-tooltip')[0].remove();
    }
    this.warningDiv = d3
      .select(element)
      .append('div')
      .attr('class', 'warning-tooltip')
      .style('opacity', 0);
    if (document.getElementsByClassName('limit-tooltip')[0])
      document.getElementsByClassName('limit-tooltip')[0].remove();
    this.limitDiv = d3
      .select(element)
      .append('div')
      .attr('class', 'limit-tooltip')
      .style('opacity', 0);
  };

  public defineWarningLine = (x: any, y: any, svg: any, width: any) => {
    if (this.options.licenseWarn) {
      let drag = d3.drag()
        .on('start', this.dragStarted)
        .on('drag', this.dragged)
        .on('end', this.dragEnd);
      let line = svg
        .append('line') // attach a line
        .attr('class', 'warning-limit-line') // colour the line
        .attr('x1', 4) // x position of the first end of the line
        .attr('y1', y(this.options.warningCount)) // y position of the first end of the line
        .attr('x2', width) // x position of the second end of the line
        .attr('y2', y(this.options.warningCount)) // y position of the second end of the line
        .on('mouseover', (event: any, d: any) => {
          let htmlString =
            '<strong>' +
            this.translationService.getTranslation('licesnseWarningLimit') +
            ': </strong>' +
            this.options.warningCount;
          this.warningDiv
            .transition()
            .duration(200)
            .style('opacity', 0.9);
          this.warningDiv
            .html(htmlString) // can format the dates here
            .style('left', event.pageX - 300 + 'px')
            .style('top', event.pageY + 30 + 'px');
          line.style('stroke-width', '4px');
          line.style('cursor', 'grab');
        })
        .on('mouseout', (event: any, d: any) => {
          this.warningDiv
            .transition()
            .duration(500)
            .style('opacity', 0)
            .style('cursor', 'auto');
          line.style('stroke-width', '2px');
        });
      line.call(drag);
    }
  };

  public defineLimitLine = (y: any, svg: any, width: any) => {
    svg
      .append('line') // attach a line
      .attr('class', 'limit-line') // colour the line
      .attr('x1', 4) // x position of the first end of the line
      .attr('y1', y(this.options.limitCount)) // y position of the first end of the line
      .attr('x2', width) // x position of the second end of the line
      .attr('y2', y(this.options.limitCount)) // y position of the second end of the line
      .on('mouseover', (event: any, d: any) => {
        let htmlString =
          '<strong>' +
          this.translationService.getTranslation('licesnsesLimit') +
          ': </strong>' +
          this.options.limitCount;
        this.limitDiv
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        this.limitDiv
          .html(htmlString) // can format the dates here
          .style('left', event.pageX - 300 + 'px')
          .style('top', event.pageY + 30 + 'px');
      })
      .on('mouseout', (event: any, d: any) => {
        this.limitDiv
          .transition()
          .duration(500)
          .style('opacity', 0)
          .style('cursor', 0);
      });
  };

  public makeDomain = (x: any, y: any, data: any[]) => {
    x.domain(
      d3.extent(data, (d: any) => {
        return d.date;
      })
    );
    y.domain([
      0,
      Math.max(
        d3.max(data, (d: any) => {
          return d.count;
        }),
        this.maxGraphY
      ),
    ]);
  };

  public addDots = (svg: any, data: any[], x: any, y: any) => {
    this.dots = svg
      .selectAll('dot')
      .data(data)
      .enter()
      .append('circle')
      .attr('r', 4)
      .attr('cx', (d: any) => {
        return x(d.date);
      })
      .attr('cy', (d: any) => {
        return y(d.count);
      })
      .attr('class', 'dot')
      .on('mouseover', (event: any, d: any) => {
        let htmlString =
          '<strong>' +
          this.localDatePipe.transform(d.date) +
          ':</strong> ' +
          d.count +
          ' ' +
          (d.count > 1
            ? this.translationService.getTranslation('licenses')
            : this.translationService.getTranslation('license'));
        this.tooltipDiv
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        this.tooltipDiv
          .html(htmlString) // can format the dates here
          .style('left', event.pageX - 300 + 'px')
          .style('top', event.pageY - 10 + 'px');
      })
      .on('mouseout', (event: any, d: any) => {
        this.tooltipDiv
          .transition()
          .duration(500)
          .style('opacity', 0);
      });
  };

  public addValueLine = (svg: any, x: any, y: any, data: any[]) => {
    let valueline = d3
      .line()
      .x((d: any) => {
        return x(d.date);
      })
      .y((d: any) => {
        return y(d.count);
      });
    // Add the valueline path.
    svg
      .append('path')
      .data([data])
      .attr('class', 'line')
      .attr('d', valueline);
  };

  public addYGridAndAxis = (svg: any, y: any, x: any, width: any, height: any) => {
    // add the Y gridlines
    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        this.makeYgridlines(y)
          .tickSize(-width)
          .tickFormat(d3.format('d'))
      );
    // Add the X Axis
    svg
      .append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).ticks(7));
  };

  public addVerticalLine = (svg, height, elem, x, y) => {
    // Hover line
    let hoverLineGroup = svg.append('g').attr('class', 'hover-line');
    let hoverLine = hoverLineGroup
      .append('line')
      .attr('x1', 10)
      .attr('x2', 10)
      .attr('y1', 0)
      .attr('y2', height);

    // Add mouseover events
    d3.select(elem)
      .on('mouseover', () => {
        this.resultDiv.style('display', 'inline');
      })
      .on('mousemove', (event: any) => {
        let point = d3.pointer(event, elem);
        if (!point) return;
        let date = moment(this.x.invert(point[0])).startOf('day');
        let count;
        // Find the right count for this point
        let data = this.dots.data();
        for (let i = 0; i < data.length; i++) {
          if (moment(data[i].date).isSame(date)) {
            count = data[i].count;
          }
        }
        if (!count && count !== 0) return;
        // Setting up the tooltip
        let rightPostion = point[0];
        hoverLine
          .attr('x1', rightPostion)
          .attr('x2', rightPostion)
          .style('opacity', 1);
        let htmlString =
          '<strong>' +
          this.localDatePipe.transform(date) +
          ':</strong> ' +
          count +
          ' ' +
          (count > 1
            ? this.translationService.getTranslation('licenses')
            : this.translationService.getTranslation('license'));
        this.resultDiv
          .html(htmlString)
          .style('left', rightPostion + 'px')
          .style('top', event.pageY + 'px');
        document.getElementsByTagName('body')[0].style.cursor = 'none';
      })
      .on('mouseout', () => {
        document.getElementsByTagName('body')[0].style.cursor = '';
        hoverLine.style('opacity', 1e-6);
        this.resultDiv.style('display', 'none');
      });
  };
}
