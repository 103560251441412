import { TranslationService } from 'app/services/translation-service.service';
import { GkAlertType } from './../../../contracts/ui.contracts';
import { GkSnackbar } from './../../../services/gkSnackbar/gk-snackbar.service';
import { GkIntegration, AuthStep } from './../../../contracts/contracts';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntegrationsService } from '../../pages/integrations/integrations.service';

@Component({
  selector: 'userpassword-integration-modal',
  templateUrl: './userpassword-integration.modal.component.html',
  styleUrls: ['./userpassword-integration.modal.component.scss']
})
export class UserPasswordModalComponent {
  public integration: GkIntegration;
  public authError: string;
  public form: any = {
    username: null,
    password: null
  };
  public verifyCode: string;
  public step: AuthStep = AuthStep.AUTH;
  public helpMessage: string;
  public loader: boolean;
  constructor(
    private dialogRef: MatDialogRef<UserPasswordModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private integrationService: IntegrationsService,
    public snackbar: GkSnackbar,
    private translationService: TranslationService
  ) {
    if (this.data && this.data.integration) {
      this.integration = this.data.integration;
    }
    this.helpMessage = 'pleaseEnterUserPassword';
  }

  public authIntegration = (e: KeyboardEvent, fromButton: boolean) => {
    if (!fromButton && e.keyCode !== 13) return;
    else {
      if (this.step === AuthStep.AUTH) {
        this.loader = true;
        this.integrationService.authIntegration(this.integration._id, this.form).subscribe(
          (res: any) => {
            // is authrized
            this.loader = false;
            if (res && res.auth && res.auth.authorized) {
              this.dialogRef.close(res);
              return;
            }
            switch (res) {
              // two-factor authentication is needed
              case null:
                this.step = AuthStep.TWO_SV;
                this.authError = null;
                this.helpMessage = 'pleaseEnterYourPinCode';
                break;
              // authentication passed
              case true:
                this.dialogRef.close(res.data);
                this.snackbar.openSweetAlert(
                  GkAlertType.Success,
                  this.translationService.getTranslation('integrationInstalledSuccessfully')
                );
                break;
              default:
                return;
            }
          },
          // authentication error
          (res: any) => {
            this.loader = false;
            if (res.error.message.indexOf('Duplicate') !== -1) {
              this.authError = 'duplicateUsersError';
              return;
            }
            this.authError = 'userPasswordAuthError';
          }
        );
      } else if (this.step === AuthStep.TWO_SV) {
        this.loader = true;
        this.form.verifyCode = this.verifyCode;
        this.integrationService.authIntegration(this.integration._id, this.form).subscribe(
          (result: any) => {
            // authentication passed
            this.integration = result;
            this.loader = false;
            this.integrationService.fetchIntegrationEntities(this.integration._id).subscribe(
              (res: any) => {
                // entities fetched
                this.loader = false;
                this.integration.entities = res;
                this.dialogRef.close(this.integration);
                this.snackbar.openSweetAlert(
                  GkAlertType.Success,
                  this.translationService.getTranslation('integrationInstalledSuccessfully')
                );
              },
              // entities fetch error
              (e: any) => {
                this.loader = false;
                this.dialogRef.close(this.integration);
                console.log('Failed to fetch integration entities');
              }
            );
          },
          // authentication error
          (res: any) => {
            this.loader = false;
            if (res.error.message.indexOf('Login Error') !== -1) {
              this.authError = 'WrongPinCode';
              return;
            } else {
              this.authError = res.error.message;
            }
          }
        );
      }
    }
  };
}
