import { EntitiesService } from './../entities.service';
import { Component, OnInit, Input } from '@angular/core';
import { GkTeam, GkEntity } from '../../../../contracts/contracts';
import { BreadCrumbOptions } from '../../../../contracts/ui.contracts';
import { ActivatedRoute } from '@angular/router';
import { TeamsService } from '../../teams/teams.service';
import { TranslationService } from '../../../../services/translation-service.service';

@Component({
  selector: 'gk-entity',
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss'],
})
export class EntityComponent implements OnInit {
  public teams: GkTeam[];
  public breadcrumbOptions: BreadCrumbOptions;
  public entity: GkEntity;
  public subscriptions: any[] = [];

  constructor(private route: ActivatedRoute, private entitiestService: EntitiesService) {
    this.init();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  private init = () => {
    this.subscriptions.push(
      this.route.params.subscribe((params: any) => {
        if (params.entityId) {
          let entityId = params.entityId;
          this.entitiestService.GetEntity(entityId).subscribe((entity: GkEntity) => {
            this.entity = entity;
            this.breadcrumbOptions = {
              path: [{ titleKey: 'integrations', url: '../integrations-list' }, { titleKey: 'updateEntity', url: '' }],
              itemId: entity.name,
            };
          });
        }
      })
    );
  };

  ngOnInit() {}
}
