<div class="userpassword-integration-modal-container" fxLayout="column" fxLayoutAlign="space-around start">
  <h1 mat-dialog-title>{{ 'activate' | translate }} {{ integration?.name }}</h1>
  <h4 class="helper-title-text">{{ helpMessage | translate }}</h4>
  <mat-dialog-content>
    <div class="input-container" fxLayout="column" fxLayoutAlign="center center">
      <input type="text" name="name" [(ngModel)]="form.username" placeholder="{{'userName' | translate}}" *ngIf="step === 0">
      <input type="password" name="password" [(ngModel)]="form.password" placeholder="{{'password' | translate}}" *ngIf="step === 0" (keydown)="authIntegration($event, false)">

      <input type="password" class="pin-form" [(ngModel)]="verifyCode" placeholder="Pin" *ngIf="step === 1" (keydown)="authIntegration($event, false)">

      <div class="error-content" *ngIf="authError">
        {{ authError | translate }}
      </div>
    </div>
  </mat-dialog-content>
  <div class="loader-container" *ngIf="loader">
    <div class="loader" alt="loader"></div>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button type="submit" class="activate-button" (click)="authIntegration($event, true)">{{'activateIntegration' | translate}}</button>
  </mat-dialog-actions>
</div>
