import _ from 'lodash';

let windowRef: any = window;
let config = windowRef.config || {};

let AppUrl = config.APP_URL;
let ApiUrl = config.API_URL;
let MAPBOX_ACCESS_TOKEN = config.MAPBOX_ACCESS_TOKEN;
let MAPS_URL = config.MAPS_URL;
let IS_CLOUD = config.IS_CLOUD && config.IS_CLOUD !== 'false';
let mapStyleUrl;

if (MAPS_URL) {
  mapStyleUrl = MAPS_URL + '/styles.json';
}
let SECOND_MS = 1000;
let MINUTE_MS = 60 * 1000;
const DENGEROUS_CHARTS_REGEX = /([@|+=-])+/g;
/**
 * Enum of all entity available statuses
 * @const
 */
const Statuses = Object.freeze({
  NONE: 0,
  ONLINE: 1 << 1,
  LOGGED_OUT: 1 << 2,
  LOST_CONNECTION: 1 << 3,
  STREAMING_AUDIO: 1 << 4,
  STREAMING_VIDEO: 1 << 5,
  NO_LOCATION: 1 << 6,
  COMMANDER: 1 << 7,
  PANIC: 1 << 8,
  SLEEPING: 1 << 9
});

const Priority = Object.freeze({
  OFFLINE: 0,
  ONLINE: 1,
  STREAMING: 2,
  PANIC: 3
});

const EntityTypes = Object.freeze({
  USER: 0,
  TRACKER: 1,
  RADIO: 2,
  CAMERA: 3,
  PHONE: 4,
  COMMUNITY: 5,
  VOLUNTEER: 6,
  SNIPER: 7
});

const HistoryTypes = Object.freeze({
  NONE: 0,
  ACTIVITIES: 1 << 1,
  RECORDINGS: 1 << 2,
  LOCATIONS: 1 << 3,
  STOPS: 1 << 4
});

const SecurityFeatures = Object.freeze({
  HIDE_NOTIFICATIONS_CONTENT: 1 << 1,
  DEVICE_TRUST_USER: 1 << 2,
  DEVICE_TRUST_ADMIN: 1 << 3,
  DISABLE_PANIC: 1 << 4
});

const TeamModes = Object.freeze({
  NONE: 0,
  ROUTINE: 1 << 1,
  REAL_TIME: 1 << 2,
  DISABLED: 1 << 3
});

const PlaceTypes = Object.freeze({
  MEDICAL: 1 << 4, // icons starts here
  CRIME: 1 << 5,
  FIRE: 1 << 6,
  SUSPICIOUS: 1 << 7,
  SNAKE: 1 << 8,
  TRAFFIC: 1 << 9,
  MUNICIPAL: 1 << 10,
  TEST: 1 << 11,
  OTHER: 1 << 12, // end here
  NONE: 0,
  POI: 1 << 1,
  REPORT: 1 << 2,
  SOS: 1 << 3,
});

const KEY_IMAGE = 'image';
const KEY_PANIC = 'panic';
const KEY_MESSAGE = 'message';
// const KEY_STOP = 'stop';
const KEY_PLACE = 'place-created';
const KEY_REAL_TIME = 'real-time';
const KEY_FILE = 'file';
const KEY_TASK = 'tasks';
const KEY_LOCATIONS = 'locations';
const KEY_VIDEO = 'video';

const ActivityTypes = Object.freeze({
  [KEY_IMAGE]: 'Photos,photoUpload',
  [KEY_VIDEO]: 'Videos,videoUpload',
  [KEY_PANIC]: 'Panics,panicPressed',
  [KEY_MESSAGE]: 'Messages,messageSent',
  [KEY_PLACE]: 'Places,createPlace',
  [KEY_REAL_TIME]: 'RealTimeMode ,realTimeTurnOn',
  [KEY_FILE]: 'files ,fileUploaded',
  [KEY_TASK]: 'tasks ,tasks',
  [KEY_LOCATIONS]: 'locations ,locations',
  // [KEY_STOP]: 'Stop Locations,stopped'
});

function hasFlag(status, flag) {
  return (status & flag) !== 0;
}

/**
 * Checking if the status has some of the flags.
 * @param status - The status to check.
 * @param flags - The flags to check.
 * @return - True if the status has one of the flags,
 *           false if it doesn't contain any of them.
 */
function hasFlags(status, flags) {
  for (let flag of flags) {
    if (hasFlag(status, flag)) {
      return true;
    }
  }
  return false;
}

function getStatusColor(status, type, isSvg) {
  if (hasFlag(status, Statuses.PANIC)) {
    return isSvg ? '#F51C1C' : 'red_';
  } else if (hasFlags(status, [Statuses.STREAMING_AUDIO, Statuses.STREAMING_VIDEO])) {
    return isSvg ? '#1783EA' : 'blue_';
  } else if (hasFlag(status, Statuses.ONLINE)) {
    if (type !== EntityTypes.USER) {
      return isSvg ? '#C520DE' : 'purple_';
    }

    return isSvg ? '#26B73E' : 'green_';
  }
  return isSvg ? '#9A9A9A' : 'gray_';
}

function getStatusIcon(status, type) {
  if (hasFlag(status, Statuses.SLEEPING)) {
    return 'sleeping';
  } else if (hasFlag(status, Statuses.NO_LOCATION)) {
    return 'no_location';
  } else if (hasFlag(status, Statuses.LOST_CONNECTION)) {
    return 'lost_connection';
  } else if (hasFlag(status, Statuses.COMMANDER)) {
    return 'commander';
  } else if (hasFlag(status, Statuses.STREAMING_AUDIO)) {
    return 'audio_streaming';
  } else if (hasFlag(status, Statuses.STREAMING_VIDEO)) {
    return 'video_streaming';
  } else {
    return 'pin';
  }
}

function getStatusPriority(status) {
  if (hasFlag(status, Statuses.ONLINE)) {
    if (hasFlag(status, Statuses.PANIC)) {
      return Priority.PANIC;
    } else if (hasFlags(status, [Statuses.STREAMING_AUDIO, Statuses.STREAMING_VIDEO])) {
      return Priority.STREAMING;
    }

    return Priority.ONLINE;
  } else {
    return Priority.OFFLINE;
  }
}

// Common service?
function getDeviceIcon(userAgent) {
  // Default icon
  if (userAgent) {
    userAgent = userAgent.toLowerCase();
    if (userAgent.indexOf('android') !== -1) {
      return 'assets/img/svg/ic_device_android@3x.svg';
    }
    if (userAgent.indexOf('windows') !== -1) {
      return 'assets/img/svg/ic_device_windows@3x.svg';
    }
    if (userAgent.indexOf('apple') !== -1 || userAgent.indexOf('cfnetwork') !== -1) {
      return 'assets/img/svg/ic_device_apple@3x.svg';
    }
    if (userAgent.indexOf('linux') !== -1) {
      return 'assets/img/svg/ic_device_linux@3x.svg';
    }
  }
  return 'assets/img/svg/ic_device_unknown@2x.svg';
}

// escape dengerous charts, use in export of csv
//  symbols (+, -, =, @, |)
function checkChar(array: any[]) {
  let stringArray = JSON.stringify(array);
  return stringArray.replace(DENGEROUS_CHARTS_REGEX, "'$1");
}

export const Config: any = {
  AppUrl,
  ApiUrl,
  MAPBOX_ACCESS_TOKEN,
  IS_CLOUD,
  PANIC_PIN_TIMEOUT: 1 * MINUTE_MS,
  DISCONNECT_PIN_TIMEOUT: 5 * MINUTE_MS,
  MAX_SKIP_WAIT_TIME: 3 * SECOND_MS,
  MIN_SKIP_TIME: 5 * MINUTE_MS,
  MAX_STOP_RADIUS: 15,
  KEY_IMAGE,
  KEY_PANIC,
  KEY_MESSAGE,
  // KEY_STOP,
  Priorities: Priority,
  Statuses,
  EntityTypes,
  HistoryTypes,
  ActivityTypes,
  getDeviceIcon,
  checkChar,
  SecurityFeatures,
  TeamModes,
  PlaceTypes,
  // Maps style
  mapStyleUrl,
  // Smooth move config
  SMOOTH_MOVE_MIN_ZOOM: 10,
  SMOOTH_MOVE_MAX_DISTANCE: 0.75, // KM
  SMOOTH_MOVE_DELAY: 100,
  hasFlag,
  hasFlags,
  statusToColor: getStatusColor,
  statusToDrawable: (status, type, colorOnly) => {
    if (colorOnly) {
      return `./img/svg/${this.getStatusColor(status, type)}pin.svg`;
    }
    return `./img/svg/${this.getStatusColor(status, type)}${getStatusIcon(status, type)}.svg`;
  },
  RTL_LAB: 'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js'
};
