<div class="table-container">
  <div class="buttons-container">
    <ng-container *ngIf="tableOptions?.dataSource && tableOptions.dataSource.length > 0 && !tableOptions.hideSearch">
      <div class="search-container">
        <input type="text" (input)="terms$.next($event.target.value)" placeholder="{{'search' | translate}}">
      </div>
    </ng-container>
  </div>

  <!-- loader -->
  <div class="loader" *ngIf="pageLoader"></div>
  <!-- download button row -->
  <!-- For now only in history -->
  <div class="download-container" fxLayout="row" fxLayoutAlign="end center"
    *ngIf="tableOptions && tableOptions.id === 'historyTable' && !tableOptions?.isLoading">
    <!-- check all  -->
    <mat-checkbox matRippleDisabled [indeterminate]="false" [disableRipple]="true" (change)="checkAllDownloads($event)"
    [(ngModel)]="allDownloadChecked">
    </mat-checkbox>
    <!-- arrow menu for mark by file type -->
    <mat-menu #downloadMenu [overlapTrigger]="false" class="download-menu">
      <button mat-menu-item (click)="checkAllDownloads({checked: true})">
        {{ 'all' | translate}}
      </button>
      <button mat-menu-item (click)="checkAllDownloads({checked: true}, 'image')">
        {{ 'photos' | translate}}
      </button>
      <button mat-menu-item (click)="checkAllDownloads({checked: true}, 'file')">
        {{ 'files' | translate}}
      </button>
    </mat-menu>

    <span class="arrow" [matMenuTriggerFor]="downloadMenu"></span>
    <!-- actuavl download button -->
    <div class="download-button-container">
      <svg-icon src="assets/img/svg/ic_download@3x.svg" class="main-download-button svg-icon"
        *ngIf="downloadsArray?.length > 0" (click)="downloadFiles()"></svg-icon>
    </div>
  </div>

  <ng-container
    *ngIf="tableOptions && tableOptions.dataSource && tableOptions.dataSource.length > 0 && !tableOptions?.isLoading">
    <mat-table #table MatTableDataSource matSort [dataSource]="dataSource">
      <ng-container matColumnDef="{{column.name}}" *ngFor="let column of tableOptions?.columns; trackBy:trackByIndex;">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="{{column.width}}"
          [ngStyle]="{'max-width': column.width + '!important'}">
          {{column.displayNameKey | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [ngSwitch]="column.template" fxFlex="{{column.width}}"
          [ngStyle]="{'max-width': column.width + '!important'}">
          <div *ngSwitchCase="ColumnTemplete.DeleteButton">
            <a (click)="column.onClick($event, element)" class="delete-column">
              <span matTooltip="{{ 'delete' | translate }}">
                <mat-icon class="column-icon">delete</mat-icon>
              </span>
            </a>
          </div>
          <div *ngSwitchCase="ColumnTemplete.UpdateButton">
            <a (click)="column.onClick($event, element)">
              <mat-icon class="column-icon">edit</mat-icon>
            </a>
          </div>
          <div *ngSwitchCase="ColumnTemplete.DeleteUpdateButton">
            <mat-icon class="column-icon" (click)="column.onClick($event, element)">edit</mat-icon>
            <mat-icon class="column-icon" (click)="column.onClick($event, element)">delete</mat-icon>
          </div>
          <div *ngSwitchCase="ColumnTemplete.Custom">
            <!-- column is column option obj and element is the data -->
            <div [innerHTML]="column.templateCode"></div>
          </div>
          <div *ngSwitchCase="ColumnTemplete.Boolean">
            <div *ngIf="element[column.name] === true">{{'yes' | translate}}</div>
            <div *ngIf="element[column.name] === false">{{'no' | translate}}</div>
          </div>
          <div *ngSwitchCase="ColumnTemplete.Interval">
            {{getStringIntreval(element.reportInterval)}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.Date">
            {{element[column.name] | localFullDateFormat}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.DateOnly">
            {{element[column.name] | localDateFormat}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.Time">
            {{element[column.name] | localTimeFormat}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.DownloadButton" class="download-column">
            <mat-checkbox *ngIf="element.type === 'image' || element.type == 'video' || element.type === 'file'"
              [(ngModel)]="element.checked" matRippleDisabled [indeterminate]="false" [disableRipple]="true"
              (change)="downloadCheck($event, element)"></mat-checkbox>
          </div>
          <div *ngSwitchCase="ColumnTemplete.ActivityTooltip">
            <!-- Tooltips content templates -->
            <ng-template #mediaTooltip class="data-tooltip">
              <!-- Image -->
              <div *ngIf="element.type == 'image'" id="{{'image_preview_'+element._id}}"
                style="text-align:center;width:100%" class="data-tooltip">
                <img [src]="element.data.urls.vga" alt="image" class="tooltip-image">
              </div>
              <!-- Video -->
              <div *ngIf="element.type == 'video'" id="{{'image_preview_'+element._id}}"
                style="text-align:center;width:100%" class="data-tooltip">
                <img [src]="element.data.thumb" alt="video" class="tooltip-image">
              </div>
              <!-- Panic -->
              <div *ngIf="element.type == 'panic'" id="panic_tooltip" style="text-align:center;width:100%"
                [innerHTML]="getToolTipImage(element.type, element.location)">
              </div>
              <!-- Message -->
              <div *ngIf="element.type == 'message'" id="message_tooltip" style="text-align:center;width:100%">
                {{element.data.message}}
              </div>
              <!-- File -->
              <div *ngIf="element.type == 'file'" id="file_tooltip" style="text-align:center;width:100%">
                {{element.data.name}}
              </div>
              <!-- Place -->
              <div *ngIf="element.type.indexOf('place') !== -1 || element.type.indexOf('geofence') !== -1"
                id="{{'image_preview_'+element._id}}" style="text-align:center;width:100%"
                [innerHTML]="getToolTipImage(element.type, element.location)">
              </div>
            </ng-template>
            <!-- Tooltips Icons -->
            <span placement="{{getTooltipPlacement()}}" [ngbTooltip]="mediaTooltip" *ngIf="element.type != 'task'">
              <!-- Image -->
              <svg-icon *ngIf="element.type == 'image'" src="assets/img/svg/picture_icon.svg" class="svg-icon">
              </svg-icon>
              <!-- Video - change to video icon -->
              <svg-icon *ngIf="element.type == 'video'" src="assets/img/svg/picture_icon.svg" class="svg-icon">
              </svg-icon>
              <!-- Panic -->
              <svg-icon *ngIf="element.type == 'panic'" src="assets/img/svg/panic_icon.svg" class="svg-icon"></svg-icon>
              <!-- Message -->
              <svg-icon *ngIf="element.type == 'message'" src="assets/img/svg/message_dark_icon.svg" class="svg-icon">
              </svg-icon>
              <!-- File -->
              <svg-icon *ngIf="element.type == 'file'" [src]="getIconByFileType(element.data.type)" class="svg-icon">
              </svg-icon>
              <!-- Place -->
              <svg-icon *ngIf="element.type.indexOf('place') !== -1 || element.type.indexOf('geofence') !== -1"
                src="assets/img/svg/location_icon.svg" class="svg-icon"></svg-icon>
            </span>
            <!-- Tasks -->
            <span>
              <svg-icon *ngIf="element.type == 'task'" src="assets/img/svg/tasks.svg" class="svg-icon task-icon">
              </svg-icon>
            </span>
            <!-- Real Time -->
            <span>
              <svg-icon *ngIf="element.type == 'real-time'" src="assets/img/svg/realtime_dark_icon.svg"
                class="svg-icon real-time-icon"></svg-icon>
            </span>
          </div>
          <div *ngSwitchCase="ColumnTemplete.LocationTooltip">
            <ng-template #locationTooltip class="location-tooltip">
              <!-- Image -->
              <div style="text-align:center;width:100%" class="tooltip-image"
                [innerHTML]="getToolTipImage(element.type, element.location)">
              </div>
            </ng-template>
            <!-- Tooltips Icons -->
            <span placement="left" [ngbTooltip]="locationTooltip">
              {{element[column.name]}}
            </span>
          </div>
          <div *ngSwitchCase="ColumnTemplete.Cellular">
            <svg-icon
              [src]="(element.sessInfo?.ua) ? getDeviceIcon(element.sessInfo?.ua) : getDeviceIcon(element.useragent)"
              style="width: 32px;" matTooltip="{{ element.sessInfo?.ua  || element.useragent }}"
              matTooltipPosition="left"></svg-icon>
          </div>
          <div *ngSwitchCase="ColumnTemplete.Location">
            {{element.location.address ? element.location.address : element.location.latitude + '/' +
          element.location.longitude}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.MenuUpdateButton">
            <mat-menu #appMenu class="menu-update-button-menu" [overlapTrigger]="false" panelClass="edit-menu">
              <button mat-menu-item (click)="column.onClick($event, element)" id="disable-button">
                {{ (element.enabled ? 'disable' : 'enable') | translate}} {{ getElementStringType(element, column.isTeam) | translate}}
              </button>
              <button mat-menu-item (click)="column.onClick($event, element)" id="delete-button">
                {{ 'delete' | translate}} {{ getElementStringType(element, column.isTeam) | translate }}
              </button>
            </mat-menu>

            <mat-icon class="column-icon" (click)="column.onClick($event, element)" id="edit-button">edit</mat-icon>
            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
          <div *ngSwitchCase="ColumnTemplete.trustButton">
            <mat-icon class="column-icon" (click)="column.onClick($event, element)">check</mat-icon>
            <mat-icon class="column-icon delete-trust" (click)="column.onClick($event, element)">close</mat-icon>
          </div>
          <div *ngSwitchCase="ColumnTemplete.recordingsActions">
            <mat-menu #recordingMenu class="recording-button-menu" [overlapTrigger]="false" panelClass="recording-menu">
              <button mat-menu-item (click)="column.onClick($event, element)" id="download-button">
                <svg-icon src="assets/img/svg/ic_download@3x.svg" class="svg-icon" style="height: 32px;"></svg-icon>
                <span class="text">{{ 'download' | translate}}</span>
              </button>
              <button mat-menu-item (click)="column.onClick($event, element)" id="play-recording">
                <svg-icon src="assets/img/svg/ic_show_recording@3x.svg" class="svg-icon" style="height: 32px;">
                </svg-icon>
                <span class="text">{{ 'showRecording' | translate}}</span>
              </button>
              <button mat-menu-item (click)="column.onClick($event, element)" id="open-session-button">
                <svg-icon src="assets/img/svg/ic_show_session@3x.svg" class="svg-icon" style="height: 32px;"></svg-icon>
                <span class="text">{{ 'openSession' | translate}}</span>
              </button>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="recordingMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

          </div>
          <div *ngSwitchCase="ColumnTemplete.recordingsTooltip">
            <svg-icon src="assets/img/svg/ic_recording@3x.svg" (click)="column.onClick($event, element)"
              class="svg-icon recording-modal-button" style="height: 32px;"></svg-icon>
          </div>
          <div *ngSwitchCase="ColumnTemplete.FileSize">
            {{ element.size | fileSize}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.Duration">
            {{ element.duration | duration}}
          </div>
          <div *ngSwitchCase="ColumnTemplete.EmailStatus">
            <div class="status-container" fxLayout="row" fxLayoutAlign="center center">
              <span class="dot"
                [ngStyle]="{'background-color': element.expired || element.isExpired ? 'red' : 'rgb(0, 255, 240)'}"></span>
              <span class="text">{{ (element.expired || element.isExpired ? 'expired' : 'pending') | translate}}</span>
            </div>
          </div>

          <div *ngSwitchCase="ColumnTemplete.EmailActions">
            <mat-menu #EmailActions class="menu-update-button-menu" [overlapTrigger]="false"
              panelClass="menu-update-button-menu">
              <button mat-menu-item (click)="column.onClick($event, element)" id="resend-button"
                *ngIf="element.expired || element.isExpired">
                <span class="text">{{ 'resend' | translate}}</span>
              </button>
              <button mat-menu-item (click)="column.onClick($event, element)" id="delete-invitation-button">
                <span class="text">{{ (element.isExpired ? 'deleteInvitation' : 'revoke') | translate}}</span>
              </button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="EmailActions">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <div *ngSwitchCase="ColumnTemplete.MagicEmailActions">
            <mat-menu #EmailActions class="menu-update-button-menu" [overlapTrigger]="false"
              panelClass="menu-update-button-menu">
              <button mat-menu-item (click)="column.onClick($event, element)" id="edit-button">
                <span class="text">{{ 'editInvitation' | translate}}</span>
              </button>
              <button mat-menu-item (click)="column.onClick($event, element)" id="delete-invitation-button">
                <span
                  class="text">{{ (element.isExpired || element.isMaxedOut ? 'deleteInvitation' : 'revoke') | translate}}</span>
              </button>
              <button mat-menu-item (click)="column.onClick($event, element)" id="copy-invitation-button">
                <span class="text">{{ 'copyLink' | translate}}</span>
              </button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="EmailActions">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>

          <div *ngSwitchCase="ColumnTemplete.ActivityElipsys">
            <span class="ellipsis" matTooltipPosition="right" matTooltip="{{ element.typeLabel }}">
              {{ element.typeLabel }}</span>
          </div>

          <div *ngSwitchCase="ColumnTemplete.Elipsys">
            <span class="ellipsis" matTooltipPosition="right" matTooltip="{{ element[column.name] }}">
              {{ element[column.name] }}
            </span>
          </div>

          <div *ngSwitchCase="ColumnTemplete.IncidentType">
            <img [src]="getIncidentIconByType(element)" alt="image" class="incident-icon">
          </div>

          <div *ngSwitchCase="ColumnTemplete.AddressLocationTooltip">
            <ng-template #addressLocationTooltip class="location-tooltip">
              <!-- Image -->
              <div style="text-align:center;width:100%" class="tooltip-image"
                [innerHTML]="getToolTipImage('place-created', element.location)">
              </div>
            </ng-template>
            <!-- Tooltips Icons -->
            <span placement="left" [ngbTooltip]="addressLocationTooltip" class="ellipsis">
              {{element[column.name]}}
            </span>
          </div>

          <div *ngSwitchCase="ColumnTemplete.MaxHits">
            <span>
              {{ element.hits + ' / ' + element.maxHits }}
            </span>
          </div>

          <div *ngSwitchDefault>
            {{getDefaultColumnHtml(element, column.name)}}
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
        (click)="tableOptions.onRowClicked ? tableOptions.onRowClicked($event, row) : null"
        [ngClass]="{'gktable-row-hover': tableOptions.rowHoverClass, 'expired': tableOptions.rowError && row.isExpired}"></mat-row>
    </mat-table>
  </ng-container>

  <ng-container
    *ngIf="tableOptions?.dataSource && tableOptions.dataSource.length >= tableOptions.pageSizeOptions && tableOptions?.dataSource.length > 0 && dataSource.filteredData.length !== 0">
    <mat-paginator #paginator [pageSize]="tableOptions.pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="pageChange($event)" [showFirstLastButtons]="true">
    </mat-paginator>
  </ng-container>

  <!-- PlaceHolder -->
  <mat-table [dataSource]="[{},{},{}]" *ngIf="!tableOptions?.dataSource || tableOptions?.isLoading"
    class="table-placeholder">
    <ng-container matColumnDef="{{column.name}}" *ngFor="let column of tableOptions?.columns; trackBy:trackByIndex;">
      <mat-header-cell *matHeaderCellDef>
        {{column.displayNameKey | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="loading-row content-placeholder">&nbsp;</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <!-- No Filtered Data -->
  <div class="no-data-container" fxLayout="column" fxLayoutAlign="center center"
    *ngIf="dataSource?.filter && dataSource.filteredData.length === 0">
    <img src="assets/img/desert@2x.png" alt="noItems">
    <div class="text">
      {{'noResultsText' | translate}} "{{dataSource?.filter}}"
      <br> {{'orTry' | translate }}
      <a (click)="applyFilter('', true);">{{'newSearch' | translate}}</a>
    </div>
  </div>

  <!-- No Data -->
  <div class="no-data-container inited-no-data" fxLayout="column" fxLayoutAlign="center center"
    *ngIf="tableOptions?.dataSource &&  dataSource.data?.length === 0">
    <img src="assets/img/desert@2x.png" alt="noItems">
    <div class="text">
      {{'noData' | translate}}
    </div>
  </div>
</div>
