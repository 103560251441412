import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import * as $ from 'jquery';

@Component({
  selector: 'pinModal',
  templateUrl: './pin.modal.component.html',
  styleUrls: ['./pin.modal.component.scss']
})
export class PinModalComponent implements OnInit {
  public pin: string;

  constructor(private dialogRef: MatDialogRef<PinModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}

  public pinEntered = (pin: string[]) => {
    this.pin = pin[0] + pin[1] + pin[2] + pin[3];
    this.closeDialog();
  };

  public closeDialog() {
    this.dialogRef.close(this.pin);
  }
}
