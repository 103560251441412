import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((item) => {
      return searchString(item, searchText);
    });
  }
}

function searchString(obj, str) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'string' || obj[key] instanceof String) {
        if (obj[key].toLowerCase().includes(str)) {
          return true;
        }
      } else if (obj[key] !== null && typeof obj[key] === 'object') {
        searchString(obj[key], str);
      } else if (obj[key] !== null && obj[key] instanceof Array) {
        for (let j = 0; j < obj[key].length; j++) {
          searchString(obj[key][j], str);
        }
      }
    }
  }
  return false;
}
