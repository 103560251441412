<div class="disable-confirmation-modal-container" fxLayout="column" fxLayoutAlign="space-around start">
  <h1 mat-dialog-title>{{ (data.isDisabled ? 'enable' : 'disable') | translate }} {{ data.type | translate }}</h1>
  <mat-dialog-content>
    <div class="email-user-warning" *ngIf="allUsersGotEmail">
      <span class="warning"> {{'twoFactorEmailWarning' | translate}}</span> <br>
      <ul class="users-list">
        <li *ngFor="let user of allUsersGotEmail">
          <span>{{user.name}} - </span>
           <mat-icon class="column-icon" (click)="editUser(user)">edit</mat-icon>
        </li>
        <br>
      </ul>
    </div>

    {{'areYouSureYouWantTo' | translate}} {{ (data.isDisabled ? 'enableVerb' : 'disableVerb') | translate }}
    <br>
    <strong>{{ data.name | translate}} - </strong>
    {{ data.description | translate}}
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between start">
    <button mat-raised-button type="button" class="confirm-button" mat-dialog-close>{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit" class="confirm-button disable-button" mat-dialog-close="true" [ngStyle]="{'color': data.isDisabled && !warningModal ? 'green' : 'red'}">
      {{ (data.isDisabled ? 'enable' : 'disable') | translate}} {{ data.type | translate }}</button>
  </mat-dialog-actions>
</div>
