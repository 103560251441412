import { MapsService } from '../../services/maps-service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import CONSTS from '../gk-map/map.consts';
import * as _ from 'lodash';
import * as mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'gk-location-point-selector',
  templateUrl: './gk-location-point-selector.component.html',
  styleUrls: ['./gk-location-point-selector.component.scss']
})

export class GkLocationPointSelectorComponent implements OnInit {
  public map: any;
  public mapStyle: any;
  public lat = CONSTS.START_LAT;
  public lng = CONSTS.START_LON;
  public location: any;
  // Event emitter thet emit location select to parent component.
  @Output() onLocationSelected: EventEmitter<any> = new EventEmitter();
  public marker: any; // Marker inctance
  public coordinates: any = document.getElementById('coordinates'); // result element
  public address: string; // string address
  constructor(private mapService: MapsService, private localStorage: LocalStorageService) { }

  // Set function for location input
  // Add marker if doesnt added from map OnLoad and got not null point
  @Input()
  set locationData(selectedLocation: any) {
    if (selectedLocation && selectedLocation.latitude > 0) {
      this.location = selectedLocation;
      if (!this.marker && this.map) this.addMarker(this.location);
    }
  }
  // Entry component point
  ngOnInit() {
    this.getMapStyles();
  }
  // Get basic map styles
  private getMapStyles = () => {
    this.mapService.GetStyles().subscribe((styles: any) => {
      if (styles && styles.length) {
        this.mapStyle = styles[0];
      }
      this.initializeMap();
    });
  };

  // then, Get user location and call this.buildMap()
  private initializeMap = () => {
    if (!this.location) {
      // get user location for center the map if doesnt got pre location
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.lat = position.latitude;
        this.lng = position.longitude;
      });
    } else {
      // got point from parent
      this.lat = this.location.latitude;
      this.lng = this.location.longitude;
    }
    this.buildMap();
  };

  // init map obj and call this.load()
  private buildMap = () => {
    let mapOptions: any = {
      container: 'gk-location-selector-map',
      style: this.mapStyle.url,
      minZoom: CONSTS.MIN_ZOOM,
      zoom: CONSTS.START_ZOOM,
      center: [this.lng, this.lat]
    };
    // Init map
    this.map = new mapboxgl.Map(mapOptions);
    this.load();
  };

  // add map onLoad event then
  // fitting view to all features
  public load = () => {
    this.map.on('load', () => {
      // Add marker if doesnt added from set locationData function and got not null point
      if (!this.marker && this.location && this.location.latitude > 0) this.addMarker(this.location);
      this.registerMapListeners();
    });
  };

  public onDragEnd = () => {
    let lngLat = this.marker.getLngLat();
    // Make geo request
    const hash = `g${lngLat.lat},${lngLat.lng}`;
    let geolocation = this.localStorage.retrieve(hash);
    if (geolocation) {
      this.address = '\n' + geolocation;
    } else {
      this.mapService.getGeoLocation(lngLat.lat, lngLat.lng).then((address: string) => {
        this.address = '\n' + address;
        this.localStorage.store(hash, address);
      }, (e) => {
        console.log('Failed to get geocode');
      });
    }
    // Pan to entity
    this.map.panTo(lngLat, { zoom: CONSTS.DEFAULT_ZOOM });
    this.emitData({
      latitude: lngLat.lat,
      longitude: lngLat.lng
    });
  }

  // get features, parse and emit to parent component
  public emitData = (location: any) => {
    // emit data to parent components
    this.onLocationSelected.emit(location);
  };

  // Map Listeners
  // prevent zoom control
  public registerMapListeners = () => {
    this.map.on('dblclick', (e) => {
      e.preventDefault();
    });

    // Add marker on map click, if doesnt have one
    this.map.on('click', (e) => {
      if (!this.marker) {
        this.addMarker(e.lngLat);
      }
    });

    // Show 'crosshair' cursor only if didn't pick a location
    this.map.on('mouseover', (e) => {
      if (!this.location || this.location.latitude === 0) e.target.getCanvas().style.cursor = 'crosshair';
    });

    this.map.on('mouseout', (e) => {
      e.target.getCanvas().style.cursor = '';
    });
  };

  // Add
  public addMarker = (point: any) => {
    // create a HTML element for each feature
    let el = document.createElement('div');
    el.className = 'camera-marker'; // Can change here when want to get types
    // make a marker for each feature and add to the map
    if (this.marker) {
      this.marker.remove();
    }
    this.marker = new mapboxgl.Marker({
      element: el,
      draggable: true
    }).setLngLat([point.lng || point.longitude, point.lat || point.latitude])
      .addTo(this.map)
      .on('dragend', this.onDragEnd);

    // Emit to parent
    this.onDragEnd();
  };

  // Set location to 0, 0
  public deleteLocation = () => {
    this.marker.remove();
    this.location.longitude = this.location.latitude = 0;
    this.emitData(this.location);
    this.coordinates = undefined;
    this.marker = undefined;
  }

  // zoom is available here only from buttons
  public mapZoom = (zoom: string) => {
    if (zoom === 'in') {
      this.map.flyTo({ zoom: this.map.getZoom() + 1 });
    } else {
      this.map.flyTo({ zoom: this.map.getZoom() - 1 });
    }
  }
}
