import { Injectable, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "../shared/constant";
import { UsersService } from "app/components/pages/users/users.service";
import { GkUser } from "app/contracts/contracts";
const GK_LANGUAGE_KEY = "GK_LANGUAGE";

@Injectable()
export class TranslationService implements OnInit {
  public supportedLanguages: any;

  data: any = {};

  // Translation
  public LANGUAGE: string = Constants.language; // Default value - 'en'
  public BROWSER_LANGUAGE = this.translateService.getBrowserLang();

  constructor(
    private translateService: TranslateService,
    private usersService: UsersService
  ) {}

  ngOnInit() {
    this.init();
  }
  public init = (): void => {
    if (!this.data.dictionary) {
      if (this.translateService.currentLang) {
        this.data.dictionary = this.translateService.translations[
          this.translateService.currentLang
        ];
      }
      this.getLangs();
    }
    this.handleLanguageChoose();
  };

  public getTranslation = (key: string): string => {
    // Make sure that the translation service initialized allready
    if (this.data && this.data.dictionary && this.data.dictionary[key]) {
      return this.data.dictionary[key];
    } else {
      this.init();
      // if not, get the translation from the translation store by the support language
      if (this.translateService.store.langs.length < 1) {
        return key;
      }
      return this.translateService.store.translations[
        this.translateService.langs[0]
      ][key];
    }
  };

  public setDefaultLang = (lang: string): void => {
    this.translateService.setDefaultLang(lang);
  };

  public getLangs = () => {
    this.supportedLanguages = this.translateService.getLangs();
    return this.supportedLanguages;
  };

  public changeLanguage = (language: string): void => {
    if (this.translateService.currentLang === language) return console.log('Same language');
    this.data = {};
    this.handlePageDirection(language);
    this.translateService.use(language).subscribe((data: any) => {
      this.data.dictionary = data;
    });
  };

  public handlePageDirection = (language: string) => {
    if (language === "he") {
      document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
      return;
    }
    document.getElementsByTagName("body")[0].setAttribute("dir", "ltr");
  };

  public handleLanguageChoose = () => {
    this.usersService.getCurrentUser().subscribe((currentUser: GkUser) => {
      let savedLang = localStorage.getItem(GK_LANGUAGE_KEY);
      if (currentUser.locale) {
        this.LANGUAGE = currentUser.locale;
        // save in local-storage for paginator
        localStorage.setItem(GK_LANGUAGE_KEY, btoa(this.LANGUAGE));
      } else if (savedLang) {
        this.LANGUAGE = atob(savedLang);
      } else if (navigator.language) {
        let browserLanguage = navigator.language.split("-")[
          this.BROWSER_LANGUAGE
        ];
        for (let i = 0; i < Constants.AVAILABLE_LANGUAGES.length; i++) {
          if (browserLanguage === Constants.AVAILABLE_LANGUAGES[i]) {
            this.LANGUAGE = browserLanguage;
          }
        }
      }
      this.handlePageDirection(this.LANGUAGE);
      this.translateService.use(this.LANGUAGE);
      this.translateService.setDefaultLang(this.LANGUAGE);
    });
  };
}
