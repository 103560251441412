import { Injectable } from "@angular/core";
import { GkUser } from "./contracts/contracts";
import { Config } from "./shared/config";
import { Constants } from "./shared/constant";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class MeProvider {
  private me: any = null;
  constructor(private http: HttpClient) {}
  public getMe(): GkUser {
    return this.me;
  }

  load() {
    return new Promise((resolve, reject) => {
      this.http
        .get(Config.ApiUrl + Constants.Services.Me, {
          withCredentials: true,
          headers: new HttpHeaders().set("Content-Type", "application/json")
        })
        .subscribe(
          (response: any) => {
            this.me = response;
            resolve(this.me);
          },
          (e: any) => {
            // Redirect logic
            if (e.status === 401 || e.status === 403) {
              // redirect to Dispatch login
              let login = window.config.APP_URL || "http://127.0.0.1:5002/#";
              if (!login.endsWith("/#/login")) {
                login = login + "/#/login";
              }
              let url = login + "/?redirect=" + window.location.href;
              return (window.location.href = url);
            }
          }
        );
    });
  }
}
