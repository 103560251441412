<div class="users-container">
  <div class="page-title">
    {{ 'users' | translate }}
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>
  <div class="buttons-container offset-4 col-md-8 top-buttons">
    <div class="table-buttons">
      <button mat-raised-button class="table-button add-user-button" [matMenuTriggerFor]="addUserMenu">
        <mat-icon>person_add</mat-icon>
        {{ 'addUser' | translate }}
      </button>
      <button mat-raised-button class="table-button" (click)="exportToCsv()" [disabled]="!(users && users.length)">
        <mat-icon>insert_drive_file</mat-icon>
        {{ 'exportToCsv' | translate }}
      </button>
    </div>
    <div class="disabled-buttons">
      <button
        mat-raised-button
        class="disabled-button right-button"
        (click)="handleTabChange(true)"
        [ngClass]="{ 'active-button': !disabledFilter }"
      >
        {{ 'users' | translate }} <span *ngIf="tabCounter?.enabled > 0">{{ '(' + tabCounter?.enabled + ')' }}</span>
      </button>
      <button
        mat-raised-button
        class="disabled-button"
        (click)="handleTabChange(false)"
        [ngClass]="{ 'active-button': disabledFilter }"
      >
        {{ 'disabled' | translate }}
        <span *ngIf="tabCounter?.disabled > 0">{{ '(' + tabCounter?.disabled + ')' }}</span>
      </button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <!-- users list -->
  <gk-table [options]="tableOptions"></gk-table>
</div>

<div class="invitations-container" *ngIf="emailInvitations?.length">
  <div class="section-title">
    {{ 'emailInvitations' | translate }}
  </div>
  <hr class="divider" />
  <gk-table [options]="emailInvitationsTableOptions" *ngIf="emailInvitationsTableOptions"></gk-table>
</div>

<div class="invitations-container" *ngIf="magicInvitations?.length">
  <div class="section-title">
    {{ 'linkInvitations' | translate }}
  </div>
  <hr class="divider" />
  <gk-table [options]="magicEmailInvitationsTableOptions" *ngIf="magicEmailInvitationsTableOptions"></gk-table>
</div>

<!-- add user menu -->
<mat-menu
  #addUserMenu
  id="add-user-button-menu"
  class="add-user-button-menu"
  [overlapTrigger]="false"
  panelClass="'add-user-button-menu'"
>
  <button mat-menu-item (click)="openInviteModal()" id="invite-button" fxLayout="row" fxLayoutAlign="start center">
    <svg-icon src="assets/img/svg/ic_invitation.svg" class="svg-icon"></svg-icon>
    <div class="text">{{ 'inviteUser' | translate }}</div>
  </button>
  <!-- remove ng if after server will support -->
  <button
    mat-menu-item
    (click)="openSharedLinkModal()"
    id="share-link-button"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <svg-icon src="assets/img/svg/link.svg" class="svg-icon"></svg-icon>
    <div class="text">{{ 'invitationLink' | translate }}</div>
  </button>
  <button mat-menu-item id="create-button" routerLink="/user" fxLayout="row" fxLayoutAlign="start center">
    <svg-icon src="assets/img/svg/ic_add_user@3x.svg" class="svg-icon"></svg-icon>
    <div class="text">{{ 'createNewUser' | translate }}</div>
  </button>
</mat-menu>
