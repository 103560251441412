import { MapsService } from './../../services/maps-service';
import { TranslationService } from './../../services/translation-service.service';
import { UsersService } from './../pages/users/users.service';
import { TableOptions, GkColumn, ColumnTemplete, StateProperties } from './../../contracts/ui.contracts';
import { Component, OnInit, ViewChild, Input, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Config } from 'app/shared/config';
import { UiStateService } from '../../services/ui.state.service';
import { TranslateService } from '@ngx-translate/core';
import { GkIncident, GkUser, GkActivity } from 'app/contracts/contracts';
import { MatDialog } from '@angular/material/dialog';
import { DownloadModalComponent } from '../modals/downloadModaComponent/downloadModalComponent';
import { ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// tslint:disable-next-line: no-var-requires
const fileDownload = require('js-file-download');
// tslint:disable-next-line: no-var-requires
const axios = require('axios');

@Component({
  selector: 'gk-table',
  templateUrl: './gk-table.component.html',
  styleUrls: ['./gk-table.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GkTableComponent implements OnInit {
  private terms$ = new Subject<string>();

  public tableOptions: TableOptions;
  public displayedColumns: string[] = [];
  public dataSource: any;
  public isInit: boolean = false;
  public itemsTranslation: string = this.translationService.getTranslation('items');
  public pageLoader: boolean;
  public isRtl: boolean;
  public downloadsArray: string[] = [];
  public currentUser: GkUser;
  // ENUM
  public ColumnTemplete = ColumnTemplete;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;
  allDownloadChecked: boolean;

  @Input()
  set options(value: TableOptions) {
    if (value) {
      this.tableOptions = value;
      if (this.tableOptions.disabledFilter) {
        if (this.tableOptions.disabledFilter.isItemsDisabled) {
          this.handleDisabledFilter(true);
        } else {
          this.handleDisabledFilter(false);
        }
      }
      // tableOptions data assign to table datasource
      this.dataSource = new MatTableDataSource<any>(
        this.tableOptions && this.tableOptions.dataSource ? this.tableOptions.dataSource : []
      );
      this.displayedColumns = [];
      for (let column of this.tableOptions.columns) {
        this.displayedColumns.push(column.name);
      }
      /* configure filter */
      this.dataSource.filterPredicate = this.customFilterpredicate;
      this.tableVisualInit();
      // Only in new tables
      if (!this.tableOptions.dataSource) {
        // reset downloads
        this.allDownloadChecked = this.checkIfAllDownloadableFilesAreChecked();
        this.downloadsArray = [];
      }
    }
  }

  constructor(
    private translationService: TranslationService,
    private mapService: MapsService,
    private uiStateService: UiStateService,
    private translateService: TranslateService,
    private usersService: UsersService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.usersService.getCurrentUser().subscribe((currentUser: GkUser) => {
      this.currentUser = currentUser;
    });
    this.terms$.pipe(
      debounceTime(400), // discard emitted values that take less than the specified time between output
      distinctUntilChanged() // only emit when value has changed
    ).subscribe(term => {
      this.applyFilter(term);
    });
  }

  ngAfterViewInit() {
    this.tableVisualInit();
    this.detectLangChange();
  }

  // Use overload init to resonse the case the user delete all the letter
  // from the search input and send applyFilter('') with empty string that
  // breaks the table paginator
  public applyFilter(filterValue: string, init = false) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filterPredicate = this.customFilterpredicate;
    this.dataSource.filter = filterValue;
    if ((!this.paginator && init)) {
      this.dataSource = new MatTableDataSource<any>();
      this.tableVisualInit();
    }
  }

  public getStringIntreval = (interval: number) => {
    if (interval === 1) {
      return ' ' + this.translationService.getTranslation('realTime');
    }
    let duration = moment.duration(interval, 'seconds');
    if (duration.minutes() > 0) {
      return duration.minutes() + ' ' + this.translationService.getTranslation('minutes');
    }
    return duration.seconds() + ' ' + this.translationService.getTranslation('seconds');
  };

  private tableVisualInit = () => {
    if (!this.tableOptions) {
      return;
    }
    // https://stackoverflow.com/questions/48785965/angular-matpaginator-not-working
    setTimeout(() => {
      if (this.table) {
        if (this.tableOptions.paginator) {
          // Server pagination
          if (this.tableOptions.pagination && this.tableOptions.pagination.totalItemsLength) {
            if (this.paginator) this.paginator.length = this.tableOptions.pagination.totalItemsLength;
          } else {
            this.dataSource.paginator = this.paginator;
          }
        }
        if (this.tableOptions.sort) {
          this.dataSource.sort = this.sort;
          if (this.tableOptions.customSort) {
            this.dataSource.sort.sort(this.tableOptions.customSort);
          }
        }
        this.dataSource.data = this.tableOptions && this.tableOptions.dataSource ? this.tableOptions.dataSource : [];
        if (this.pageLoader) this.pageLoader = false;
      }
    });
  };

  public getToolTipImage = (type: any, location: any) => {
    return this.mapService.getToolTipImage(type, location);
  };

  public getDefaultColumnHtml = (e: any, columnName: string) => {
    // No deep parse is needed
    if (columnName.split('.').length === 1) return e[columnName];
    // deep parse
    let splittedName = columnName.split('.');
    if (e[splittedName[0]] && e[splittedName[0]][splittedName[1]]) {
      return e[splittedName[0]][splittedName[1]];
    } else {
      return null;
    }
  };

  public getDeviceIcon = (userAgent: string) => {
    return Config.getDeviceIcon(userAgent);
  };

  public handleDisabledFilter = (disabled: boolean) => {
    if (!(this.tableOptions && this.tableOptions.dataSource && this.tableOptions.dataSource.length)) return;
    this.tableOptions.dataSource = _.filter(this.tableOptions.dataSource, (item: any) => {
      return disabled ? !item.enabled : item.enabled;
    });
  };

  public customFilterpredicate = (data: any, filter: string) => {
    if (filter === '') {
      return true;
    }
    for (let j = 0; j < this.displayedColumns.length; j++) {
      let columnName = this.displayedColumns[j];
      if (data[columnName]) {
        // String search
        if (
          data[columnName]
            .toString()
            .toLowerCase()
            .indexOf(filter.toLowerCase()) !== -1
        )
          return true;
      }
    }
    return false;
  };

  public getElementStringType = (e: any, isTeam?: boolean) => {
    if (isTeam === true) return 'team';
    e.type && (e.type !== 0) ? 'entity' : 'user';
    if (e.email) {
      return 'user'
    } else if (e.entityid) {
      return 'entity'
    }
    
    return 'team';
  };

  public pageChange = e => {
    this.pageLoader = true;
    if (this.tableOptions.pagination && this.tableOptions.pagination.pageChange) {
      this.tableOptions.pagination.pageChange(e);
    } else {
      this.pageLoader = false;
    }
    this.uiStateService.SaveUiState(StateProperties.itemPerPage, this.tableOptions.id, e.pageSize);
  };

  public detectLangChange = () => {
    if (this.translateService.currentLang === 'he') return (this.isRtl = true);
    this.translateService.onLangChange.subscribe((res: any) => {
      if (res.lang === 'he') return (this.isRtl = true);
      return (this.isRtl = false);
    });
  };

  // Troubly here - might makes trouble with big amount of data
  // need to replace this tooltip
  public getTooltipPlacement = () => {
    return this.isRtl ? 'left' : 'right';
  };

  public getIncidentStringType = (incident: GkIncident) => {
    // tslint:disable-next-line:forin
    for (let incidentType in Config.PlaceTypes) {
      if (Config.PlaceTypes[incidentType] && incident.place.type === Config.PlaceTypes[incidentType]) {
        return this.translationService.getTranslation(incidentType.toLowerCase());
      }
    }
  };

  public getIncidentIconByType = (incident: GkIncident) => {
    for (let incidentType in Config.PlaceTypes) {
      let incidentTypeIcon = 'other';
      if (incident.place && incident.place.type &&
        Config.PlaceTypes[incidentType] &&
        incident.place.type === Config.PlaceTypes[incidentType]) {
        try {
          let file = require('assets/img/svg/placesTypes/' + incidentType.toLowerCase() + '.svg');
          incidentTypeIcon = incidentType.toLowerCase();
        } catch (e) {
          console.warn(e, 'File not exists');
        }
        return 'assets/img/svg/placesTypes/' + incidentTypeIcon + '.svg';
      }
      return 'assets/img/svg/placesTypes/' + incidentTypeIcon + '.svg';
    }
  };

  public getIconByFileType = (type: string) => {
    let baseUrl: string = 'assets/img/svg/';
    let iconName: string;
    switch (type) {
      case 'pdf':
        iconName = 'file-pdf';
        break;
      case 'xlx':
      case 'xlsx':
        iconName = 'file-xlsx';
        break;
      case 'doc':
      case 'docx':
        iconName = 'file-doc';
        break;
      case 'txt':
        iconName = 'file-txt';
        break;
      case 'csv':
        iconName = 'file-csv';
        break;
      case 'ppt':
      case 'pptx':
        iconName = 'file-ppt';
        break;
      default:
        iconName = 'file-doc';
        break;
    }
    return baseUrl + iconName + '.svg';
  };

  public downloadCheck = (event: any, element: any) => {
    let index = this.downloadsArray.findIndex((activityId: string) => {
      return element._id === activityId;
    });
    if (event.checked) {
      if (index === -1) {
        this.downloadsArray.push(element._id);
        element.checked = true;
      }
    } else {
      if (index > -1) {
        this.downloadsArray.splice(index, 1);
        element.checked = false;
      }
    }
    this.allDownloadChecked = this.checkIfAllDownloadableFilesAreChecked();
  };

  public checkAllDownloads = (event: any, type?: string) => {
    let data = this.tableOptions.fullActivitiesData || this.dataSource.data;
    for (let i = 0; i < data.length; i++) {
      if (type) {
        // Check specific type
        if (data[i].type === type) {
          this.downloadCheck({ checked: event.checked }, data[i]);
          data[i].checked = event.checked;
        } else {
          this.downloadCheck({ checked: !event.checked }, data[i]);
          data[i].checked = !event.checked;
        }
      } else {
        // Check all
        if (data[i].type === 'image' || data[i].type === 'video' || data[i].type === 'file') {
          this.downloadCheck({ checked: event.checked }, data[i]);
          data[i].checked = event.checked;
        }
      }
    }
    if (!event.checked) this.downloadsArray = [];
  };

  public checkIfAllDownloadableFilesAreChecked = () => {
    let data = this.tableOptions.fullActivitiesData || this.dataSource.data || [];
    let thereIsDownloadable;
    for (let i = 0; i < data.length; i++) {
      if (data[i].type === 'image' || data[i].type === 'video' || data[i].type === 'file') {
        thereIsDownloadable = true;
        if (!data[i].checked) return false;
      }
    }
    if (!thereIsDownloadable) return false;
    return true;
  }

  public downloadFiles = () => {
    if (this.downloadsArray.length === 1) {
      let index = this.dataSource.data.findIndex((activity: GkActivity) => {
        return activity._id === this.downloadsArray[0];
      });
      if (this.dataSource.data[index].type === 'image' || this.dataSource.data[index].type === 'video') {
        let a = document.createElement('a');
        let url =
          this.dataSource.data[index].type === 'image'
            ? this.dataSource.data[index].data.urls.full_hd
            : this.dataSource.data[index].data.url;
        a.href = url;
        return a.click();
      } else {
        let file = this.dataSource.data[index].data;
        return this.downloadSingleFile(file.url, file.name);
      }
    }
    let Dialog = this.dialog.open(DownloadModalComponent, {
      width: '400px',
      height: '300px',
      data: {
        files: this.downloadsArray,
        user: this.currentUser,
      },
    });

    this.dialog.afterAllClosed.subscribe(() => {
      this.checkAllDownloads({ checked: false });
    });
  };

  public downloadSingleFile = (url: string, name: string) => {
    axios
      .get(url, { responseType: 'arraybuffer' })
      .then(response => {
        fileDownload(response.data, name);
      })
      .catch(err => {
        console.log(err);
      });
  };

  public trackByIndex = (index: number, obj: any) => {
    return index;
  };
}
