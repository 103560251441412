<div class="confirmation-modal-container" fxLayout="column" fxLayoutAlign="space-around start">
  <h1 mat-dialog-title [ngStyle]="{'margin-bottom': deepWarning ? '0' : 'inherite'}">{{ 'delete' | translate }} {{ type
    | translate | capitalizeFirst}}</h1>
  <mat-dialog-content>
    <span>{{'areYouSureYouWantToDelete' | translate}} {{data[type]?.name ? data[type]?.name : type | translate}} ?</span>
    <br>
    <div class="deep-warning" *ngIf="deepWarning">
      <span>{{ 'theFollowingAlsoWillBeDeleted' | translate}}: </span>
      <br>
      <ul>
        <li>{{ 'allActivitiesAndPhotos' | translate}}</li>
        <li *ngIf="type !== 'team'">{{ 'allLocationHistory' | translate }}</li>
        <li *ngIf="type === 'user'">{{ 'allDevices' | translate }}</li>
        <li *ngIf="type === 'integration'">{{ 'allEntities' | translate }}</li>
        <li *ngIf="type === 'team'">{{ 'allRecordings' | translate }}</li>
        <br>
      </ul>
        <span>{{ 'thisCanotBeUndoneOnceConfirmed' | translate }}</span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between start">
    <button mat-raised-button type="button" class="confirm-button" mat-dialog-close>{{'cancel' | translate}}</button>
    <button mat-raised-button type="submit" class="confirm-button cancel-button" mat-dialog-close="true">{{'delete' |
      translate}} {{type | translate | capitalizeFirst }}</button>
  </mat-dialog-actions>
</div>
