import { Component, OnInit, Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UsersService } from '../../pages/users/users.service';
import { GkUser, SecurityTypes } from '../../../contracts/contracts';
import { Router } from '@angular/router';

@Component({
  selector: 'DisableConfirmationComponent',
  templateUrl: './disableConfirmation.modal.component.html',
  styleUrls: ['./disableConfirmation.modal.component.scss'],
})
export class DisableConfirmationComponent implements OnDestroy {
  public type: string;
  public allUsersGotEmail: any;
  public warningModal: boolean;
  constructor(
    private dialogRef: MatDialogRef<DisableConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService
  ) {
    this.initModal(data);
  }

  public initModal(data: any) {
    if (data.id === SecurityTypes.DEVICE_TRUST_USER && data.isDisabled) {
      this.allUsersGotEmail = [];
      this.usersService.checkAllUsersEmail().then((result: any) => {
        this.allUsersGotEmail = result;
        // warningModal is boolean that affects on modal style
        if (this.allUsersGotEmail && this.allUsersGotEmail.length > 0) this.warningModal = true;
      });
    }
  }

  ngOnDestroy(): void {
    delete this.allUsersGotEmail;
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  public editUser = (user: GkUser) => {
    this.dialogRef.close(user);
  };
}
