import { EmailInvitation, GkOrganization, MagicInvitation } from '../../../../contracts/contracts';
import { ConfirmationComponent } from '../../../modals/confirmation.modal/confirmation.modal.component';
import { UsersService } from '../users.service';
import { GkSnackbar } from '../../../../services/gkSnackbar/gk-snackbar.service';
import { BreadCrumbOptions, ColumnTemplete, GkMode, GkUiState, TableOptions } from '../../../../contracts/ui.contracts';
import { AppState, GkUser } from '../../../../contracts/contracts';
import { TranslationService } from '../../../../services/translation-service.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from 'moment';
import { Config } from '../../../../shared/config';
import { OrganizationService } from '../../dashboard/organization.service';
import { UiStateService } from '../../../../services/ui.state.service';
import { InvitationLinkModalComponent } from 'app/components/modals/invitationLinkModal/invitationLink.modal.component';
import { InvitationModalComponent } from 'app/components/modals/invitation.modal/invitation.modal.component';
import { LocalFullDateFormatPipe } from 'app/shared/pipes/localTime.pipe';
import { take } from 'rxjs/operators';
import * as _ from 'lodash';
import { sortByLastSeen } from '../../../../shared/sorting';

@Component({
  selector: 'users-list',
  templateUrl: './usersList.component.html',
  styleUrls: ['../users.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
// encapsulation mode change for add-user-menu design
// see issue: https://stackoverflow.com/questions/46821027/set-mat-menu-style
export class UsersListComponent implements OnInit {
  public dataSource: any;
  public users: GkUser[];
  public inAction: boolean;
  public isInit: boolean = false;
  public breadcrumbOptions: BreadCrumbOptions;
  public tableOptions: TableOptions;
  public emailInvitations: EmailInvitation[];
  public disabledFilter: boolean;
  public selectedTab: number;
  public tabCounter: any;
  public organization: GkOrganization;
  public uiState: GkUiState;
  public subscriptions: any[] = [];
  public emailInvitationsTableOptions: TableOptions;
  public magicEmailInvitationsTableOptions: TableOptions;
  magicInvitations: any;

  constructor(
    private userService: UsersService,
    private translationService: TranslationService,
    private store: Store<AppState>,
    private router: Router,
    private snackbar: GkSnackbar,
    private organizationService: OrganizationService,
    public dialog: MatDialog,
    public uiStateService: UiStateService,
    private localDatePipe: LocalFullDateFormatPipe
  ) {}

  ngOnInit() {
    this.userService.getUsers();
    this.initBreadCrumb();
    this.tableInit();
    this.initEmailInvitationsTable();
    this.initMagicEmailInvitationsTable();
    this.getUsers();
    this.getOrganiztion();
    this.getUiState();
    this.getEmailInvitations();
    this.getMagicEmailInvitations();
  }

  private initBreadCrumb() {
    this.breadcrumbOptions = new BreadCrumbOptions();
    this.breadcrumbOptions.path = [{ titleKey: 'users', url: '../users-list' }];
  }

  public getOrganiztion() {
    this.subscriptions.push(
      this.organizationService.GetMyOrganization().subscribe((org: GkOrganization) => {
        this.organization = org;
      })
    );
  }

  public getUsers() {
    this.subscriptions.push(
      this.store.select('users').subscribe((users: any) => {
        if (users && users.length) {
          this.users = users;
          this.usersCount();
          this.tableInit(users);
        }
      })
    );
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  public tableInit = (dataSource?: any) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.pageSize = this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.usersList : null;
    this.tableOptions.id = 'usersList';
    this.tableOptions.rowHoverClass = true;
    this.tableOptions.columns = [
      {
        name: 'name',
        displayNameKey: 'name'
      },
      {
        name: 'entityid',
        displayNameKey: 'userid'
      },
      {
        name: 'email',
        displayNameKey: 'email'
      },
      {
        name: 'lastSeen',
        displayNameKey: 'lastSeen',
        template: ColumnTemplete.Date
      },
      {
        name: 'monitor',
        displayNameKey: 'actions',
        template: ColumnTemplete.MenuUpdateButton,
        onClick: (e: any, user: GkUser) => {
          if (e.target.id.indexOf('delete') !== -1) {
            this.openDeleteModal(user);
          } else if (e.target.id.indexOf('edit') !== -1) {
            if (!this.inAction) {
              this.inAction = true;
              this.router.navigateByUrl('user/' + user._id);
            }
          } else {
            this.toggleDisable(user);
          }
        }
      }
    ];
    if (this.disabledFilter) {
      this.tableOptions.disabledFilter = {
        isItemsDisabled: true
      };
    } else {
      this.tableOptions.disabledFilter = {
        isItemsDisabled: false
      };
    }
    if (dataSource) {
      this.tableOptions.dataSource = this.users;
    }
    this.isInit = true;
  };

  public getEmailInvitations = () => {
    this.organizationService
      .GetEmailInvitations(0, 100)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          if (res.items && res.items.length) {
            this.emailInvitations = res.items;
            this.initEmailInvitationsTable();
          }
        },
        () => {
          this.snackbar.open(this.translationService.getTranslation('failedToGetEmailInvitations'));
        }
      );
  };

  public deleteEmailInvitation = (emailInvitation: EmailInvitation) => {
    this.subscriptions.push(
      this.organizationService.DeleteEmailInvitations(emailInvitation.email).subscribe(
        () => {
          this.snackbar.open(this.translationService.getTranslation('invitationDeletedSuccess'));
          let index = _.findIndex(this.emailInvitations, (invite: EmailInvitation) => {
            return invite.email === emailInvitation.email;
          });
          this.emailInvitations.splice(index, 1);
          this.initEmailInvitationsTable();
        },
        () => {
          this.snackbar.open(this.translationService.getTranslation('invitationDeletedFailed'));
        }
      )
    );
  };

  public initEmailInvitationsTable = () => {
    this.emailInvitationsTableOptions = new TableOptions();
    this.emailInvitationsTableOptions.id = 'emailInvitations';
    this.emailInvitationsTableOptions.pageSize =
      this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.emailInvitations : null;
    this.emailInvitationsTableOptions.rowHoverClass = true;
    this.emailInvitationsTableOptions.columns = [
      {
        name: 'email',
        displayNameKey: 'email'
      },
      {
        name: 'name',
        displayNameKey: 'name'
      },
      {
        name: 'created',
        displayNameKey: 'sentTime',
        template: ColumnTemplete.Date
      },
      {
        name: 'isExpired',
        displayNameKey: 'status',
        width: '15',
        template: ColumnTemplete.EmailStatus
      },
      {
        name: 'actions',
        displayNameKey: '',
        width: '15',
        template: ColumnTemplete.EmailActions,
        onClick: (e: any, emailInvitation: EmailInvitation) => {
          if (e.target.parentNode.id.indexOf('delete') !== -1 || e.target.id.indexOf('delete') !== -1) {
            this.deleteEmailInvitation(emailInvitation);
          } else if (e.target.parentNode.id.indexOf('resend') !== -1 || e.target.id.indexOf('resend') !== -1) {
            this.resendEmailInvitation(emailInvitation);
          }
        }
      }
    ];
    if (this.emailInvitations && this.emailInvitations.length) {
      // Preventing from fuck the loader
      if (this.emailInvitations[0].email) this.emailInvitations = sortByLastSeen(this.emailInvitations);
      this.emailInvitationsTableOptions.dataSource = this.emailInvitations;
      return;
    }
    this.emailInvitationsTableOptions.dataSource = [];
  };

  public resendEmailInvitation = (emailInvitation: EmailInvitation) => {
    this.snackbar.open(this.translationService.getTranslation('invitationResentSuccess'));
    let index = _.findIndex(this.emailInvitations, (invite: EmailInvitation) => {
      return invite.email === emailInvitation.email;
    });
    // Save Old State for case of rollback
    let savedState: EmailInvitation = Object.assign({}, this.emailInvitations[index]);

    this.emailInvitations[index].sent = Date.now();
    this.emailInvitations[index].isExpired = false;
    this.initEmailInvitationsTable();
    this.subscriptions.push(
      this.organizationService.ResendEmailInvitations(emailInvitation.email).subscribe(
        () => {
          // All Good
        },
        () => {
          // Roll Back          this.snackbar.open(this.translationService.getTranslation('invitationResentFailed'));
          let j = _.findIndex(this.emailInvitations, (invite: EmailInvitation) => {
            return invite.email === emailInvitation.email;
          });
          this.emailInvitations[j] = savedState;
          this.initEmailInvitationsTable();
        }
      )
    );
  };

  // Magic link table
  public getMagicEmailInvitations = () => {
    this.organizationService
      .GetMagicEmailInvitations(0, 100)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          if (res.items && res.items.length) {
            let emailInvitations = res.items;
            this.initMagicEmailInvitationsTable(emailInvitations);
          }
        },
        () => {
          this.snackbar.open(this.translationService.getTranslation('failedToGetEmailInvitations'));
        }
      );
  };

  public initMagicEmailInvitationsTable = (magicEmailInvitations?: MagicInvitation[]) => {
    this.magicEmailInvitationsTableOptions = new TableOptions();
    this.magicEmailInvitationsTableOptions.id = 'magicEmailInvitations';
    this.magicEmailInvitationsTableOptions.pageSize =
      this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.magicEmailInvitations : null;
    this.magicEmailInvitationsTableOptions.rowHoverClass = true;
    this.magicEmailInvitationsTableOptions.columns = [
      {
        name: '_id',
        displayNameKey: 'link'
      },
      {
        name: 'userName',
        displayNameKey: 'createdBy'
      },
      {
        name: 'created',
        displayNameKey: 'createdOn',
        template: ColumnTemplete.Date
      },
      {
        name: 'maxHits',
        displayNameKey: 'registeredUsers',
        template: ColumnTemplete.MaxHits
      },
      {
        name: 'expiration',
        displayNameKey: 'expiresOn',
        width: '15',
        template: ColumnTemplete.Date
      },
      {
        name: 'actions',
        displayNameKey: '',
        width: '7',
        template: ColumnTemplete.MagicEmailActions,
        onClick: (e: any, emailInvitation: MagicInvitation) => {
          if (e.target.parentNode.id.indexOf('delete') !== -1 || e.target.id.indexOf('delete') !== -1) {
            this.deleteMagicLink(emailInvitation._id);
          } else if (e.target.parentNode.id.indexOf('edit') !== -1 || e.target.id.indexOf('edit') !== -1) {
            let dialogRef = this.dialog.open(InvitationLinkModalComponent, {
              disableClose: false,
              closeOnNavigation: true,
              data: {
                emailInvitation
              }
            });
            dialogRef.afterClosed().subscribe(() => {
              this.getMagicEmailInvitations();
            });
          } else if (
            e.target.parentNode.id.indexOf('copy-invitation-button') !== -1 ||
            e.target.id.indexOf('copy-invitation-button') !== -1
          ) {
            this.copyUrl(emailInvitation.url);
          }
        }
      }
    ];
    if (magicEmailInvitations && magicEmailInvitations.length) {
      this.magicInvitations = magicEmailInvitations;

      for (let i = 0; i < magicEmailInvitations.length; i++) {
        const user = magicEmailInvitations[i].user;
        magicEmailInvitations[i].userName = user ? user.name : this.translationService.getTranslation('userInvalid');
      }

      this.magicEmailInvitationsTableOptions.dataSource = magicEmailInvitations;
      return;
    }
    this.magicEmailInvitationsTableOptions.dataSource = [];
  };

  public deleteMagicLink(invitationId: string): void {
    this.snackbar.open(this.translationService.getTranslation('invitationDeletedSuccess'));
    let index = _.findIndex(this.magicInvitations, (invite: MagicInvitation) => {
      return invite._id === invitationId;
    });
    let oldInvite = this.magicInvitations[index];
    this.magicInvitations.splice(index, 1);
    this.initMagicEmailInvitationsTable(this.magicInvitations);

    this.organizationService.DeleteMagicInvitation(invitationId).subscribe(
      () => {},
      () => {
        this.magicInvitations.push(oldInvite);
        this.initEmailInvitationsTable();
        this.snackbar.open(this.translationService.getTranslation('invitationDeletedFailed'));
      }
    );
  }

  public copyUrl = (url: string) => {
    // Copy the url
    let bodyElement = document.getElementsByTagName('body')[0];
    let tempInput: any = document.createElement('INPUT');
    bodyElement.appendChild(tempInput);
    tempInput.setAttribute('value', url);
    tempInput.select();
    document.execCommand('copy');
    bodyElement.removeChild(tempInput);
    // Notice the user
    this.snackbar.open(this.translationService.getTranslation('linkCopiedSuccessfully'));
  };

  public deleteUser = (userId: string) => {
    this.inAction = false;
    // fb style - dont wait to the server response, rollback if needed
    let index = this.users.findIndex((user: GkUser) => {
      return user._id === userId;
    });
    // save for rollback option
    let savedUser = Object.assign({}, this.users[index]);
    let userName = this.users[index].name;
    this.users.splice(index, 1);
    this.store.dispatch({ type: 'SET_USERS', payload: this.users });
    this.usersCount();
    this.tableInit(this.users);
    this.subscriptions.push(
      this.userService.DeleteUser(userId).subscribe(
        () => {
          // Update the store
          this.store.dispatch({ type: 'SET_USERS', payload: this.users });
          this.snackbar.open(userName + ' ' + this.translationService.getTranslation('wasRemoved'));
        },
        () => {
          // rollback
          this.snackbar.open(this.translationService.getTranslation('failedToDelete') + ' ' + userName);
          this.users.push(savedUser);
          this.store.dispatch({ type: 'SET_USERS', payload: this.users });
          this.usersCount();
          this.tableInit(this.users);
        }
      )
    );
  };

  public openUser = (mode: GkMode, entityId: string) => {
    switch (mode) {
      case GkMode.Add:
        this.router.navigateByUrl('user/new-user');
        break;
      case GkMode.Update:
        this.router.navigateByUrl('user/' + entityId);
        break;
      default:
        this.router.navigateByUrl('404');
        return;
    }
  };

  public openDeleteModal(user: GkUser): void {
    let confirmDialog = this.dialog.open(ConfirmationComponent, {
      width: '400px',
      height: '300px',
      data: {
        user,
        deepWarning: true
      }
    });
    confirmDialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.deleteUser(user._id);
      }
    });
  }

  public exportToCsv = () => {
    let headers = [
      this.translationService.getTranslation('userid'),
      this.translationService.getTranslation('admin'),
      this.translationService.getTranslation('name'),
      this.translationService.getTranslation('created'),
      this.translationService.getTranslation('disabled')
    ];
    let csvFileName = 'users-' + moment.utc().format('MM-DD-YYYY');
    let formattedArray = this.formatUsers(this.users);
    let safeArray = Config.checkChar(formattedArray);
    this.subscriptions.push(
      this.userService.notifyUsersExport().subscribe(() => {
        // tslint:disable-next-line:no-unused-expression
        new Angular5Csv(safeArray, csvFileName, {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: false,
          useBom: true,
          noDownload: false,
          headers
        });
      })
    );
  };

  public formatUsers = (users: GkUser[]) => {
    let formmatedUsersArray = [];
    for (let i = 0; i < users.length; i++) {
      formmatedUsersArray.push({
        a: users[i].entityid,
        b: !!users[i].admin,
        c: users[i].name,
        e: this.localDatePipe.transform(users[i].created),
        f: !users[i].enabled
      });
    }
    return formmatedUsersArray;
  };

  public handleTabChange = (allUsers: boolean) => {
    // All users
    if (allUsers) {
      this.disabledFilter = false;
    } else {
      // Diactivated users
      this.disabledFilter = true;
      this.tableOptions.disabledFilter = {
        isItemsDisabled: true
      };
    }
    this.tableInit(this.users);
  };

  public toggleDisable = (user: GkUser) => {
    user.enabled = !user.enabled;
    this.subscriptions.push(
      this.userService.UpdateUser(user._id, user).subscribe(
        (res: any) => {
          if (res.enabled) {
            this.snackbar.open(user.name + ' ' + this.translationService.getTranslation('wasEnabled'));
            this.handleTabChange(true);
            this.usersCount();
          } else {
            this.snackbar.open(user.name + ' ' + this.translationService.getTranslation('wasDisabled'));
            this.handleTabChange(false);
            this.usersCount();
          }
        },
        () => {
          this.snackbar.open(this.translationService.getTranslation('failedToUpdate ' + user.name));
        }
      )
    );
  };

  public usersCount = () => {
    this.tabCounter = {
      enabled: 0,
      disabled: 0
    };
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].enabled) {
        this.tabCounter.enabled++;
      } else {
        this.tabCounter.disabled++;
      }
    }
  };

  public openInviteModal = () => {
    this.dialog.open(InvitationModalComponent, {
      disableClose: true,
      panelClass: 'invitation-modal',
      data: {
        organizationName: this.organization.name
      }
    });
  };

  public getUiState = () => {
    this.subscriptions.push(
      this.uiStateService.GetUiState().subscribe((state: GkUiState) => {
        this.uiState = state;
        this.tableOptions.pageSize = this.uiState.itemPerPage.usersList;
        this.tableInit(this.users);
      })
    );
  };

  public openSharedLinkModal = () => {
    this.dialog.open(InvitationLinkModalComponent, {
      disableClose: false,
      closeOnNavigation: true
    });
  };
}
