import { FilterPipe } from './shared/pipes/filter.pipe';
import { CapitalizeFirstPipe } from './shared/pipes/capitaizeFirst.pipe.';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { EntitiesService } from './components/pages/entities/entities.service';
import { Validators } from '@angular/forms';
import { UicommonModule } from './vendor/uicommon/uicommon.module';
import { GkSnackbar } from './services/gkSnackbar/gk-snackbar.service';
import { GkStoreModule } from './shared/store/store.module';
import { WebApiService } from './services/web-api.service';
import { LocalizationModule, HttpLoaderFactory } from './localization/localization.module';
import { MaterialModule } from './material/material.module';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { SweetAlertService } from 'angular-sweetalert-service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { QRCodeModule } from 'angular2-qrcode';
import { NgProgressModule } from 'ngx-progressbar';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function meProviderFactory(provider: MeProvider) {
  return () => provider.load();
}
/*
 * Platform and Environment providers/directives/pipes
 */
// Pipes
//
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { NoContentComponent } from './no-content';
import { DevModuleModule } from './+dev-module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GkToolbarComponent } from './components/gk-toolbar/gk-toolbar.component';
import { GkNavbarComponent } from './components/gk-navbar/gk-navbar.component';
import { UsersListComponent } from './components/pages/users/users-list/usersList.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateLoader } from '@ngx-translate/core';
import { MissingTranslationHandler } from '@ngx-translate/core';
import { MyMissingTranslationHandler } from './localization/my-missing-translation-handler';
import { UsersService } from './components/pages/users/users.service';
import { TranslationService } from './services/barrel-services';
import { GkBreadcrumbComponent } from './components/gk-breadcrumb/gk-breadcrumb.component';
import { GkTableComponent } from './components/gk-table/gk-table.component';
import { UserComponent } from './components/pages/users/user/user.component';
import { UserFormComponent } from './components/pages/users/user-form/user-form.component';
import { FileUploadModule } from 'ng2-file-upload';
import 'jquery';
import _ from 'lodash';
import { ConfirmationComponent } from './components/modals/confirmation.modal/confirmation.modal.component';
import { TeamsService } from './components/pages/teams/teams.service';
import { TeamsListComponent } from './components/pages/teams/teams-list/teamsList.component';
import { TeamComponent } from './components/pages/teams/team/team.component';
import { TeamFormComponent } from 'app/components/pages/teams/team-form/team-form.component';
import { IntegrationListComponent } from './components/pages/integrations/integration-list/integration-list.component';
import { IntegrationsService } from './components/pages/integrations/integrations.service';
import { TruncatePipe } from './shared/pipes/limitTo.pipe';
import { IntegrationCardComponent } from './components/integration-card/integration-card.component';
import { GkIntegrationComponent } from './components/gk-integration/gk-integration.component';
import { EntityComponent } from './components/pages/entities/entity/entity.component';
import { EntityFormComponent } from './components/pages/entities/entity-form/entity-form.component';
import { HistoryLogComponent } from './components/pages/history/history-log/history-log.component';
// tslint:disable-next-line:max-line-length
import { IntegrationCardPlaceholderComponent } from './components/placeholders/integration-card-placeholder/integration-card-placeholder.component';
import { HistoryService } from 'app/components/pages/history/history.service';
import { GkFilterComponent } from './components/gk-filter/gk-filter.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { ResponseInterceptor } from '../../interceptors/response.interceptor';
import { BrowserXhr } from '@angular/common/http/src/xhr';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import { CustExtBrowserXhr } from './vendor/cors/custExtbrowser';
import { GkMapComponent } from './components/gk-map/gk-map.component';
import { MapsService } from './services/maps-service';
import { PaginationService } from './services/pagination-service';
import { NameFilterPipe } from './shared/pipes/name.pipe';
import { GkVersionComponent } from './components/gk-version/gk-version.component';
import { GkDashboardComponent } from './components/pages/dashboard/gk-dashboard/gk-dashboard.component';
import { OrganizationService } from 'app/components/pages/dashboard/organization.service';
import { GkGeoSelectorComponent } from './components/gk-geo-selector/gk-geo-selector.component';
import { GkItemsSelectorComponent } from './components/gk-items-selector/gk-items-selector.component';
import { GkFormValidationComponent } from './components/gk-form-validation/gk-form-validation.component';
import { PinModalComponent } from './components/modals/pin.modal/pin.modal.component';
import { GkPinSelectorComponent } from './components/gk-pin-selector/gk-pin-selector.component';
import { QrModalComponent } from './components/modals/qr.modal/qr.modal.component';
import { GkImageUploaderComponent } from './components/gk-image-uploader/gk-image-uploader.component';
// tslint:disable-next-line:max-line-length
import { UserPasswordModalComponent } from './components/modals/userpassword-integration.modal/userpassword-integration.modal.component';
import { InvitationModalComponent } from './components/modals/invitation.modal/invitation.modal.component';
import { GkAutocompleteComponent } from './components/gk-autocomplete/gk-autocomplete.component';
// tslint:disable-next-line:max-line-length
import { DisableConfirmationComponent } from './components/modals/disableConfirmation.modal/disableConfirmation.modal.component';
// tslint:disable-next-line:max-line-length
import { TrustConfirmationComponent } from './components/modals/trustConfirmation.modal/trustConfirmation.modal.component';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { UiStateService } from './services/ui.state.service';
import { RecordingsComponent } from './components/pages/history/recordings/recordings.component';
import { FileSizePipe } from 'app/shared/pipes/filesSize.pipe';
import { DurationPipe } from './shared/pipes/duration.pipe';
// tslint:disable-next-line:max-line-length
import { RecordingSessionModalComponent } from './components/modals/recording-session-modal/recording-session-modal.component';
import { VideoModalComponent } from './components/modals/video-modal/video-modal.component';
import { LocalDateFormatPipe, LocalTimeFormatPipe, LocalFullDateFormatPipe } from './shared/pipes/localTime.pipe';
import { GkSecurityComponent } from './components/pages/security/security.component';
import { GkLineChartComponent } from './components/gk-line-chart/gk-line-chart.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getPaginatorIntlByLang } from './material/paginator.intl';
// tslint:disable-next-line:max-line-length
import { GkLocationPointSelectorComponent } from './components/gk-location-point-selector/gk-location-point-selector.component';
import { IncidentsListComponent } from './components/pages/incidents/incidents-list/incidentsList.component';
import { IncidentsService } from './components/pages/incidents/incidents-list/incidents.service';
import { IncidentLogComponent } from './components/pages/history/incident-log/incident-log.component';
import { DownloadModalComponent } from './components/modals/downloadModaComponent/downloadModalComponent';
import { InvitationLinkModalComponent } from './components/modals/invitationLinkModal/invitationLink.modal.component';
import { MockCapitalizeFirstPipe } from './shared/mockPipes/mockCapitaizeFirst.pipe.';
import { MockLocalFullDateFormatPipe, MockLocalDateFormatPipe, MockLocalTimeFormatPipe } from './shared/mockPipes/mockDates.pipe';
import { LocalDateMockPipe } from './shared/pipes/moke.pipes';
import { MeProvider } from './MeProvider';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  AppState,
  UsersService,
  TeamsService,
  IntegrationsService,
  IncidentsService,
  EntitiesService,
  HistoryService,
  MapsService,
  PaginationService,
  OrganizationService,
  GkSnackbar,
  TranslationService,
  UiStateService,
  WebApiService,
  MeProvider,
  { provide: APP_INITIALIZER, useFactory: meProviderFactory, deps: [MeProvider], multi: true }
];

interface StoreType {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [AppComponent, GkNavbarComponent],
  declarations: [
    // Components
    AppComponent,
    NoContentComponent,
    GkToolbarComponent,
    GkNavbarComponent,
    UsersListComponent,
    TeamsListComponent,
    IncidentsListComponent,
    IntegrationListComponent,
    GkBreadcrumbComponent,
    GkTableComponent,
    GkLineChartComponent,
    UserComponent,
    TeamComponent,
    UserFormComponent,
    TeamFormComponent,
    ConfirmationComponent,
    DisableConfirmationComponent,
    TrustConfirmationComponent,
    IntegrationCardComponent,
    GkIntegrationComponent,
    // mock pipes
    MockCapitalizeFirstPipe,
    MockLocalFullDateFormatPipe,
    MockLocalDateFormatPipe,
    MockLocalFullDateFormatPipe,
    MockLocalTimeFormatPipe,
    LocalDateMockPipe,
    // Pipes
    NameFilterPipe,
    LocalDateFormatPipe,
    LocalTimeFormatPipe,
    LocalFullDateFormatPipe,
    TruncatePipe,
    FileSizePipe,
    FilterPipe,
    DurationPipe,
    CapitalizeFirstPipe,
    EntityComponent,
    EntityFormComponent,
    HistoryLogComponent,
    IncidentLogComponent,
    IntegrationCardPlaceholderComponent,
    GkFilterComponent,
    GkMapComponent,
    GkVersionComponent,
    GkDashboardComponent,
    GkSecurityComponent,
    GkGeoSelectorComponent,
    GkLocationPointSelectorComponent,
    GkItemsSelectorComponent,
    GkFormValidationComponent,
    PinModalComponent,
    GkPinSelectorComponent,
    QrModalComponent,
    GkImageUploaderComponent,
    UserPasswordModalComponent,
    InvitationModalComponent,
    GkAutocompleteComponent,
    RecordingsComponent,
    RecordingSessionModalComponent,
    VideoModalComponent,
    DownloadModalComponent,
    InvitationLinkModalComponent,
    // Placeholders
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    UicommonModule,
    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxWebstorageModule.forRoot(),
    OwlDateTimeModule,
    OwlMomentDateTimeModule,
    QRCodeModule,
    NgProgressModule,
    NgbModule,
    TooltipModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    SweetAlert2Module.forRoot(),
    LocalizationModule,
    FlexLayoutModule,
    GkStoreModule,
    FileUploadModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MyMissingTranslationHandler,
      },
    }),
    RouterModule.forRoot(ROUTES, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),

    /**
     * This section will import the `DevModuleModule` only in certain build types.
     * When the module is not imported it will get tree shaked.
     * This is a simple example, a big app should probably implement some logic
     */
    ...(environment.showDevModule ? [DevModuleModule] : []),
  ],
  // Must add modals to entryComponent
  // tslint:disable-next-line:max-line-length
  entryComponents: [
    ConfirmationComponent,
    PinModalComponent,
    QrModalComponent,
    UserPasswordModalComponent,
    InvitationModalComponent,
    DisableConfirmationComponent,
    TrustConfirmationComponent,
    RecordingSessionModalComponent,
    VideoModalComponent,
    DownloadModalComponent,
    InvitationLinkModalComponent,
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    environment.ENV_PROVIDERS,
    APP_PROVIDERS,
    Validators,
    FilterPipe,
    DurationPipe,
    DatePipe,
    FileSizePipe,
    LocalDateFormatPipe,
    LocalTimeFormatPipe,
    LocalFullDateFormatPipe,
    CookieService,
    SweetAlertService,
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntlByLang() },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
