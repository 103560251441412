import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { httpMethod, GkTeam, GkEntity, AppState } from './../../../contracts/contracts';
import { Injectable, OnInit } from '@angular/core';
import { WebApiService } from '../../../services/barrel-services';
import { Constants } from '../../../shared/constant';

@Injectable()
export class EntitiesService implements OnInit {
  constructor(private webApi: WebApiService, private store: Store<AppState>) {}

  public ngOnInit() {}

  public GetEntities = () => {
    return this.webApi
      .makeRequest(Constants.Services.Entities, null, httpMethod.Get)
      .map((payload: any) => {
        return { type: 'SET_ENTITIES', payload };
      })
      .subscribe((action) => this.store.dispatch(action));
  };

  public GetEntity = (entityId: string): Observable<GkEntity> => {
    return this.webApi.makeRequest(Constants.Services.Entities + '/' + entityId, null, httpMethod.Get);
  };

  public DeleteEntity = (entityId: string) => {
    return this.webApi.makeRequest(Constants.Services.Entities + '/' + entityId, null, httpMethod.Delete);
  };
}
