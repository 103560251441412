<div class="row integration-card-placeholders">
  <mat-card class="integration-card" fxLayout="column" fxLayoutAlign="start center" style="width:265px; height:365px;">
    <mat-card-title class="title content-placeholder " style="width:120px; ">
    </mat-card-title>
    <mat-card-title class="title content-placeholder " style="width:100px; ">
      &nbsp;
    </mat-card-title>
    <p class="description content-placeholder " style="width:220px">
      &nbsp;
    </p>
    <span class="divider "></span>
    <div class="actions-container ">
      <span class="text-add content-placeholder" style="width:130px">
        &nbsp;
      </span>
    </div>
  </mat-card>
  <mat-card class="integration-card" fxLayout="column" fxLayoutAlign="start center" style="width:265px; height:365px;">
    <mat-card-title class="title content-placeholder " style="width:120px; ">
      &nbsp;
    </mat-card-title>
    <mat-card-title class="title content-placeholder " style="width:100px; ">
      &nbsp;
    </mat-card-title>
    <p class="description content-placeholder " style="width:220px">
      &nbsp;
    </p>
    <span class="divider "></span>
    <div class="actions-container ">
      <span class="text-add content-placeholder" style="width:130px">
        &nbsp;
      </span>
    </div>
  </mat-card>

  <mat-card class="integration-card" fxLayout="column" fxLayoutAlign="start center" style="width:265px; height:365px;">
    <mat-card-title class="title content-placeholder " style="width:120px; ">
      &nbsp;
    </mat-card-title>
    <mat-card-title class="title content-placeholder " style="width:100px; ">
      &nbsp;
    </mat-card-title>
    <p class="description content-placeholder " style="width:220px">
      &nbsp;
    </p>
    <span class="divider "></span>
    <div class="actions-container ">
      <span class="text-add content-placeholder" style="width:130px">
        &nbsp;
      </span>
    </div>
  </mat-card>
</div>
