import { FeatureCollection } from './../../contracts/maps.contracts';
import { MapsService } from './../../services/maps-service';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import CONSTS from '../gk-map/map.consts';
import SELECTOR_MAP_STYLE from './const';
import * as _ from 'lodash';
import * as mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.js';

@Component({
  selector: 'gk-geo-selector',
  templateUrl: './gk-geo-selector.component.html',
  styleUrls: ['./gk-geo-selector.component.scss'],
})
export class GkGeoSelectorComponent implements OnInit {
  public map: any;
  public mapStyle: any;
  public draw: any;
  public lat = CONSTS.START_LAT;
  public lng = CONSTS.START_LON;
  public area: FeatureCollection;
  public isDrawing: boolean;
  @Output() onAreaSelected: EventEmitter<any> = new EventEmitter();

  constructor(private mapService: MapsService) {}

  // Set function for polygons input
  // basic server data parsed to FeatureCollection
  @Input()
  set areaSelected(values: any) {
    if (values) {
      this.area = this.getBuildFeatures(values);
      this.refreshMap();
    }
  }
  ngOnInit() {
    this.getMapStyles();
  }
  // Get basic map styles
  public getMapStyles = () => {
    this.mapService.GetStyles().subscribe((styles: any) => {
      if (styles && styles.length) {
        this.mapStyle = styles[0];
      }
      this.initializeMap();
    });
  };

  // then, Get user location and call this.buildMap()
  private initializeMap = () => {
    // locate the user
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
    this.buildMap();
  };

  // init map obj and call this.load()
  private buildMap = () => {
    let mapOptions: any = {
      container: 'gk-selector-map',
      style: this.mapStyle.url,
      minZoom: CONSTS.MIN_ZOOM,
      zoom: CONSTS.START_ZOOM,
      center: [this.lng, this.lat],
    };
    this.map = new mapboxgl.Map(mapOptions);
    this.load();
  };

  // add map onLoad event then
  // Add map controls without default buttons, add draw listeners
  // fitting view to all features
  public load = () => {
    this.map.on('load', () => {
      let selectorMapStyle = SELECTOR_MAP_STYLE.style;
      this.draw = new MapboxDraw({
        styles: selectorMapStyle,
        displayControlsDefault: false,
      });
      // add basic control
      this.map.addControl(this.draw);
      // add emit listeners
      this.map.on('draw.create', this.emitData);
      this.map.on('draw.delete', this.emitData);
      this.map.on('draw.update', this.emitData);
      // Add pre defined polygons
      this.refreshMap();
    });
  };

  public refreshMap = () => {
    this.nullFilter();
    if (this.draw && this.area) {
      this.draw.add(this.area);
      this.fitToFeatures();
      this.emitData(null);
    }
  };

  public nullFilter = () => {
    if (!this.area || !this.area.features || !this.area.features.length) return;
    for (let i = 0; i < this.area.features.length; i++) {
      const feature: any = this.area.features[i];
      if (feature.geometry.coordinates === 0 || !feature.geometry.coordinates) {
        this.area.features = this.area.features.slice(1, i);
      }
    }
  };

  // get features, parse and emit to parent component
  public emitData = (e: any) => {
    // get features from MapboxDraw obj
    let data = this.draw.getAll();
    // Parse features to server format
    let emittedData: any = this.getGeoJson(data.features);
    // emit data to parent components
    this.onAreaSelected.emit(emittedData);
  };

  // Parse features to server format
  public getGeoJson = (features: any) => {
    let obj: any = {};
    if (features && features.length === 1) {
      obj.type = 'Polygon';
      obj.coordinates = features[0].geometry.coordinates;
      return obj;
    }
    obj.type = 'MultiPolygon';
    obj.coordinates = [];
    for (let i = 0; i < features.length; i++) {
      if (features[i].geometry.type === 'MultiPolygon') {
        obj.coordinates = features[i].geometry.coordinates;
      } else {
        obj.coordinates.push(features[i].geometry.coordinates);
      }
    }
    return obj;
  };

  // Get featurs list, calculate the bounds and fit the map.
  public fitToFeatures = () => {
    if (this.area && !this.area.features.length) return;
    let bounds = new mapboxgl.LngLatBounds();
    _.forEach(this.area.features, (boundedFeature: any) => {
      let coords: any = boundedFeature.geometry.coordinates[0];
      for (let i = 0; i < coords.length; i++) {
        bounds.extend(coords[i]);
      }
    });
    this.map.fitBounds(bounds, {
      padding: CONSTS.MAP_PADDING,
      duration: 0,
    });
  };

  // Map buttons functions
  public startDraw = () => {
    if (this.draw.getMode() === 'simple_select') {
      this.draw.changeMode('draw_polygon');
      this.isDrawing = true;
    } else {
      this.draw.changeMode('simple_select');
      this.isDrawing = false;
    }
  };

  public delete = () => {
    this.draw.trash();
  };

  // parse incoming missing data server
  // single Polygon no needs parsing as well
  // MultiPolygon needs parsing
  public getBuildFeatures = (serverF: any) => {
    let poligonsFeatures: FeatureCollection = new FeatureCollection([]);
    let polygonsData = serverF.type === 'Polygon' ? [serverF.coordinates] : serverF.coordinates;
    for (let i = 0; i < polygonsData.length; i++) {
      let polyFeature: any = {
        geometry: {
          coordinates: polygonsData[i],
          type: 'Polygon',
        },
        properties: {},
        type: 'Feature',
      };
      poligonsFeatures.features.push(polyFeature);
    }
    return poligonsFeatures;
  };

  public mapZoom = (zoom: string) => {
    if (zoom === 'in') {
      this.map.flyTo({ zoom: this.map.getZoom() + 1 });
    } else {
      this.map.flyTo({ zoom: this.map.getZoom() - 1 });
    }
  };
}
