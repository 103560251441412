<div class="entity-form-container">
  <form [formGroup]="form">
    <!-- first row -->
    <div class="section-title">
      {{'general' | translate}}
    </div>
    <hr class="divider">
    <div class="row general-form-container">
      <div class="details-form-container col-md-6" fxLayout="column" fxLayoutAlign="start start">
        <!-- Identifier -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'identifier' | translate}}</span>
          <div class="col-md-8 form-field">
            <input class="text-input" formControlName="identifier" type="text"
              placeholder="{{'identifier' | translate}}">
            <gk-form-validation *ngIf="form.controls['identifier'].errors?.required" [validationText]="'fieldRequire'">
            </gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon"
              [hidden]="form.controls['identifier'].errors">
          </div>
        </div>
        <!-- Name -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'entityName' | translate}}</span>
          <div class="col-md-8 form-field">
            <input class="text-input" formControlName="name" type="text" placeholder="{{'name' | translate}}">
            <gk-form-validation *ngIf="form.controls['name'].errors?.required" [validationText]="'fieldRequire'">
            </gk-form-validation>
            <img src="assets/img/ic_installed@2x.png" class="validated-icon" [hidden]="form.controls['name'].errors">
          </div>
        </div>
        <!-- Description -->
        <div class="form-group" fxLayout="row" fxLayoutAlign="start start">
          <span class="input-label col-md-4" id="description-label">{{'description' | translate}}</span>
          <div class="col-md-8 form-field">
            <textarea class="text-area-input" formControlName="description" rows="14"></textarea>
          </div>
        </div>
      </div>
      <!-- Team select -->
      <div class="details-form-container col-md-6" fxLayout="column" fxLayoutAlign="start start">
        <gk-items-selector [mode]="2" style="width: 100%;" (onItemsSelected)="onSelectedTeams($event)"
          [preItems]="entity?.teams"></gk-items-selector>
        <gk-form-validation class="teams-validations" *ngIf="!selectedTeams.length" [validationText]="'teamRequired'">
        </gk-form-validation>
        <img src="assets/img/ic_installed@2x.png" class="teams-validated-icon" [hidden]="!selectedTeams.length">
      </div>
    </div>
    <!-- second row -->
    <div class="row general-form-container">
      <!-- picture -->
      <div class="details-form-container col-md-6">
        <div class="form-group" fxLayout="row" fxLayoutAlign="start center">
          <span class="input-label col-md-4">{{'picture' | translate}}</span>
          <div class="col-md-7 form-field">
            <div class="image-container" fxLayout="row" fxLayoutAlign="start center">
              <gk-image-uploader [image]="entity?.extras?.picture" (onImageSelected)="onImageSelected($event)"
                (onImageDeleted)="onImageDeleted()"></gk-image-uploader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- third row -->
    <div class="row general-form-container" *ngIf="entity?.type === CameraEntityType">
      <!-- picture -->
      <div class="details-form-container col-md-12">
        <div class="form-group" fxLayout="row" fxLayoutAlign="start start">
          <span class="input-label col-md-2">{{'location' | translate}}</span>
          <div class="col-md-10 form-field">
            <div class="map-selctor-container">
              <span
                class="selector-helper-text">{{(entity?.location && entity?.location?.latitude > 0 ? 'dragTheMarkerToEditCameraLocation' : 'clickOnTheMapToPlaceTheCamera') | translate}}</span>
              <div class="map-container">
                <gk-location-point-selector (onLocationSelected)="onLocationSelected($event)"
                  [locationData]="entity?.location"></gk-location-point-selector>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <hr class="divider">
  <div class="row" fxLayout="row" fxLayoutAlign="end center">
    <div class="changes-warning" *ngIf="formChanges">
      <mat-icon>warning</mat-icon>
      {{'unsavedChangesWarning' | translate}}
    </div>
    <button mat-raised-button class="form-button" (click)="cancel()">
      <svg-icon src="assets/img/svg/ic_cancel.svg" class="svg-icon"></svg-icon>
      {{'cancel' | translate}}
    </button>
    <button mat-raised-button class="form-button" [disabled]="form.status !== 'VALID'" (click)="updateIntegration()">
      <svg-icon src="assets/img/svg/ic_save_blue@3x.svg" class="svg-icon"></svg-icon>
      {{'save' | translate}}
    </button>
  </div>
</div>