<div class="autocomplete-container" *ngIf="options">
  <mat-form-field class="example-chip-list" (click)="autuocompleteClicked()">
    <mat-chip-list #chipList fxLayout="row">
      <!-- selectedItems -->
      <mat-chip *ngFor="let item of selectedItems" [selectable]="options.selectable" [removable]="options.removable" (removed)="remove(item)"
      fxLayout="row" fxLayoutAlign="center center">
        {{item.name}}
        <!-- <mat-icon matChipRemove *ngIf="options.removable">cancel</mat-icon> -->
        <svg-icon src="assets/img/svg/ic_cancel.svg" matChipRemove *ngIf="options.removable" class="delete-svg"></svg-icon>
      </mat-chip>
      <input placeholder="{{options.placeholder | translate}}..." #itemsInput [formControl]="itemsCtrl" [matAutocomplete]="auto"
        [matChipInputFor]="chipList" (matChipInputTokenEnd)="add($event)" id="itemsInput">
      <mat-icon class="search-icon">search</mat-icon>
    </mat-chip-list>
    <!-- Filtered Items -->
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let item of filteredItems | async" [value]="item._id">
        {{item.name}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
