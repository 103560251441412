import { Component, OnInit, Inject } from '@angular/core';
import {
  GkRecording,
  GkUiState,
  TableOptions,
  RecordingMeta,
  GkRecordingMeta,
  ColumnTemplete,
} from '../../../contracts/ui.contracts';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UsersService } from '../../pages/users/users.service';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { GkEntity, GkUser } from '../../../contracts/contracts';
import { LocalTimeFormatPipe } from '../../../shared/pipes/localTime.pipe';
import { TranslationService } from '../../../services/barrel-services';

@Component({
  selector: 'app-recording-session-modal',
  templateUrl: './recording-session-modal.component.html',
  styleUrls: ['./recording-session-modal.component.scss'],
})
export class RecordingSessionModalComponent implements OnInit {
  public recordingSession: RecordingMeta[];
  public tableOptions: TableOptions;
  public uiState: GkUiState;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private dialogRef: MatDialogRef<RecordingSessionModalComponent>,
    public dialog: MatDialog,
    private translationService: TranslationService,
    private loaderService: Ng4LoadingSpinnerService
  ) {
    this.usersService.initUsersDic();
  }

  ngOnInit() {
    this.recordingSession = this.getRecordingSessionMeta(this.data);
    this.initTable(this.recordingSession);
  }

  public initTable = (dataSource: RecordingMeta[]) => {
    this.tableOptions = new TableOptions();
    this.tableOptions.id = 'recordingSessionTable';
    this.tableOptions.pageSize =
      this.uiState && this.uiState.itemPerPage ? this.uiState.itemPerPage.recordingSessionTable : null;
    this.tableOptions.rowHoverClass = true;
    this.tableOptions.hideSearch = true;
    this.tableOptions.columns = [
      {
        name: 'entity.name',
        displayNameKey: 'name',
        width: '20',
      },
      {
        name: 'started',
        displayNameKey: 'startRecording',
        template: ColumnTemplete.Time,
        width: '17',
      },
      {
        name: 'stopped',
        displayNameKey: 'endRecording',
        template: ColumnTemplete.Time,
        width: '17',
      },
      {
        name: 'duration',
        displayNameKey: 'duration',
        template: ColumnTemplete.Duration,
        width: '17',
      },
      {
        name: 'size',
        displayNameKey: 'fileSize',
        template: ColumnTemplete.FileSize,
        width: '16',
      },
      {
        name: 'url',
        displayNameKey: '',
        width: '10',
        template: ColumnTemplete.recordingsTooltip,
        onClick: (e: any, recording: RecordingMeta) => {
          this.openRecordingModal(recording);
        },
      },
    ];

    if (dataSource) {
      this.tableOptions.dataSource = dataSource;
    }
    this.loaderService.hide();
  };

  public getRecordingSessionMeta = (data: any): RecordingMeta[] => {
    if (!data) return;
    let sessionParts: RecordingMeta[] = [];
    let meta = data.recording && data.recording.meta ? data.recording.meta : null;
    // For each entityId in meta
    // tslint:disable-next-line:forin
    for (let entityId in meta) {
      let entity: any = this.usersService.GetLocalUser(entityId);
      if (!entity) {
        entity = {
          name: this.translationService.getTranslation('unknownEntity'),
        };
      }
      let userSessionsObject = meta[entityId];
      for (const sessionId in userSessionsObject) {
        if (userSessionsObject[sessionId]) {
          userSessionsObject[sessionId].entity = entity;
          userSessionsObject[sessionId].sessionId = sessionId;
          userSessionsObject[sessionId].url = data.recordingsUrl + '/' + entityId + '-' + sessionId + '.mkv';
          userSessionsObject[sessionId].created = data.recording.started;

          sessionParts.push(userSessionsObject[sessionId]);
        }
      }
    }
    return sessionParts;
  };

  public openRecordingModal = (recording: any) => {
    let dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      url: recording.url,
      sessionId: recording.sessionId,
      entityName: recording.entity.name,
      entityImage: recording.entity.extras.picture,
      created: recording.created,
    };
    dialogConfig.panelClass = 'video-modal';
    this.dialog.open(VideoModalComponent, dialogConfig);
  };

  public close = () => {
    this.dialogRef.close();
  };
}
