<div class="teams-container">
  <div class="page-title" [ngSwitch]="mode">
    <span *ngSwitchCase="1">
      {{ 'addTeam' | translate }}
    </span>
    <span *ngSwitchCase="2">
      {{ 'updateTeam' | translate }}
    </span>
  </div>

  <gk-breadcrumb [options]="breadcrumbOptions"></gk-breadcrumb>

  <team-form [gkTeam]="team" *ngIf="!forbidden; else elseBlock"></team-form>
  <ng-template #elseBlock>
    <h2 class='forbidden-label'>
      {{ 'teamForbidden' | translate }}
    </h2>
  </ng-template>
</div>
