<div class="dashboard-container">
  <!-- First Row - organiztion info -->
  <div class="row" fxLayout="row" fxLayoutAlign="space-between center">
    <div
      class="title-card"
      fxLayout="row"
      fxLayoutAlign="space-between center"
      [ngStyle]="{ width: !IS_CLOUD ? '32%' : '66%' }"
    >
      <div class="left-box" fxLayout="row">
        <gk-image-uploader
          [image]="organization?.icon ? organization.icon : 'assets/img/desert@2x.png'"
          [isDashboard]="true"
          (onImageSelected)="updateOrganizationIcon($event)"
          class="organizition-logo"
          (onImageDeleted)="onImageDeleted()"
        ></gk-image-uploader>
        <div class="details" fxLayout="column" fxLayoutAlign="space-around start">
          <span class="name">{{ organization?.name }}</span>
          <input
            type="email"
            class="email-input"
            placeholder="support@globekeeper.com"
            [(ngModel)]="email"
            (focusout)="updateOrganizationEmail()"
          />
          <input
            type="phone"
            class="phone-input"
            placeholder="Enter phone number..."
            [(ngModel)]="phone"
            (focusout)="updateOrganizationPhone()"
          />
          <span class="date">
            {{ 'from' | translate }}
            <span class="created-placeholder content-placeholder" style="width: 130px" *ngIf="!organization?.created">
              &nbsp;
            </span>
            {{ organization?.created | localDateFormat }}
          </span>
        </div>
      </div>
    </div>
    <div
      *ngIf="licenses"
      (click)="downloadUsage()"
      class="download-usage d-flex justify-content-center align-items-center"
    >
      <img *ngIf="!preparingUsageData" src="assets/img/svg/download.svg" class="mr-2 svg-icon" />
      <span *ngIf='!preparingUsageData'>{{ 'downloadUsage' | translate }}</span>
      <div class="loader-container" *ngIf="preparingUsageData">
        <div class="loader"></div>
      </div>
    </div>
  </div>
  <hr class="divider" />
  <!-- Second Row - Counters -->
  <div class="row" fxLayout="row" fxLayoutAlign="space-between start">
    <div class="count-card" fxLayout="column" *ngFor="let counter of counters" [routerLink]="counter.url">
      <span class="title">{{ counter.name | translate }}</span>
      <span class="count">{{ counter.count }}</span>
      <a [routerLink]="counter.url" class="more-details">
        {{ 'clickForMoreDetails' | translate }}
      </a>
    </div>
  </div>

  <!-- Third row - licenses -->
  <div class="licenses-container">
    <div class="first-row">
      <div class="section-title">
        {{ 'licenses' | translate }}
      </div>

      <!-- License Warning Toggle-->
      <div
        class="slide-container"
        fxLayout="row"
        matTooltip="{{ 'licenseWarningToggleTooltip' | translate }}"
        matTooltipPosition="right"
      >
        <span class="input-label">{{ 'licensesUsageWarning' | translate }}</span>

        <div class="form-field" *ngIf="licenseWarningChecked">
          <input
            class="counter"
            matInput
            type="number"
            (change)="licenseCountChange($event)"
            [(ngModel)]="maxLicensesWarning"
          />
        </div>
        <div class="form-field">
          <mat-slide-toggle
            [checked]="licenseWarningChecked"
            (change)="licensesWarningToggle($event)"
          ></mat-slide-toggle>
        </div>
      </div>
    </div>
    <hr class="divider" />

    <div class="chart-container">
      <gk-line-chart [chartOptions]="lineChartOptions" (onWarningSelected)="licenseCountChange($event)"></gk-line-chart>
    </div>
  </div>
</div>
