<div class="invitation-modal-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="d-flex justify-content-end"></div>

  <div class="text-left w-100">
    <div class="d-flex justify-content-between">
      <div class="title">{{ 'inviteMembersTo' | translate }} {{ organizationName }}</div>
      <svg-icon
        src="assets/img/svg/ic_cancele@3x.svg"
        class="svg-icon close-invitation-modal align-self-end"
        mat-dialog-close="true"
        (click)="dialogRef.close()"
      ></svg-icon>
    </div>
    <span class="helper-text">{{ 'invitationModalHelper' | translate }}</span>
  </div>

  <div class="w-100 divider" fxLayout="row" fxLayoutAlign="start">
    <div class="user-type-toggle" fxLayout="row" fxLayoutAlign="start start">
      <button
        (click)="selectedEntityType = EntityTypes.USER"
        [ngClass]="{ 'active-button': selectedEntityType === EntityTypes.USER }"
      >
        <span class="text">{{ 'professional' | translate }}</span>
      </button>
      <button
        (click)="selectedEntityType = EntityTypes.VOLUNTEER"
        [ngClass]="{ 'active-button': selectedEntityType === EntityTypes.VOLUNTEER }"
      >
        <span class="text">{{ 'volunteer' | translate }}</span>
      </button>
    </div>
  </div>

  <div class="invited-users d-flex flex-column w-100 divider">
    <div
      class="d-flex justify-content-between pt-2 pb-2"
      *ngFor="let invite of invitations; let index = index; trackBy: trackByIndex"
    >
      <div class="d-flex flex-column mr-3">
        <label [htmlFor]="'invitation-email-' + index"> {{ 'emailAddress' | translate }} </label>
        <input
          placeholder="name@example.com"
          type="email"
          [attr.id]="'invitation-email-' + index"
          [ngClass]="{ error: invitations[index]?.errors?.email }"
          [(ngModel)]="invitations[index].email"
          [ngModelOptions]="{ standalone: true }"
          (focusout)="onInputTyped($event, index)"
        />
      </div>

      <div class="d-flex flex-column mr-3">
        <label [htmlFor]="'invitation-fullName-' + index"> {{ 'fullName' | translate }} </label>
        <input
          type="text"
          placeholder="{{ 'optional' | translate }}"
          [attr.id]="'invitation-fullName-' + index"
          [ngClass]="{ error: invitations[index]?.errors?.name }"
          [(ngModel)]="invitations[index].fullName"
          (focusout)="onInputTyped($event, index)"
        />
      </div>

      <div class="d-flex flex-column">
        <span class="monitor-permissions-title">{{ 'monitorPermissions' | translate }}</span>
        <label [htmlFor]="'invitation-monitor-' + index" class="monitor-checkbox">
          <input [attr.id]="'invitation-monitor-' + index" type="checkbox" [(ngModel)]="invitations[index].monitor" />
          <span class="label"></span>
        </label>
      </div>

      <div class="d-flex flex-column">
        <svg-icon src="assets/img/svg/ic_cancele@3x.svg" (click)="deleteInput(index)" class="pt-4 svg-icon"></svg-icon>
      </div>
    </div>
    <div class="add-member">
      <svg-icon src="assets/img/svg/ic_add_installed@3x.svg" class="add-svg" (click)="addInvitation()"></svg-icon>
      <span class="text" (click)="addInvitation()">{{ 'addAnotherMember' | translate }}</span>
    </div>
  </div>

  <div class="teams-selection">
    <span class="sub-heading">{{ 'newMemberTeams' | translate }}</span>
    <gk-autocomplete [autoOptions]="autocompleteOptions" (onItemsSelected)="teamsSelected($event)"></gk-autocomplete>
  </div>

  <div class="submit-container d-flex justify-content-between">
    <div class="invitation-availability d-flex align-items-center">
      <svg-icon src="assets/img/svg/clock.svg" class="mr-2"></svg-icon>
      {{ 'invitationAvailability' | translate }}
    </div>
    <div class="loader-container" *ngIf="loader">
      <div class="loader"></div>
    </div>
    <div
      *ngIf="!loader"
      (click)="sendInvitations()"
      class="submit-button d-flex justify-content-center align-items-center"
      [ngClass]="{ disabled: invitationsError || !invitations[0]?.email }"
    >
      <svg-icon src="assets/img/svg/ic_invitation_fill.svg" class="submit-icon mr-2"></svg-icon>
      <div
        class="text"
        matTooltipPosition="right"
        matTooltip="{{ (invitationsError || !invitations[0]?.email ? 'pleaseFillTheRequiredFields' : '') | translate }}"
      >
        {{ 'sendInvitations' | translate }}
      </div>
    </div>
  </div>

  <div class="errors-container justify-content-center" *ngIf="invitationsError">
    {{ invitationsError | translate }}
  </div>
</div>
