import { AutocompleteOptions } from './../../contracts/ui.contracts';
import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'gk-autocomplete',
  templateUrl: './gk-autocomplete.component.html',
  styleUrls: ['./gk-autocomplete.component.scss']
})
export class GkAutocompleteComponent {
  @Input()
  set autoOptions(value: any) {
    if (value) {
      this.options = value;
      this.allItems = this.options.allItems;
      this.checkForDefaultValues();
    }
  }

  @Input() selectionLimit: number;
  @Output() onItemsSelected = new EventEmitter<string[]>();

  public options: AutocompleteOptions;
  public itemsCtrl = new FormControl();
  public filteredItems;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public visible = true;
  public selectable = true;
  public removable = true;
  public addOnBlur = false;
  public selectedItems: any[] = [];
  public allItems: any[];

  @ViewChild('itemsInput', {static: false}) itemsInput: ElementRef;

  constructor() {
    this.filteredItems = this.itemsCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filter(item) : this.allItems && this.allItems.slice()))
    );
  }

  // add vs select duplication update call
  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    // Add our item
    if (value) {
      let item = this.findItem(value, 'name');
      if (!item) return;
      this.updateLists(item, true);
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.itemsCtrl.setValue(null);
  }

  // Remove item from selectedItems
  public remove(item: any): void {
    this.updateLists(item, false);
  }

  // On Item Selected
  public selected(event: MatAutocompleteSelectedEvent): void {
    let item = this.findItem(event.option.value, '_id');
    // return if cannot find item with this name
    // can couse by hitting enter key without select team
    if (!item) return;
    if (this.selectionLimit === 1 && this.selectedItems.length === 1) {
      this.updateLists(this.selectedItems[0], false);
    }
    this.updateLists(item, true);
    this.itemsInput.nativeElement.value = '';
    this.itemsCtrl.setValue(null);
    this.itemsInput.nativeElement.blur();
  }

  // Search filter function
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    // Filter by _id
    return this.allItems.filter((item: any) => item.name.toLowerCase().indexOf(filterValue) === 0);
  }

  // utils
  // Find item by serach value and search property
  // return Item from allItems list
  private findItem = (searchValue: string, searchProperty: string) => {
    let item = this.allItems.find((iteratedItem: any) => {
      return iteratedItem[searchProperty] === searchValue;
    });
    return item;
  };

  // Get selected Ids from selectedItems array
  private getSelectedIds = () => {
    let selectedIds: string[] = [];
    for (let i = 0; i < this.selectedItems.length; i++) {
      selectedIds.push(this.selectedItems[i]._id);
    }
    return selectedIds;
  };

  // check options for default values, assign if neccecery
  private checkForDefaultValues = () => {
    this.selectedItems = [];
    if (this.options && this.options.defaultItems) {
      this.updateLists(this.options.defaultItems[0], true);
    }
  };

  // update lists after every change
  private updateLists = (item: any, selected: boolean) => {
    if (selected) {
      // find item index in allItems Array
      let index = this.allItems.findIndex((iteratedItem: any) => {
        return iteratedItem._id === item._id;
      });
      // remove item from allItems list and add it to selectedItems list
      this.allItems.splice(index, 1);
      this.selectedItems.push(item);
    } else {
      // find item index in allItems Array
      let index = this.selectedItems.findIndex((iteratedItem: any) => {
        return iteratedItem._id === item._id;
      });
      // remove item from allItems list and add it to selectedItems list
      this.selectedItems.splice(index, 1);
      this.allItems.push(item);
    }
    // emit to parent
    this.onItemsSelected.emit(this.getSelectedIds());
  };

  autuocompleteClicked() {
    document.getElementById('itemsInput').focus();
  }
}
