import { GkTeam } from '../../../../contracts/contracts';
import { TeamsService } from '../teams.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadCrumbOptions, GkMode } from '../../../../contracts/ui.contracts';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'team',
  templateUrl: 'team.component.html',
  styleUrls: ['team.component.scss'],
})
export class TeamComponent implements OnInit {
  public teams: GkTeam[];
  public breadcrumbOptions: BreadCrumbOptions;
  public mode: GkMode;
  public team: GkTeam;
  public subscriptions: any[] = [];
  public forbidden: boolean = false;

  constructor(private route: ActivatedRoute, private teamsService: TeamsService) {
    this.init();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      for (let i = 0; i < this.subscriptions.length; i++) {
        this.subscriptions[i].unsubscribe();
      }
    }
  }

  private init = () => {
    this.subscriptions.push(
      this.route.params.subscribe((params: any) => {
        this.mode = params.teamId ? GkMode.Update : GkMode.Add;
        this.breadcrumbOptions = {
          path: [
            {titleKey: 'teams', url: '/../teams-list'},
            {
              titleKey: this.mode === GkMode.Add ? 'addTeam' : 'updateTeam',
              url: this.mode === GkMode.Add ? '../team' : '../../team/' + params.teamId,
            },
          ],
        };
        if (this.mode === GkMode.Update) {
          this.teamsService.GetTeam(params.teamId).subscribe((team: GkTeam) => {
              this.team = team;
              this.breadcrumbOptions.itemId = team.name;
            },
            (error: HttpErrorResponse) => {
              if (error.status === 403) {
                this.forbidden = true;
              }
            });
        }
      })
    );
  };
}
