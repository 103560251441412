<div class="pin-modal-container">
  <mat-dialog-content>
    <span class="text">
      {{ 'pleaseEnterYourPinCode' | translate }}
    </span>

    <gk-pin-selector (onPinEntered)="pinEntered($event)" style="width: 100%;"></gk-pin-selector>

    <span class="helper-text">
      {{'qrHelperTextFirst' | translate}}
      <br> {{'qrHelperTextSecond' | translate }}
    </span>

  </mat-dialog-content>
</div>
