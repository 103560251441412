import { Observable } from 'rxjs';
import { httpMethod, GkTeam } from './../../../contracts/contracts';
import { Injectable, OnInit } from '@angular/core';
import { WebApiService } from '../../../services/barrel-services';
import { Store } from '@ngrx/store';
import { Istore } from '../../../shared/store/store.module';
import 'rxjs/add/operator/map';
import { Constants } from '../../../shared/constant';
import { Config } from 'app/shared/config';
@Injectable()
export class TeamsService {
  constructor(private webApi: WebApiService, private store: Store<Istore>) {}
  public teamsDictionry: any = {};
  public teams: GkTeam[] = [];
  public getTeams = () => {
    this.webApi
      .makeRequest(Constants.Services.Teams, null, httpMethod.Get)
      .map((teams: any) => {
        return { type: 'SET_TEAMS', payload: teams };
      })
      .subscribe((action) => {
        this.store.dispatch(action);
        this.initTeamsService();
      });
  };

  public SetTeam = (): Observable<GkTeam> => {
    return this.webApi.makeRequest(Constants.Services.Teams, null, httpMethod.Post);
  };

  public GetTeam = (teamId: string): Observable<GkTeam> => {
    return this.webApi.makeRequest(Constants.Services.Teams + '/' + teamId, null, httpMethod.Get);
  };

  public GetLocalTeam = (teamId: string): GkTeam => {
    return this.teams[teamId];
  }

  public DeleteTeam = (teamId: string) => {
    return this.webApi.makeRequest(Constants.Services.Teams + '/' + teamId, null, httpMethod.Delete);
  };

  public CreateTeam = (team: any): Observable<GkTeam> => {
    return this.webApi.makeRequest(Constants.Services.Teams, team, httpMethod.Post);
  };

  public UpdateTeam = (team: any, teamId: string): Observable<GkTeam> => {
    return this.webApi.makeRequest(Constants.Services.Teams + '/' + teamId, team, httpMethod.Put);
  };

  public initTeamsService = () => {
    this.store.select('teams').subscribe((teams: any) => {
      if (teams.length) {
        this.teamsDictionry = {};
        for (let i = 0; i < teams.length; i++) {
          // Support in case of server will deprecate enable property
          if (!teams[i].hasOwnProperty('enabled')) {
            teams[i].enabled = !((Config.TeamModes.DISABLED & teams[i].mode) !== 0);
          }
          this.teamsDictionry[teams[i].name] = 1;
          this.teams[teams[i]._id] = teams[i];
        }
      }
    });
  };

  public checkTeamNameAvailable = (teamName: string): boolean => {
    if (this.teamsDictionry && this.teamsDictionry[teamName]) return false;
    return true;
  };

  public notifyTeamsExport = () => {
    return this.webApi.makeRequest(Constants.Services.Teams + '/export', null, httpMethod.Get);
  };
}
